import React from 'react';
import { Props } from './interface';
import './FormGroup.scss'

const FormGroup = (props: Props) => {
  const getClassName = () => {
    let className = 'form__group';
    if (props.isColumn) className += ' form__group_column';
    if (props.className) className += ` ${props.className}`;

    return className;
  };

  return (
    <div className={getClassName()} {...props}>
      {props.children}
    </div>
  );
};

export default FormGroup;
