import React, { Component } from 'react';
import { Props, State } from './interface';
import bind from '../../../lib/decorators/bind';
import withApollo from '../../../lib/HOC/withApollo';

import deepEqual from 'deep-equal';
import queryString from 'query-string';
import Page from '../../../components/Page';
import Grid from '../../../components/GridList';
import Wrapper from '../../../components/Wrapper';
import TabSlider from '../../../components/TabSlider';
import Typography from '../../../components/Typography';
import ArticleCard from '../../../components/ArticleCard';
import SectionTitle from '../../../components/SectionTitle';
import getTypographyProps from '../../../lib/getTypographyProps';

import { MainRoutes } from '../../../components/Router/config/routes';

import News, { TNews } from '../../../gql/actions/News';
import { IMapQuery } from '../../../types/app';
import './ArticlesPage.scss';
import MetaTags from '../../../components/MetaTags';

const isArticlesPage = (location: any) =>
  location.pathname === MainRoutes.articlesPage.path;

const LIMIT = 10;

class ArticlesPage extends Component<Props, State> {
  state: State = this.getInitialState();

  @bind
  getInitialState(): State {
    const { data, total } = this.props.news;

    try {
      return {
        items: data || [],
        total: total || 0,
      };
    } catch (error) {
      return {
        items: [],
        total: 0,
      };
    }
  }

  componentDidMount() {
    this.updateAppolo(true);
  }

  componentDidUpdate(prevProps: Props) {
    this.isChangedItems = false;
    const prevQuery = queryString.parse(prevProps.location.search) || 1;
    const currentQuery = queryString.parse(this.props.location.search);

    const isChangedPage = prevQuery.page !== currentQuery.page;
    const isChangedQuery = prevQuery.query !== currentQuery.query;

    const isEqualProps = deepEqual(this.props.news.data, prevProps.news.data);

    if (this.props.news.offset !== prevProps.news.offset) {
      let prevItems = this.state.items;

      if (this.props.news.total !== prevProps.news.total) {
        prevItems = [];
      }

      if (!currentQuery.page) {
        prevItems = [];
      }

      this.setState({
        total: this.props.news.total,
        items: [...prevItems, ...this.props.news.data],
      });
    } else if (!isEqualProps) {
      this.isChangedItems = true;
      this.setState({
        total: this.props.news.total,
        items: this.props.news.data,
      });
    }

    if (isChangedPage || isChangedQuery) {
      this.updateAppolo();
    }
  }

  @bind
  updateAppolo(withoutRerender?: boolean) {
    const currentQuery = queryString.parse(this.props.location.search);
    const isActicles = isArticlesPage(this.props.location);

    const page = currentQuery.page ? Number(currentQuery.page) : 1;
    const type: TNews = isActicles ? 'articles' : 'stories';

    const filters: any = { page, LIMIT };
    if (this.tabIndex !== 0) {
      filters.tags = [this.getTags()[this.tabIndex]];
    }

    const requests: IMapQuery = () => ({
      news: News.get(type, filters),
      tags: News.getTags(type),
    });

    this.props.updateApollo(requests, withoutRerender);
  }

  @bind
  onChangeTab(index: number) {
    this.props.history.push(this.props.location.pathname);
    this.tabIndex = index;
    this.updateAppolo();
  }

  @bind
  getTabs() {
    const tags = this.getTags();
    return tags.map((a, key) => <div key={key}>{a}</div>);
  }

  @bind
  getTags() {
    const { data } = this.props.tags;
    return ['Все', ...data];
  }

  @bind
  renderContent() {
    const { items, total } = this.state;
    const tags = this.getTags();

    if (!items.length) return [];

    const maxPage = Math.ceil((total || LIMIT) / LIMIT);
    const isArticles = isArticlesPage(this.props.location);

    const content = tags.map((a, key) => {
      const data = items.map((b, j) => (
        <ArticleCard
          key={`${key}-${j}`}
          type={isArticles ? 'article' : 'news'}
          {...b}
        />
      ));

      return (
        <Grid
          key={`${key}-grid`}
          isChangedItems={this.isChangedItems}
          items={data}
          totalPages={maxPage}
          type='articles'
        />
      );
    });

    return content;
  }

  getTitle() {
    const { location } = this.props;
    const isArticles = isArticlesPage(location);

    const currentPage = isArticles ? 'Полезные статьи' : 'Новости';
    const additionalPage = isArticles ? 'Новости' : 'Полезные статьи';
    const to = isArticles
      ? MainRoutes.newsPage.path
      : MainRoutes.articlesPage.path;

    return (
      <React.Fragment>
        <Typography
          style={{ display: 'inline' }}
          {...getTypographyProps('heading', '2xl', 'dark-text', 'medium')}
        >
          {currentPage}
        </Typography>
        <Typography
          as='span'
          style={{ display: 'inline' }}
          {...getTypographyProps('heading', '2xl', 'dark-text', 'medium')}
        >
          {' / '}
        </Typography>
        <Typography
          style={{ display: 'inline' }}
          {...getTypographyProps('text', 'xl', 'main', 'regular')}
          children={additionalPage}
          to={to}
        />
      </React.Fragment>
    );
  }

  @bind
  renderPreload() {
    const data = new Array(12).fill({});

    const items = data.map((b, j) => (
      <ArticleCard key={`${j}`} isPreload type='article' {...b} />
    ));

    return (
      <Page page='articles'>
        <MetaTags canonical={this.props.match.url} />
        <Wrapper isPageWrapper>
          <div className={this.classNames.preload}>
            <div className={this.classNames.title} />
            <div className={this.classNames.tabs} />
            <Grid items={items} type='articles' />
          </div>
        </Wrapper>
      </Page>
    );
  }

  classNames = {
    preload: 'page_preload',
    title: 'page__title',
    tabs: 'page__tabs',
  };

  tabIndex = 0;

  isChangedItems: boolean = false;

  render() {
    const { loading } = this.props.tags;
    if (loading) return this.renderPreload();
    return (
      <Page page='articles'>
        <Wrapper className='wrapper_withTabs' isPageWrapper>
          <MetaTags 
            canonical={this.props.match.url} 
            title={isArticlesPage(this.props.location) ? 'Полезные статьи для осужденных и их родственников' : 'Новости для осужденных и их родственников'}
            description={isArticlesPage(this.props.location) ? 'Полезные статьи для осужденных и их родственников' : 'Новости для осужденных и их родственников'}
          />
          <div className='page__title'>{this.getTitle()}</div>
          <TabSlider
            className={this.classNames.tabs}
            content={this.renderContent()}
            direction='horizontal'
            onChangeTab={this.onChangeTab}
            startIndex={this.tabIndex}
            tabs={this.getTabs()}
          />
        </Wrapper>
      </Page>
    );
  }
}

function mapQuery(props: Props) {
  const isArticles = isArticlesPage(props.location);
  const type: TNews = isArticles ? 'articles' : 'stories';
  const currentQuery = queryString.parse(props.location.search);
  const page = currentQuery.page ? Number(currentQuery.page) : 1;

  const filters: any = { LIMIT: LIMIT * page, page: 1 };
  return {
    news: News.get(type, filters),
    tags: News.getTags(type),
  };
}

export default withApollo(mapQuery, undefined)(ArticlesPage);
