import bind from '../../lib/decorators/bind';
import React, { Component } from 'react';
import { Props, State } from './interface';
import cloneDeep  from 'lodash/cloneDeep';
import sortBy  from 'lodash/sortBy';
import Collapse from '../Collapse';

import News from '../../gql/actions/News';
import { connect } from 'react-redux';
import withApollo from '../../lib/HOC/withApollo';
import Notification from '../../components/Notification';

import Svg from '../Svg';
import Typography from '../Typography';
import getTypographyProps from '../../lib/getTypographyProps';
import Comment from '../Comment/Comment'

import FormComment from './FormComments/FormComment';
import { createComment, changeRating } from '../../gql/actions/Mutations';
import arrow from '../../images/arrow-down.svg';

import './Comments.scss';
import { IGlobalState } from '../../types/redux';
import { IComments, IComment } from './../../types/app';

class ReactComments extends Component<Props, State> {
  state: State = {
    comments: null,
    sortToggle: true,
    inputCommentContent:"",
    inputContent:"",
    viewComments: 3,
    position: null,  
    showMoreMessage : false,
    portionComments : 20
  };

  componentDidMount() {
    if (window && window.innerWidth < 600 && this.state.portionComments !==  10) {
      this.setState({
        portionComments: 10
      })
    }
    window && window.addEventListener('resize', () => 
    {
      if (window.innerWidth < 600) {
        this.setState({
          portionComments: 10
        })
      }
      else{
        this.setState({
          portionComments: 20
        })
      }
    }
    );
  }

  componentDidUpdate(prevProps: Props, prevState: State){
    if (this.state.position !== null &&  this.state.showMoreMessage) {
          window.scrollTo(0, this.state.position)
          this.setState({
            showMoreMessage: false
          })    
    }
    else if (prevState.position === null &&  this.state.showMoreMessage && this.state.position) {
      window.scrollTo(0, this.state.position)
      this.setState({
        showMoreMessage: false
      })
    }
    return null
  }

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    if (nextProps.comments.comments) {
      if ( prevState.comments === null ) {
        return {
          comments: nextProps.comments.comments,
      }
      }
    } else if(nextProps.comments.comments === null) {
      if ( prevState.comments === null ) {
        return {
          comments:[],
      }
      }
    }
    return null;
  }

  @bind
  onAddName(item: IComment){
    if (item && this.state.inputContent === "") {
      this.setState({
        inputContent:item.author_name + ", "
      })
    }
  }

  @bind
  onAddEmoji( emoji: any, replies: boolean){
    this.setState({
      inputContent:replies ? this.state.inputContent + emoji.emoji : '',
      inputCommentContent: !replies ? this.state.inputCommentContent + emoji.emoji : ''
    })
  }

  @bind
  onAddRating(comment_id: string, change: string, parent_id: boolean ){
    changeRating({ comment_id: comment_id, change: change }, (res:any,status:any) => {
      const copyDeep = cloneDeep(this.state.comments);
      const found = this.searchRecursive( copyDeep, comment_id);
      if (res.errors && res.errors[0]) {
              Notification.show(res.errors[0].message);
      } else {
      if (this.props.account && this.props.account.userInfo) {
        found.Comment.rating = res.data.rateComment.value;
        this.setState({
      comments:[ ...copyDeep]
        })   
      }
    }
    });
  }
    
searchRecursive(data: any, id: string, newCommentData?: any) {
  let found = data.find((d: IComments, q: number) => d.Comment.comment_id === id);
  if (!found) {
    let i = 0;
    while(!found && i < data.length) {
      if (data[i].Replies  && data[i].Replies.length) {
        found = this.searchRecursive(data[i].Replies, id);
      }
      i++;
    }
  }
  return found;
}

@bind
onAddCommmentFormInput(e: any, replies: boolean){

this.setState({
  inputContent: replies ? e.target.value : '',
  inputCommentContent: !replies ? e.target.value : ''
})
}

@bind
onKeypress(e: any, content: any, parent_id?: boolean){
  if (e.keyCode === 13 && e.ctrlKey) {
    e.preventDefault();
    e.stopPropagation();
    if (!parent_id) {
      this.setState((prevState: State)=> ({ inputCommentContent: prevState.inputCommentContent+'\n' }))
    } else {
      this.setState((prevState: State) => ({ inputContent: prevState.inputContent+'\n' }))
    }
  }else if (e.keyCode === 13) { 
    e.preventDefault();
    this.commentSubmit(e, !parent_id ? this.state.inputCommentContent : this.state.inputContent )
  }
}

@bind
commentSubmit(e:any, inputContent:any, parent_id?: string){
  e.preventDefault();
  createComment({content:inputContent.replace(/\n/g,'</br>'), resource_id: this.props.uid, parent_id: parent_id }, (res:any,status:any) => {
    if (res.errors && res.errors[0]) {
      Notification.show(res.errors[0].message);
    } else {
    if (this.props.account && this.props.account.userInfo) {
    !parent_id ? this.commentSave(res): this.repliesSave(this.state.comments, parent_id ,res);
    Notification.show('Сообщение успешно отправлено');
    }
  }
  });
  this.setState({inputContent:'',inputCommentContent:''})
}

CommentsSortToggle(){
this.setState({
  sortToggle: !this.state.sortToggle,
  comments: this.state.sortToggle ?  sortBy(this.state.comments, 'Comment.rating' ) : sortBy(this.state.comments, 'Comment.rating' ).reverse()
})
}

commentSave( data:any ){
  if (this.props.account && this.props.account.userInfo) {
    this.setState({
      comments: [{Comment:{ author_id: this.props.account.userInfo.user_id,
        author_name: `${this.props.account.userInfo.firstname}  ${this.props.account.userInfo.lastname}` ,
        rating: 0, ...data.data.addComment }, Replies:[]}, ...this.state.comments]
    })   
  }
}

repliesSave( data:any, id: any, res: any ){
  const copyDeep = cloneDeep(this.state.comments);

  const newCommentDatas =this.props.account && this.props.account.userInfo && {
    Comment:{ author_id: this.props.account.userInfo.user_id,
      author_name: `${this.props.account.userInfo.firstname}  ${this.props.account.userInfo.lastname}` ,
      rating: 0, ...res.data.addComment}, Replies:[]}

  const found = this.searchRecursive( copyDeep, id, newCommentDatas)
  found.Replies = found.Replies !== null && found.Replies !== [] ? [{...newCommentDatas}, ...found.Replies] : [{...newCommentDatas}] ;

  if (this.props.account && this.props.account.userInfo) {
    this.setState({
  comments:[ ...copyDeep]
    })   
  }
}

showMoreCommentsHandler(e: any){
  const comments = this.state.comments;
  if (comments) {
    if (this.state.viewComments < comments.length) {
      let low = false
      const diff = comments.length - this.state.viewComments
      if (diff <= this.state.portionComments && diff !== 0) {
        low = true
        }
      this.setState({
        viewComments : this.state.viewComments + ( low ? diff : this.state.portionComments)
      })
    }
    
      this.setState({
        position: e.target.offsetTop,
        showMoreMessage: true
      })
    
  }
}

renderCommentsHeader(collapse: boolean){
  const comments = this.state.comments;
  return (
    <div className="comments__header">
      {!collapse && <Typography
        className={"comments__header_text"}
        {...getTypographyProps('text', 'xl', 'dark-text')}
      >
        {'Комментарии'}
        <span className="comments__header_value">
          {comments && comments.length}
        </span>
      </Typography>}
      {comments && comments.length > 3 && <button
        className={`typography typography_type_text typography_size_s typography_color_dark-text typography_clickable comments__sort_button ${collapse ? 'stick_to_right' : ''} `}
        onClick={() => this.CommentsSortToggle()}
        >
          по популярности
          <img className={`comments__sort_icon ${this.state.sortToggle ? 'rotate' : ''}`} src={ arrow } />
      </button>}
    </div>
  )
}

renderComment(){
  const comments = this.state.comments;
  return comments && comments.slice(0, this.state.viewComments).map((item: IComments, i: number) => {
  return (
    <Comment
      className="comments_item"
      key={item.Comment.comment_id}
      item={item}
      account={this.props.account}
      onAddName={this.onAddName}
      onAddCommmentFormInput={this.onAddCommmentFormInput}
      onAddEmoji={this.onAddEmoji}
      inputContent={this.state.inputContent}
      commentSubmit={this.commentSubmit}
      onAddRating={this.onAddRating}
      onKeypress={this.onKeypress}
    />
  )
})
}

  renderComments(collapse: boolean) {
    const comments = this.state.comments
    const loading = this.props.comments.loading
    return (
      <div className = "comments">
        {!collapse && window && window.innerWidth < 600 && <hr className = "comments__header_line"></hr>}
        {this.renderCommentsHeader(collapse)}
        <FormComment
          onAddCommmentFormInput={this.onAddCommmentFormInput}
          onAddName={this.onAddName}
          onAddEmoji={this.onAddEmoji}
          inputContent={this.state.inputCommentContent}
          commentSubmit={this.commentSubmit}
          account={this.props.account}
          onKeypress={this.onKeypress}
          />
          
        {(comments === null && loading) && 
          <div className="comments__lds lds-ellipsis"><div></div><div></div><div></div><div></div></div>
        }
        <ul className="comments__list">
          {comments !== null  && this.renderComment()}
        </ul>
        {comments && comments !== null && this.state.viewComments !== comments.length && comments.length > 3
          &&
          <button
            className="comments__show_more"
            onClick={(e) => this.showMoreCommentsHandler(e)}
          >
            {` Еще ${comments.length - this.state.viewComments < this.state.portionComments ? '' : this.state.portionComments} комментариев`}
          </button>
        }
      </div>
      );
  }

  render() {
    const comments = this.state.comments;
    if (this.props.collapse) {
      return (
        <Collapse
        children={this.renderComments(true)}
        title={`Комментарии ${comments && comments.length > 0 ?  comments.length : ''}`}
      /> 
      )
    }
    return this.renderComments(false)
  }
}

function mapQuery(this: any, props: Props) {
  return {
    comments: News.getComments(props.uid)
  };
}

function mapState(state: IGlobalState) {
  return {
    account:state.account,
  }
}

export default connect(mapState)(withApollo(mapQuery, undefined)(ReactComments));
