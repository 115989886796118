import React, { Component } from 'react'
import Button from '../../../components/Button';
import Page from '../../../components/Page';
import StatusBar from '../../../components/StatusBar';
import Typography from '../../../components/Typography';
import Wrapper from '../../../components/Wrapper';
import Foto from '../../../gql/actions/Foto';
import Relations from '../../../gql/actions/Relations';
import getTypographyProps from '../../../lib/getTypographyProps';
import withApollo from '../../../lib/HOC/withApollo';
import { FOTO_HTTP_END_POINT } from '../../../lib/request/config';
import { Props } from './interface'
import './OrdersPage.scss'
import Notification from '../../../components/Notification';
import MetaTags from '../../../components/MetaTags';

class OrdersPage extends Component<Props>{

  formattingDate(date: string) {
    const obj = new Date(date);
    const day = ('0' + obj.getDate()).slice(-2);
    const month = ('0' + (obj.getMonth() + 1)).slice(-2);
    const year = obj.getFullYear();
    const hour = ('0' + obj.getHours()).slice(-2);
    const minute = ('0' + obj.getMinutes()).slice(-2);
    return `${day}.${month}.${year} ${hour}:${minute} (МСК)`;
  }

  redirectToPayment() {
    const { orderInfo } = this.props
    const { uploadId } = orderInfo.data
    let query = Foto.getConfirmationUrl(uploadId)

    fetch(FOTO_HTTP_END_POINT, {
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      body: JSON.stringify({
        opertionName: 'confirmationUrl',
        variables: {},
        query: query.query.loc.source.body
      })
    }).then(a => a.json())
      .then(response => {
        if (response.data.confirmationUrl) {
          const link = document.createElement('a');
          link.href = response.data.confirmationUrl;
          link.click();
          link.remove();
        } else {
          Notification.show('Возникла проблема при получении ссылки на оплату')
        }
      }).catch(e => {
        Notification.show('Возникла проблема при запросе на получение ссылки оплаты')
      })
  }

  renderContent() {
    const { orderInfo } = this.props
    const created: any = orderInfo.data.statusCollection.data.find((item) => item.id === 'created')
    const paid: any = orderInfo.data.statusCollection.data.find((item) => item.id === 'paid')
    const createdDate = this.formattingDate(created.date)
    console.log(FOTO_HTTP_END_POINT);
    return (
      <div>
        <div className="page__box">
          <Typography
            {...getTypographyProps('heading', 'xl', 'dark-text')}
            children={`Заказ №${orderInfo.data.externalId}`}
          />
          <Typography
            {...getTypographyProps('text', 's', 'light-text')}
            children={createdDate}
          />
        </div>

        <StatusBar className="page__status-bar" collection={orderInfo.data.statusCollection.data} />

        <div className="page__box">
          <Typography
            {...getTypographyProps('heading', 'm', 'text')}
            children='Куда'
          />
          <Typography
            {...getTypographyProps('text', 'm', 'text')}
            children={orderInfo.data.colony.fullName}
          />
        </div>
        <div className="page__box">
          <Typography
            {...getTypographyProps('heading', 'm', 'text')}
            children='Кому'
          />
          <Typography
            {...getTypographyProps('text', 'm', 'text')}
            children={orderInfo.data.recipient}
          />
        </div>
        <div className="page__box">
          <Typography
            {...getTypographyProps('heading', 'm', 'text')}
            children='Стоимость'
          />

          {orderInfo.data.cart.items.map((item: any, index) => (
            <div key={index}>
              {item.name === 'Фото 10х15'
                ? <div className='page__row'>
                  <Typography
                    {...getTypographyProps('text', 'm', 'text')}
                    children={this.photoCost(item.qty)}
                  />
                  <Typography
                    {...getTypographyProps('text', 'm', 'text')}
                    children={item.amount + ' \u20BD'}
                  />
                </div>
                : <div className='page__row'>
                  <Typography
                    {...getTypographyProps('text', 'm', 'text')}
                    children={`Сообщение`}
                  />
                  <Typography
                    {...getTypographyProps('text', 'm', 'text')}
                    children={item.amount + ' \u20BD'}
                  />
                </div>}
            </div>
          ))}
        </div>
        <div className='page__row page__box last'>
          <Typography
            {...getTypographyProps('heading', 'xl', 'text')}
            children='Итого'
          />
          <Typography
            {...getTypographyProps('heading', 'xl', 'text')}
            children={orderInfo.data.cart.amount}
          />
        </div>
        {!paid && <Button isFullWidth isDarkGreen label="Оплатить" onClick={() => this.redirectToPayment()} />}
      </div>
    )
  }

  photoCost(qty: any) {
    const cases = ['фотографии', 'фотографий', 'фотография']
    return qty > 1 && qty < 5
      ? (qty + ' ' + cases[0])
      : (qty >= 5 ? (qty + ' ' + cases[1]) : (qty + ' ' + cases[2]))
  }

  toMain() {
    const { history } = this.props
    history.push('/')
  }

  renderError() {
    console.log(FOTO_HTTP_END_POINT);
    return (
      <div className='page__center'>
        <svg className="icon" width="159" height="110" viewBox="0 0 159 110" fill="none" xmlns="http://www.w3.org/2000/svg">
          <ellipse cx="79.5" cy="63.5" rx="79.5" ry="46.5" fill="#F0F3FA" />
          <path d="M123 25V65L80 90V50L123 25Z" fill="#3399FF" />
          <path d="M80 50V90L37 65V25L80 50Z" fill="#1A8CFF" />
          <path d="M80 50L123 25L80 0V50Z" fill="#1470CC" />
          <path d="M80 49.9999V0L37 24.9999L80 49.9999Z" fill="#2E8AE6" />
          <path d="M88.6603 45L80 50L71.3397 45L80 40L88.6603 45Z" fill="#1262B3" />
          <path d="M123 25L135 43L92 68L80 50L123 25Z" fill="#80BFFF" />
          <path d="M68 68L80 50L37 25L25 43L68 68Z" fill="#80BFFF" />
        </svg>
        <Typography
          {...getTypographyProps('heading', 'xl', 'text')}
          children='Заказ не найден'
        />
        <Button label="На главную" onClick={() => this.toMain()} />
      </div>
    )
  }

  setMeta(){

    return (
      <MetaTags 
        title='Информация о заказе'
      />
    )
  }

  render() {
    return (
      <>
      {this.setMeta()}
        <Page page='orders-foto'>
          <Wrapper isRow isPageWrapper>
            <div className="page__content">
              {!this.props.orderInfo.error
                ? !this.props.orderInfo.loading && this.renderContent()
                : this.renderError()}
            </div>
          </Wrapper>
        </Page>
      </>
    )
  }
}

function mapQuery(props: Props) {
  //@ts-ignore
  const { order } = props.match.params;

  if (order) {
    return {
      orderInfo: Foto.getOrderData(order)
    };
  }

  return {
    relations: Relations.getRelations()
  };
}

export default withApollo(mapQuery, undefined)(OrdersPage);
