import App from './app';
import React from 'react';
import { hydrate, render } from 'react-dom';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { HelmetProvider } from 'react-helmet-async';
const container = document.getElementById('app');

hydrate(
  <HelmetProvider>
    <App />
  </HelmetProvider>,
  container
);

serviceWorkerRegistration.unregister();
