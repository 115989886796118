import React from 'react';
import { Props } from './interface';
import Link from '../Link';

import './Popup.scss';
import Typography from '../Typography';
import getTypographyProps from '../../lib/getTypographyProps';

const Popup = ({
  toggleClose,
  children,
  isShow,
  fromTop,
  fromBottom,
  fromLeft,
  fromRight,
}: Props) => {

    const getClassPosition = () => {
        if(fromTop){
            return 'top';
        } 
        if(fromBottom){
            return 'bottom'
        }
        if(fromLeft){
            return 'left'
        }
        if(fromRight){
            return 'right'
        }
    }

  return (
    <div className={`popup ${isShow ? 'show' : ''} ${getClassPosition()}`}>
      {children}
    </div>
  );
};

export default Popup;
