import {
  IRegion,
  IInstitution,
  IServiceType,
  IShortInstitution,
  IDocument,
  IShortRegion,
  ISchedule,
  IShortNews,
  IMiddleInstitution,
  INews,
  ICommentsC,
  ITags,
  ISender,
  IPriceCost,
  IMailOrder,
  IShortDistricts,
  ICustomPageContent,
  IPrisoner,
  IOrderCost,
  IScheduleMonth,
  IScheduleSlots,
  IScheduleResult,
  IBanner
} from '../../types/app';

// const notFound = {
//   phone: 'Телефон отсутствует',
//   info: 'Информация отсутствует'
// };

export const mapServices = (a: any): IServiceType | undefined => {
  if (a && a.name) {
    switch (a.name) {
      case 'zmoney':
        return 'transfer';
      case 'zsols':
        return 'queue';
      case 'zfoto':
        return 'photo';
      case 'zmail':
        return 'mail';
      case 'zphone':
        return 'phone';
      case 'zvideo':
        return 'video_talks';
      default:
        return undefined;
    }
  }

  return undefined;
};

export const mapCustomPage = (a: any): ICustomPageContent => ({
  slug: a.slug || '',
  title: a.title || '',
  content: a.content || '',
  metaTitle: a.meta_title || '',
  metaDescription: a.meta_description || ''
});

export const mapShortInstitutions = (a: any): IShortInstitution => ({
  slug: a.slug || '',
  id: a.company_id || '',
  shortName: a.shortname || '',
  address: a.address || '',
  can_color_print: a.can_color_print || false
});

export const mapMailOrder = (a: any): IMailOrder => ({
  text: a.text || '',
  cost: mapMailCost(a.cost[0] || {}),
  sender: mapSender(a.customer || {}),
  prisoner: mapPrisoner(a.prisoner || {}),
  institution: mapMiddleInstitution(a.company.length && a.company[0] || {}),
});

export const mapSender = (a: any): ISender => ({
  snils: a.snils || '',
  phone: a.phone || '',
  email: a.email || '',
  lastName: a.lastname || '',
  passport: a.passport || '',
  middleName: a.surname || '',
  firstName: a.firstname || ''
});

export const mapPrisoner = (a: any): IPrisoner => ({
  lastName: a.lastname || '',
  middleName: a.surname || '',
  firstName: a.firstname || '',
  birthDate: a.bdate || ''
});

export const mapCostPrice = (a: any): IPriceCost => ({
  count: a.count || 0,
  price: a.price || 0,
  total: a.total || 0
});

export const mapMailCost = (a: any): IOrderCost => ({
  total: a.total || 0,
  price: a.price || 0,
  pages: mapCostPrice(a.pages || {}),
  images: mapCostPrice(a.images || {}),
  answers: mapCostPrice(a.answers || {}),
});

export const mapTags = (a: any): string => a.value;

export const mapSimilar = (a: any): ITags => ({
  value: a.value || '',
  content: a.articles ? (a.articles || a.stories).map(mapShortNews) : [],
  //   // id: a.document_id || ''
});

export const mapComments = (a: any): ICommentsC => ({
  comments: a.comments || []
});

// documents

export const mapDocFiles = (a: any) => ({
  ext:a.ext || "",
  file_id: a.file_id ||  "" 
});

export const mapDocumentsFiles = (a: any) => ({
  document_id: a.document_id || "",
  files:a.files.map(mapDocFiles) || [],
  name: a.name || ""
}
);

export const mapDocumentsGroups = (a: any) => ({
  documents : a.documents.map(mapDocumentsFiles) || [],
  name:a.name || ""
}
);

export const mapDocuments = (a: any) => {
  return {
  category_id:a.category_id || "",
  name:a.name || "",
  groups: a.groups.map(mapDocumentsGroups) || []
}
}

export const mapDocumentsData = (a: any) => ({
  categories: a.categories.map(mapDocuments)
 })
//  --- documents

export const mapDocument = (a: any): IDocument => ({
  name: a.doc_type || '',
  id: a.document_id || ''
});

export const mapShortRegion = (a: any): IShortRegion => ({
  name: a.name || '',
  slug: a.slug || '',
  id: a.region_id || '',
  companies: a.companies ? a.companies.map(mapShortInstitutions) : []
});

export const mapSchedule = (a: any): ISchedule => ({
  day: a.weekday || '',
  times: a.times || ''
});

const getPhone = (contacts: any[] = []) => {
  const phone = contacts && contacts.filter((b: any) => b.type === 'Телефон приемной');
  return phone[0] && phone[0].number;
};

export const mapMiddleInstitution = (a: any): IMiddleInstitution => {
  const phone = getPhone(a.company_contacts);

  return {
    can_color_print: a.can_color_print || false,
    slug: a.slug || '',
    phone: phone || '',
    id: a.company_id || '',
    address: a.address || '',
    fullName: a.fullname || '',
    shortName: a.shortname || '',
    main: Boolean(a.main),
    region: mapShortRegion(a.region || {}),
    services: a.services ? a.services.map(mapServices) : [],
    contacts: (a.company_contacts || []).map((b: any) => ({
      description: b.description,
      number: b.number,
      type: b.type
    }))
  };
};

export const mapCompanies = (a: any = {}) => {
  const phone = getPhone(a.company_contacts);

  return {
    slug: a.slug || '',
    // id: a.company_id || '',
    address: a.address || '',
    fullName: a.fullname || '',
    shortName: a.shortname || '',
    region: mapShortRegion(a.region || {}),
    contacts: (a.company_contacts || []).map((b: any) => ({
      description: b.description,
      number: b.number,
      type: b.type
    })),
    // queueSchedule: a.longdate_schedule.map((b: any) => ({
    //   days: b.days,
    //   info: b.info,
    //   times: b.times
    // })),
    // transferSchedule: a.package_schedule.map((b: any) => ({
    //   days: b.days,
    //   info: b.info,
    //   times: b.times
    // })),
    // receiptionSchedule: a.reception_schedule.map((b: any) => ({
    //   position: b.position || '',
    //   fullname: b.fullname || '',
    //   schedule: (b.schedules || []).map(mapSchedule)
    // })),
    phone: phone || '',
    // limit: a.limitofall,
    // point: a.point || '',
    // chiefName: a.chief_name || '',
    // documents: a.documents.map(mapDocument),
    // services: a.services ? a.services.map(mapServices) : []
  };
};
export const mapInstitution = (a: any = {}): IInstitution => {
  const phone = getPhone(a.company_contacts);

  return {
    slug: a.slug || '',
    id: a.company_id || '',
    address: a.address || '',
    fullName: a.fullname || '',
    shortName: a.shortname || '',
    region: mapShortRegion(a.region || {}),
    contacts: (a.company_contacts || []).map((b: any) => ({
      description: b.description,
      number: b.number,
      type: b.type
    })),
    queueSchedule: a.longdate_schedule.map((b: any) => ({
      days: b.days,
      info: b.info,
      times: b.times
    })),
    transferSchedule: a.package_schedule.map((b: any) => ({
      days: b.days,
      info: b.info,
      times: b.times
    })),
    receiptionSchedule: a.reception_schedule.map((b: any) => ({
      position: b.position || '',
      fullname: b.fullname || '',
      schedule: (b.schedules || []).map(mapSchedule)
    })),
    phone: phone || '',
    limit: a.limitofall,
    point: a.point || '',
    chiefName: a.chief_name || '',
    documents: a.documents.map(mapDocument),
    services: a.services ? a.services.map(mapServices) : []
  };
};

export const mapRegion = (a: any): IRegion => ({
  name: a.name || '',
  slug: a.slug || '',
  id: a.region_id || '',
  companies: a.companies.map(mapMiddleInstitution)
});

export const mapShortDistricts = (a: any): IShortDistricts => ({
  name: a.name || '',
  slug: a.slug || '',
  id: a.district_id || '',
  regions: a.regions.map(mapShortRegion)
});

export const mapShortNews = (a: any): IShortNews => ({
  slug: a.slug || '',
  title: a.name || '',
  image: a.image || '',
  date: a.creation_date || '',
  updation_date: a.updation_date || '',
  tags: (a.tags || []).map(mapTags),
  id: a.article_id || a.news_id || ''
});

export const mapBanners = (a: any): IBanner => ({
  btn_link: a.btn_link || '',
  btn_text: a.btn_text || '',
  content: a.content || '',
  position: a.position || '',
  title: a.title || '',
  type: a.type || '',
});

export const mapNews = (a: any): INews => ({
  slug: a.slug || '',
  title: a.name || '',
  image: a.image || '',
  content: a.content || '',
  date: a.creation_date || '',
  update_date: a.updation_date || '',
  timeToRead: a.time_to_read || 0,
  companies: (a.companies || []).map(mapCompanies),
  banners: (a.banners || []).map(mapBanners),
  tags: (a.tags || []).map(mapTags),
  similar: (a.tags || []).map(mapSimilar),
  id: a.article_id || a.news_id || ''
});

export const mapScheduleResult = (a: any): IScheduleResult => ({
  day: a.day || '',
  slots: a.slots || ''
});


export const mapScheduleMonth = (a: any): any => ({
  result: (a.result || []).map(mapScheduleResult),
  total: a.total || ''
});

export const mapFotoRegions = (a: any): any => ({
  id: a.id || '',
  name: a.name || '',
  region: (a.region || []).map(mapFotoRegion),
  view: a.view || false,
  delivery: (a.delivery || []).map(mapFotoDelivery)
})

export const mapFotoRegion = (a: any): any => ({
  id: a.id || '',
  name: a.name || ''
})

export const mapFotoDelivery = (a:any): any => ({
  colony: (a.colony || []).map(mapFotoDeliveryItem),
  region: (a.region || []).map(mapFotoDeliveryItem),
  default: (a.default || []).map(mapFotoDeliveryItem),
  match: (a.match || []).map(mapFotoDeliveryItem)
})

export const mapFotoDeliveryItem = (a:any): any => ({
  cnt: a.cnt || 0,
  min: a.min || 0,
  max: a.max || 0,
  avg: a.avg || 0
})

export const mapFotoTariffs = (a: any): any => ({
  tariffs: (a.tariffs || []).map(mapFotoTariffsItem)
})

export const mapFotoTariffsItem = (a: any): any => ({
  id: a.id || 0,
  min: a.min || 0,
  max: a.max || 0,
  cost: a.cost || 0,
  oldCost: a.oldCost || null,
  name: a.name || '',
  type: a.type || ''
})

export const mapTransferCost = (a:any): any => ({
  sum: a.sum || 0,
  paymentSum: a.paymentSum || 0,
  tax: a.tax || 0,
  description: a.description || ''
})

export const mapFotoOrderDataById = (a: any): any => ({
  externalId: a.externalId || '',
  uploadId: a.uploadId || '',
  sender: a.sender || '',
  recipient: a.recipient || '',
  colony: a.colony ? (a.colony || []).map(mapColonyFotoOrderData) : [],
  cart: a.cart ? (a.cart || []).map(mapCartFotoOrderData) : [],
  statusCollection: a.statusCollection ? (a.statusCollection || []).map(mapStatusCollectionFotoOrderData): []
})

export const mapColonyFotoOrderData = (a: any): any => ({
  fullName: a.fullName || ''
})

export const mapCartFotoOrderData = (a: any): any => ({
  amount: a.amount || 0,
  items: (a.items || []).map(mapCustomerOrderFotoOrderData)
})

export const mapCustomerOrderFotoOrderData = (a: any): any => ({
  name: a.name || '',
  amount: a.amount || 0,
  qty: a.qty || 0
})

export const mapStatusCollectionFotoOrderData = (a: any): any => ({
  data: (a.data || []).map(mapStatusCollectionDataFotoOrderData)
})

export const mapStatusCollectionDataFotoOrderData = (a: any): any => ({
  id: a.id || '',
  date: a.date || ''
})

export const mapFotoConfirmUrl = (a: any): any => ({
  data: (a.data || []).map(mapFotoConfirmUrlData)
})

export const mapFotoConfirmUrlData = (a: any): any => ({
  confirmationUrl: a.confirmationUrl || ''
})