import ReactDOM from 'react-dom';
import { State } from './interface';
import React, { Component } from 'react';
import bind from '../../lib/decorators/bind';

import Typography from '../Typography';
import getTypographyProps from '../../lib/getTypographyProps';

// @ts-ignore
import styles from './Notification.module.scss';

const HIDE_TIMEOUT = 3000;
const ANIMATED_UPDATE_DURATION = 200;
const ANIMATED_RENDER_DURATION = 500;

class Notification extends Component<{}, State> {
  static instance: null | Notification = null;

  static show(message: string = '') {
    const { instance } = Notification;
    if (instance) instance.onShow(message);
  }

  static hide() {
    const { instance } = Notification;
    if (instance) instance.onHide();
  }

  state: State = {
    message: '',
    isUpdate: false,
    isRender: false,
    isVisible: false
  };

  componentDidMount() {
    Notification.instance = this;
  }

  @bind
  getClassName() {
    let className = styles.notification;
    if (this.state.isUpdate) className += ` ${styles.isUpdate}`;
    if (this.state.isVisible) className += ` ${styles.isVisible}`;

    return className;
  }

  @bind
  onShow(message: string) {
    const { isRender } = this.state;

    clearTimeout(this.hideRenderTimeout);
    clearTimeout(this.hideVisibleTimeout);

    this.setState(
      {
        isUpdate: isRender === true,
        isRender: true,
        message
      },
      () => {
        setTimeout(() => {
          this.setState(
            {
              isVisible: true
            },
            this.onHide
          );
        }, 0);
      }
    );
  }

  @bind
  onHide() {
    const { isUpdate } = this.state;

    if (isUpdate) {
      setTimeout(() => {
        this.setState({ isUpdate: false });
      }, ANIMATED_UPDATE_DURATION);
    }

    this.hideRenderTimeout = setTimeout(() => {
      this.setState({ isVisible: false }, () => {
        this.hideVisibleTimeout = setTimeout(() => {
          this.setState({ isRender: false });
        }, ANIMATED_RENDER_DURATION);
      });
    }, HIDE_TIMEOUT);
  }

  @bind
  renderNotification() {
    const { message } = this.state;

    return (
      <div className={this.getClassName()}>
        <Typography
          {...getTypographyProps('text', 'm', 'white', 'regular')}
          children={message}
        />
      </div>
    );
  }

  hideVisibleTimeout: any = 0;

  hideRenderTimeout: any = 0;

  render() {
    if (!this.state.isRender) return null;
    return ReactDOM.createPortal(this.renderNotification(), document.body);
  }
}

export default Notification;
