import React from 'react';
import './Html.scss';

const contentClasses = {
  h1: 'typography typography_type_heading typography_size_xl typography_color_dark-text',
  h2: 'typography typography_type_heading typography_size_l typography_color_dark-text',
  h3: 'typography typography_type_heading typography_size_l typography_color_dark-text',
  p: 'typography typography_type_text typography_size_m typography_color_text',
  warning: 'typography typography_type_text typography_size_s typography_color_warning',
  a: 'typography typography_type_text typography_size_m typography_color_main link link_blue',
  li: 'typography typography_type_text typography_size_m typography_color_text',
};

export default (props: { html?: string; image?: string; title?: string; isPreload?: boolean, isSpecialContent?: boolean }) => {
  const { isPreload, isSpecialContent } = props;
  let { html, image, title } = props;
  if (isSpecialContent) {
  let text = `<p class='${contentClasses.p} page__description'>Внимание! Портал sizo.ru не предоставляет справок о местонахождении осужденных и иную персональную информацию о заключенных.</p>`
  text += `<p class='${contentClasses.p} page__description'>Личную информацию запрашивайте у администрации исправительного учреждения, где находится заключенный. В каталоге исправительных учреждений <a href=" https://sizo.ru/colony"  class="link typography typography_type_text typography_size_m typography_color_text" target="_blank" rel="noopener noreferrer">https://sizo.ru/colony/</a>  вы найдёте нужные адреса, телефоны и графики приёма руководителей.</p>`

  // html = html && html.replace(/<div class="button button_transparent">Задать вопрос в чате<\/div>/gm, `<a class="button button_transparent" href="#question">Задать вопрос</a>`);
  html =html && html.replace(/<div class="page__content">/gm, `<div class="page__content">${text}`);
  }
  // <div class="button button_transparent" wfd-id="164">Задать вопрос в чате</div>
  if (!isPreload) {
    if (!html) html = `<p className={${contentClasses.p}}>Описание отсутствует...</p>`;
    else {
      html = html.replace(/(<p ([^>]*)>|<p>)/gm, `<p $2 class="${contentClasses.p}">`);
      html = html.replace(/(<a ([^>]*)>|<a>)/gm, `<a $2 class="${contentClasses.a}">`);
      html = html.replace(/<li>/gm, `<li class="${contentClasses.li}">`);
      html = html.replace(/<h1>/gm, `<h1 class="${contentClasses.h1}">`);
      html = html.replace(/<h2>/gm, `<h2 class="${contentClasses.h2}">`);
      html = html.replace(/<h3>/gm, `<h3 class="${contentClasses.h3}">`);
      html = html.replace(/<p class="warning">/gm, `<p class="${contentClasses.warning}">`);
      let content_images: any = html.match(/<img[^>]*>/gi)
      if(content_images){
        html = html.replace(content_images[0], '')
      }
      if(image){
        const image_content = `<img width='100%' src='${image}' alt='${title}' />`;
        html = image_content + html;
      }
    }
  }

  let className = 'html';
  if (isPreload) className += ' html_preload';
  if (isSpecialContent) className += ' html_special';
  if (isSpecialContent) {
    return(
      <div
      className='page__wrapper'

      dangerouslySetInnerHTML={{ __html: html || '' }}
    />
    )
  } else {
    return (
      <div
        className={className}
        dangerouslySetInnerHTML={{ __html: html || '' }}
      />
    );  
  }
};
