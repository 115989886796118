import React from 'react';
import { Props } from './interface';

import Typography from '../Typography';
import getTypographyProps from '../../lib/getTypographyProps';

import {ReactComponent as DocumentIcon} from '../../images/document.svg';
import {ReactComponent as Download} from '../../images/download.svg';
import API from '../../lib/request/config';

import { saveAs } from 'file-saver';
import './Document.scss';
class Document extends React.Component<Props> {
  downloadDocument = () => {
    const { id, name } = this.props;
    const url = `${API}`.replace('graphql', `documents/${id}`);
    
    saveAs(url, name);
  }

  render() {
    const { name } = this.props;

    return (
      <div
        key={name}
        className='document'
      >
        <DocumentIcon />
        <Typography
          {...getTypographyProps('text', 'm', 'main')}
          children={name}
        />
        <Download
          onClick={this.downloadDocument}
        />
      </div>
    );
  }
}

export default Document;
