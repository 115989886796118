import moment from 'moment';
import React, { Component } from 'react';
import Button from '../../../../../components/Button';
import FormElement from '../../../../../components/Form/FormElement';
import bind from '../../../../../lib/decorators/bind';
import { State } from '../../IndexPage';
import './style.scss';

interface Props {
  fullState: State;
  handleChange: CallableFunction;
  onConfirm: CallableFunction;
}

class FormConfirm extends Component<Props> {
  state = {
    validateError: false,
  };

  @bind
  isValid() {
    const { acceptData, acceptOffer } = this.props.fullState;
    if (acceptData && acceptOffer) {
      this.setState({ validateError: false });
      return true;
    } else {
      this.setState({ validateError: true });
      return false;
    }
  }

  onSubmit() {
    if (this.isValid()) this.props.onConfirm();
  }

  renderDate() {
    return (
      moment(this.props.fullState.selectedTime.startTime)
        .locale('ru')
        .format('DD MMMM yyyy[г. с] HH:mm') +
      ' до ' +
      moment(this.props.fullState.selectedTime.endTime)
        .locale('ru')
        .format('HH:mm')
    );
  }

  render() {
    const {
      selectedRegion,
      selectedInstitution,
      firstName,
      lastName,
      middleName,
      birthDay,
      senderFirstName,
      senderLastName,
      senderMiddleName,
      passportSeries,
      passportNumber,
      senderEmail,
      senderPhone,
      acceptData,
      acceptOffer,
      relationship,
      visitorPassportIssuedBy,
      visitorPassportIssuedDate,
      visitorRegistration,
      visitorYearOfBirth
    } = this.props.fullState;

    const { handleChange } = this.props;

    return (
      <>
        <div className='FormConfirm__info'>
          <h5>Учреждение</h5>
          <div className='FormConfirm__item'>
            <span>Город</span>
            <span>{selectedRegion.name}</span>
          </div>
          <div className='FormConfirm__item'>
            <span>Учреждение</span>
            <span>{selectedInstitution.shortName}</span>
          </div>
          <div className='FormConfirm__item'>
            <span>Тип посещения</span>
            <span>{selectedInstitution.variation}</span>
          </div>
          <div className='FormConfirm__item'>
            <span>Дата и время записи</span>
            <span>{this.renderDate()}</span>
          </div>
        </div>

        <div className='FormConfirm__info'>
          <h5>
            {selectedInstitution.serviceType === 'package'
              ? 'Получатель'
              : 'Посещаемый'}
          </h5>
          <div className='FormConfirm__item'>
            <span>Ф.И.О.</span>
            <span>{lastName + ' ' + firstName + ' ' + middleName}</span>
          </div>
          <div className='FormConfirm__item'>
            <span>Год рождения</span>
            <span>{birthDay}</span>
          </div>
          <div className='FormConfirm__item'>
            <span>Степень родства</span>
            <span>{relationship?.label}</span>
          </div>
        </div>

        <div className='FormConfirm__info'>
          <h5>
            {selectedInstitution.serviceType === 'package'
              ? 'Отправитель'
              : 'Посетитель'}
          </h5>
          <div className='FormConfirm__item'>
            <span>Ф.И.О.</span>
            <span>
              {senderLastName + ' ' + senderFirstName + ' ' + senderMiddleName}
            </span>
          </div>
          <div className='FormConfirm__item'>
            <span>Серия и номер паспорта</span>
            <span>
              {passportSeries} / {passportNumber}
            </span>
          </div>
          <div className='FormConfirm__item'>
            <span>Дата выдачи паспорта</span>
            <span>
              {moment(visitorPassportIssuedDate).locale('ru').format('ll')}
            </span>
          </div>
          <div className='FormConfirm__item'>
            <span>Кем выдан паспорт</span>
            <span>
              {visitorPassportIssuedBy}
            </span>
          </div>
          <div className='FormConfirm__item'>
            <span>Место регистрации/проживания</span>
            <span>
              {visitorRegistration}
            </span>
          </div>
          <div className='FormConfirm__item'>
            <span>Год рождения</span>
            <span>
              {visitorYearOfBirth}
            </span>
          </div>
          <div className='FormConfirm__item'>
            <span>Телефон</span>
            <span>{senderPhone}</span>
          </div>
          <div className='FormConfirm__item'>
            <span>Email</span>
            <span>{senderEmail}</span>
          </div>
        </div>

        <div className='FormConfirm__checkbox-wrap'>
          <div className='FormConfirm__checkbox'>
            <FormElement
              label={
                <>
                  <span
                    className={this.state.validateError ? 'star_error' : ''}
                  >
                    *
                  </span>{' '}
                  Со статьей{' '}
                  <a
                    href='https://sizo.ru/info/koaprf1912'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    19.12. КоАП РФ
                  </a>{' '}
                  ознакомлен
                </>
              }
              onChange={handleChange('acceptData')}
              name='acceptData'
              type='checkbox'
              value={acceptData}
            />
          </div>
          <div className='FormConfirm__checkbox'>
            <FormElement
              label={
                <>
                  <span
                    className={this.state.validateError ? 'star_error' : ''}
                  >
                    *
                  </span>{' '}
                  С{' '}
                  <a
                    href='https://sizo.ru/info/privacypolicy'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    условиями обработки персональных данных
                  </a>{' '}
                  ознакомлен
                </>
              }
              onChange={handleChange('acceptOffer')}
              name='acceptOffer'
              type='checkbox'
              value={acceptOffer}
            />
          </div>
        </div>
        {this.state.validateError && (
          <p className='FormConfirm__error'>
            Подтвердите, что с 19.12. КоАП РФ ознакомлены и разрешаете обработку
            персональных данных
          </p>
        )}
        <Button
          isButtonTag
          isColored
          label={
            selectedInstitution.serviceType === 'package'
              ? 'Записаться на передачу'
              : 'Записаться на свидание'
          }
          onClick={() => this.onSubmit()}
        />
      </>
    );
  }
}

export default FormConfirm;
