import request from '../../../lib/request';
import { ACCOUNT_END_POINT } from '../../../lib/request/config';
import gql from "graphql-tag";

const QUERY = (): any => {
  return gql`
          {
            userContacts{
              data{
                companies{
                  company_id
                }
                contact_id
                date_of_birth
                firstname
                lastname
                middlename
                relation_id
              }
            }
          }`
};

export function contactsQuery(success: (response: any) => void) {
  const {body} = QUERY().loc.source;
  const operation = 'query';

  return request({
    method: 'POST',
    body: JSON.stringify({[operation]: body}),
    headers: { 'Content-Type': 'application/json' },
    success
  });
}