import React, { Component } from 'react';
import Svg from '../../components/Svg';
import Typography from '../../components/Typography';
import CloseSVG from '../../images/close.svg';
import getTypographyProps from '../../lib/getTypographyProps';
import './style.scss';

interface IModal {
  isOpen: boolean;
  rules?: boolean;
  setOpenModal: CallableFunction;
  things?: boolean;
  userRules?: boolean;
  className?: string;
}

class Modal extends Component<IModal> {
  
  scrollBreak() {
    document.body.classList.toggle('scroll-brake');
  }

  contentRules() {
    return (
      <>
        <Typography
          {...getTypographyProps('heading', 'l', 'dark-text')}
          children='ПРОВЕДЕНИЕ СВИДАНИЙ ПОДОЗРЕВАЕМЫХ И ОБВИНЯЕМЫХ С РОДСТВЕННИКАМИ И
                    ИНЫМИ ЛИЦАМИ'
            className="title-xl"
        />
        <p>
          Подозреваемым и обвиняемым на основании письменного разрешения лица
          или органа, в производстве которых находится уголовное дело, может
          быть предоставлено не более двух свиданий в месяц с родственниками и
          иными лицами продолжительностью до трех часов каждое. Разрешение
          действительно только на одно свидание.
        </p>

        <p>
          В письменном разрешении на свидание, заверенном гербовой печатью,
          должно быть указано, кому и с какими лицами оно разрешается. На
          свидание с подозреваемым или обвиняемым допускаются одновременно не
          более двух взрослых человек.
        </p>

        <p>
          Осужденному, в отношении которого приговор вступил в законную силу, но
          еще не обращен к исполнению, свидание с родственниками предоставляется
          на основании разрешения председательствующего в судебном заседании по
          уголовному делу или председателя суда.
        </p>

        <p>
          На основании письменного разрешения лица или органа, в производстве
          которых находится уголовное дело, а также документов, удостоверяющих
          личность, начальник СИЗО либо лицо, его замещающее, дает письменное
          указание о разрешении свидания, после чего отдает распоряжение
          дежурному помощнику о его проведении.
        </p>

        <p>
          Свидания предоставляются в порядке общей очереди. Перед началом
          свидания лица, прибывшие на него, информируются о правилах поведения
          во время свидания и предупреждаются о прекращении свидания в случае
          нарушения установленных правил.
        </p>

        <p>
          Гражданам, прибывшим на свидание без документов, удостоверяющих их
          личность, либо в состоянии опьянения, а также лицам, не указанным в
          разрешении, свидания не предоставляются. Причины отказа в
          предоставлении свидания объявляются лицу, прибывшему на свидание.
        </p>

        <p>
          Свидания подозреваемых и обвиняемых с родственниками и иными лицами
          проводятся под контролем сотрудников СИЗО в специально оборудованных
          для этих целей помещениях через разделительную перегородку,
          исключающую передачу каких-либо предметов, но не препятствующую
          переговорам и визуальному общению.
        </p>

        <p>
          Переговоры подозреваемых или обвиняемых с лицами, прибывшими на
          свидание, осуществляются через переговорное устройство и могут
          прослушиваться сотрудниками СИЗО.
        </p>

        <p>
          Родственникам и иным лицам, получившим письменные разрешения на
          свидания с подозреваемыми или обвиняемыми в порядке, установленном
          частью третьей статьи 18 Федерального закона, запрещается проносить в
          СИЗО и пользоваться во время свидания техническими средствами связи,
          компьютерами, кино-, фото-, аудио-, видео- и множительной аппаратурой.
        </p>

        <p>
          <b>Основаниями для досрочного прекращения свидания являются:</b>
        </p>

        <p>
          - попытка передачи подозреваемому или обвиняемому запрещенных
          предметов, веществ и продуктов питания;
        </p>

        <p>
          - попытка передачи лицами, прибывшими на свидание, сведений, которые
          могут препятствовать установлению истины по уголовному делу или
          способствовать совершению преступления.
        </p>

        <p>
          (Глава XVI Правил внутреннего распорядка следственных изоляторов
          уголовно-исполнительной системы, утвержденных приказом Минюста России
          от 14.10.2005 N 189)
        </p>
      </>
    );
  }

  contentThings() {
    return (
      <>
        <Typography
          {...getTypographyProps('heading', 'l', 'dark-text')}
          children='ПЕРЕЧЕНЬ ВЕЩЕЙ И ПРЕДМЕТОВ, ПРОДУКТОВ ПИТАНИЯ, КОТОРЫЕ ОСУЖДЕННЫМ
          ЗАПРЕЩАЕТСЯ ИМЕТЬ ПРИ СЕБЕ, ПОЛУЧАТЬ В ПОСЫЛКАХ, ПЕРЕДАЧАХ, БАНДЕРОЛЯХ
          ЛИБО ПРИОБРЕТАТЬ'
            className="title-xl"
        />
        <ol>
          <li>
            Предметы, изделия и вещества, изъятые из гражданского оборота.
          </li>

          <li>Все виды оружия, боеприпасы.</li>

          <li>Транспортные и летательные средства передвижения.</li>

          <li>
            Взрывчатые, отравляющие, пожароопасные и радиоактивные вещества,
            зажигалки.
          </li>

          <li> Деньги, ценные вещи.</li>

          <li>Ценные бумаги, валюта зарубежных стран.</li>

          <li>Оптические приборы.</li>

          <li>
            Продукты питания, требующие тепловой обработки (кроме чая и кофе,
            сухого молока, пищевых концентратов быстрого приготовления, не
            требующих кипячения или варки), продукты домашнего консервирования,
            дрожжи.
          </li>

          <li>Все виды алкогольной продукции, пиво.</li>

          <li> Духи, одеколон и иные изделия на спиртовой основе.</li>

          <li>
            Наркотические средства, психотропные токсические и сильнодействующие
            вещества, их аналоги и без медицинских показаний - лекарственные
            вещества, предметы медицинского назначения, курительные смеси.
          </li>

          <li>
            Электронно-вычислительные машины, пишущие машинки, множительные
            аппараты, электронные носители информации и другая компьютерная и
            оргтехника.
          </li>

          <li> Ножи, опасные бритвы, лезвия для безопасных бритв.</li>

          <li>
            Колюще-режущие и остроконечные предметы, в том числе предметы и
            тара, изготовленные из стекла, керамики и металла (за исключением
            алюминиевых ложек, вилок, кружек, тарелок и консервированных
            продуктов в металлической таре).
          </li>

          <li> Топоры, молотки и другой инструмент.</li>

          <li> Игральные карты.</li>

          <li>
            Фотоаппараты, фотоматериалы, химикаты, кинокамеры, видео-,
            аудиотехника (кроме телевизионных приемников, радиоприемников общего
            пользования), телевизионные приемники с выходом в
            информационно-телекоммуникационную сеть "Интернет" и с встроенными
            медиаплеерами, электронные носители и накопители информации,
            средства мобильной связи и коммуникации либо комплектующие к ним,
            обеспечивающие их работу.
          </li>

          <li>
            Любые документы (кроме документов установленного образца,
            удостоверяющих личность осужденного, медицинских документов, их
            копий и выписок из медицинских документов, копий приговоров и
            определений судов, ответов по результатам рассмотрения предложений,
            заявлений, ходатайств и жалоб, квитанций на сданные для хранения
            деньги, вещи, ценности).
          </li>

          <li>
            Топографические карты, компасы, литература и обучающие видеофильмы
            по топографии, служебному собаководству, единоборствам, подготовке
            бойцов специальных подразделений, горной подготовке и паркуру,
            устройству оружия, изготовлению взрывчатых, ядовитых, отравляющих,
            наркотических и сильнодействующих веществ.
          </li>

          <li>
            Литература, документы либо информация на любых носителях,
            призывающие к осуществлению экстремистской деятельности или
            оправдывающие необходимость осуществления такой деятельности.
          </li>

          <li> Военная и другая форменная одежда, принадлежности к ней.</li>

          <li>
            Одежда, головные уборы, обувь и постельное белье (за исключением
            одного комплекта тапочек, спортивного костюма и спортивной обуви
            темных расцветок) неустановленных образцов.
          </li>

          <li> Порнографические материалы, предметы и видеофильмы.</li>

          <li> Татуировочные машинки и принадлежности к ним.</li>

          <li>
            Электробытовые приборы (за исключением электробритв, бытовых
            электрокипятильников заводского исполнения мощностью не более 0,5
            кВт).
          </li>

          <li>
            Вещи и предметы, продукты питания, полученные либо приобретенные в
            не установленном Уголовно-исправительным кодексом Российской
            Федерации и Правилами порядке.
          </li>
        </ol>

        <p>
          <b>Примечания:</b>
        </p>
        <ol>
          <li>
            Настоящий перечень распространяется на осужденных, отбывающих
            наказание в колониях-поселениях, за исключением продуктов питания,
            денег, ценных вещей, одежды, головных уборов и обуви гражданского
            образца, постельных принадлежностей и зажигалок.
          </li>

          <li>
            При переводе в другое ИУ, освобождении осужденным разрешается брать
            с собой только личные вещи, продукты питания и предметы,
            приобретенные ими в установленном порядке.
          </li>

          <li>
            Количество вещей и предметов, продуктов питания, которые осужденные
            могут иметь при себе, определяется в приложении к приказу ИУ,
            утверждающему распорядок дня ИУ, исходя из местных условий и
            возможностей. Общий вес принадлежащих осужденному вещей и предметов,
            продуктов питания, за исключением находящихся на складе ИУ, не может
            превышать 36 кг.
          </li>

          <li>
            Телевизионные приемники и радиоприемники используются только для
            коллективного пользования и устанавливаются в местах, определенных
            администрацией ИУ (осужденным, содержащимся в облегченных условиях
            отбывания наказания, а также отбывающим наказание в
            колониях-поселениях разрешено пользоваться аудиоплеерами без функции
            записи, техническими устройствами для чтения электронных книг без
            функции выхода в информационно-телекоммуникационную сеть Интернет и
            функции аудио-, видеозаписи в количестве не более одного устройства
            каждого вида на осужденного, в местах коллективного пользования
            могут устанавливаться DVD- и аудиопроигрыватели);
          </li>

          <li>
            Спортивные костюмы и спортивная обувь хранятся в помещениях для
            хранения личных вещей осужденных и выдаются для ношения во время
            спортивно-массовых мероприятий, за исключением утренней физической
            зарядки.
          </li>

          <li>
            Перечень продуктов питания, которые осужденные могут иметь при себе,
            хранить, получать в посылках, передачах, бандеролях либо
            приобретать, может быть ограничен по предписанию
            санитарно-эпидемиологической службы.
          </li>
        </ol>
        <p>
          (Приложение №1 Правил внутреннего распорядка исправительных
          учреждений, утвержденных приказом Минюста России от 16.12.2016 № 295,
          с изменениями на 01 апреля 2020 года )
        </p>
      </>
    );
  }

  contentUsageRules(){

    return (
      <>
      <Typography
          {...getTypographyProps('heading', 'l', 'dark-text')}
          isUppercase
          children='Публичная оферта об оказании услуг'
            className="title-xl"
        />
        <Typography
          {...getTypographyProps('text', 'm', 'dark-text')}
          isUppercase
          children='Город Тула'
            className="title-xl"
        />
      <p>
        Настоящим Общество с ограниченной ответственностью «Регион Телеком», в
        лице Генерального директора, действующего на основании Устава,
        предлагает неограниченному кругу лиц заключить договор возмездного
        оказания услуг на нижеследующих условиях:
      </p>

      <h3>1 Основные понятия</h3>
      <p>1.1 Пользователь Сайта — лицо, пришедшее на Сайт.</p>
      <p>
        1.2 Заказчик — Пользователь Сайта, принявший условия настоящей оферты и
        разместивший Заявку на Сайте.
      </p>
      <p>1.3 Исполнитель – ООО «Регион Телеком».</p>
      <p>1.4 Сайт — foto.sizo.ru</p>
      <p>
        1.5 Заявка — заполненная на Сайте электронная форма, содержащая
        информацию о Заказчике, Адресате и содержащая приложения в виде
        фотографий.
      </p>
      <p>
        1.6 Адресат – лицо, содержащее в учреждениях Федеральной службы
        исполнения наказаний, чьи фамилия, имя отчество и дата рождения указаны
        в Заявке, а также указаны регион местонахождения и само местонахождение
        учреждения Федеральной службы исполнения наказаний, в котором содержится
        такое лицо.
      </p>

      <h3>2 Общие положения</h3>
      <p>
        2.1 Заказчик осуществляет прием Заявок исключительно через Сайт.
      </p>
      <p>
        2.2 Размещая Заявку на Сайте, Заказчик соглашается с условиями
        исполнения Заявки, изложенными в настоящей оферте.
      </p>
      <p>
        2.3 Настоящая оферта являются публичной офертой в соответствии со ст.
        435 и п. 2 ст. 437 ГК РФ.
      </p>
      <p>
        2.4 Условия настоящей оферты могут быть изменены Исполнителем в
        одностороннем порядке путем размещения оферты в новой редакции на Сайте.
        Вносимые изменения, при этом, вступают в силу по истечению 10 (Десяти)
        календарных дней с момента размещения оферты в новой редакции на Сайте,
        если иное не предусмотрено условиями настоящей оферты.
      </p>
      <p>
        2.5 Оферта признается акцептованной Посетителем Сайта с момента
        размещения Заявки на Сайте.
      </p>
      <p>
        2.7 Указывая при размещении Заявки любую информацию, содержащую
        персональные данные, Посетитель Сайта тем самым дает согласие
        Исполнителю на использование такой информации любым не запрещенным
        действующим законодательством РФ способом с целью заключения,
        исполнения, изменения и прекращения договора, заключенного на основании
        настоящей оферты, а также третьими лицами, привлекаемыми Исполнителем
        для этих целей, а также дает Исполнителю согласие для осуществления
        рассылок рекламного и информационного характера, содержащих информацию о
        скидках, предстоящих и действующих акциях и других мероприятиях
        Исполнителя.
      </p>
      <p>
        2.8 Размещая Заявку, Пользователь соглашается с тем, что Исполнитель
        может поручить исполнение Договора третьему лицу, при этом оставаясь
        ответственным за его исполнение.
      </p>
      <p>
        2.9 Размещая Заявку, Пользователь подтверждает, что ознакомлен с
        перечнем предметов, запрещенных действующим законодательством РФ к
        передаче Абоненту, и несет полную ответственность за соответствие
        передаваемых фотографий требованиям действующего законодательства РФ.
      </p>
      <p>
        2.10 В рамках исполнения договора, заключенного на основании настоящей
        оферты, Исполнитель предоставляет Заказчику информационное сопровождение
        заключенного Пользователем с Продавцом Договора.
      </p>

      <h3>3 Предмет и иные условия договора возмездного оказания услуг</h3>
      <p>
        3.1 Предметом договора возмездного оказания услуг, заключенного на
        основании настоящей оферты (далее – Договор), является оказание
        Исполнителем Заказчику услуг по передаче фотографий, приложенных к
        Заявке, Абоненту, на фотобумаге в запечатанном конверте.
      </p>
      <p>
        3.2 Моментом оказания услуг по Договору является поступление в
        отделение АО «Почта России», обслуживающее учреждение Федеральной службы исполнения наказаний,
        указанного в Заявке, фотографий, приложенных к Заявке.
      </p>
      <p>
        3.3 Срок оказания услуг по Договору составляет не более 20 (двадцати)
        рабочих дней с момента получения Исполнителем оплаты по Договору.
      </p>
      <p>
        3.4 Цена услуг по Договору определяется на основании количества
        фотографий, приложенных Заказчиком к заявке, а также на основании
        тарифа, размещенного на Сайте.
      </p>
      <p>
        3.5 Оплата услуг по Договору производится путем перечисления денежных
        средств, размер которых определен в соответствии с п. 3.4 Договора, на
        расчетный счет Исполнителя, указанный на странице Сайта, открываемой
        после заполнения Заявки.
      </p>
      <p>
        3.6 Исполнитель не несет ответственности за изменение сроков оказания
        услуг по Договору, обусловленных обстоятельствами, за которые
        Исполнитель не отвечает. К таким обстоятельствам могут относится, в том
        числе, но не ограничиваясь: обстоятельства форс-мажора, действия органов
        исполнительной власти, администрации учреждения ФСИН, любых иных третьих
        лиц. Исполнитель также не несет ответственности за невозможность
        оказания услуг в случае, если фотографии, подлежащие передаче, не
        соответствуют требованиям действующего законодательства РФ или запрещены
        к передаче Абоненту.
      </p>
      <p>
        3.7 Исполнитель не несет ответственность за действия третьих лиц,
        в том числе, но не ограничиваясь ненадлежащими действиями АО «Почта России»,
        администрации учреждения, в котором пребывает Адресат.
      </p>
      <p>
        3.8 Договор действует до момента исполнения принятых на себя сторонами
        по договору обязательств.
      </p>
      <p>
        3.9 Исполнитель вправе изменить условия Договора в одностороннем порядке
        путем изменения условий оферты, размещенной на Сайте. Условия Договора,
        в этом случае, изменяются в срок и на условиях, указанных в п. 2.4
        настоящей оферты.
      </p>

      <h3>4 Заключительные условия</h3>
      <p>
        4.1 Признание судом недействительности какого-либо положения настоящей
        оферты не влечет за собой недействительность остальных положений.
      </p>
      <p>
        4.2 Все вопросы, не нашедшие своего разрешения в тексте настоящей
        оферты, разрешаются в соответствии с нормами действующего
        законодательства РФ.
      </p>

      <h3>Реквизиты исполнителя</h3>
      <p>ООО «Регион Телеком»</p>
      <p>Юридический адрес: г. Тула, ул. Металлургов, д. 69, оф. 83</p>
      <p>ИНН 7105503773</p>
      <p>КПП 710501001</p>
      <p>ОКПО 86775874</p>
      <p>
        ОКВЭД 61.1; 26.1; 26.3; 26.4; 62.0; 62.02; 62.09; 63.1; 63.11.1; 68.2;
        68.3; 73.1; 74.20; 74.30; 82.92; 95.1
      </p>
      <p>ОГРН 1087154023000</p>
      <p>Р/сч 40702810887760000726</p>
      <p>Кор/счет 30101810000000000256</p>
      <p>БИК 044525256</p>
      <p>В ПАО РОСБАНК г. Москва</p>
      <p>
        Директор Малахов Сергей Андреевич действует на основании Устава
      </p>
      <h3>Контакты</h3>
      <p>
        Email: info@sizo.ru<br />
        Телефон: +7 (487) 2604871
      </p>
      </>
    )
  }

  render() {
    const { isOpen, setOpenModal, children, rules, things, userRules, className } = this.props;
    return (
      <div className={`modal_queue__dark ${isOpen ? 'show' : ''}`}>
        <div
          className='close-click'
          onClick={() => {
            setOpenModal();
          }}
        ></div>
        <div className={`modal_queue ${className}`}>
          <img
            src={CloseSVG}
            className='modal_queue__close'
            onClick={() => {
              setOpenModal();
            }}
          />
          <div className={`modal_queue__content`}>
            {children}
            {rules && this.contentRules()}
            {things && this.contentThings()}
            {userRules && this.contentUsageRules()}
          </div>
        </div>
      </div>
    );
  }
}

export default Modal;
