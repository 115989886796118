import request from '../../../lib/request';
import { ACCOUNT_END_POINT } from '../../../lib/request/config';
import gql from "graphql-tag";

const QUERY = (contactId: string): any => {
  return gql`
          {
  userContacts(contact_id: "${contactId}"){
    data{
      companies{
        company_id
      }
      contact_id
      date_of_birth
      firstname
      lastname
      middlename
      relation_id
    }
  }
}`
};

export function contactQuery(contactId: string, success: (response: any) => void) {
  const {body} = QUERY(contactId).loc.source;
  const operation = 'query';

  return request({
    method: 'POST',
    body: JSON.stringify({[operation]: body}),
    headers: { 'Content-Type': 'application/json' },
    success
  });
}