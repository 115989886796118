import { ACCOUNT_END_POINT, BASE_URL, END_POINT } from '../lib/request/config';
import { store } from '../redux/createStore';
import { selectAuthAccessToken, selectAuthGuest, selectAuthRefreshToken, setAuthDataAction } from '../redux/reducers/authReducer';
import gql from "graphql-tag";
import isEmpty from 'lodash/isEmpty';
import { setAuthDataInStorage } from '../models/AuthModel';

let fetch = require('node-fetch').default;
// if (!isServer) ({fetch} = window);

// const getUrl = (endPoint: string) => {
//   if (isServer) return END_POINT;
//   let baseUrl = '';
//   switch (endPoint) {
//     case END_POINT:
//       baseUrl = BASE_URL;
//       break;
//     case ACCOUNT_END_POINT:
//       baseUrl = ACCOUNT_BASE_URL;
//       break;
//   }
//   return isProduction ? END_POINT : endPoint;
// };

const REFRESH_TEMPLATE = (): any => {
  return gql`
mutation refreshMutation($inputRefresh: InputRefresh) {
  refresh(input: $inputRefresh) {
    access_token
    iat
    refresh_token
  }
}`
};

let refreshingPromise: any = null;
export const customFetch = async (uri: any, options: any = {}, isBlob?: boolean) => {
  const state = store.getState();
  options.headers = options.headers || {};
  if (selectAuthAccessToken(state)) {
    if (options.body.indexOf("createTransferOrder") !== -1) {
    options.headers['Authorization'] = `Bearer  ${selectAuthAccessToken(state)}`
    } else {
    options.headers['Authorization'] = `Bearer ${selectAuthAccessToken(state)}`
    }
  }
  // const response = await fetch(getUrl(uri), options);
  const response = await fetch(uri, options);
  const responseJson = await response.json();
  if (responseJson && !isEmpty(responseJson.errors) && responseJson.errors[0].message === 'ERR_UNAUTH' && selectAuthRefreshToken(state)) {
    if (!refreshingPromise) {
      const {body} = REFRESH_TEMPLATE().loc.source;
      const operation = 'query';
      const data = JSON.stringify({
        operationName: null,
        variables: {
          inputRefresh: {
            refresh_token: selectAuthRefreshToken(state)
          }
        },
        [operation]: body
      });
      refreshingPromise = fetch(ACCOUNT_END_POINT, {
        method: 'POST',
        body: data,
        headers: {
          'Content-Type': 'application/json'
        }
      });
      const refreshResponse = await refreshingPromise;
      refreshingPromise = null;
      const json = await refreshResponse.clone().json();
      if (json.data.refresh) {
        const {access_token, iat, refresh_token} = json.data.refresh;
        setAuthDataInStorage(access_token, refresh_token, iat, !!selectAuthGuest(state));
        store.dispatch(setAuthDataAction(access_token, refresh_token, !!selectAuthGuest(state)));
        if (options.body.indexOf("createTransferOrder") !== -1) {
          options.headers['Authorization'] = `Bearer  ${access_token}`
        } else {
            options.headers['Authorization'] = `Bearer ${access_token}`
        }
      }
    } else {
      const refreshResponse = await refreshingPromise;
      const json = await refreshResponse.clone().json();
      if (json.data.refresh) {
        const {access_token} = json.data.refresh;
        if (options.body.indexOf("createTransferOrder") !== -1) {
            options.headers['Authorization'] = `Bearer  ${access_token}`
        } else {
            options.headers['Authorization'] = `Bearer ${access_token}`
        }
      }
    }
    const retryInitialResponse = await fetch(uri, options);
    const retryInitialResponseJson = await retryInitialResponse.json();
    return {
      response: retryInitialResponseJson,
      status: retryInitialResponse.status
    }
  } else {
    return {
      response: responseJson,
      status: response.status
    }
  }
}
