import gql from 'graphql-tag';
import { IGQLRequest } from '../../types/app';
import { mapShortDistricts } from '../mappers';

interface IDistrictController {
  get: () => IGQLRequest;
}

class District {
  static get() {
    const filters = '(search:{order:{name:asc}})';
    
    return {
      mapper: mapShortDistricts,
      query: gql`
        {
          districts${filters}{
            data{
              name
              district_id
              regions{
                name
                slug
                region_id
                companies{
                  company_id
                }
              }
            }
          }
        }
      `
    };
  }
}

export default District as IDistrictController;
