import { IContactFieldsType } from './types/IContactFieldsType';
import find from 'lodash/find';

export function contactFullName(contact: IContactFieldsType) {
  let fullName = contact.lastname;
  if (contact.firstname) {
    fullName += ` ${contact.firstname}`
    if (contact.middlename) {
      fullName += ` ${contact.middlename}`
    }
  }
  return fullName;
}

export function getRegionByCompanyId(companyId: string, regions: any) {
  if (regions && regions.data) {
    const region = find(regions.data, (r: any) => find(r.companies, (c: any) => c.id === companyId));
    if (region) {
      return region;
    }
  }
  return {};
}


export function getCompanyById(companyId: string, regions: any) {
  const region = getRegionByCompanyId(companyId, regions);
  const company = find(region.companies, (c: any) => c.id === companyId);
  if (company) {
    return company;
  }
  return {}
}

export function getRelationById(relationId: string, relations: any) {
  if (relations && relations.data) {
    const relation = find(relations.data, (c: any) => c.relation_id == relationId);
    if (relation) {
      return relation;
    }
  }
  return {}
}

export function getYearFromISOString(date_of_birth: string | undefined) {
  try {
    if (date_of_birth) {
      return new Date(date_of_birth).getFullYear().toString();
    } else {
      return undefined;
    }
  } catch (e) {
    return date_of_birth;
  }
}