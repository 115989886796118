import moment from 'moment';
import React, { Component } from 'react';
import { Props, State } from './interface';
import bind from '../../../lib/decorators/bind';
import { IMetaTags } from '../../../types/server';
import withApollo from '../../../lib/HOC/withApollo';

import Tag from '../../../components/Tag';
import Html from '../../../components/Html';
import Page from '../../../components/Page';
import Button from '../../../components/Button';
import Wrapper from '../../../components/Wrapper';
import Typography from '../../../components/Typography';
import getTypographyProps from '../../../lib/getTypographyProps';
import Service from '../../../components/Service/Service';
import PromoWidgets from '../../../components/PromoWidgets';

import Comments from '../../../components/Comments/Comments';

import {ReactComponent as Map} from '../../../images/map.svg';
import {ReactComponent as Phone} from '../../../images/phone.svg';
import {ReactComponent as Photo} from '../../../images/service/icon/photo.svg';
import { withRouter } from "react-router-dom";
import { getPathnameModel, MainRoutes } from '../../../components/Router/config/routes';

import News, { TNewsById } from '../../../gql/actions/News';
import ErrorPage from '../../common/ErrorPage'

import './ArticlePage.scss';
import MetaTags from '../../../components/MetaTags';
import Banner from '../../../components/Banner';

const isArticlesPage = (location: any) =>
  location.pathname.indexOf(MainRoutes.articlesPage.path) !== -1;

class ArticlePage extends Component<Props, State> {

  state: State = {
    isDesktop: true
  };

  componentDidMount() {
    //@ts-ignore
    if (!isNaN(Number(this.props.match.params.id))) {
      this.props.history.push(isArticlesPage(this.props.location) ? "/articles" :"/news")
    }
    if (window && window.innerWidth < 600 && this.state.isDesktop) {
      this.setState({
        isDesktop: false
      })
    }
    window && window.addEventListener('resize', () =>
    {
      if (window.innerWidth < 600 ) {
        this.setState({
          isDesktop: false
        })
      }
      else{
        this.setState({
          isDesktop: true
        })
      }
    }
    );
  }

  renderAside() {
    return (['mail', 'transfer', 'photo', 'video_talks'].map((a: any, i) => (
      <Service
        key={`${a} ${i}`}
        isShort
        kind='widget'
        type={a}
      />)));
  }

  renderCompaniesInfo() {
    const { companies } = this.props.article.data[0];
    const path = getPathnameModel(MainRoutes.institutionByIdPage.path);
    if (companies.length) {
      return (
        <div className='page__companiesInfo service_short'>
          <Typography
            {...getTypographyProps('heading', 'm', 'dark-text')}
            children={companies[0].shortName}
          />
          <Typography
            className='page__companiesInfo__blockInfo'
            isDivElement
            {...getTypographyProps('text', 's', 'text')}
          >
            <div className='page__companiesInfo__fullName'>
              <p>{companies[0].fullName}</p>
            </div>
            <div className='page__companiesInfo__adress'><Map />
              <p><span>Адрес:</span>{companies[0].address}</p>
            </div>
            {companies[0].phone && (
            <div>
              <Phone />
              <p>
                <span>Телефон:</span>
                {companies[0].phone}
              </p>
            </div>)
            }
            <Typography
              isLinkElement
              {...getTypographyProps('text', 's', 'main')}
              to={path.replace(':id', companies[0].slug).replace(':region', companies[0].region.slug)}
            >Страница учреждения
            </Typography>
          </Typography>
        </div>
      );
    }
  }

  renderLinksToRelatedArticles() {
    const isArticles = isArticlesPage(this.props.location);
    const path = isArticles ? MainRoutes.articleByIdPage.path : MainRoutes.newsByIdPage.path;
    let filteredArticleByTag: any[] = [];
    if (this.props.article.data[0].similar.length !== 0) {
      const articlesByTag = this.props.article.data[0].similar.filter((item) =>
        this.props.article.data[0].tags[0] === item.value);

      filteredArticleByTag = articlesByTag[0].content.filter((item: { id: string; }) =>
        item.id !== this.props.article.data[0].id);
    }

    if (filteredArticleByTag.length > 0) {
      return (
        <div className='page__linksToRelatedArticles service_short'>
          <Typography
            {...getTypographyProps('heading', 'm', 'dark-text')}
            children='Читайте также'
          />
          <hr />
          {filteredArticleByTag.map((item, i) => (i < 3 ? (
            <Typography
              isLinkElement
              {...getTypographyProps('text', 's', 'text')}
              to={getPathnameModel(path).replace(':slug', item.slug)}
              key={i}
            >
              {!item.image.length ? <Photo /> : <img
                alt='фото'
                src={item.image}
              />}
              <p>{item.title}</p>
            </Typography>)
          : null)
          )}
        </div>
      );
    }
  }

  renderButton() {
    const { history } = this.props;
    if (history.length < 3) return null;

    return (
      <div className={this.classNames.button}>
        <Button
          isTransparent
          label='Вернуться'
          onClick={this.props.history.goBack}
          withArrowBack
        />
      </div>
    );
  }

  renderTitle() {
    const { title } = this.props.article.data[0];
    return (
      <Typography
        className={this.classNames.title}
        {...getTypographyProps('heading', '2xl', 'dark-text')}
        children={title}
      />
    );
  }

  @bind
  renderTag() {
    const { tags } = this.props.article.data[0];
    if (!tags[0]) return null;

    return (<Tag
      children={tags[0]}
      className={this.classNames.tag}
    />);
  }

  @bind
  getText(value: number) {
    let word = 'минут';

    const string = value.toString();
    let numeral = parseInt(string[string.length - 1], 10);
    if (value === 11) numeral = 5;

    if (numeral === 1) word = 'минута';
    else if (numeral > 1 && numeral < 5) word = 'минуты';
    else if (numeral >= 5) word = 'минут';

    return `${value} ${word}`;
  }

  renderInfo() {
    const { date } = this.props.article.data[0];

    return (
      <Typography
        className={this.classNames.info}
        isDivElement
        {...getTypographyProps('text', 's', 'text')}
      >
        <time>{moment.utc(date).locale('ru').format('DD MMMM YYYY')}</time>
        {/* <div>
          <Svg image={clock} /> Время чтения: {this.getText(5)}
        </div>
        <div>
          <Svg image={comment} /> 4 комментария
        </div>
        <div>
          <Svg image={eye} /> 256
        </div> */}
      </Typography>
    );
  }

  renderComments() {
    return (
      <Comments account={this.props.account} uid={this.props.article.data[0].id}/>
    );
  }

  renderHTML() {
    const { content, image, title } = this.props.article.data[0];
    return <Html html={content} image={image} title={title} />;
  }

  renderPreload() {
    return (
      <Page page='article'>
        <Wrapper isPageWrapper>
          <div className={this.classNames.isPreload}>
            <div className={this.classNames.button} />
            <div className={this.classNames.tag} />
            <div className={this.classNames.title} />
            <div className={this.classNames.info} />
            <div className={this.classNames.photo} />
            <Html isPreload />
            <div className={this.classNames.comments} />
          </div>
        </Wrapper>
      </Page>
    );
  }

  classNames = {
    tag: 'page__tag',
    info: 'page__info',
    title: 'page__title',
    photo: 'page__photo',
    button: 'page__button',
    isPreload: 'page_preload',
    comments: 'page__comments'
  };

  setMeta(){
    const meta: IMetaTags = {};
    const { id } = this.props.match.params as { id: string };
    const url  = this.props.match.url;
    const [type] = this.props.match.path.substr(1).split('/');

    let extendTitle;
    if (type === 'articles') {
      extendTitle = '. Полезные статьи для осужденных и их родственников';
    } else if (type === 'news') {
      extendTitle = '. Новости для осужденных и их родственников';
    }

    if (this.props.article && this.props.article.data[0]) {
      let text = this.props.article.data[0].content.replace(/<[^>]*>/gm, '');
      text = text.replace(/([\n\r]{2,})/gm, ' ');
      meta.title = this.props.article.data[0].title + extendTitle;
      const [first, second] = text.split(/\.(?=$|\s+)/);
      meta.description = first + second;
      if (type === 'articles') {
        meta.description = 'Полезные статьи для осужденных и их родственников. ' +  meta.description;
      } else if (type === 'news') {
        meta.description = 'Новости для осужденных и их родственников' + meta.description;
      }

      meta.json_ld = `{
        "@context": "http://schema.org",
        "@type":"${type === 'articles' ? 'Article' : 'NewsArticle' }",
        "mainEntityOfPage":{
            "@type": "WebPage",
            "@id": "${id}"
        },
        "publisher": {
            "@type": "Organization",
            "name": "sizo.ru",
            "logo": {
                "@type": "ImageObject",
                "url": "https://sizo.ru/favicon.ico",
                "width": "40",
                "height": "40"
            }
        },
        "name": "${this.props.article.data[0].title}",
        "headline": "${this.props.article.data[0].title}",
        "articleBody": "${first}.${second}",
        "datePublished": "${this.props.article.data[0].date}",
        "dateModified": "${this.props.article.data[0].update_date}",
        "image": {
            "@type": "ImageObject",
            "url":  "https://sizo.ru${this.props.article.data[0].image}"
        },
        "author": {
            "@type": "Person",
            "name": "sizo.ru"
        }    }`

      return (
        <MetaTags
        title={meta.title}
        description={meta.description}
        jsonLd={meta.json_ld}
        canonical={url}
        />
      )
    }
  }

  getBanner(position: 'top' | 'bottom') {
    const banners = this.props?.article?.data?.[0]?.banners;
    return banners && banners.find(banner => banner.position === position);
  }

  render() {

    const { data, loading } = this.props.article;
    if (!this.props.article.loading && this.props.article.data.length === 0) {
      return (<ErrorPage/>)
    }
    const bannerTop = this.getBanner('top');
    const bannerBottom = this.getBanner('bottom');

    if (loading || !data.length) return this.renderPreload();

    return (
      <Page page='article'>
        {this.setMeta()}
        <Wrapper isPageWrapper>
          <div className='page__article'>
            {this.renderButton()}
            {bannerTop ? <Banner {...bannerTop} /> : <PromoWidgets type='transfer' />}
            {this.renderTag()}
            {this.renderTitle()}
            {this.renderInfo()}
            {this.renderHTML()}
            {bannerBottom ? <Banner {...bannerBottom} /> : <></>}
          </div>
          { this.state.isDesktop  && <div className='page__services'>
            {this.renderAside()}
            {this.renderCompaniesInfo()}
            {this.renderLinksToRelatedArticles()}
          </div>}
        </Wrapper>
      </Page>
    );
  }
}

function mapQuery(props: Props) {
  const isArticles = isArticlesPage(props.location);
  const { slug } = props.match.params as { slug: string };
  const type: TNewsById = isArticles ? 'article' : 'story';
  return {
    article: News.getBySlug(type, slug),
  };
}


export default  withRouter(withApollo(mapQuery, undefined)(ArticlePage));
