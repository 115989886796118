/* eslint-disable import/no-duplicates */
import { Props } from './interface';
import React, { Component } from 'react';
import bind from '../../lib/decorators/bind';
import { ISelectItem } from '../../types/app';

import Select, { components } from 'react-select';
import { ValueType } from 'react-select';

import {ReactComponent as Arrow} from './../../images/arrow-down.svg';
import './Select.scss';
import classNames from 'classnames';
import Typography from '../Typography';
import getTypographyProps from '../../lib/getTypographyProps';

class SelectComp extends Component<Props> {
  @bind
  onChange(value: ValueType<ISelectItem, any>) {
    if (this.props.onChange) this.props.onChange(value as ISelectItem);
  }

  renderComponents() {
    return {
      DropdownIndicator: () => (
        <div
          className='select__indicator'
          
        >
          <Arrow className="svg-image" />
          </div>
      ),
      Input: (data: any) => {
        const props = {
          ...data,
          'aria-autocomplete': 'both',
          'aria-haspopup': 'false',
          autoCapitalize: 'off',
          autoComplete: 'new-password',
          autoCorrect: 'off',
          spellCheck: 'false'
        };

        return <components.Input {...props} />;
      }
    };
  }

  renderNoMessage() {
    return 'Ничего не найдено';
  }

  render() {
    const {errorMessage, canShowValidation} = this.props;

    const customStyles = {
      container: (provided: any, state: any) => ({
        ...provided,
        zIndex: state.isFocused ? '55' : '52',
      })
    };
    const { blurInputOnSelect } = this.props;

    const component = (
      <Select
        blurInputOnSelect={blurInputOnSelect != undefined ? blurInputOnSelect : true}
        className={classNames('select', errorMessage ? 'select_error' : '', this.props.className ?? '')}
        classNamePrefix='select'
        components={this.renderComponents()}
        isDisabled={this.props.isDisabled}
        noOptionsMessage={this.renderNoMessage}
        onChange={this.onChange}
        options={this.props.items}
        placeholder={this.props.placeholder}
        styles={customStyles}
        value={this.props.value}
        name={this.props.name}
      />
    );

    if (canShowValidation) {
      return (
        <div className="select__select-and-error-container">
          {component}
          {errorMessage &&
          <div className="select__error-message">
            <Typography
              children={errorMessage}
              className='validation-error'
              {...getTypographyProps('text', 'xs', 'red')}
              data-testid="form-error"
            />
          </div>}
        </div>
      )
    } else {
      return component;
    }
  }
}

export default SelectComp;
