const MoneyIcon: React.FC = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='48'
      height='48'
      viewBox='0 0 48 48'
      fill='none'
    >
      <circle cx='24' cy='24' r='24' fill='#3FCC6F' />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M30.2836 12.1796C29.878 11.206 28.7862 10.7147 27.7885 11.0568L14.6756 15.5541C14.5564 15.595 14.4499 15.6561 14.3587 15.7324C12.8521 16.2579 11.6851 17.5173 11.4176 19.2005C11.197 20.5878 11 22.5211 11 25C11 27.4789 11.197 29.4122 11.4176 30.7995C11.7394 32.8241 13.3625 34.2356 15.3127 34.4943C17.1516 34.7382 19.8641 35 23.0407 35C26.2172 35 28.9297 34.7382 30.7686 34.4943C32.719 34.2356 34.3421 32.8234 34.6638 30.799C34.7754 30.0969 34.881 29.2549 34.9581 28.2729C35.5961 27.7228 36 26.9086 36 26V24C36 23.0915 35.5961 22.2772 34.9581 21.7271C34.881 20.7452 34.7754 19.9031 34.6638 19.201C34.3992 17.5359 33.2541 16.2849 31.7706 15.7494L30.2836 12.1796ZM29.4358 15.3455L28.4374 12.9487L22.4473 15.003C22.6432 15.001 22.841 15 23.0407 15C25.5199 15 27.7166 15.1595 29.4358 15.3455ZM23.0407 17C19.9709 17 17.3483 17.2532 15.5757 17.4884C14.4191 17.6418 13.5629 18.4439 13.3928 19.5145C13.1885 20.7992 13 22.6276 13 25C13 27.3724 13.1885 29.2008 13.3928 30.4856C13.5629 31.5561 14.4191 32.3582 15.5757 32.5117C17.3483 32.7468 19.9709 33 23.0407 33C26.1105 33 28.733 32.7468 30.5056 32.5117C31.662 32.3583 32.5185 31.5558 32.6886 30.4851C32.7575 30.0518 32.8246 29.5568 32.8832 29H29C27.3431 29 26 27.6569 26 26V24C26 22.3432 27.3431 21 29 21H32.8832C32.8246 20.4432 32.7575 19.9482 32.6886 19.5149C32.5185 18.4443 31.662 17.6418 30.5056 17.4884C28.733 17.2532 26.1105 17 23.0407 17ZM29 23C28.4477 23 28 23.4477 28 24V26C28 26.5523 28.4477 27 29 27H33C33.5523 27 34 26.5523 34 26V24C34 23.4477 33.5523 23 33 23H29Z'
        fill='white'
      />
    </svg>
  );
};

export default MoneyIcon;
