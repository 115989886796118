export function parseStringToNumber(string: string) {
  const completedString = string.replace(/\s/g, '');
  return Number(completedString.replace(',', '.'));
}

export function parseNumberToString(number: number, withoutMaximumFraction?: boolean) {
  const options = { maximumFractionDigits: withoutMaximumFraction ? 20 : 2 };

  let completedNumber = parseStringToNumber(String(number));
  if (completedNumber < 0) completedNumber = 0;

  const completedString = String(completedNumber.toLocaleString('ru-RU', options));
  return completedString.replace('.', ',');
}
