import React from 'react';
import { Props } from './interface';

import Button from '../Button';
import Typography from '../Typography';
import getTypographyProps from '../../lib/getTypographyProps';

import './QuestionChat.scss';

class QuestionChat extends React.Component<Props> {
  getClassName() {
    let className = 'question-chat';
    if (this.props.className) className += ` ${this.props.className}`;

    return className;
  }

  onCallChat() {}

  classNames = {
    main: 'question-chat'
  };

  render() {
    return (
      <div className={this.getClassName()}>
        <Typography
          {...getTypographyProps('heading', 'l', 'dark-text')}
          children={this.props.title}
        />
        <Typography {...getTypographyProps('text', 'm', 'main')}>Другие способы связи</Typography>
      </div>
    );
  }
}

export default QuestionChat;
