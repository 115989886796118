import moment from 'moment';
import { Props } from './interface';
import React, { useEffect, useState } from 'react';

import Tag from '../Tag';
import Link from '../Link';
import Typography from '../Typography';
import getTypographyProps from '../../lib/getTypographyProps';
import getBackgroundStyle from '../../lib/getBackgroundStyle';

import 'moment/locale/ru';
import './ArticleCard.scss';
import { getPathnameModel, MainRoutes } from '../Router/config/routes';

const classNames = {
  block: 'article-card',
  tag: 'article-card__tag',
  time: 'article-card__time',
  image: 'article-card__image',
  title: 'article-card__title',
  isPreload: 'article-card_preload',
};

const ArticleCard = (props: Props) => {
  const [path, setPath] = useState<string | string[]>('');
  const [to, setTo] = useState('');
  const [name, setName] = useState('');
  const [renderedClasses, setRenderedClasses] = useState('')

  useEffect(() => {
    if (props.type === 'article') {
      setPath(MainRoutes.articleByIdPage.path);
    } else {
      setPath(MainRoutes.newsByIdPage.path);
    }
    setTo(getPathnameModel(path).replace(':slug', props.slug));
    setName(to.split('/')[2]);

    let className = classNames.block;
    if (props.className) className += ` ${props.className}`;
    if (props.isPreload) className += ` ${classNames.isPreload}`;

    setRenderedClasses(className)

  }, [props.type, path, props.slug, to, props.className, props.isPreload])

  const renderPreload = () => {
    return (
      <div className={renderedClasses}>
        <div className={classNames.image} />
        <div className={classNames.tag} />
        <div className={classNames.title} />
        <div className={classNames.time} />
      </div>
    );
  };
  const { isPreload } = props;
  if (isPreload) return renderPreload();

  const { image, title, tags, date } = props;

  return (
    <div className={renderedClasses}>
      <Link
        className={classNames.image}
        style={getBackgroundStyle(image)}
        children={name}
        to={to}
      />
      <Typography
        {...getTypographyProps('text', 's', 'text')}
        isDivElement
        style={{ display: 'flex' }}
      >
        {tags[0] &&
          tags.map((tag, i) => (
            <Tag children={tag} key={i} className={classNames.tag} />
          ))}
      </Typography>
      <Typography
        {...getTypographyProps('heading', 'm', 'dark-text')}
        children={title}
        className={classNames.title}
        to={to}
      ></Typography>
      <Typography
        className={classNames.time}
        isTimeElement
        {...getTypographyProps('text', 's', 'text')}
        children={moment(date).locale('ru').format('DD MMMM YYYY')}
      />
    </div>
  );
};

export default ArticleCard;
