import React, { Component } from 'react'
import { Props } from './interface'
import './StatusBar.scss'

const propsExt = { state: 'default', picked: false, halfLine: false }

class StatusBar extends Component<Props>{
    state = {
        collection: [
            { id: 'created', name: 'Принят', date: '', ...propsExt },
            { id: 'paid', name: 'Оплачен', date: '', ...propsExt },
            { id: 'shipped', name: 'Передан в доставку', date: '', ...propsExt },
            { id: 'delivered', name: 'В городе вручения', date: '', ...propsExt },
            { id: 'success', name: 'Доставлен', date: '', ...propsExt },
        ],
        pickedPos: 0,
        lastPos: 0
    }

    private formattingDate(date: string) {
        const obj = new Date(date);
        const day = ('0' + obj.getDate()).slice(-2);
        const month = ('0' + (obj.getMonth() + 1)).slice(-2);
        const year = obj.getFullYear().toString().match(/\d{2}$/);
        const hour = ('0' + obj.getHours()).slice(-2);
        const minute = ('0' + obj.getMinutes()).slice(-2);
        return `${day}.${month}.${year} ${hour}:${minute} (МСК)`;
    }

    componentDidMount() {
        this.state.collection.forEach((item: any, index) => {
            const found = this.props.collection.find(collect => collect.id === item.id)
            if (found) {
                this.setCollectionState({ index, date: found.date })
                this.setState({ pickedPos: index })
            }
        })
        if (this.state.pickedPos === 0) this.setCollectionState({ pickedPos: this.state.pickedPos })
    }

    componentDidUpdate(prevProps: any, prevState: any) {
        if (prevState.pickedPos !== this.state.pickedPos) {
            this.setCollectionState({ pickedPos: this.state.pickedPos })
            this.setStateLine(this.state.pickedPos)
        }
        if (prevState.lastPos !== this.state.lastPos) {
            if (this.state.pickedPos === this.state.lastPos && this.state.pickedPos !== this.state.collection.length - 1) {
                this.setCollectionState({ pickedPos: this.state.pickedPos, halfLine: true })
            }
            if (this.state.pickedPos > this.state.lastPos) {
                this.setCollectionState({ pickedPos: this.state.lastPos, halfLine: true })
            }
        }
    }

    setStateLine(currIndex: number) {
        this.state.collection.forEach((item: any, index) => {
            if (item.state === 'active') {
                this.setCollectionState({ pickedPos: index, halfLine: false })
                this.setState({ lastPos: index })
            }
        })
    }

    setCollectionState({ index, date, pickedPos, halfLine }: any) {
        this.setState((prevState: any) => {
            let collectionCopy = prevState.collection
            if (date) {
                collectionCopy[index].date = this.formattingDate(date)
                collectionCopy[index].state = 'active'
            }
            if (pickedPos && halfLine === undefined) {
                collectionCopy[pickedPos].picked = true
            }
            if (pickedPos && halfLine !== undefined) {
                collectionCopy[pickedPos].halfLine = halfLine
            }
            return { collection: collectionCopy }
        })
    }

    classNames = {
        li: 'status__li',
        active: 'status_active',
        picked: 'status_picked',
        line: 'status__line',
        half_line: 'status__line_half',
        item: 'status__item',
        top: 'status__top',
        num: 'status__num',
        text: 'status__text',
        middle: 'status__middle',
        icon_container: 'status__icon-container',
        icon: 'status__icon',
        mark: 'status__mark',
        bottom: 'status__bottom',
        sub_text: 'status__sub-text'
    }

    render() {
        const { collection } = this.state
        const {className} = this.props

        return (
            <ul className={`status status_static ${className ? className : ''}`}>
                {collection.map((item, index) => {
                    return (
                        <li
                            key={index}
                            className={this.classNames.li +
                                ' ' + (item.state === 'active' ? this.classNames.active : '') +
                                ' ' + (item.picked === true ? this.classNames.picked : '')}>

                            <div
                                className={
                                    this.classNames.line +
                                    ' ' + (item.state === 'active' && item.halfLine == true ? this.classNames.half_line : '')
                                }>

                            </div>
                            <div className={this.classNames.item}>
                                <div className={this.classNames.top}>
                                    <p className={this.classNames.num}>{index + 1}</p>
                                    <p className={this.classNames.text}>{item.name}</p>
                                </div>

                                <div className={this.classNames.middle}>
                                    <div className={this.classNames.icon_container}>
                                        <div className={this.classNames.icon}>
                                            <svg className={this.classNames.mark} viewBox="0 0 9 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1 3.5L3 5.5L7.5 1" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>

                                <div className={this.classNames.bottom}>
                                    <p className={this.classNames.num}></p>
                                    <p className={this.classNames.text + ' ' + this.classNames.sub_text}>{item.date}</p>
                                </div>
                            </div>
                        </li>)
                })}
            </ul>
        )
    }
}

export default StatusBar