import SERVICE from '../lib/getServiceType';
import getLocalStorage from '../lib/storage/getLocalStorage';
import { IGlobalMailState, IGlobalCommonState, IGlobalFotoState } from '../types/redux';
import { InitialLinkState, InitialLinkStateType } from './reducers/globalLink';

export const initialMailGlobalState: IGlobalMailState = {
  mailPageState: {
    activeRegion: undefined,
    activeInstitution: undefined,

    sum: '',
    mail: '',
    firstName: '',
    lastName: '',
    birthDay: '',
    middleName: '',

    senderPhone: getLocalStorage('phone') || '',
    senderEmail: getLocalStorage('email') || '',
    senderLastName: getLocalStorage('lastName') || '',
    senderFirstName: getLocalStorage('firstName') || '',
    senderMiddleName: getLocalStorage('middleName') || '',

    minSenderPhoneLength: 0,
    images: [],
    sheets: 0,
    blanks: 0,

    isDataSave: false,
    isSnilsSend: false,
    isColoredPhotos: true,
    isLoadingOrder: false
  },
  auth: {},
  account: {},
  contact: {},
  globalLinkState: InitialLinkState
};

export const initialFotoGlobalState: IGlobalFotoState = {
  fotoPageState: {
    firstName: '',
    lastName: '',
    birthDay: '',
    middleName: '',

    senderPhone: getLocalStorage('phone') || '',
    senderEmail: getLocalStorage('email') || '',
    senderLastName: getLocalStorage('lastName') || '',
    senderFirstName: getLocalStorage('firstName') || '',
    senderMiddleName: getLocalStorage('middleName') || '',

    textMessage: '',
    images: [],
    isDataSave: false,
    isLoadingOrder: false,
    uploadId: '',
    numberOfImages: 0,
    imagesError: false,
    isOpenModal: false
  },
  auth: {},
  account: {},
  contact: {}
}

export const initialCommonState: IGlobalCommonState = {
  auth: {},
  account: {},
  contact: {},
  isFormError: false,
  globalLinkState: InitialLinkState
};

const getInitialState = () => {
  if (SERVICE === 'MAIL') return initialMailGlobalState;
  return initialCommonState;
};


export default getInitialState();
