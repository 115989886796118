/* eslint-disable camelcase */
import gql from 'graphql-tag';
import request from '../../lib/request';
import { FOTO_HTTP_END_POINT, BASE_URL } from '../../lib/request/config';
import {
  ICreateTransferBody,
  ICreateMistakeBody,
  ICreateQuestionBody,
  ICreateCommentBody,
  IChangeRatingBody,
  ICreateMailBody,
  ICreateFotoBody
} from '../../types/app';

export const CREATE_QUESTUION = ({ name, email, message, phone }: ICreateQuestionBody): any => gql`
  mutation {
    createQuestion(name: "${name}", phone: "${phone}", email: "${email}", message: "${message}") {
      question_id
    }
  }
`;

export const CREATE_COMMENT = ({ resource_id, parent_id, content }: ICreateCommentBody): any => gql`
  mutation {
    addComment(input:{resource_id:"${resource_id}" ${parent_id ? ', parent_id: "' + parent_id +'"' : ''}, content: "${content}"}){
      parent_id
      comment_id
      content
      created_at
      resource_id
      user_id
    }
  }
`;

export const CHANGE_RATING = ({ comment_id, change }: IChangeRatingBody): any => gql`
  mutation {
    rateComment(input:{comment_id:"${comment_id}", change: ${change}}){
      value
    }
  }
`;

export const CREATE_MISTAKE = ({ mistake, message }: ICreateMistakeBody): any => gql`
  mutation {
    createErrorMsg(message: "${message}", mistake: "${mistake}") {
      error_id
    }
  }
`;

export const CREATE_TRANSFER = ({
  recipient,
  amount,
  company_id,
  sender
}: ICreateTransferBody): any => gql`
  mutation {
    createTransferOrder(
      amount: ${amount},
      company_id: "${company_id}", 
      recipient:{
        lastName: "${recipient.lastName}",
        birthDate: ${recipient.birthDate},
        firstName: "${recipient.firstName}",
        middleName: "${recipient.middleName}"
      },
      sender:{
        ${sender.snils ? `snils: "${sender.snils}",` : ''}
        ${sender.passport ? `passport: "${sender.passport}",` : ''}
        email: "${sender.email}",
        phone: "${sender.phone}",
        lastName: "${sender.lastName}", 
        firstName: "${sender.firstName}",
        middleName: "${sender.middleName}"
      }
    ) 
    {
      confirmationUrl
    }
  }
`;

export const CREATE_MAIL = ({
  answerCount,
  company_id,
  recipient,
  sender,
  text,
  images,
  colored
}: ICreateMailBody): any => gql`
  mutation {
    createMailOrder(
      text: ${JSON.stringify(text)},
      company_id: "${company_id}", 
      answer_count: ${answerCount},
      images: ${JSON.stringify(images)},
      colored: ${colored},
      person:{
        firstname: "${recipient.firstName}",
        lastname: "${recipient.lastName}",
        middlename: "${recipient.middleName}"
        date_of_birth: ${recipient.birthDate},
      },
      customer:{
        phone: "${sender.phone}",
        email: "${sender.email}",
        lastname: "${sender.lastName}", 
        firstname: "${sender.firstName}",
        middlename: "${sender.middleName}"
      }
    ) {
      order_id
    }
  }
`;

export const CREATE_PAYMENT_MAIL = ( id : { id: string }): any => gql`
  mutation {
    createMailPayment(order_id: ${id}) {
      payment_url
    }
  }
`;

export const CREATE_FOTO_ORDER = ({
  colonyId, uploadId, text, sender, recipient, returnUrl
  }: ICreateFotoBody): any => gql`
  mutation {
    create(
      order: {
        colonyId: "${colonyId}",
        uploadId: "${uploadId}",
        returnUrl: "${returnUrl}",
        text: "${text}",
        sender: {
          firstName: "${sender.firstName}",
          lastName: "${sender.lastName}",
          middleName: "${sender.middleName}",
          email: "${sender.email}",
          phone: "${sender.phone}"
        },
        recipient: {
          firstName: "${recipient.firstName}",
          lastName: "${recipient.lastName}",
          middleName: "${recipient.middleName}",
          birthDate: ${recipient.birthDate}
        }
      }
      ){
        confirmationUrl
      }
  }
  `;


export const createFotoOrder = (order: ICreateFotoBody, success: (response: any) => void) => {
  const { body } = CREATE_FOTO_ORDER(order).loc.source
  const operation = 'query'

  const data = JSON.stringify({
    operationName: null,
    variables: {},
    [operation]: body
  })


  return request({
    endPoint: FOTO_HTTP_END_POINT,
    method: 'POST',
    body: data,
    success
  })
}


export const createQuestion = (requestData: ICreateQuestionBody, success: () => void) => {
  const { body } = CREATE_QUESTUION(requestData).loc.source;
  const operation = 'query';

  const data = JSON.stringify({
    operationName: null,
    variables: {},
    [operation]: body
  });

  return request({
    method: 'POST',
    body: data,
    success
  });
};

export const createComment = (requestData: ICreateCommentBody, success: (response: any, status: any) => void
) => {
  const { body } = CREATE_COMMENT(requestData).loc.source;
  const operation = 'query';

  const data = JSON.stringify({
    operationName: null, 
    variables: {},
    [operation]: body
  });

  return request({
    method: 'POST',
    body: data,
    success
  });
};


export const changeRating = (requestData: IChangeRatingBody, success: (response: any, status: any) => void
) => {
  const { body } = CHANGE_RATING(requestData).loc.source;
  const operation = 'query';

  const data = JSON.stringify({
    operationName: null, 
    variables: {},
    [operation]: body
  });

  return request({
    method: 'POST',
    body: data,
    success
  });
};

export const createMistake = (
  requestData: ICreateMistakeBody,
  success: (response: any) => void
) => {
  const { body } = CREATE_MISTAKE(requestData).loc.source;
  const operation = 'query';

  const data = JSON.stringify({
    operationName: null,
    variables: {},
    [operation]: body
  });

  return request({
    method: 'POST',
    body: data,
    success
  });
};

export const createTransfer = (
  requestData: ICreateTransferBody,
  success: (response: any, status: any) => void
) => {
  const { body } = CREATE_TRANSFER(requestData).loc.source;
  const operation = 'query';

  const data = JSON.stringify({
    operationName: null,
    variables: {},
    [operation]: body
  });

  return request({
    endPoint: BASE_URL,
    method: 'POST',
    body: data,
    success
  });
};

export const createMail = (
  requestData: ICreateMailBody,
  success: (response: any, status: any) => void
) => {
  const { body } = CREATE_MAIL(requestData).loc.source;
  const operation = 'query';

  const data = JSON.stringify({
    operationName: null,
    variables: {},
    [operation]: body
  });

  return request({
    method: 'POST',
    body: data,
    success
  });
};

export const createMailPayment = (
  requestData: { id: string },
  success: (response: any, status: any) => void
) => {
  const { body } = CREATE_PAYMENT_MAIL(requestData).loc.source;
  const operation = 'query';

  const data = JSON.stringify({
    operationName: null,
    variables: {},
    [operation]: body
  });

  return request({
    method: 'POST',
    body: data,
    success
  });
};
