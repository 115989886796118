import gql from 'graphql-tag';
import { mapInstitution, mapMiddleInstitution } from '../mappers';
import { IGQLRequest } from '../../types/app';

interface IInstitutionsController {
  getBySlug: (slug: string) => IGQLRequest;
  getByQuery: (search: string, limit: number, page?: number) => IGQLRequest;
}

export const INSTITUTION_GET_BY_ID = gql`
query CompanyById($id: String){
  company(id: $id){
    data{
      slug
      company_id
      address
      web_site
      fullname
      shortname
      limitofall
      point
      account_info
      services {
        name
      }
      region {
        name
        slug
        region_id
      }
      documents{
        ext
        doc_type
        doc_type_id
        document_id
      }
      longdate_schedule{
        days
        info
        times
      }
      package_schedule{
        days
        info
        times
      }
      company_contacts{
        description
        number
        type
      }
      reception_schedule{
        fullname
        position
        schedules {
          times
          weekday
        }
      }
    }
  }
}
`

class Institutions {
    static getBySlug(slug: string) {
      return {
        mapper: mapInstitution,
          query: gql`
              {
                  company(slug: ${JSON.stringify(slug)}){
                      data{
                          can_color_print
                          slug
                          company_id
                          address
                          web_site
                          fullname
                          shortname
                          limitofall
                          point
                          account_info
                          services {
                              name
                          }
                          region {
                              name
                              slug
                              region_id
                          }
                          documents{
                              ext
                              doc_type
                              doc_type_id
                              document_id
                          }
                          longdate_schedule{
                              days
                              info
                              times
                          }
                          package_schedule{
                              days
                              info
                              times
                          }
                          company_contacts{
                              description
                              number
                              type
                          }
                          reception_schedule{
                              fullname
                              position
                              schedules {
                                  times
                                  weekday
                              }
                          }
                      }
                  }
              }
          `
      };
  }

  static getByQuery(search: string, LIMIT: number = 20, page: number) {
    const OFFSET = LIMIT * (page - 1);

    // let filteredByActiveService = '';
    // if (SERVICE === 'TRANSFER') filteredByActiveService = '(service_id:16)';
    // else if (SERVICE === 'MAIL') filteredByActiveService = '(service_id:20)'

    return {
      mapper: mapMiddleInstitution,
      query: gql`
        {
          search(query: "${search}", limit: ${LIMIT}, offset: ${OFFSET}){
            total
            limit
            offset
            data{
              slug
              address
              fullname
              company_id
              shortname
              services{
                name
              }
              region {
                name
                slug
                region_id
              }
            }
          }
        }
      `
    };
  }
}

export default Institutions as IInstitutionsController;
