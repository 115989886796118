import React, {
  ChangeEvent,
  FormEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Props, IFormElementInputProps } from './interface';

import InputMask from 'react-input-mask';
import Typography from '../../Typography';
import Validators, { TValidator } from '../Validator';
import getTypographyProps from '../../../lib/getTypographyProps';

import { ReactComponent as Check } from '../../../images/check.svg';

import './FormElement.scss';
import PhoneInput from 'react-phone-input-2';
import { selectFormError } from '../../../redux/reducers/formErrorReducer';
import { IGlobalCommonState } from '../../../types/redux';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';

const FormElement = (props: Props) => {
  const [message, setMessage] = useState('');
  const refInput = useRef();
  const getInputId = (): string => {
    const id = Math.random().toString(36).replace(/\d|\./g, '');

    return id;
  };
  const [id, setId] = useState(getInputId());
  const location = useLocation()

  const getClassName = () => {
    let className = 'form__element';
    if (props.isRound) className += ' form__element_round';
    if (props.isError || message) className += ' form__element_error';
    if (props.isColumn) className += ' form__element_column';
    if (props.className) className += ` ${props.className}`;
    if (props.isSmallElement) className += ' form__element_small';
    if (props.type === 'checkbox') className += ' form__element_checkbox';

    if ((props.placeholder && props.isDynamicPlaceholder) || props.isDynamic) {
      className += ' form__element_dynamic';
      if (props.value) className += ' form__element_dynamic_active';
    }

    return className;
  };

  const onChange = (event: any, customEvent: any) => {
    if (message) setMessage('');
    let input: any = refInput.current;
    if (input !== undefined) {
      let isPhoneInput = input && input.hasOwnProperty('numberInputRef');
      if (isPhoneInput) {
        input = input.numberInputRef;
      }
      input.dataset.iserror = false;
    }

    if (props.withContyPhones) {
      // @ts-ignore
      const val: string = event;
      if (props.onChange)
        props.onChange(props.name as string, val, customEvent);
      return;
    }
    if (event.target) {
      const { name, checked, value } = event.target as HTMLInputElement;

      if (props.onChange) {
        if (props.type === 'checkbox') {
          props.onChange(name, checked, customEvent || event);
        } else {
          props.onChange(name, value, customEvent || event);
        }
      }
    }
  };

  const renderLabel = () => {
    const { type, label, placeholder, isDynamicPlaceholder } = props;

    const isPlaceholderLabel = placeholder && isDynamicPlaceholder;
    if (!label && !isPlaceholderLabel) return;

    let content = label || placeholder;
    const isCheckbox = type === 'checkbox';

    const color = isCheckbox ? 'text' : 'light-text';

    return (
      <label
        children={
          <Typography
            {...getTypographyProps('text', 'm', color)}
            children={content}
          />
        }
        className='form__element__label'
        htmlFor={id}
      />
    );
  };

  const onFocus = () => {
    if (props.onFocus) props.onFocus();
  };

  const renderElement = () => {
    const {
      mask,
      type,
      name,
      value,
      onRef,
      isRound,
      maskChar,
      country,
      required,
      disabled,
      maxLength,
      minLength,
      validations,
      placeholder,
      isSmallElement,
      withContyPhones,
      preferredCountries,
      isDynamicPlaceholder,
      forceMaxLength,
      forceMinLength,
      label,
      className,
    } = props;

    const inputProps: Props = {
      mask,
      type,
      name,
      value,
      country,
      maxLength,
      minLength,
      className,
      onChange: onChange,
      onFocus: onFocus,
      onBlur: onBlur,
      ref: refInput,
      id: id,
      disabled: Boolean(disabled),
      required: Boolean(required),
    };
    let Tag: any = 'input';

    if (!isDynamicPlaceholder) {
      inputProps.placeholder = placeholder;
    }

    if (!validations) {
      inputProps.maxLength = maxLength;
      inputProps.minLength = minLength;
    }

    if (forceMaxLength) {
      inputProps.maxLength = forceMaxLength;
    }

    if (forceMinLength) {
      inputProps.minLength = forceMinLength;
    }

    if (withContyPhones) {
      Tag = PhoneInput;
      inputProps.country = country;
      inputProps.preferredCountries = preferredCountries;
      // @ts-ignore
      inputProps.specialLabel = false;
    } else if (type === 'textarea') {
      Tag = 'textarea';
    } else if (mask) {
      inputProps.maskChar = maskChar;
      inputProps.mask = mask;
      Tag = InputMask;

      delete inputProps.maxLength;
    } else {
      inputProps.type = type || 'text';
    }

    if (type === 'checkbox') {
      // @ts-ignore
      delete inputProps.value;
      inputProps.checked = Boolean(value);

      return (
        <div className='checkbox-block'>
          <Tag {...inputProps} />
          <div className='checkbox'>
            {!isRound && (inputProps.checked ? <Check /> : '')}
          </div>
        </div>
      );
    }
    delete inputProps.checked;
    inputProps.value = String(value);
    return <Tag {...inputProps} />;
  };

  const validateInput = useCallback(() => {
    const {
      validations,
      cyrillic,
      noDash,
      value,
      maxLength,
      name,
      minLength,
      equalsTo,
    } = props;

    if (typeof value === 'string') {
      if (refInput.current) {
        // @ts-ignore
        refInput.current.value = value.trim()
      }
    }

    if (validations === undefined) return;
    for (let i = 0; i < validations.length; i++) {
      const method: TValidator = validations[i];

      let addOption = undefined;
      if (method === 'max') addOption = maxLength;
      if (method === 'min') addOption = minLength;
      if (method === 'equals') addOption = equalsTo;
      if (method === 'cyrillic') addOption = cyrillic;
      if (method === 'noDash') addOption = noDash;

      let inputValue: any = refInput.current;
      let isPhoneInput =
        inputValue && inputValue.hasOwnProperty('numberInputRef');
      if (isPhoneInput) {
        // @ts-ignore
        inputValue = refInput.current.numberInputRef;
      }
      const message = Validators[method](
        String(value),
        String(name),
        addOption
      );
      if (typeof message === 'string' && message) {
        setMessage(message);
        inputValue.dataset.iserror = true;
      }
    }
  }, [props]);

  useEffect(() => {
    if (props.onRef) props.onRef(refInput);

    if(props.isFormError){
      validateInput()
    }
  }, [props, validateInput]);

  const onBlur = () => {
    if (props.onBlur) props.onBlur();
    validateInput()
  };

  const renderValidation = () => {
    if (!message) return null;

    return (
      <Typography
        children={message}
        className='validation-error'
        dataTestId='form_error'
        {...getTypographyProps('text', 'xs', 'red')}
      />
    );
  };

  useEffect(() => {
    setMessage('')
  }, [location])

  return (
    <div className={getClassName()}>
      <div style={{ position: 'relative', display: 'flex', width: '100%' }}>
        {renderElement()}
        {renderLabel()}
      </div>
      {renderValidation()}
    </div>
  );
};

function mapState(state: IGlobalCommonState) {
  return {
    isFormError: selectFormError(state)
  };
}

export default connect(mapState)(FormElement);
