import React, { Component } from 'react';
import './style.scss';

export interface ContainerWrapperProps {
  title: string;
  description?: string;
  children: any;
}

class ContainerWrapper extends Component<ContainerWrapperProps>{

    render(){
        return (
            <div className="ContainerWrapper">
            <p className="ContainerWrapper__title">{this.props.title}</p>
            {this.props.description && <p className="ContainerWrapper__description">{this.props.description}</p>}
            {this.props.children}
            </div>
        );
    }
};

export default ContainerWrapper;