import { INavigationLink } from '../../types/app';
import SERVICE, { links } from '../../lib/getServiceType';
import { getPathnameModel, MainRoutes } from '../Router/config/routes';

const getConfig = (): INavigationLink[] => {
  switch (SERVICE) {
    case undefined:
      return [
        { label: 'Учреждения', href: getPathnameModel(MainRoutes.institutionsPage.path) },
        { label: 'Новости', href: getPathnameModel(MainRoutes.newsPage.path) },
        { label: 'Полезные статьи', href: getPathnameModel(MainRoutes.articlesPage.path) }
      ];

    default:
      return [
        {
          label: 'Учреждения',
          // @ts-ignore
          href: MainRoutes.institutionsPage.path,
          isExternal: true
        },
        // @ts-ignore
        { label: 'Новости', href: MainRoutes.newsPage.path, isExternal: true },
        {
          label: 'Полезные статьи',
          // @ts-ignore
          href: MainRoutes.articlesPage.path,
          isExternal: true
        }
      ];
  }
};

export default getConfig();
