import request from '../../../lib/request';
import gql from "graphql-tag";
import { ACCOUNT_END_POINT } from '../../../lib/request/config';


export interface IInputUpdateContactType {
  date_of_birth?: string
  relation_id?: number
  company_id?: string
  firstname?: string
  lastname?: string
  middlename?: string
}

export interface IInputUpdateContact {
  contact_id: string,
  patch: IInputUpdateContactType
}

const UPDATE_CONTACT = (): any => {
  return gql`
mutation updateContactMutation($input: InputUpdateContact) {
  updateContact(input: $input) {
    contact {
      contact_id
    }
  }
}
`
};


export function contactUpdateMutation(requestData: IInputUpdateContact, success: (response: any) => void) {
  const {body} = UPDATE_CONTACT().loc.source;
  const operation = 'query';

  const data = JSON.stringify({
    operationName: null,
    variables: {
      input: requestData
    },
    [operation]: body
  });

  return request({
    method: 'POST',
    body: data,
    success
  });
}
