import React, { Component } from 'react';
import { Props, State } from './interface';
import bind from '../../../lib/decorators/bind';
import { IMetaTags } from '../../../types/server';
import withApollo from '../../../lib/HOC/withApollo';

import deepEqual from 'deep-equal';
import queryString from 'query-string';
import Page from '../../../components/Page';
import Grid from '../../../components/GridList';
import Search from '../../../components/Search';
import Button from '../../../components/Button';
import Wrapper from '../../../components/Wrapper';
import Typography from '../../../components/Typography';
import Institution from '../../../components/Institution';
import SectionTitle from '../../../components/SectionTitle';
import getTypographyProps from '../../../lib/getTypographyProps';


import InstitutionController from '../../../gql/actions/Institution';
import { IMapQuery } from '../../../types/app';
import './SearchPage.scss';
import { getPathnameModel, MainRoutes } from '../../../components/Router/config/routes';
import MetaTags from '../../../components/MetaTags';

const LIMIT = 20;

class SearchPage extends Component<Props, State> {
  state: State = this.getInitialState();

  @bind
  getInitialState(): State {
    const { data, total } = this.props.search;

    try {
      return {
        items: data || [],
        total: total || 0
      };
    } catch (error) {
      return {
        items: [],
        total: 0
      };
    }
  }

  componentDidMount() {
    this.updateAppolo(true);
    window.addEventListener('keypress', this.onKeyPress);
  }

  componentDidUpdate(prevProps: Props) {
    this.isChangedItems = false;
    const prevQuery = queryString.parse(prevProps.location.search) || 1;
    const currentQuery = queryString.parse(this.props.location.search);

    const isChangedPage = prevQuery.page !== currentQuery.page;
    const isChangedQuery = prevQuery.query !== currentQuery.query;

    const isEqualProps = deepEqual(this.props.search.data, prevProps.search.data);

    if (this.props.search.offset !== prevProps.search.offset) {
      let prevItems = this.state.items;
     
      if (this.props.search.total !== prevProps.search.total) {
        prevItems = [];
      }

      this.setState({
        total: this.props.search.total,
        items: [...prevItems, ...this.props.search.data]
      });
    } else if (!isEqualProps) {
      this.isChangedItems = true;
      this.setState({
        total: this.props.search.total,
        items: this.props.search.data
      });
    }
   
    if (isChangedPage || isChangedQuery) {   
      this.updateAppolo();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('keypress', this.onKeyPress);
  }

  @bind
  onKeyPress() {
    if (!this.isFocusInput) {
      if (this.searchRef && this.searchRef.refInput && this.searchRef.refInput.current) {
        if (this.searchRef.refInput.current.focus) {
          this.searchRef.refInput.current.focus();
          this.isFocusInput = true;
        }
      }
    }
  }

  @bind
  onHistoryBack() {
    const { history } = this.props;
    history.push(getPathnameModel(MainRoutes.indexPage.path));
  }

  @bind
  getText(value: number = 0) {
    let word = 'учреждений';

    const string = value.toString();
    let numeral = parseInt(string[string.length - 1], 10);
    if (value === 11) numeral = 5;

    if (numeral === 1) word = 'учреждение';
    else if (numeral > 1 && numeral < 5) word = 'учреждения';
    else if (numeral >= 5) word = 'учреждений';

    return `Найдено ${value} ${word}`;
  }

  @bind
  renderContent() {
    const { items, total } = this.state;
    const maxPage = Math.ceil((total || LIMIT) / LIMIT);
    
    const data = items.map((a: any) => (<Institution
      key={a.id}
      {...a}
    />));

    return (
      <div className='content'>
        <Typography
          {...getTypographyProps('text', 'm', 'dark-text')}
          children={this.getText(total)}
        />
        <Grid
          isChangedItems={this.isChangedItems}
          items={data}
          totalPages={maxPage}
          type='institutions'
        />
      </div>
    );
  }

  @bind
  updateAppolo(withoutRerender?: boolean) {
    const currentQuery = queryString.parse(this.props.location.search);

    const query = currentQuery.query ? String(currentQuery.query) : '';
    const page = currentQuery.page ? Number(currentQuery.page) : 1;

    const requests: IMapQuery = () => ({
      search: InstitutionController.getByQuery(query || '', LIMIT, page)
    });

    this.props.updateApollo(requests, withoutRerender);
  }

  @bind
  onRef(data: any) {
    this.searchRef = data;
  }

  @bind
  onBlur() {
    this.isFocusInput = false;
  }

  isFocusInput: boolean = false;

  searchRef: any = null;

  isChangedItems: boolean = false;

  setMeta() {
    const meta: IMetaTags = {
      title: 'Навигатор по колониям и СИЗО России. Адреса, телефоны, график приема, график передач',
      description:
        'Удобные сервисы, помогают получить информацию по учреждениям, отправлять письма и фотографии для заключенных.'
    };
  
    meta.last_modified = "Sat, 10 Oct 2020 06:59:13 GMT"
    meta.robots = 'follow';
    meta.canonical = "/search";
  
    return (
      <MetaTags 
      title={meta.title}
      description={meta.description}
      lastModified={meta.last_modified}
      canonical={meta.canonical}
      />
    );
  }

  render() {
    return (
      <Page page='search'>
        {this.setMeta()}
        <Wrapper isPageWrapper>
          <SectionTitle title='Результаты поиска' />
          <div className='button-wrapper'>
            <Button
              isTransparent
              label='Вернуться'
              onClick={this.onHistoryBack}
              withArrowBack
            />
          </div>
          <Search
            onBlur={this.onBlur}
            onRefInput={this.onRef}
          />
          {this.renderContent()}
        </Wrapper>
      </Page>
    );
  }
}

function mapQuery(props: Props) {
  const currentQuery = queryString.parse(props.location.search);

  const query = currentQuery.query ? String(currentQuery.query) : '';
  const page = currentQuery.page ? Number(currentQuery.page) : 1;

  return {
    search: InstitutionController.getByQuery(query, LIMIT * page, 1)
  };
}

export default withApollo(mapQuery, undefined)(SearchPage);
