import React, { useEffect, useState } from 'react';

import Link from '../Link';
import { ReactComponent as Arrow } from '../../images/arrow.svg';

import './button.scss';
import { Props } from './interface';

const classNames = {
  block: 'button',
  isRedButton: 'button_red',
  isBlueButton: 'button_blue',
  isGreenButton: 'button_green',
  onlyBackButton: 'button_only-back',
  isTransparentButton: 'button_transparent',
  isOutlineButton: 'button_outline',
  isWhiteButton: 'button_white',
  isDisabled: 'button_disabled',
  isOrangeButton: 'button_orange',
  isDarkGreenButton: 'button_dark_green',
  isFullWidthButton: 'button_full_width',
};

const TagName = (props: any) => {
  if (props.to) {
    return <Link {...props} />;
  } else {
    return <button {...props} />;
  }
};

const Button = (props: Props) => {
  const getClasses = () => {
    let className = 'button';

    if (props.className) className += ` ${props.className}`;
    if (props.isRed) className += ` ${classNames.isRedButton}`;
    if (props.isTransparent) className += ` ${classNames.isTransparentButton}`;
    if (props.isOutline) className += ` ${classNames.isOutlineButton}`;
    if (props.isBlue) className += ` ${classNames.isBlueButton}`;
    if (props.isGreen) className += ` ${classNames.isGreenButton}`;
    if (props.isWhite) className += ` ${classNames.isWhiteButton}`;
    if (props.isOrange) className += ` ${classNames.isOrangeButton}`;
    if (props.isDarkGreen) className += ` ${classNames.isDarkGreenButton}`;
    if (props.isFullWidth) className += ` ${classNames.isFullWidthButton}`;
    if (props.withArrowBack && props.label === '')
      className += ` ${classNames.onlyBackButton}`;
    if (props.isDisabled) className += ` ${classNames.isDisabled}`;

    return className;
  };

  const onClick = (event: Event) => {
    const { isDisabled, onClick } = props;
    if (isDisabled || !onClick) return;

    onClick(event);
  };

  const getChildren = () => {
    const { label, withArrowBack, isLoading } = props;

    if (isLoading) {
      return (
        <svg
          className='loader'
          stroke='#FFFFFF'
          viewBox='0 0 38 38'
          xmlns='http://www.w3.org/2000/svg'
        >
          <g fill='none' fillRule='evenodd'>
            <g strokeWidth='2' transform='translate(1 1)'>
              <circle cx='18' cy='18' r='18' strokeOpacity='.5' />
              <path d='M36 18c0-9.94-8.06-18-18-18'>
                <animateTransform
                  attributeName='transform'
                  dur='1s'
                  from='0 18 18'
                  repeatCount='indefinite'
                  to='360 18 18'
                  type='rotate'
                />
              </path>
            </g>
          </g>
        </svg>
      );
    }

    return (
      <>
        {withArrowBack && <Arrow />}
        {label}
      </>
    );
  };

  const btnProps1 = () => {
    if (props.isExternal || props.to) {
      return {
        onClick: onClick,
        children: getChildren(),
        className: getClasses(),
        to: props.to,
        isExternal: props.isExternal,
      };
    } else {
      return {
        onClick: onClick,
        children: getChildren(),
        className: getClasses(),
      };
    }
  };

  return <TagName {...btnProps1()} data-testid={props.dataTestId ?? ''} />;
};

export default Button;
