import App from './App';
import React from 'react';
import { Provider } from 'react-redux';
import { END_POINT, COMMENTS_END_POINT} from '../lib/request/config';
import { ApolloProvider } from 'react-apollo';
// import  ApolloClient, { PresetConfig } from 'apollo-boost';
import ApolloClient from 'apollo-client';

import fetch, { Headers as ServerHeaders } from 'node-fetch';
import { RestLink } from 'apollo-link-rest';
import createStore from '../redux/createStore';
import getInitialState from '../redux/initialState';
import Notification from '../components/Notification';
import { BrowserRouter, StaticRouter } from 'react-router-dom';
import cookie from 'cookie';
import { createCookieStorage, IStorage } from '../models/StorageModel';
import { getAccessTokenFromStorage, getGuestFromStorage, getRefreshTokenFromStorage } from '../models/AuthModel';
import { setAuthDataAction } from '../redux/reducers/authReducer';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createHttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { ApolloLink } from 'apollo-link';
import { userInfoQuery } from '../gql/actions/queries/userInfoQuery';
import { setAccountUserInfoAction } from '../redux/reducers/accountReducer';
import MetaTags from '../components/MetaTags';
import { Helmet } from 'react-helmet';

const isServer = process.env.IS_SERVER_SIDE;
let initialStateF
if(isServer){
  // @ts-ignore
  initialStateF = { ...getInitialState, ...global.window.__PRELOADED_STATE__ }
}else{
  initialStateF = getInitialState
}

//@ts-ignore
const GlobalStore = createStore(initialStateF);
//@ts-ignore
GlobalStore.subscribe(() => global.window.__renderedState = GlobalStore.getState());

//@ts-ignore
if (global.Headers == null) {
  //@ts-ignore
  global.Headers = require("fetch-headers");
}

//@ts-ignore
const httpLink = createHttpLink({
  uri: END_POINT,
  //@ts-ignore
  fetch: fetch
})

const renderHelmet = () => (
  <Helmet>
    <meta
    content='width=device-width, initial-scale=1'
    name='viewport'
    />
  </Helmet>
)

//@ts-ignore
const restLink = new RestLink({ uri: COMMENTS_END_POINT, customFetch: fetch,
  //@ts-ignore
  responseTransformer: async response => response.json().then(({ data }) => data)
});
// const clientOptions: PresetConfig = {
const clientOptions = {
  // uri: END_POINT,
  // link: restLink,
  link: ApolloLink.from([restLink,
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors && graphQLErrors.length) {
        const { message } = graphQLErrors[0];
        if (message) Notification.show(message);
      }
    }),
    httpLink,
  ]),
  fetch: fetch,
  cache: new InMemoryCache()
  // onError: ({graphQLErrors}) => {
  //   if (graphQLErrors && graphQLErrors.length) {
  //     const {message} = graphQLErrors[0];
  //     if (message) Notification.show(message);
  //   }
  // }
};


// @ts-ignore
if (isServer) clientOptions.fetch = fetch;


export const SSR = {
  redirectCondition: (screenProps: any) => undefined,
  setRenderComponent: (comp?: any, meta?: any, redirectUrl?: string) => {
  },
  req: {
    get: (name: string) => {
    }
  }
};

export default ({ serverData, setRenderComponent, serverRoute, redirectCondition, req }: any) => {
  if (setRenderComponent) SSR.setRenderComponent = setRenderComponent;
  if (redirectCondition) SSR.redirectCondition = redirectCondition;

  const accessToken = getAccessTokenFromStorage();
  const refreshToken = getRefreshTokenFromStorage();
  const guest = getGuestFromStorage();
  // @ts-ignore
  GlobalStore.dispatch(setAuthDataAction(accessToken, refreshToken, guest));
  userInfoQuery((response: any) => {
    if (response.data.userInfo.user && response.data.userInfo.user.hasOwnProperty('user_id')) {
      document.cookie = `user_id=${response.data.userInfo.user.user_id}; max-age=2629743`
      // @ts-ignore
      GlobalStore.dispatch(setAccountUserInfoAction(response.data.userInfo.user));
    } 
  });

  const client = new ApolloClient(clientOptions);

  if (serverData) {
    const { location, context } = serverData;

    return (
      <>
      {renderHelmet()}
      <Provider store={GlobalStore}>
        <ApolloProvider client={client}>
          <StaticRouter
            context={context}
            location={location}
          >
            <App serverRoute={serverRoute} />
          </StaticRouter>
        </ApolloProvider>
      </Provider>
      </>
    );
  }

  return (
    <>
    {renderHelmet()}
    <Provider store={GlobalStore}>
      <ApolloProvider client={client}>
        <BrowserRouter>
          <App serverRoute={serverRoute} />
        </BrowserRouter>
      </ApolloProvider>
    </Provider>
    </>
  );
};
