import React, { Component } from 'react';
import { QInstitution, SelectedInstitution } from '../../interface';
import Button from '../button';

import './style.scss';

interface Props {
  institutions: Array<QInstitution>;
  regionId: number;
  setSelectedInstitution: CallableFunction;
  onClick: CallableFunction;
}

class FormInstitution extends Component<Props> {
  onClick(item: SelectedInstitution) {
    this.props.setSelectedInstitution(item);
    setTimeout(() => {
      this.props.onClick();
    }, 100);
  }

  renderIntitution(item: QInstitution) {
    return (
      <li className='FormInstitution__item' key={item.id}>
        <div>
          <h5>{item.shortName}</h5>
          <p>{item.address}</p>
        </div>
        <div className='FormInstitution__buttons'>
          <Button
            theme='institution'
            text='Передача'
            onClick={() =>
              this.onClick({
                ...item,
                variation: 'Передача',
                serviceType: 'package',
              })
            }
          />
          <Button
            theme='institution'
            text='Свидание'
            onClick={() =>
              this.onClick({
                ...item,
                variation: 'Свидание',
                serviceType: 'visit',
              })
            }
          />
        </div>
      </li>
    );
  }

  render() {
    if (this.props.regionId === 175) {
      return (
        <ul className='FormInstitution'>
          {this.props.institutions
            .filter((item) => item.shortName.indexOf('СИЗО') === 0)
            .map((item) => this.renderIntitution(item))}
        </ul>
      );
    } else {
      return (
        <ul className='FormInstitution'>
          {this.props.institutions
            .filter(
              (item) =>
              item.id === '6dc20d4b-ae31-46ab-a5bf-94861a705cb0' ||
              item.id === 'adb4e08b-1e5d-485b-9b58-9c9abf40dbfe' ||
              item.id === 'b3abcdbb-11be-4335-806f-7b6ffdb1524d'
            )
            .map((item) => this.renderIntitution(item))}
        </ul>
      );
    }
  }
}

export default FormInstitution;
