import { Props } from './interface';
import React, { MouseEvent } from 'react';
import Link from '../Link';
import './Typography.scss';

const Typography = (props: Props) => {
  const getClassName = () => {
    const { mark, size, type, color } = props;
    let className = 'typography';

    if (mark) className += ` typography_mark_${mark}`;
    if (type) className += ` typography_type_${type}`;
    if (size) className += ` typography_size_${size}`;
    if (color) className += ` typography_color_${color}`;
    if (props.onClick) className += ' typography_clickable';
    if (props.isUppercase) className += ' typography_uppercased';
    if (props.className) className += ` ${props.className}`;

    return className;
  };

  const renderTitle = () => {
    const { size } = props;
    let Tag = getTypographyTag('div');
    const props_add = getTypographyProps();

    if (size === '2xl') Tag = getTypographyTag('h1');
    else if (size === 'l') Tag = getTypographyTag('h3');
    else if (size === 'xl') Tag = getTypographyTag('h2');
    if(props.as) Tag = props.as

    return <Tag {...props_add} />;
  };

  const onClick = (event: MouseEvent) => {
    if (!props.onClick) return;
    return props.onClick(event);
  };

  const getTypographyProps = () => {
    const { children, isHtmlContent, to, isActiveLink, isExternalLink, style } =
      props;
    const isLinkElement = to;

    const props_add: any = {
      style,
      onClick: onClick,
      className: getClassName(),
    };

    if (isLinkElement) {
      props_add.to = to;
      props_add.isActive = isActiveLink;
      props_add.isExternal = isExternalLink;
    }

    if (!isHtmlContent) props_add.children = children;
    else props_add.dangerouslySetInnerHTML = { __html: children };

    return props_add;
  };

  const getTypographyTag = (originalTag: string) => {
    const { isDivElement, isTimeElement, isSpanElement, to } = props;
    const isLinkElement = to;

    if (isDivElement) return 'div';
    else if (isSpanElement) return 'span';
    else if (isTimeElement) return 'time';
    else if (isLinkElement) return Link;

    return originalTag;
  };

  const renderText = () => {
    const Tag = props.as ??  getTypographyTag('p');
    const props_add = getTypographyProps();

    return <Tag {...props_add} />;
  };

  if (props.type === 'heading') return renderTitle();
  return renderText();
};

export default Typography;
