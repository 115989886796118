import React, { Component } from 'react';
import { Props, State } from './interface';
import { Link as ReactLink, withRouter } from 'react-router-dom';

import bind from '../../lib/decorators/bind';
import SERVICE from '../../lib/getServiceType';
import './Link.scss';

class Link extends Component<Props, State> {
  state = {
    pathname: this.props.location.pathname + this.props.location.hash + this.props.location.search
  };

  componentDidMount() {
    const {history} = this.props;

    this.unListen = history.listen((location) => {
      this.setState({
        pathname: location.pathname + location.hash + location.search
      });
    });
  }

  componentWillUnmount() {
    if (this.unListen) this.unListen();
  }

  @bind
  onClick(event: any) {
    const {
      to, onClick = () => {
      }, onForceClick
    } = this.props;
    const {pathname} = this.state;
    if (onForceClick) {
      event.preventDefault();
      onForceClick();
    } else if (to === pathname) {
      event.preventDefault();
    } else {
      onClick();
    }
  }

  unListen = () => {
  };

  @bind
  getReference() {
    const {to} = this.props;
    return to || '';
  }

  @bind
  getClassName() {
    const {isActive, to, isDisabled, isColored} = this.props;
    const {pathname} = this.state;

    let className = this.classNames.main;
    if (this.props.className) className += ` ${this.props.className}`;
    if (isDisabled) {
      className += ` ${this.classNames.isDisabledLink}`;
    } else {
      if (isColored) {
        switch (SERVICE) {
          case 'TRANSFER':
            className += ` ${this.classNames.isGreenLink}`;
            break;

          case 'MAIL':
          default:
            className += ` ${this.classNames.isBlueLink}`;
            break;
        }

        if (this.props.match.path === "/perevod") className += ` ${this.classNames.isGreenLink}`
      }
      if (isActive) {
        if (to === '/') {
          if (to === pathname) {
            className += ` ${this.classNames.isActiveLink}`;
          }
        } else if ((to === pathname || pathname.indexOf(to) !== -1)) {
          if (to === "/perevod" && pathname.split('/')[4] === "perevod") {
            return className;
          }
            className += ` ${this.classNames.isActiveLink}`;
        } 
      }
    }

    return className;
  }

  classNames = {
    main: 'link',
    isBlueLink: 'link_blue',
    isGreenLink: 'link_green',
    isActiveLink: 'link_active',
    isDisabledLink: 'link_disabled'
  };

  render() {
    const {
      to,
      match,
      label,
      history,
      location,
      isActive,
      children,
      isColored,
      isExternal,
      isDisabled,
      staticContext,
      onForceClick,
      ...props
    } = this.props;

    let Tag: any = ReactLink;
    if (isExternal) Tag = 'a';
    else if (isDisabled) Tag = 'div';

    const propsLink: any = {
      className: this.getClassName(),
      children: children || label,
      onClick: this.onClick
    };

    if (isExternal) {
      propsLink.target = '_blank';
      propsLink.href = this.getReference();
      propsLink.rel = 'noopener noreferrer';
    } else if (!isDisabled) {
      propsLink.to = this.getReference();
    }

    return (<Tag
      {...props}
      {...propsLink}
    />);
  }
}

export default withRouter(Link);
