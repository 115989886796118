import { Props } from './interface';
import { withRouter } from 'react-router-dom';
import React, { MouseEvent, useEffect, useRef, useState } from 'react';

import queryString from 'query-string';
import Form, { FormElement } from '../Form';
import Typography from '../Typography/Typography';
import getTypographyProps from '../../lib/getTypographyProps';

import { MainRoutes } from '../../components/Router/config/routes';
import { ReactComponent as Icon } from '../../images/search1.svg';
import close from '../../images/close.svg';

import './Search.scss';

const classNames = {
  block: 'search',
  form: 'search__form',
  examples: 'search__examples',

  isMenuSearch: 'search_menu',
  isFocus: 'search_focusible',
  withSearch: 'search_with-search',
};

const Search = (props: Props) => {
  const [isFocus, setIsFocus] = useState(false);
  const [query, setQuery] = useState('');
  const searchRef = useRef()

  useEffect(() => {
    if (!props.autoSearch) return;
    const timer = setTimeout(()  => {
      onSubmit();
    }, 500);
    return () => clearTimeout(timer);
  }, [query, props.autoSearch])
  
  useEffect(() => {
    const { search } = props.location;
    let { query } = queryString.parse(search);

    if (query) {
      if (Array.isArray(query)) query = query.join();
      setQuery(query);
    }
  }, [props])

  const getClassName = () => {
    let className = classNames.block;
    if (props.className) className += ` ${props.className}`;
    if (isFocus) className += ` ${classNames.isFocus}`;
    if (query) className += ` ${classNames.withSearch}`;
    if (props.isMenuSearch) className += ` ${classNames.isMenuSearch}`;

    return className;
  };

  const onSubmit = () => {
    const { onSubmit } = props;

    if (onSubmit) {
      onSubmit(query);
      return;
    }

    props.history.push(`${MainRoutes.searchPage.path}?query=${query}`);
  };

  const onBlur = () => {
    if (props.onBlur) props.onBlur();
    setIsFocus(false);
  };

  const onChangeInput = (name: string, value: string | boolean) => {
    // @ts-ignore
    let filteredValue = value.replace(/[^а-яa-z0-9\-\s]/gi, '');
    setQuery(filteredValue);
  };

  const onFocus = () => {
    setIsFocus(true);
  };

  const onRef = () => {
    const { onRefInput } = props;

    if (typeof onRefInput === 'function') return onRefInput;
    return undefined;
  };

  const onClearQuery = () => {
    setQuery('');
  }
  const onChangeSearch = (event: MouseEvent) => {
    const { innerText } = event.currentTarget as HTMLElement;
    setQuery(innerText);
  }

  const renderExample = () => {
    if (!props.withExample) return null;

    return (
      <div className={classNames.examples}>
        <Typography
          onClick={onChangeSearch}
          {...getTypographyProps('text', 'm', 'dark-text')}
          children='ИК-2 Талицы'
        />
        <Typography
          onClick={onChangeSearch}
          {...getTypographyProps('text', 'm', 'dark-text')}
          children='Тульская область'
        />
      </div>
    );
  }

  return (
    <div className={getClassName()}>
      <Form refs={[searchRef]} className={classNames.form} onSubmit={onSubmit}>
        <div>
          <FormElement
            isDynamicPlaceholder={false}
            name='query'
            label='Поиск'
            onBlur={onBlur}
            onChange={onChangeInput}
            onFocus={onFocus}
            onRef={onRef()}
            ref={searchRef}
            placeholder={
              isFocus ? undefined : props.placeholder || 'Что будем искать?'
            }
            required={props.required !== undefined ? props.required : true}
            value={query}
          />
          {query && (
            <img className='cross' src={close} onClick={onClearQuery} />
          )}
        </div>
        <button
          name='Поиск'
          aria-label='Поиск'
          children={
            <div>
              <Icon className='svg-image' />
            </div>
          }
        />
      </Form>
      {renderExample()}
    </div>
  );
};

export default withRouter(Search);
