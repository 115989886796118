type LinkActions = {
  type: 'set_utm';
  value: string;
};

export type InitialLinkStateType = {
  utmList: Record<string, string> | undefined;
  transformLink: (link: string, utmList?: Record<string, string>) => string;
};



const extractParams = (link: string): Record<string, string> | undefined => {
  const url = new URL(link);
  const searchParams = url.searchParams.toString();
  if (searchParams.length === 0) {
    return;
  }
  const searchParamsChunks = searchParams.split('&');
  const params: Record<string, string> = {}

  for (const param of searchParamsChunks) {
    const chunks = param.split('=');
    params[chunks[0]] = chunks[1]
  }

  return params
}
const isUrl = (link: string) => {
  try { 
    return Boolean(new URL(link)); 
  } catch(e){ 
    return false; 
  }
}
const transformLink = (link: string, utmList?: Record<string, string>) => {
  if (utmList === undefined) {
    return link;
  }
  if(isUrl(link)) {
    const url = new URL(link);
    if (url.searchParams.toString().length > 0) {
      for (const name in utmList) {
        url.searchParams.set(name, utmList[name]);
      }
    }
    return url.toString();
  }
  
  return link;
};

export const InitialLinkState: InitialLinkStateType = {
  utmList: undefined,
  transformLink: transformLink,
};

const globalLinkReducer = (
  state: InitialLinkStateType,
  action: LinkActions
): InitialLinkStateType => {
  switch (action.type) {
    case 'set_utm':
      return {
        ...state,
        utmList: extractParams(action.value),
      };
    default:
      return state ?? {};
  }
}
export  {globalLinkReducer};
