import gql from 'graphql-tag';
import { mapTransferCost } from '../mappers';
import { IGQLRequest, IGetTransferCost } from '../../types/app';

interface ITransferController {
    getCost: (data: IGetTransferCost) => IGQLRequest;
}

class TransferController {
    static getCost(data: IGetTransferCost){
        return {
            mapper: mapTransferCost,
            query: gql`
                {
                    transferCost(sum: ${data.sum}, company_id: "${data.company_id}"){
                        paymentSum,
                        sum,
                        tax,
                        description
                    }
                }
            `
        }
    }
}

export default TransferController as ITransferController;
