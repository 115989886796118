import React, {Component} from 'react';
import cn from 'classnames';
import './style.scss';

type MenuRegistrationItem = { id: string, title: string, to: string };

type MenuRegistrationProps = {
  onClick: (id: string) => void,
  activeItem: string,
};

type MenuRegistrationState = {
  menuItems: Array<MenuRegistrationItem>,
};

class MenuRegistration extends Component<MenuRegistrationProps, MenuRegistrationState> {

  state = {
    menuItems: [
      { id: '1', title: 'Регион', to: '/' },
      { id: '2', title: 'Учреждение', to: '/' },
      { id: '3', title: 'Дата', to: '/' },
      { id: '4', title: 'Время', to: '/' },
      { id: '5', title: 'Данные', to: '/' },
      { id: '6', title: 'Подтверждение', to: '/' },
    ]
  }

  onClick = (id: string) => {
    this.props.onClick(id);
  };

  render() {
    const { activeItem } = this.props;
    const { menuItems } = this.state;

    return (
      <div className="MenuRegistration">
        <ul className="MenuRegistration__list">
          {menuItems.map((item, index) => (
            <li
              key={index}
              className={cn('MenuRegistration__item',
                item.id === activeItem && 'MenuRegistration__item_active')}
            >
              <button className="MenuRegistration__link" onClick={() => this.onClick(item.id)}>
                {item.title}
              </button>
              <svg
                width="12"
                height="8"
                viewBox="0 0 12 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.8786 0.174384C8.69821 -0.0358886 8.38249 -0.0592925 8.1734 0.12211C7.96431 0.303512 7.94104 0.621028 8.12142 0.8313L10.4107 3.5H0.5C0.223858 3.5 0 3.72386 0 4C0 4.27614 0.223858 4.5 0.5 4.5H10.4107L8.12142 7.1687C7.94104 7.37897 7.96431 7.69649 8.1734 7.87789C8.38249 8.05929 8.69821 8.03589 8.8786 7.82561L11.8786 4.32846C12.0405 4.13975 12.0405 3.86025 11.8786 3.67154L8.8786 0.174384Z"
                  fill="#B8C0CC"
                />
              </svg>
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

export default MenuRegistration;