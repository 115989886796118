import React, { Component } from 'react';
import { Props, State } from './interface';
import { IMetaTags } from '../../../types/server';
import withApollo from '../../../lib/HOC/withApollo';

import Sticky from 'react-stickynode';
import Wrapper from '../../../components/Wrapper';
import Collapse from '../../../components/Collapse';
import Typography from '../../../components/Typography';
import Page, { PageBlock } from '../../../components/Page';
import QuestionChat from '../../../components/QuestionChat';
import SectionTitle from '../../../components/SectionTitle';
import getTypographyProps from '../../../lib/getTypographyProps';

import './SupportPage.scss';
import MetaTags from '../../../components/MetaTags';

const STICKY_ID = 'sticky-container';
const sidebarConfig = [
  {
    to: 'home',
    label: 'Общие вопросы'
  },
  {
    to: 'news',
    label: 'Письмо заключенному'
  },
  {
    to: 'history',
    label: 'Деньги заключенному'
  },
  {
    to: 'photo',
    label: 'Фото заключенному'
  },
  {
    to: 'queue',
    label: 'Запись на свидание с заключенными '
  }
];

class SupportPage extends Component<Props, State> {
  renderSideBar() {
    const tabs = sidebarConfig.map((a, i) => (
      <Typography
        key={a.label}
        {...getTypographyProps('text', 'm', 'main')}
      >
        {a.label}
      </Typography>
    ));

    return (
      <Sticky
        bottomBoundary={`#${STICKY_ID}`}
        enabled={false}
        top={32}
      >
        <div className={this.classNames.sidebar}>
          <div className={`${this.classNames.sidebarTabs} mobile_hide tablet_hide`}>{tabs}</div>
          <QuestionChat title='Получить быстрый ответ в течении 15 минут' />
        </div>
      </Sticky>
    );
  }

  renderCommon() {
    return (
      <div>
        <Typography {...getTypographyProps('heading', 'l', 'dark-text')}>Общие вопросы</Typography>

        <div className='collapse-wrapper'>
          <Collapse title='Collapse 1' />
          <Collapse title='Collapse 2' />
          <Collapse title='Collapse 3' />
        </div>
      </div>
    );
  }

  renderMail() {
    return (
      <div>
        <Typography {...getTypographyProps('heading', 'l', 'dark-text')}>
          <span className='mail'>Письмо заключенному</span>
        </Typography>

        <div className='collapse-wrapper'>
          <Collapse title='Collapse 1' />
          <Collapse title='Collapse 2' />
          <Collapse title='Collapse 3' />
        </div>
      </div>
    );
  }

  renderTransfer() {
    return (
      <div>
        <Typography {...getTypographyProps('heading', 'l', 'dark-text')}>
          <span className='transfer'>Деньги заключенному</span>
        </Typography>

        <div className='collapse-wrapper'>
          <Collapse title='Collapse 1' />
          <Collapse title='Collapse 2' />
          <Collapse title='Collapse 3' />
        </div>
      </div>
    );
  }

  renderTv() {
    return (
      <div>
        <Typography {...getTypographyProps('heading', 'l', 'dark-text')}>
          <span className='tv'>ТВ</span>
        </Typography>

        <div className='collapse-wrapper'>
          <Collapse title='Collapse 1' />
          <Collapse title='Collapse 2' />
          <Collapse title='Collapse 3' />
        </div>
      </div>
    );
  }

  renderPhoto() {
    return (
      <div>
        <Typography {...getTypographyProps('heading', 'l', 'dark-text')}>
          <span className='photo'>Фото заключенному</span>
        </Typography>

        <div className='collapse-wrapper'>
          <Collapse title='Collapse 1' />
          <Collapse title='Collapse 2' />
          <Collapse title='Collapse 3' />
        </div>
      </div>
    );
  }

  renderQueue() {
    return (
      <div>
        <Typography {...getTypographyProps('heading', 'l', 'dark-text')}>
          <span className='queue'>Запись на свидание с заключенными </span>
        </Typography>
        <div className='collapse-wrapper'>
          <Collapse title='Collapse 1' />
          <Collapse title='Collapse 2' />
          <Collapse title='Collapse 3' />
        </div>
      </div>
    );
  }

  renderContent() {
    return (
      <div className={this.classNames.content}>
        {this.renderCommon()}
        {this.renderMail()}
        {this.renderTransfer()}
        {this.renderTv()}
        {this.renderPhoto()}
        {this.renderQueue()}
      </div>
    );
  }

  classNames = {
    sidebar: 'page__sidebar',
    sidebarTabs: 'page__sidebar-tabs',
    content: 'page__content'
  };

  setMeta() {
    const meta: IMetaTags = {
      title: 'Навигатор по колониям и СИЗО России. Адреса, телефоны, график приема, график передач',
      description: 'Удобные ФСИН сервисы, помогают получить информацию по учреждениям, отправлять письма и фотографии для заключенных.',
    };
  
    meta.last_modified = "Sat, 10 Oct 2020 06:59:13 GMT"
    meta.robots = 'follow';
    meta.canonical = "/support";
  
    return (
      <MetaTags 
      title={meta.title} 
      description={meta.description}
      lastModified={meta.last_modified}
      robots={meta.robots}
      canonical={meta.canonical}
      />
    );
  }

  render() {
    return (
      <Page page='support'>
        {this.setMeta()}
        <Wrapper isPageWrapper>
          <SectionTitle title='Помощь и поддержка' />
          <PageBlock id={STICKY_ID}>
            {this.renderSideBar()}
            {this.renderContent()}
          </PageBlock>
        </Wrapper>
      </Page>
    );
  }
}

export default withApollo(undefined, undefined)(SupportPage);
