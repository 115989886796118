import React, { useEffect, useState } from 'react';
import { FormGroup, FormElement } from '../../Form';
import getTypographyProps from '../../../lib/getTypographyProps';
import Typography from '../../Typography';
import { SendingContact } from '../SendingContact/SendingContact';
import Button from '../../Button';
import './sending-sender-form.scss';
import {
  startFotoOrder,
  startMailOrder,
  startTransferOrder,
} from '../../../lib/dataLayerEvents';
export type SenderFormMode = 'mail' | 'transfer' | 'fullData';

export interface Props {
  mode: SenderFormMode;
  refInput: any;
  inputRefs: any;
  handleChange: any;
  phoneChange: any;
  updateState: any;
  userInfo?: any;

  senderFirstName: string;
  senderMiddleName: string;
  senderLastName: string;
  senderPhone: string;
  senderEmail: string;

  senderSnils?: string;
  senderPassportNumber?: string;
  senderPassportSeries?: string;
  isSnilsSend?: boolean;
  toggleSendMode?: any;

  isDataSave: boolean;
  dataLayerName: string;
}

export interface State {
  mode: SenderFormMode;
}

const SendingSenderForm = (props: Props) => {
  const [mode, setMode] = useState<'mail' | 'transfer' | 'fullData'>();

  useEffect(() => {
    setMode(props.mode);
  }, [props.mode]);

  useEffect(() => {
    if (props.userInfo) {
      props.updateState({
        senderFirstName: props.userInfo.firstname,
        senderMiddleName: props.userInfo.middlename || '',
        senderLastName: props.userInfo.lastname,
      });
    }
  }, []);

  const onFocusEvent = () => {
    const { dataLayerName } = props;
    switch (dataLayerName) {
      case 'transfer':
        startTransferOrder();
        break;
      case 'foto':
        startFotoOrder();
        break;
      case 'mail':
        startMailOrder();
      default:
        break;
    }
  };

  const renderWithFullData = () => {
    const { senderFirstName, senderMiddleName, senderLastName } = props;

    return (
      <div>
        <Typography
          {...getTypographyProps('heading', 'l', 'dark-text', 'medium')}
          children='От кого'
        />
        <div className='sending-sender-form__existing-contacts-container'>
          <SendingContact
            className='sending-sender-form__existing-contact'
            firstname={senderFirstName}
            middlename={senderMiddleName}
            lastname={senderLastName}
            isSelected={true}
          />
          <Button
            className='sending-sender-form__button'
            onClick={changeContact}
            isColored
            label='Изменить'
          />
        </div>
      </div>
    );
  };

  const renderMailEditForm = () => {
    const {
      senderFirstName,
      senderMiddleName,
      senderLastName,
      senderPhone,
      senderEmail,
      isDataSave,
    } = props;

    const { handleChange, refInput, phoneChange } = props;

    return (
      <div>
        <Typography
          {...getTypographyProps('heading', 'l', 'dark-text', 'medium')}
          children='От кого'
        />
        <FormGroup>
          <FormElement
            name='lastName'
            onChange={handleChange('senderLastName')}
            onRef={refInput('senderLastName')}
            placeholder='Фамилия'
            validations={['cyrillic']}
            value={senderLastName}
            onFocus={() => onFocusEvent()}
            data-testid='senderLastName'
            isDynamic
            isDynamicPlaceholder
          />
          <FormElement
            name='firstName'
            onChange={handleChange('senderFirstName')}
            onRef={refInput('senderFirstName')}
            placeholder='Имя'
            validations={['cyrillic']}
            value={senderFirstName}
            onFocus={() => onFocusEvent()}
            data-testid='senderFirstName'
            isDynamic
            isDynamicPlaceholder
          />
        </FormGroup>
        <FormGroup>
          <FormElement
            name='middleName'
            onChange={handleChange('senderMiddleName')}
            onRef={refInput('senderMiddleName')}
            placeholder='Отчество'
            validations={['cyrillicNotRequired']}
            value={senderMiddleName}
            onFocus={() => onFocusEvent()}
            data-testid='senderMiddleName'
            isDynamic
            isDynamicPlaceholder
          />
          <FormElement
            country='ru'
            name='senderPhone'
            onChange={phoneChange}
            onRef={refInput('senderPhone')}
            preferredCountries={['ru']}
            value={senderPhone}
            withContyPhones
            validations={['phone']}
            placeholder=' '
            // @ts-ignore
            specialLabel={false}
            onFocus={() => onFocusEvent()}
            data-testid='senderPhone'
            isDynamic
            isDynamicPlaceholder
          />
        </FormGroup>
        <FormGroup>
          <FormElement
            maxLength={36}
            minLength={6}
            name='email'
            onChange={handleChange('senderEmail')}
            onRef={refInput('senderEmail')}
            placeholder='Почта'
            validations={['required', 'email']}
            value={senderEmail}
            onFocus={() => onFocusEvent()}
            data-testid='senderEmail'
            isDynamic
            isDynamicPlaceholder
          />
          <div className='form__element form__element_empty' />
        </FormGroup>
        <FormGroup>
          <FormElement
            label='Запомнить мои данные'
            onChange={handleChange('isDataSave')}
            type='checkbox'
            value={isDataSave}
            onClick={() => onFocusEvent()}
            data-testid='senderIsDataSave'
          />
        </FormGroup>
      </div>
    );
  };

  const renderTransferEditForm = () => {
    const {
      senderFirstName,
      senderMiddleName,
      senderLastName,
      senderPhone,
      senderSnils,
      senderEmail,
      senderPassportNumber,
      senderPassportSeries,
      isDataSave,
      isSnilsSend,
      toggleSendMode,
    } = props;

    const { handleChange, refInput, phoneChange } = props;

    return (
      <div>
        <Typography
          {...getTypographyProps('heading', 'l', 'dark-text', 'medium')}
          children='От кого'
        />
        <FormGroup>
          <FormElement
            name='lastName'
            onChange={handleChange('senderLastName')}
            onRef={refInput('senderLastName')}
            placeholder='Фамилия'
            validations={['cyrillic']}
            value={senderLastName}
            onFocus={() => onFocusEvent()}
            data-testid='senderLastName'
            isDynamicPlaceholder={true}
            isDynamic
          />
          <FormElement
            name='firstName'
            onChange={handleChange('senderFirstName')}
            onRef={refInput('senderFirstName')}
            placeholder='Имя'
            validations={['cyrillic']}
            value={senderFirstName}
            onFocus={() => onFocusEvent()}
            data-testid='senderFirstName'
            isDynamicPlaceholder={true}
            isDynamic
          />
        </FormGroup>
        <FormGroup>
          <FormElement
            name='middleName'
            onChange={handleChange('senderMiddleName')}
            onRef={refInput('senderMiddleName')}
            placeholder='Отчество'
            validations={['cyrillicNotRequired']}
            value={senderMiddleName}
            onFocus={() => onFocusEvent()}
            data-testid='senderMiddleName'
            isDynamicPlaceholder={true}
            isDynamic
          />
          <div className='form__element form__element_empty' />
        </FormGroup>
        <FormGroup>
          <FormElement
            isRound
            label='Ввести серию и номер паспорта'
            onChange={!isSnilsSend ? undefined : toggleSendMode}
            type='checkbox'
            value={!isSnilsSend}
          />
          <FormElement
            isRound
            label='Ввести номер СНИЛС'
            onChange={isSnilsSend ? undefined : toggleSendMode}
            type='checkbox'
            value={isSnilsSend || false}
          />
        </FormGroup>

        {isSnilsSend ? (
          <FormGroup key='snils'>
            <FormElement
              minLength={11}
              name='snils'
              onChange={handleChange('senderSnils')}
              onRef={refInput('senderSnils')}
              placeholder='Снилс'
              validations={['required', 'min']}
              value={senderSnils || ''}
              onFocus={() => onFocusEvent()}
              isDynamicPlaceholder={true}
              isDynamic
            />
          </FormGroup>
        ) : (
          <FormGroup key='passport'>
            <FormElement
              maxLength={4}
              minLength={4}
              name='passportSeries'
              onChange={handleChange('senderPassportSeries')}
              onRef={refInput('senderPassportSeries')}
              placeholder='Серия паспорта'
              validations={['required', 'min']}
              value={senderPassportSeries || ''}
              onFocus={() => onFocusEvent()}
              isDynamicPlaceholder={true}
              isDynamic
            />
            <FormElement
              maxLength={6}
              minLength={6}
              name='passportNumber'
              onChange={handleChange('senderPassportNumber')}
              onRef={refInput('senderPassportNumber')}
              placeholder='Номер паспорта'
              validations={['required', 'min']}
              value={senderPassportNumber || ''}
              onFocus={() => onFocusEvent()}
              isDynamicPlaceholder={true}
              isDynamic
            />
          </FormGroup>
        )}
        <FormGroup>
          <FormElement
            country='ru'
            name='senderPhone'
            // @ts-ignore
            onChange={phoneChange}
            onRef={refInput('senderPhone')}
            preferredCountries={['ru']}
            value={senderPhone}
            withContyPhones
            validations={['required', 'phone']}
            onFocus={() => onFocusEvent()}
            isDynamic
            // @ts-ignore
            specialLabel={false}
            data-testid='senderPhone'
          />
          <FormElement
            name='email'
            onChange={handleChange('senderEmail')}
            onRef={refInput('senderEmail')}
            placeholder='Почта'
            validations={['email']}
            value={senderEmail}
            onFocus={() => onFocusEvent()}
            data-testid='senderEmail'
            isDynamicPlaceholder={true}
            isDynamic
          />
        </FormGroup>
        <FormGroup>
          <FormElement
            label='Запомнить мои данные'
            onChange={handleChange('isDataSave')}
            type='checkbox'
            value={isDataSave}
            data-testid='senderIsDataSave'
          />
        </FormGroup>
      </div>
    );
  };

  const changeContact = () => {
    setMode(props.mode);
  };

  if (mode === 'fullData') {
    return renderWithFullData();
  } else if (mode === 'mail') {
    return renderMailEditForm();
  } else {
    return renderTransferEditForm();
  }
};

export { SendingSenderForm };
