import React, { Component } from 'react';
import './style.scss';

interface company {
  address: string;
  id: string | null;
  shortName: string;
}

interface responseType {
  id: string;
  name: string;
  companies: Array<company> | undefined;
}

interface Props {
  regions: {
    data: Array<any>;
    loading: boolean;
  };
  onClick: any;
  setSelectedRegion: any;
}

/// ------------- LIMIT REGION  ------------////
const LIMIT = true;
const ID_ROSTOV = 175;
const ID_MURMANSK = 163;
const ID_SMOLENSK = 181;
const ID_PENZA = 171;
const ID_KIROV = 155;

class FormRegion extends Component<Props> {
  state = {
    regionList: [],
    regionsOutput: [],
  };

  onClick(item: any) {
    this.props.setSelectedRegion(item);
    this.props.onClick();
  }

  componentDidMount() {
    if (!this.props.regions.loading) {
      const regionLimit = this.props.regions.data.filter(
        (item: any) => item.id === ID_PENZA || item.id === ID_KIROV
      );
      this.setState({ regionList: regionLimit });
    }
  }

  render() {
    return (
      <ul className='FormRegion'>
        {this.state.regionList.map((item: responseType) => (
          <li
            className='FormRegion__item'
            key={item.id}
            onClick={() => this.onClick(item)}
          >
            <span>{item.name}</span>
            {item.companies && (
              <span>
                {
                  item.companies.filter(
                    (item) =>
                      item.id === '6dc20d4b-ae31-46ab-a5bf-94861a705cb0' ||
                      item.id === 'adb4e08b-1e5d-485b-9b58-9c9abf40dbfe' ||
                      item.id === 'b3abcdbb-11be-4335-806f-7b6ffdb1524d'
                  ).length
                }
              </span>
            )}
          </li>
        ))}
      </ul>
    );
  }
}

export default FormRegion;
