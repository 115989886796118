const PhotoIcon: React.FC = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='48'
      height='48'
      viewBox='0 0 48 48'
      fill='none'
    >
      <circle cx='24' cy='24' r='24' fill='#FFB31A' />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M18 25.25C18 21.9363 20.6863 19.25 24 19.25C27.3137 19.25 30 21.9363 30 25.25C30 28.5637 27.3137 31.25 24 31.25C20.6863 31.25 18 28.5637 18 25.25ZM24 21.25C21.7909 21.25 20 23.0409 20 25.25C20 27.4591 21.7909 29.25 24 29.25C26.2091 29.25 28 27.4591 28 25.25C28 23.0409 26.2091 21.25 24 21.25Z'
        fill='white'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M18.0509 15.1843C18.0736 15.1162 18.1041 15.0496 18.1425 14.9855L19.4349 12.8315C19.9771 11.9279 20.9536 11.375 22.0074 11.375H25.9926C27.0464 11.375 28.0229 11.9279 28.5651 12.8315L29.8575 14.9855C29.8959 15.0496 29.9264 15.1162 29.9491 15.1843C31.2117 15.2718 32.2195 15.3805 33.007 15.4882C34.8401 15.739 36.2521 17.0945 36.5532 18.9302C36.783 20.3307 37 22.3542 37 25C37 27.6458 36.783 29.6693 36.5532 31.0698C36.2521 32.9055 34.8401 34.261 33.007 34.5118C31.2416 34.7532 28.3693 35 24 35C19.6307 35 16.7584 34.7532 14.993 34.5118C13.1599 34.261 11.7479 32.9055 11.4468 31.0698C11.217 29.6693 11 27.6458 11 25C11 22.3542 11.217 20.3307 11.4468 18.9302C11.7479 17.0945 13.1599 15.739 14.993 15.4882C15.7805 15.3805 16.7883 15.2718 18.0509 15.1843ZM21.1499 13.8605C21.3306 13.5593 21.6561 13.375 22.0074 13.375H25.9926C26.3439 13.375 26.6694 13.5593 26.8501 13.8605L27.5704 15.061C26.5105 15.0227 25.324 15 24 15C22.676 15 21.4895 15.0227 20.4296 15.061L21.1499 13.8605ZM24 17C19.7041 17 16.924 17.2428 15.264 17.4698C14.2909 17.6029 13.5777 18.2945 13.4204 19.2539C13.208 20.5489 13 22.4641 13 25C13 27.5359 13.208 29.4511 13.4204 30.7461C13.5777 31.7055 14.2909 32.3971 15.264 32.5302C16.924 32.7572 19.7041 33 24 33C28.2959 33 31.076 32.7572 32.736 32.5302C33.7091 32.3971 34.4223 31.7055 34.5796 30.7461C34.792 29.4511 35 27.5359 35 25C35 22.4641 34.792 20.5489 34.5796 19.2539C34.4223 18.2945 33.7091 17.6029 32.736 17.4698C31.076 17.2428 28.2959 17 24 17Z'
        fill='white'
      />
    </svg>
  );
};

export default PhotoIcon;
