/* eslint-disable func-names */
// import jQuery from 'jquery';
import React, { Component } from 'react';
import { Props, State } from './interface';
import { IMetaTags } from '../../../types/server';

import Page from '../../../components/Page';
import Wrapper from '../../../components/Wrapper';
import withApollo from '../../../lib/HOC/withApollo';
import SectionTitle from '../../../components/SectionTitle';

import QuestionChat from '../../../components/QuestionChat';
import { Link } from 'react-scroll'
import Sticky from 'react-stickynode';


import Html from '../../../components/Html';
import Pages from '../../../gql/actions/Pages';
import './CustomPage.scss';
import MetaTags from '../../../components/MetaTags';

const STICKY_ID = 'sticky-container';
const sidebarConfig = [
  {
    to: 'main',
    label: 'Общие вопросы'
  },
  {
    to: 'mail',
    label: 'Письмо заключенному'
  },
  {
    to: 'transfer',
    label: 'Деньги заключенному'
  },
  {
    to: 'https://zt.ru/services/photo-fsin/buy-photo?utm_source=sizoru&utm_medium=redirect&utm_campaign=fsinfoto&utm_content=/foto',
    label: 'Фото заключенному'
  },
  {
    to: 'date',
    label: 'Запись на свидание с заключенными '
  }
];

class CustomPage extends Component<Props, State> {
  componentDidUpdate(prevProps:any) {
    if (this.props.content.data[0] !== prevProps.content.data[0] ) {
      
        const collapse = document.querySelectorAll('.collapse');
        collapse.forEach(item => {
            //@ts-ignore
          item.querySelector('.collapse__title').addEventListener('click',(e)=>{
            var content: { style: { display: string; maxHeight: string; }; };
            //@ts-ignore
            if (e.target.classList[0] !== "collapse__title") {
              //@ts-ignore
              content = e.target.parentNode.parentNode.children[1].querySelector('.ReactCollapse--collapse');
            } else {
              //@ts-ignore
              content = e.target.parentNode.children[1].querySelector('.ReactCollapse--collapse')
            }
            
            if (content.style.display === "block") {
              content.style.maxHeight = "0px"
              
              setTimeout(() => {
                content.style.display = "none"
              }, 500);
    
            } else {
              content.style.display = "block"
              // content.style.transition = "all linear .3s"
              content.style.maxHeight = "0px"
              setTimeout(() => {
                content.style.maxHeight = "500px"
              }, 500);
            }
    
          })
        })
      
    
    }
  
    }
  
  renderSideBar() {
    const tabs = sidebarConfig.map((a, i) => (
      <Link key={a.label} activeClass="active" className="typography typography_type_text typography_size_m typography_color_main link link_blue" to={a.to} spy={true} smooth={true} duration={500}>
      {a.label}
      </Link>
    ));

    return (
      <Sticky
        bottomBoundary={`#${STICKY_ID}`}
        enabled={true}
        top={32}
      >
        <div className={this.classNames.sidebar}>
          <div className={`${this.classNames.sidebarTabs} mobile_hide tablet_hide`}>{tabs}</div>
          <QuestionChat title='Получить быстрый ответ в течении 15 минут' />
        </div>
      </Sticky>
    );
  }


  renderPreload() {
    return (
      <Page page='custom'>
        <Wrapper isPageWrapper>
          <Html isPreload />
        </Wrapper>
      </Page>
    );
  }

  isFaqPage() {
    const { slug } = this.props.match.params;
    return slug === 'faq';
  }

  hasEvent: boolean = false;

  classNames = {
    sidebar: 'page__sidebar',
    sidebarTabs: 'page__sidebar-tabs',
    content: 'page__content'
  };

  setMeta(){
    const meta: IMetaTags = {
      title: 'Навигатор по колониям и СИЗО России. Адреса, телефоны, график приема, график передач',
      description: 'Навигатор по колониям и СИЗО России. Адреса, телефоны, график приема, график передач'
    };
  
    if (this.props.content.data.length) {
      const { metaTitle, metaDescription } = this.props.content.data[0];
  
      if (metaTitle) meta.title = metaTitle;
      if (metaDescription) meta.description = metaDescription;
    }
  
    meta.robots = 'follow';
    meta.last_modified = "Sat, 10 Oct 2020 06:59:13 GMT"

    return (
      <MetaTags 
      title={meta.title} 
      description={meta.description} 
      keywords={meta.keywords} 
      robots={meta.robots}
      lastModified={meta.last_modified}
      />
    )
  }


  render() {
    if (!(this.props.content.data && this.props.content.data.length)) {
      return this.renderPreload();
    }

    const content = this.props.content.data[0];

    return (
      <Page page='custom'>
        {this.setMeta()}
        <Wrapper isPageWrapper>
          <SectionTitle
            className='page__title'
            title={content.title}
          />
            <div className="html html_special">
              <div className="page__block faq" id="sticky-container">
              {this.props.match.params.slug === 'faq' && this.renderSideBar()}
              {(this.props.content.data && this.props.content.data.length) &&
                <Html
                html={content.content}
                isSpecialContent={this.isFaqPage()}
              />
              }
              </div>
            </div>
        </Wrapper>
      </Page>
    );
  }
}

const mapQuery = (props: Props) => {
  const { slug } = props.match.params;
      return {
    content: Pages.getBySlug(slug)
  };
};

export default withApollo(mapQuery, undefined)(CustomPage);
