import home from '../../../../images/institution/home.svg';
// import news from '../../../../images/institution/news.svg';
// import photo from '../../../../images/institution/photo.svg';
// import history from '../../../../images/institution/history.svg';
// import vacancies from '../../../../images/institution/vacancies.svg';

import { IInstitutionTabLabel } from '../interface';

const sidebar: { label: IInstitutionTabLabel; icon: string }[] = [
  {
    icon: home,
    label: 'Страница учреждения'
  },
  // {
  //   icon: news,
  //   label: 'Новости'
  // },
  // {
  //   icon: history,
  //   label: 'История учреждения'
  // },
  // {
  //   icon: photo,
  //   label: 'Фотогалерея'
  // },
  // {
  //   icon: vacancies,
  //   label: 'Вакансии'
  // }
];

export default sidebar;
