import { IReduxAction, IGlobalCommonState } from '../../types/redux';
import { SET_FORM_ERROR, CLEAR_FORM_ERROR } from '../actionsList';

export function setFormErrorAction(isFormError?: boolean) {
  return { type: SET_FORM_ERROR, payload: { isFormError } };
}

export function selectFormError(state: IGlobalCommonState) {
  return state.isFormError;
}

export default function (state: any = {}, action: IReduxAction): any {
  switch (action.type) {
    case SET_FORM_ERROR:
      return action.payload.isFormError;
    default:
      return state;
  }
}
