const MailIcon: React.FC = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='48'
      height='48'
      viewBox='0 0 48 48'
      fill='none'
    >
      <circle cx='24' cy='24' r='24' fill='#2E6BE6' />
      <path
        d='M14.2929 17.2929C14.6834 16.9024 15.3166 16.9024 15.7071 17.2929L24 25.5858L26.2922 23.2936C26.292 23.2938 26.2924 23.2934 26.2922 23.2936C26.2924 23.2934 26.2934 23.2924 26.2936 23.2922L32.2929 17.2929C32.6834 16.9024 33.3166 16.9024 33.7071 17.2929C34.0976 17.6834 34.0976 18.3166 33.7071 18.7071L28.4142 24L33.7071 29.2929C34.0976 29.6834 34.0976 30.3166 33.7071 30.7071C33.3166 31.0976 32.6834 31.0976 32.2929 30.7071L27 25.4142L24.7071 27.7071C24.3166 28.0976 23.6834 28.0976 23.2929 27.7071L21 25.4142L15.7071 30.7071C15.3166 31.0976 14.6834 31.0976 14.2929 30.7071C13.9024 30.3166 13.9024 29.6834 14.2929 29.2929L19.5858 24L14.2929 18.7071C13.9024 18.3166 13.9024 17.6834 14.2929 17.2929Z'
        fill='white'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M14.0574 13.5696C15.9478 13.2933 19.1061 13 24 13C28.8939 13 32.0522 13.2933 33.9426 13.5696C35.7735 13.8372 37.1685 15.2021 37.4765 17.0265C37.7388 18.5804 38 20.9051 38 24C38 27.0949 37.7388 29.4196 37.4765 30.9735C37.1685 32.7979 35.7735 34.1628 33.9426 34.4304C32.0522 34.7067 28.8939 35 24 35C19.1061 35 15.9478 34.7067 14.0575 34.4304C12.2265 34.1628 10.8315 32.7979 10.5235 30.9735C10.2612 29.4196 10 27.0949 10 24C10 20.9051 10.2612 18.5804 10.5235 17.0265C10.8315 15.2021 12.2265 13.8372 14.0574 13.5696ZM24 15C19.1839 15 16.1242 15.2887 14.3467 15.5485C13.3746 15.6906 12.6592 16.3905 12.4956 17.3593C12.2516 18.8049 12 21.0182 12 24C12 26.9818 12.2516 29.1951 12.4956 30.6407C12.6592 31.6096 13.3746 32.3094 14.3467 32.4515C16.1242 32.7113 19.1839 33 24 33C28.8161 33 31.8758 32.7113 33.6533 32.4515C34.6254 32.3094 35.3408 31.6096 35.5044 30.6407C35.7484 29.1951 36 26.9818 36 24C36 21.0182 35.7484 18.8049 35.5044 17.3593C35.3408 16.3904 34.6254 15.6906 33.6533 15.5485C31.8758 15.2887 28.8161 15 24 15Z'
        fill='white'
      />
    </svg>
  );
};

export default MailIcon;
