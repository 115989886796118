import request from '../../../lib/request';
import { ACCOUNT_END_POINT } from '../../../lib/request/config';
import gql from "graphql-tag";

const QUERY = (errorCode: string): any => {
  return gql`
          {
  errorWiki(code: "${errorCode}") {
    error {
      code
      msg
    }
  }
}`
};

export function errorWikiQuery(errorCode: string, success: (response: any) => void) {
  const {body} = QUERY(errorCode).loc.source;
  const operation = 'query';

  return request({
    method: 'POST',
    body: JSON.stringify({[operation]: body}),
    headers: { 'Content-Type': 'application/json' },
    success,
    endPoint: ACCOUNT_END_POINT
  });
}