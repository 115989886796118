import React, { Component } from 'react';
import { Props, State } from './interface';
import bind from '../../../lib/decorators/bind';
import { IMetaTags } from '../../../types/server';
import withApollo from '../../../lib/HOC/withApollo';

import Page from '../../../components/Page';
import Button from '../../../components/Button';
import Wrapper from '../../../components/Wrapper';
import GridRow from '../../../components/GridRow';
import Typography from '../../../components/Typography';
import SectionTitle from '../../../components/SectionTitle';
import getTypographyProps from '../../../lib/getTypographyProps';

import Mail from '../../../gql/actions/Mail';
import './PaymentPage.scss';
import moment from 'moment';
import { createMailPayment } from '../../../gql/actions/Mutations';
import Notification from '../../../components/Notification';

class IndexPage extends Component<Props, State> {
  state: State = {
    isShowMessage: false,
    isProcessing: false
  };

  classNames = {
    title: 'page__title',
    preload: 'page_preload',
    gridRowItem: 'grid-row__item',

    info: 'info',
    infoFields: 'info__fields',

    total: 'total',
    totalFields: 'total__fields',
    totalDeadline: 'total__deadline',
    totalTextButton: 'total__text-button'
  };

  @bind
  onSubmit() {
    const { id } = this.props.match.params;

    this.setState({ isProcessing: true });

    createMailPayment({ id }, (response) => {
      try {
        // eslint-disable-next-line
        const paymentUrl = response.data.createMailPayment.payment_url;

        const link = document.createElement('a');
        link.href = paymentUrl;
        link.click();
        link.remove();
      } catch (error) {
        this.setState({ isProcessing: false });
        try {
          Notification.show(response.errors[0].message);
        } catch (err) {
          console.log(err);
          Notification.show('Произошла ошибка, пожалуйста свяжитесь с поддержкой');
        }
      }
    });
  }

  @bind
  renderInfo() {
    const { isShowMessage } = this.state;
    const [data] = this.props.order.data;
    const { id } = this.props.match.params;
    const { sender, prisoner, institution, text } = data;

    return (
      <div className={`${this.classNames.gridRowItem} ${this.classNames.info}`}>
        <SectionTitle
          size='l'
          title={`Заказ №${id}`}
          type='text'
        />

        <div className={this.classNames.infoFields}>
          <SectionTitle
            size='m'
            title='Куда'
            type='text'
          />
          <div>
            <Typography
              {...getTypographyProps('text', 's', 'dark-text')}
              children='Регион'
            />
            <Typography
              {...getTypographyProps('text', 's', 'text')}
              children={institution.region.name}
            />
          </div>
          <div>
            <Typography
              {...getTypographyProps('text', 's', 'dark-text')}
              children='Учреждение'
            />
            <Typography
              {...getTypographyProps('text', 's', 'text')}
              children={institution.shortName}
            />
          </div>
        </div>
        <div className={this.classNames.infoFields}>
          <SectionTitle
            size='m'
            title='Кому'
            type='text'
          />
          <div>
            <Typography
              {...getTypographyProps('text', 's', 'dark-text')}
              children='Фамилия'
            />
            <Typography
              {...getTypographyProps('text', 's', 'text')}
              children={prisoner.lastName}
            />
          </div>
          <div>
            <Typography
              {...getTypographyProps('text', 's', 'dark-text')}
              children='Имя'
            />
            <Typography
              {...getTypographyProps('text', 's', 'text')}
              children={prisoner.firstName}
            />
          </div>
          <div>
            <Typography
              {...getTypographyProps('text', 's', 'dark-text')}
              children='Отчество'
            />
            <Typography
              {...getTypographyProps('text', 's', 'text')}
              children={prisoner.middleName}
            />
          </div>
          <div>
            <Typography
              {...getTypographyProps('text', 's', 'dark-text')}
              children='Год рождения'
            />
            <Typography
              {...getTypographyProps('text', 's', 'text')}
              children={moment(prisoner.birthDate).format('YYYY')}
            />
          </div>
        </div>
        <div className={this.classNames.infoFields}>
          <SectionTitle
            size='m'
            title='От кого'
            type='text'
          />
          <div>
            <Typography
              {...getTypographyProps('text', 's', 'dark-text')}
              children='Фио'
            />
            <Typography
              {...getTypographyProps('text', 's', 'text')}
              children={`${sender.lastName} ${sender.firstName} ${sender.middleName}`}
            />
          </div>
          <div>
            <Typography
              {...getTypographyProps('text', 's', 'dark-text')}
              children='Телефон'
            />
            <Typography
              {...getTypographyProps('text', 's', 'text')}
              children={sender.phone || 'Не указан'}
            />
          </div>
          <div>
            <Typography
              {...getTypographyProps('text', 's', 'dark-text')}
              children='Почта'
            />
            <Typography
              {...getTypographyProps('text', 's', 'text')}
              children={sender.email}
            />
          </div>
        </div>

        <div>
          <Button
            isTransparent
            label='Посмотреть письмо'
            onClick={this.toggleShowEmail}
          />
        </div>

        {isShowMessage && (
          <Typography
            {...getTypographyProps('text', 's', 'text')}
            children={text}
          />
        )}
      </div>
    );
  }

  @bind
  toggleShowEmail() {
    this.setState({ isShowMessage: !this.state.isShowMessage });
  }

  @bind
  renderTotal() {
    const [data] = this.props.order.data;
    const { cost } = data;

    const sheets = cost.pages.count;
    const sheetsSum = cost.pages.total;
    const images = cost.images.count;
    const imagesSum = cost.images.total;
    const blanks = cost.answers.count;
    const blanksSum = cost.answers.total;
    const { total } = cost;

    return (
      <div className={`${this.classNames.gridRowItem} ${this.classNames.total}`}>
        <SectionTitle
          size='l'
          title='Оплата'
          type='text'
        />
        <div className={this.classNames.totalFields}>
          <div>
            <Typography
              {...getTypographyProps('text', 's', 'text')}
              children={
                <span>
                  <span>{sheets}</span> страниц
                </span>
              }
            />
            <div>
              <Typography
                {...getTypographyProps('text', 'm', 'dark-text')}
                children={`${sheetsSum} ₽`}
              />
            </div>
          </div>
          <div>
            <Typography
              {...getTypographyProps('text', 's', 'text')}
              children={
                <span>
                  <span>{images}</span> фотографий
                </span>
              }
            />
            <div>
              <Typography
                {...getTypographyProps('text', 'm', 'dark-text')}
                children={`${imagesSum} ₽`}
              />
            </div>
          </div>
          <div>
            <Typography
              {...getTypographyProps('text', 's', 'text')}
              children={
                <span>
                  <span>{blanks}</span> бланк ответа
                </span>
              }
            />
            <div>
              <Typography
                {...getTypographyProps('text', 'm', 'dark-text')}
                children={`${blanksSum} ₽`}
              />
            </div>
          </div>
          <div>
            <Typography
              {...getTypographyProps('text', 'm', 'dark-text')}
              children='Итого к оплате:'
            />
            <div>
              <Typography
                {...getTypographyProps('text', 'xl', 'main')}
                children={`${total} ₽`}
              />
            </div>
          </div>
        </div>
        <Button
          isColored
          isLoading={this.state.isProcessing}
          label='Оплатить'
          onClick={this.onSubmit}
        />
        <Typography
          className={this.classNames.totalTextButton}
          // eslint-disable-next-line react/jsx-no-bind
          onClick={() => {}}
          {...getTypographyProps('text', 'm', 'main')}
          children='Оплатить позже'
        />
        <Typography
          className={this.classNames.totalDeadline}
          {...getTypographyProps('text', 's', 'text')}
          children='Оплатить можно в течении 3-х дней до 17:16 26.10.19'
        />
      </div>
    );
  }

  render() {
    if (this.props.order.loading) {
      return (
        <Page
          className={this.classNames.preload}
          page='payment-mail'
        >
          <Wrapper isPageWrapper>
            <SectionTitle
              className={this.classNames.title}
              title='Страница оплаты'
            />
            <GridRow>
              <div className={`${this.classNames.gridRowItem} ${this.classNames.info}`} />
              <div className={`${this.classNames.gridRowItem} ${this.classNames.total}`} />
            </GridRow>
          </Wrapper>
        </Page>
      );
    }

    return (
      <Page page='payment-mail'>
        <Wrapper isPageWrapper>
          <SectionTitle
            className={this.classNames.title}
            title='Страница оплаты'
          />
          <GridRow>
            {this.renderInfo()}
            {this.renderTotal()}
          </GridRow>
        </Wrapper>
      </Page>
    );
  }
}

function mapQuery(props: Props) {
  const { id } = props.match.params;

  return {
    order: Mail.getOrder(Number(id))
  };
}

function setMeta(data: Props): IMetaTags {
  const meta: IMetaTags = {
    title: 'Навигатор по колониям и СИЗО России. Адреса, телефоны, график приема, график передач',
    keywords:
      'фсин письмо фотографии справочник колонии денежный перевод сервисы исправительные учреждения',
    description:
      'Удобные ФСИН сервисы, помогают получить информацию по учреждениям, отправлять письма и фотографии для заключенных.'
  };

  meta.robots = ['index','follow'];

  return meta;
}

export default withApollo(mapQuery, setMeta)(IndexPage);
