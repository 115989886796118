import React from 'react';
import { Props } from './interface';
import bind from '../../lib/decorators/bind';
import { IServiceType } from '../../types/app';

import Svg from '../Svg';
import Link from '../Link';
import Typography from '../Typography';
import ServiceIcon from '../Service/ServiceIcon';
import getTypographyProps from '../../lib/getTypographyProps';

import {ReactComponent as MapIcon} from '../../images/map.svg';
import {ReactComponent as PhoneIcon} from '../../images/phone.svg';

import './Institution.scss';
import { getPathnameModel, MainRoutes } from '../Router/config/routes';

const icons = {
  phone: PhoneIcon,
  address: MapIcon
};

class Institution extends React.Component<Props> {
  @bind
  getClassName() {
    let className = this.classNames.main;
    if (this.props.className) className += ` ${this.props.className}`;
    if (this.props.isPreload) className += ` ${this.classNames.isPreload}`;

    return className;
  }

  renderServices() {
    const { services } = this.props;
    if (!services || !services.length) return null;

    const items = services
      .filter((item: any) => (item !== 'phone' && item !== 'video_talks' && (item !== 'queue' || this.props.id === '6dc20d4b-ae31-46ab-a5bf-94861a705cb0')))
      .map((a: IServiceType, i: number) => (a && <ServiceIcon
        key={i}
        type={a}
      />));
    return <div className={this.classNames.services}>{items}</div>;
  }

  renderPreload() {
    return <div className={this.getClassName()} />;
  }

  @bind
  renderContacts() {
    const arr = ['address', 'phone'].filter((a) => Boolean((this.props as any)[a]));

    return (
      <div>
        {arr.map((a) => {
          const Icon = (icons as any)[a];
          const data = (this.props as any)[a];

          return (
            <div
              key={a}
              className={this.classNames.infoBlock}
            >
              <Icon />
              <Typography
                {...getTypographyProps('text', 'm', 'text')}
                children={data}
              />
            </div>
          );
        })}
      </div>
    );
  }

  @bind
  renderAddress() {
    const { address } = this.props;
    const data = address || 'Адрес отсутствует...';

    return (
      <div className={this.classNames.infoBlock}>
        <MapIcon />
        <Typography
          {...getTypographyProps('text', 'm', 'text')}
          children={data}
        />
      </div>
    );
  }

  @bind
  renderPhone() {
    const { phone } = this.props;
    const data = phone || 'Телефон отсутствует...';

    return (
      <div className={this.classNames.infoBlock}>
        <PhoneIcon />
        <Typography
          {...getTypographyProps('text', 'm', 'text')}
          children={data}
        />
      </div>
    );
  }

  @bind
  renderInfo() {
    if (this.props.withoutInfo) return null;

    return (
      <React.Fragment>
        <span className={this.classNames.border} />
        <div className={this.classNames.info}>
          {this.renderContacts()}
          {this.renderServices()}
        </div>
      </React.Fragment>
    );
  }

  classNames = {
    main: 'institution',
    head: 'institution__head',
    info: 'institution__info',
    border: 'institution__border',
    isPreload: 'institution_preload',
    services: 'institution__services',
    infoBlock: 'institution__info-block'
  };

  render() {
    const { isPreload } = this.props;
    if (isPreload) return this.renderPreload();

    const { fullName, shortName, region, slug } = this.props;
    const path = getPathnameModel(MainRoutes.institutionByIdPage.path);
    const href = path.replace(':slug', slug).replace(':region', region.slug);

    return (
      <Link
        className={this.getClassName()}
        to={href}
      >
        <div className={this.classNames.head}>
          <Typography
            {...getTypographyProps('heading', 'm', 'dark-text')}
            children={shortName}
          />
          <Typography
            {...getTypographyProps('text', 'm', 'text')}
            children={fullName}
          />
        </div>
        {this.renderInfo()}
      </Link>
    );
  }
}

export default Institution;
