interface IYandexRotorSettings {
  IsLoaded: boolean;
}

declare global {
  interface Window {
    YandexRotorSettings: IYandexRotorSettings;
  }
}

class YandexRobot {
  private static isMetaTagsSet = false;
  private static loadCheckInterval: NodeJS.Timeout | undefined = undefined;

  private static markLoaded() {
    if (window && window.YandexRotorSettings) {
      window.YandexRotorSettings.IsLoaded = true;

      if (process.env.NODE_ENV === 'development') {
        console.info('Sending IsLoaded to yandex robot');
      }
    }
  }

  static metaTagsSet() {
    YandexRobot.isMetaTagsSet = true;
  }

  static onPageLoaded() {
    if (YandexRobot.loadCheckInterval) {
      clearInterval(YandexRobot.loadCheckInterval);
    }

    YandexRobot.loadCheckInterval = setInterval(() => {
      if (YandexRobot.isMetaTagsSet) {
        YandexRobot.markLoaded();

        if (YandexRobot.loadCheckInterval) {
          clearInterval(YandexRobot.loadCheckInterval);
        }
      } else {
        if (process.env.NODE_ENV === 'development') {
          console.info('Waiting for meta tags to load');
        }
      }
    }, 1000);
  }
}

export default YandexRobot;
