import isEmpty from 'lodash/isEmpty';
import size from 'lodash/size';
import { errorWikiQuery } from '../../gql/actions/queries/errorWikiQuery';
import {
  contactCreateMutation,
  IInputCreateContact,
} from '../../gql/actions/mutations/contactCreateMutation';
import { contactsQuery } from '../../gql/actions/queries/contactsQuery';
import {
  setContactsAction,
  setContactAction,
} from '../reducers/contactReducer';
import {
  contactUpdateMutation,
  IInputUpdateContact,
} from '../../gql/actions/mutations/contactUpdateMutation';
import { contactQuery } from '../../gql/actions/queries/contactQuery';
import first from 'lodash/first';
import {
  deleteContactMutation,
  InputDeleteContact,
} from '../../gql/actions/mutations/deleteContactMutation';

let createContactInProcess = false;
let updateContactInProcess = false;
let deleteContactInProcess = false;

function processError(
  response: any,
  error: (errorMessage: string) => void,
  defaultErrorMessage: string
) {
  if (response && !isEmpty(response.errors)) {
    errorWikiQuery(response.errors[0].message, (response) => {
      if (
        response &&
        response.data &&
        response.data.errorWiki &&
        !isEmpty(response.data.errorWiki.error) &&
        size(response.data.errorWiki.error) === 1
      ) {
        error(response.data.errorWiki.error[0].msg);
      } else {
        error(defaultErrorMessage);
      }
    });
  } else {
    error(defaultErrorMessage);
  }
}

export function createContactAction(
  requestData: IInputCreateContact,
  success: () => void,
  error: (errorMessage: string) => void
) {
  return (dispatch: any, getState: any) => {
    if (createContactInProcess) {
      return;
    }
    createContactInProcess = true;
    contactCreateMutation(requestData, (response: any) => {
      if (
        response &&
        response.data &&
        response.data.createContact &&
        response.data.createContact.contact &&
        response.data.createContact.contact.contact_id
      ) {
        success();
      } else {
        processError(
          response,
          error,
          'Произошла ошибка при создании нового контакта'
        );
      }
      createContactInProcess = false;
    });
  };
}

export function deleteContactAction(
  requestData: InputDeleteContact,
  success: () => void,
  error: (errorMessage: string) => void
) {
  return (dispatch: any, getState: any) => {
    if (deleteContactInProcess) {
      return;
    }
    deleteContactInProcess = true;
    deleteContactMutation(requestData, (response: any) => {
      if (
        response &&
        response.data &&
        response.data.deleteContact &&
        response.data.deleteContact.status &&
        response.data.deleteContact.message
      ) {
        success();
      } else {
        processError(
          response,
          error,
          'Произошла ошибка при удалении контакта'
        );
      }
      deleteContactInProcess = false;
    });
  };
}

export function loadContactsAction() {
  return (dispatch: any, getState: any) => {
    contactsQuery((response: any) => {
      if (
        response &&
        response.data &&
        response.data.userContacts &&
        response.data.userContacts.data
      ) {
        dispatch(setContactsAction(response.data.userContacts.data));
      } else {
        //@ts-ignore
        dispatch(setContactsAction([]));
      }
    });
  };
}

export function loadContactAction(contactId: string) {
  return (dispatch: any, getState: any) => {
    contactQuery(contactId, (response: any) => {
      if (
        response &&
        response.data &&
        response.data.userContacts &&
        response.data.userContacts.data
      ) {
        dispatch(setContactAction(first(response.data.userContacts.data)));
      } else {
        //@ts-ignore
        dispatch(setContactAction(null));
      }
    });
  };
}

export function updateContactAction(
  requestData: IInputUpdateContact,
  success: () => void,
  error: (errorMessage: string) => void
) {
  return (dispatch: any, getState: any) => {
    if (updateContactInProcess) {
      return;
    }
    updateContactInProcess = true;
    contactUpdateMutation(requestData, (response: any) => {
      if (
        response &&
        response.data &&
        response.data.updateContact &&
        response.data.updateContact.contact &&
        response.data.updateContact.contact.contact_id
      ) {
        success();
      } else {
        processError(
          response,
          error,
          'Произошла ошибка при изменении контакта'
        );
      }
      updateContactInProcess = false;
    });
  };
}
