import { INews } from '../types/app';

import vk from '../images/social/vk.svg';
import ok from '../images/social/ok.svg';
import fb from '../images/social/fb.svg';
import tw from '../images/social/tw.svg';

import {ReactComponent as Mir} from '../images/payment/mir.svg';
import {ReactComponent as Visa} from '../images/payment/visa.svg';
import {ReactComponent as Yakassa} from '../images/payment/yakassa.svg';
import {ReactComponent as Maestro} from '../images/payment/maestro.svg';
import {ReactComponent as Sberbank} from '../images/payment/sberbank.svg';
import {ReactComponent as Mastercard} from '../images/payment/mastercard.svg';
import { links } from './getServiceType';

export const phone = "8-800-551-52-92";

const html = `
  <figure class="kg-card kg-image-card"><img src="https://ghost.newradio.ru/content/images/2019/11/zGV_4LBgvp8.jpg" class="kg-image"></figure>
  <p> <b>Длинные разрушают ритм, так как читателю трудно найти следующую строку текста взглядом и чтение становится затруднительным </b></p >
  <p>Проект дома — это подробная инструкция для строительства дома. Это не рисунок, который можно скачать в интернете, и не набор чертежей, который вам набросал друг-инженер за обедом. Проект определяет то, насколько комфортно и безопасно будет жить в доме. От него зависит, как дом будет выглядеть, из каких материалов и как его будут строить.</p >
  <h2>Как было дело</h2>
  <p>Большая ошибка считать, что строители знают как строить. Они работают по чертежам, которые подготовили архитектор и инженер-конструктор. В этой статье мы расскажем, из чего должен состоять качественный проект дома и почему без него нельзя строить. Ответим, сколько стоит проект и почему цены меняются от 30 до 200 тысяч рублей.</p>
  <p>Проект дома — это подробная инструкция для строительства дома. Это не рисунок, который можно скачать в интернете, и не набор чертежей, который вам набросал друг-инженер за обедом. Проект определяет то, насколько комфортно и безопасно будет жить в доме. От него зависит, как дом будет выглядеть, из каких материалов и как его будут строить.</p>
  <ul>
  <li>Звонок другу</li>
  <li>Автопополнение баланса</li>
  <li>Займ от банков</li>
  <li>Видеосвязь</li>
  </ul>
  <h2>Как было дело</h2>
  <p>Проект дома — это подробная инструкция для строительства дома. Это не рисунок, который можно скачать в интернете, и не набор чертежей, который вам набросал друг-инженер за обедом. Проект определяет то, насколько комфортно и безопасно будет жить в доме. От него зависит, как дом будет выглядеть, из каких материалов и как его будут строить.</p>
  <h1>Ссылки на материалы</h1>
  <a>Подробная инструкция для строительства дома</a>
  <a>Проект определяет то, насколько комфортно</a>
  <a>Это не рисунок, который можно скачать</a>
`;

const articles: { date: string; image: string; similar: any[]; companies: any[]; timeToRead: number; id: string; title: string; slug: string; content: string; update_date: string; tags: string[] }[] = new Array(6).fill('')
  .map((a, i) => ({
    id: String(i),
    slug: '',
    content: html,
    image: 'https://picsum.photos/350/200',
    companies: [],
    similar: [],
    tags: ['Происшествия1'],
    title: 'Бывший премьер Киргизии госпитализирован из СИЗО',
    date: new Date().toISOString(),
    timeToRead: 0,
    update_date:new Date().toISOString()
  }));

const regions = [
  { name: 'A1', id: 'A1', count: 12 },
  { name: 'A2', id: 'A2', count: 3 },
  { name: 'A3', id: 'A3', count: 9 },
  { name: 'A5', id: 'A5', count: 9 },
  { name: 'A21', id: 'A21', count: 12 },
  { name: 'A22', id: 'A22', count: 3 },
  { name: 'A33', id: 'A33', count: 9 },
  { name: 'A51', id: 'A51', count: 9 },
  { name: 'B1', id: 'B1', count: 12 },
  { name: 'B2', id: 'B2', count: 3 },
  { name: 'B3', id: 'B3', count: 9 },
  { id: 'B11', name: 'B11', count: 12 },
  { id: 'B12', name: 'B12', count: 3 },
  { id: 'B31', name: 'B31', count: 9 },
  { id: 'C1', name: 'C1', count: 12 },
  { id: 'C2', name: 'C2', count: 3 },
  { id: 'C3', name: 'C3', count: 9 },
  { id: 'C11', name: 'C11', count: 12 },
  { id: 'C12', name: 'C12', count: 3 },
  { id: 'C13', name: 'C13', count: 9 }
];

const districts: any[] = [
  {
    id: '1',
    district: 'Центральный округ',
    cities: [
      { name: 'Москва', count: 17 },
      { name: 'Белгородская область', count: 17 },
      { name: 'Брянская область', count: 17 },
      { name: 'Липецкая область', count: 17 },
      { name: 'Московская область', count: 17 },
      { name: 'Москва', count: 17 },
      { name: 'Белгородская область', count: 17 }
    ]
  },
  {
    id: '2',
    district: 'Северо-Западный округ',
    cities: [
      { name: 'Москва', count: 17 },
      { name: 'Москва', count: 17 },
      { name: 'Брянская область', count: 17 },
      { name: 'Липецкая область', count: 17 },
      { name: 'Белгородская область', count: 17 },
      { name: 'Московская область', count: 17 },
      { name: 'Белгородская область', count: 17 },
      { name: 'Брянская область', count: 17 },
      { name: 'Липецкая область', count: 17 },
      { name: 'Московская область', count: 17 }
    ]
  },
  {
    id: '3',
    district: 'Южный округ',
    cities: [
      { name: 'Москва', count: 17 },
      { name: 'Московская область', count: 17 },
      { name: 'Москва', count: 17 },
      { name: 'Белгородская область', count: 17 },
      { name: 'Брянская область', count: 17 },
      { name: 'Брянская область', count: 17 },
      { name: 'Липецкая область', count: 17 }
    ]
  }
];

const questions = [
  {
    title: 'Общие',
    questions: [
      {
        title: 'Как получить свидание в СИЗО',
        path: '/'
      },
      {
        title: 'Как получить длительное свидание с родственником в колонии',
        path: '/'
      },
      {
        title: 'Как зарегистрировать брак с человеком, находящимся в местах лишения свободы',
        path: '/'
      }
    ]
  },
  {
    title: 'Справочник',
    questions: [
      {
        title: 'Не могу найти колонию',
        path: '/'
      },
      {
        title: 'Как узнать информацию о колонии',
        path: '/'
      }
    ]
  },
  {
    title: 'Письмо заключенному',
    questions: [
      {
        title: 'Как оплатить письмо в СИЗО',
        path: '/'
      },
      {
        title: 'Как узнать получено письмо или нет',
        path: '/'
      },
      {
        title: 'Оплачено письмо, а ответ не получен',
        path: '/'
      }
    ]
  },
  {
    title: 'Фото заключенному',
    questions: [
      {
        title: 'Как долго печатаются фотографии',
        path: '/'
      },
      {
        title: 'Может ли заключенный прислать в ответ фотографии',
        path: '/'
      },
      {
        title: 'Не загружаются фотографии, в чем причина',
        path: '/'
      }
    ]
  },
  {
    title: 'Деньги заключенному',
    questions: [
      {
        title: 'Не переводятся деньги',
        path: '/'
      },
      {
        title: 'Сколько можно перевести денег',
        path: '/'
      },
      {
        title: 'На что заключенный сможет потратить деньги',
        path: '/'
      }
    ]
  },
  {
    title: 'Запись на свидание с заключенными ',
    questions: [
      {
        title: 'Как узнать в какие дни можно записаться на свидание',
        path: '/'
      }
    ]
  }
];

const socials = [vk, ok, fb, tw];
const payments = [Visa, Mastercard, Maestro, Mir, Sberbank, Yakassa];
const services = [
  {
    label: 'Письмо заключенному',
    href: 'https://zt.ru/services/letter/payments-letter'
  },
  {
    label: 'Деньги заключенному',
    href: 'https://zt.ru/services/remittance/money-order'
  },
  {
    label: 'Фото заключенному',
    href: 'https://zt.ru/services/photo-fsin/buy-photo?utm_source=sizoru&utm_medium=redirect&utm_campaign=fsinfoto&utm_content=/foto'
  },
  {
    label: 'Запись на свидание с заключенными ',
    href: 'https://v-sizo.ru/'
  }
];

const institutions: any = [
  {
    id: '123123',
    fullName: 'СИЗО-6 Печатники',
    shortName: 'СИЗО-6 Печатники',
    description: 'ФКУ СИЗО-6 УФСИН России по г. Москве',
    address: 'г. Москва, ул. Шоссейная д. 92',
    phone: '8 (812) 746-15-05',
    services: ['mail', 'transfer']
  },
  {
    id: '3123123',
    fullName: 'СИЗО-6 Печатники',
    shortName: 'СИЗО-6 Печатники',
    description: 'ФКУ СИЗО-6 УФСИН России по г. Москве',
    address: 'г. Москва, ул. Шоссейная д. 92',
    phone: '8 (812) 746-15-05',
    services: ['mail', 'transfer']
  },
  {
    id: '222321',
    fullName: 'СИЗО-6 Печатники',
    shortName: 'СИЗО-6 Печатники',
    description: 'ФКУ СИЗО-6 УФСИН России по г. Москве',
    address: 'г. Москва, ул. Шоссейная д. 92',
    phone: '8 (812) 746-15-05',
    services: ['mail', 'transfer']
  },
  {
    id: '141212321',
    fullName: 'СИЗО-6 Печатники',
    shortName: 'СИЗО-6 Печатники',
    description: 'ФКУ СИЗО-6 УФСИН России по г. Москве',
    address: 'г. Москва, ул. Шоссейная д. 92',
    phone: '8 (812) 746-15-05',
    services: ['mail', 'transfer']
  }
];

const institution: any = {
  documents: [
    {
      name: 'Заявление на перевод по месту регистрации ',
      link: '/'
    },
    {
      name: 'Заявление на перевод по месту регистрации ',
      link: '/'
    }
  ],
  schedule: [
    {
      time: '9:00 - 12:00, 15:00 - 21:00',
      days: 'Пн, Вт, Ср, Пт',
      name: 'Малахов С. А.'
    },
    {
      time: '9:00 - 12:00',
      days: 'Пн, Вт, Ср, Пт',
      name: 'Малахов С. А.'
    },
    {
      time: '9:00 - 12:00, 15:00 - 21:00',
      days: 'Пн, Вт, Ср, Пт',
      name: 'Малахов С. А.'
    }
  ]
};

const defaultData = {
  regions,
  articles,
  questions,
  districts,
  institution,
  institutions,

  footer: {
    socials,
    payments,
    services
  }
};

export default defaultData;
