import { Props } from './interface';
import React from 'react';

import Link from '../Link';
import Typography from '../Typography';
import ServiceIcon from './ServiceIcon';
import getTypographyProps from '../../lib/getTypographyProps';

import { ReactComponent as Mail } from '../../images/service/background/mail.svg';
import { ReactComponent as VideoTalks } from '../../images/service/background/videoTalks.svg';
import { ReactComponent as Photo } from '../../images/service/background/photo.svg';

import './Service.scss';
import { IServiceType } from '../../types/app';
import { useLocation } from 'react-router';
import { store } from '../../redux/createStore';
import Button from '../Button';

const classNames = {
  main: 'service',
  isShort: 'service_short',
  isDisabled: 'service_disabled',
  withoutLink: 'service_withoutLink',
  withoutImage: 'service_withoutImage',
  withSmallVerticalPadding: 'service_littlePadding',
  name: 'service__name',
  header: 'service__header',
  massage: 'service__message',
  content: 'service__content',
  background: 'service__background',
  description: 'service__description',
};

const CustomTag = (props: Props) => {
  const location = useLocation();
  const getClassName = () => {
    let className = classNames.main;
    if (props.className) className += ` ${props.className}`;
    if (props.isShort) className += ` ${classNames.isShort}`;
    if (props.withoutImage) className += ` ${classNames.withoutImage}`;
    if (props.type) className += ` ${classNames.main}_${props.type}`;
    if (props.withSmallVerticalPadding)
      className += ` ${classNames.withSmallVerticalPadding}`;

    if (props.isDisabled) className += ` ${classNames.isDisabled}`;
    if (!getLink()) className += ` ${classNames.withoutLink}`;

    return className;
  };

  const getLink = () => {
    if (props.forceLink) return props.forceLink;
    switch (props.type) {
      case 'mail':
        return `https://zt.ru/services/letter/payments-letter?utm_source=fsin&utm_medium=top_blok&utm_campaign=payments_letter&utm_content=${location.pathname}`
      case 'photo':
        return 'https://zt.ru/services/photo-fsin/buy-photo?utm_source=sizoru&utm_medium=redirect&utm_campaign=fsinfoto&utm_content=/foto';
      case 'phone':
        return 'https://zt.ru/service/telefoniya';
      case 'transfer':
        return `https://zt.ru/services/remittance/money-order?utm_source=fsin&utm_medium=knopka&utm_campaign=money-transfer&utm_content=${location.pathname}`;
      case 'video_talks':
        return 'https://zt.ru/service/videoperegovory?utm_source=fsinru&utm_medium=article_right';
      case 'queue':
        return 'https://v-sizo.ru/';
      default:
        return global.location?.href;
    }
  };

  if (!props.type) return null;

  let Tag: any = 'div';

  const props_add: any = {
    className: getClassName(),
  };

  if (!props.isDisabled) {
    const link = store.getState().globalLinkState.transformLink(
      getLink(),
      store.getState().globalLinkState.utmList,
    );

      props_add.isExternal = true;
      props_add.to = link;
      Tag = Link;

  }
  //@ts-ignore
  return <Tag {...props_add} style={props.style}>{props.children}</Tag>;
};

const Service = (props: Props) => {
  const renderHeader = () => {
    const { type } = props;
  };

  const getServiceName = () => {
    const { type } = props;

    switch (type) {
      case 'mail':
        return 'Письмо заключенному';
      case 'transfer':
        return 'Деньги заключенному';
      case 'video_talks':
        return 'Видеосвязь с заключенным';
      case 'photo':
        return 'Фото заключенному';
      case 'phone':
        return 'телефония';
      case 'queue':
        return 'Запись на свидание с заключенными ';
      default:
        return '';
    }
  };

  const renderName = () => {
    const { type } = props;
    const color = !props.isDisabled ? 'dark-text' : 'text';

    const name = (
      <React.Fragment>
        <span> {getServiceName()}</span>
        {type === 'video_talks' && (
          <Typography
            isSpanElement
            className='mobile_show'
            {...getTypographyProps('text', 's', 'text', 'regular')}
            children='zt.ru'
          />
        )}
        {props.description && <Typography
          className='name_description'
          {...getTypographyProps('text', 's', 'gray', 'regular')}
          children={props.description}
        />}
      </React.Fragment>
    );

    const classService = ['service__name'];
    type === 'video_talks' && classService.push('lower_case');

    return (
      <div className={classService.join(' ')}>
        <ServiceIcon type={props.type} />
        <div>
          <Typography
            {...getTypographyProps('text', 'm', color, 'medium')}
            children={name}
          />
        </div>
      </div>
    );
  };

  const renderPromo = () => {
    return (
      <Typography
        {...getTypographyProps('text', 'm', 'red', 'medium')}
        className='promo'
        children='Самая низкая комиссия на денежные переводы'
      />
    );
  };

  const renderDescription = () => {
    const { type } = props;
    let text = 'Сервис в разработке';

    switch (type) {
      case 'photo':
        text =
          'Сервис позволяет отправлять своим близким фотографии на фотобумаге размером 10х15';
        break;

      case 'video_talks':
        text =
          'Сервис позволяет осужденным общаться с родственниками при помощи технологии видеоконференцсвязи';
        break;

      case 'mail':
        text =
          'Сервис предназначен для отправки электронных сообщений лицам, находящимся в следственных изоляторах или отбывающим наказание в местах лишения свободы';
        break;

      case 'transfer':
        text =
          'Сервис позволяет отправить электронный денежный перевод лицам, отбывающим наказание в исправительных учреждениях России';
        break;

      case 'queue':
        text =
          'Электронная запись на свидание в исправительные учреждения России';
        break;

      case 'phone':
        text =
          'Телефонные звонки от лиц, содержащихся на территории учреждений. Услуги оплачиваются при помощи карт, которые легко и быстро пополнить.';
        break;

      default:
        break;
    }

    return (
      <Typography
        className='service__description'
        {...getTypographyProps('text', 'm', 'text')}
        children={text}
      />
    );
  };

  const renderDisabledText = () => {
    if (!props.isDisabled) return null;

    return (
      <Typography
        className='service__message'
        {...getTypographyProps('text', 'm', 'main')}
        children='В разработке'
      />
    );
  };

  const getIcon = (type: IServiceType) => {
    switch (type) {
      case 'mail':
        return Mail;
      case 'photo':
        return Photo;
      case 'video_talks':
        return VideoTalks;
      default:
        return Mail;
    }
  };

  const renderBackground = () => {
    const { isShort, type, isDisabled, withoutImage } = props;

    if (!isShort && !isDisabled && !withoutImage) {
      if (type === 'mail' || type === 'photo') {
        const Icon = getIcon(type);
        return (
          <div className={classNames.background}>
            <Icon />
          </div>
        );
      }
    }

    return null;
  };

  return (
    <CustomTag {...props}>
      {!props.isShort && renderHeader()}
      <div className={classNames.content}>
        <div style={props.withButton ? {display: 'flex', justifyContent: 'space-between', alignItems: 'center'} : undefined}>
          {renderName()}
          {props.type === 'transfer' && !props.isShort && renderPromo()}
          {!props.isShort && renderDescription()}
          {props.withButton && <Button className='button_size' label={props.buttonText ?? 'Текст'} />}
        </div>
        {!props.isShort && renderDisabledText()}
      </div>
      {renderBackground()}
    </CustomTag>
  );
};

export default Service;
