import React, { useState } from 'react';
import { Props } from './interface';

import './TabSlider.scss';
import Typography from '../Typography';
import getTypographyProps from '../../lib/getTypographyProps';

const classNames = {
  block: 'tab-slide',
  tabs: 'tab-slider__tabs',
  tabsTitle: 'tab-slider__tabs-title',
  content: 'tab-slider__content',
  tabsWrapper: 'tab-slider__tabs-wrapper',
  contentWrapper: 'tab-slider__content-wrapper',
  isVertical: 'tab-slider_vertical',
  isHorizontal: 'tab-slider_horizontal',
};

const TabSlider = (props: Props) => {
  const [activeTab, setActiveTab] = useState(0);

  const getClassName = () => {
    let className = 'tab-slider';
    if (props.direction) className += ` tab-slider_${props.direction}`;
    if (props.className) className += ` ${props.className}`;

    return className;
  };

  const onChange = (index: number) => {
    const { onChangeTab } = props;
    if (index === activeTab) return undefined;

    return () => {
      if (onChangeTab) onChangeTab(index);
      setActiveTab(index);
    };
  };

  const renderTabs = () => {
    const { tabsTitle } = props;
    const tabs = props.tabs.map((a: React.ReactElement, index) => {
      const Tag = a.type;
      const className = index === activeTab ? 'active' : '';
      return (
        <Tag
          key={index}
          {...a.props}
          className={className}
          onClick={onChange(index)}
        />
      );
    });

    return (
      <div className={classNames.tabs}>
        {tabsTitle && (
          <Typography
            className={classNames.tabsTitle}
            {...getTypographyProps('text', 'm', 'dark-text', 'medium')}
          >
            {tabsTitle}
          </Typography>
        )}

        <div className={classNames.tabsWrapper}>
          <div>{tabs}</div>
        </div>
      </div>
    );
  };

  const renderServices = () => {
    const { services } = props;
    if (!services) {
      return;
    }
    return <div className='page__services'>{services}</div>;
  };

  const renderContent = () => {
    return (
      <div className={classNames.content}>
        <div className={classNames.contentWrapper}>
          {props.content[activeTab]}
        </div>
      </div>
    );
  };

  const renderLeftContainer = () => {
    return (
      <div>
        {renderTabs()}
        {renderServices()}
      </div>
    );
  };

  return (
    <div className={getClassName()}>
      {props.services ? renderLeftContainer() : renderTabs()}
      {renderContent()}
    </div>
  );
};

export default TabSlider;
