import { IObjectStringType } from '../../types/app';
import API, { END_POINT } from './config';
import { customFetch } from '../../app/customFetch';

export interface RequestOptions {
  body?: BodyInit;
  isBlob?: boolean;
  headers?: HeadersInit;
  query?: IObjectStringType | null;
  errors?: IObjectStringType;
  successStatuses?: number[];
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE';
  done?: (response: any, status: number) => void;
  failed?: (response: any, status: number) => void;
  success?: (response: any, status: number) => void;
  endPoint?: string;
}

const defaultErrors: IObjectStringType = {
  500: 'Ошибка сервера',
  504: 'Превышено время ожидания ответа от сервера'
};

const defaultRequestOptions: IObjectStringType = {
  method: 'GET'
};

const request = async (options: RequestOptions = defaultRequestOptions) => {
  const {
    done,
    body,
    errors,
    failed,
    headers,
    success,
    method = 'GET',
    successStatuses,
    endPoint = END_POINT
  } = options;

  const requestOptions: RequestInit = { method };

  if (body) requestOptions.body = body;
  if (headers) requestOptions.headers = headers;
  else requestOptions.headers = {
    'Content-Type': 'application/json'
  };

  try {
    const {response, status} = await customFetch(endPoint, requestOptions, options.isBlob);
    const statuses: number[] = successStatuses || [200];
    const errorsStatuses: IObjectStringType = { ...errors, ...defaultErrors };

    if (statuses.indexOf(status) !== -1) {
      if (success) success(response, status);
    } else {
      if (failed) return failed(response, status);

      for (const key in errorsStatuses) {
        if (errorsStatuses.hasOwnProperty(key)) {
          if (Number(status) === Number(key)) {
            console.error(API, errorsStatuses[key]);
          }
        }
      }
    }

    if (done) done(response, status);
  } catch (error) {
    console.log(error);
    console.log(`ERROR FETCH: ${endPoint}`);
  }
};

export default request;
