import moment from 'moment';
import { Props } from './interface';
import React, { useEffect, useState } from 'react';

import Button from '../Button';
import Wrapper from '../Wrapper';
import Logotype from '../Logotype';
import Typography from '../Typography';
import getTypographyProps from '../../lib/getTypographyProps';

import config from './config';
import defaultData from '../../lib/defaultData';
import { getPathnameModel, MainRoutes } from '../Router/config/routes';
import './Footer.scss';
import { useLocation } from 'react-router';
import { store } from '../../redux/createStore';

const classNames = {
  block: 'footer',
  social: 'footer__social',
  payment: 'footer__payment',
  copyright: 'footer__copyright',
  navigation: 'footer__navigation',
};

const Footer = (props: Props) => {
  const [renderedClasses, setRenderedClasses] = useState('');
  const location = useLocation()

  useEffect(() => {
    let className = classNames.block;
    if (props.className) className += ` ${props.className}`;
    setRenderedClasses(className);
  }, [props.className]);

  const renderCopyright = () => {
    return (
      <Wrapper className={classNames.copyright} isRow>
        <div>
          <Typography
            {...getTypographyProps('text', 's', 'text')}
            children={`sizo.ru © ${moment().format('YYYY')}`}
          />
          <Typography
            to={`${getPathnameModel(MainRoutes.customPage.path).replace(
              ':slug',
              'terms-and-prices'
            )}`}
            {...getTypographyProps('text', 's', 'white')}
            children='Условия и цены оказываемых услуг'
          />
          <Typography
            to={`${getPathnameModel(MainRoutes.customPage.path).replace(
              ':slug',
              'contacts'
            )}`}
            {...getTypographyProps('text', 's', 'white')}
            children='Контакты'
          />
          <Typography
            to={`${getPathnameModel(MainRoutes.customPage.path).replace(
              ':slug',
              'payment-methods'
            )}`}
            {...getTypographyProps('text', 's', 'white')}
            children='Способы оплаты'
          />
          <Typography
            to={`${getPathnameModel(MainRoutes.customPage.path).replace(
              ':slug',
              'privacypolicy'
            )}`}
            {...getTypographyProps('text', 's', 'white')}
            children='Политика обработки данных'
          />
        </div>
        <Typography
          className='mobile_hide tablet_hide'
          {...getTypographyProps('text', 's', 'text')}
          children='Ошибка в тексте? Выделите её мышкой и нажмите Ctrl + Enter'
        />
      </Wrapper>
    );
  };

  const renderPayment = () => {
    return (
      <div className={`${classNames.payment} mobile_hide`}>
        <Wrapper isRow>
          {defaultData.footer.payments.map((Icon, i) => (
            <Icon className='svg-image' key={i} />
          ))}
        </Wrapper>
      </div>
    );
  };

  const renderNavigation = () => {
    const navigation = config.map((a) => (
      <Typography
        key={a.label}
        {...getTypographyProps('text', 's', 'white')}
        children={a.label}
        isExternalLink={a.isExternal}
        to={a.href}
      />
    ));

    const services = defaultData.footer.services.map((a) => {
      if (a.label === 'ФСИН.УДО') {
        return (
            <Typography
              key={a.label}
              isExternalLink = {false}
              {...getTypographyProps('text', 's', 'white')}
              children={a.label}
              to={a.href}
            />
          )
      } else if (a.label === 'Фото заключенному') {
        return (
            <Typography
                key={a.label}
                isExternalLink
                {...getTypographyProps('text', 's', 'white')}
                children={a.label}
                to={'https://zt.ru/services/photo-fsin/buy-photo?utm_source=sizoru&utm_medium=redirect&utm_campaign=fsinfoto&utm_content=/foto'}
            />
        );
      } else if (a.label === 'ФСИН.ПЕРЕВОД') {
        return (
          <Typography
            key={a.label}
            isExternalLink
            {...getTypographyProps('text', 's', 'white')}
            children={a.label}
            to={store.getState().globalLinkState.transformLink(
              a.href + '?utm_source=fsin&utm_medium=knopka&utm_campaign=money-transfer&utm_content=' + location.pathname,
              store.getState().globalLinkState.utmList,
            )}
          />
        );
      }
      return (
        <Typography
          key={a.label}
          isExternalLink
          {...getTypographyProps('text', 's', 'white')}
          children={a.label}
          to={store.getState().globalLinkState.transformLink(
            a.href + '?utm_source=fsin&utm_medium=knopka&utm_campaign=fsinfoto_noviy_servis&utm_content=' + location.pathname,
            store.getState().globalLinkState.utmList,
          )}
        />
      );
    });

    return (
      <Wrapper className={classNames.navigation} isRow>

        <div className='mobile_hide'>
          <div>
            <div>
              <Typography
                {...getTypographyProps('text', 'm', 'text')}
                children='Информация'
              />
              {navigation}
            </div>
          </div>
          <div>
            <div>
              <Typography
                {...getTypographyProps('text', 'm', 'text')}
                children='Сервисы'
              />
              {services}
            </div>
          </div>
        </div>
      </Wrapper>
    );
  };

  return (
    <footer className={renderedClasses}>
      {renderNavigation()}
      {renderPayment()}
      {renderCopyright()}
    </footer>
  );
};

export default Footer;
