import React, { useEffect, useRef, useState } from 'react';
import './Navigation.scss';
import { connect } from 'react-redux';
import { Props } from './interface';
import LinkMain from '../Link';
import Typography from '../Typography/Typography';
import getTypographyProps from '../../lib/getTypographyProps';
import { IGlobalState } from '../../types/redux';
import { getConfig } from './config';
import { ReactComponent as ArrowIcon } from '../../images/arrow-down.svg';
import { ReactComponent as PhotoServiceIcon } from '../../images/photo-service.svg';
import { ReactComponent as QueueServiceIcon } from '../../images/queue-service.svg';
import { ReactComponent as MailServiceIcon } from '../../images/mail-service.svg';
import { ReactComponent as TransferServiceIcon } from '../../images/transfer-service.svg';
import { ReactComponent as SearchMobile } from '../../images/search1.svg';
import { selectAccountUserInfo } from '../../redux/reducers/accountReducer';
import Popup from '../Popup';
import Wrapper from '../Wrapper';
import { getPathnameModel, MainRoutes } from '../Router/config/routes';
import Burger from '../Burger';
import { store } from '../../redux/createStore';
import { logoutAction } from '../../redux/models/AccountModel';

const Navigation = ({
  className,
  currentPath,
  isMobileMenu,
  userInfo,
  history,
}: Props) => {
  const [servicesPopupShown, setServicesPopupShown] = useState(false);
  const [accountPopupShow, setAccountPopupShow] = useState(false);
  const [isOpenMobileNav, setIsOpenMobileNav] = useState(false);
  const logotypeAndArrowRef = useRef(null);
  const userInfoContainerRef = useRef(null);

  const toggleMobileNav = () => {
    setIsOpenMobileNav(!isOpenMobileNav);
    if (isOpenMobileNav) {
      window.document.body.classList.remove('no-scroll');
    } else {
      window.document.body.classList.add('no-scroll');
    }
  };

  const removeNoScrollFromBody = () => {
    window.document.body.classList.remove('no-scroll');
  };

  function onClick() {
    setIsOpenMobileNav(false);
    removeNoScrollFromBody();
  }

  function renderLinks() {
    const color = isDarkThemeNav() ? 'white' : 'dark-text';
    const props = (a: any) => ({
      ...getTypographyProps('text', isMobileMenu ? 'm' : 's', color, 'regular'),
      className: 'navigation__link main-link',
      to: a.href,
    });

    // @ts-ignore
    return getConfig(currentPath).map((a) => (
          <Typography
            {...props(a)}
            children={a.label}
            key={a.label}
            isActiveLink
            onClick={onClick}
          />
        ));
  }

  const toggleClosePopup = () => {
    setServicesPopupShown(!servicesPopupShown);
  };

  const toggleAccountPopup = () => {
    setAccountPopupShow(!accountPopupShow);
  };

  const isDarkThemeNav = () => {
    if (currentPath === '/') {
      return true;
    }
    return false;
  };

  const getArrowClassName = () => {
    if (isDarkThemeNav()) {
      return 'navigation__arrow_white';
    }
  };

  const handleClickOutsideServicesPopup = (event: any) => {
    if (
      logotypeAndArrowRef &&
      logotypeAndArrowRef.current &&
      //@ts-ignore
      !logotypeAndArrowRef.current.contains(event.target)
    ) {
      setServicesPopupShown(false);
    }
  };

  const closeAccountPopup = (event: any) => {
    if (
      userInfoContainerRef &&
      userInfoContainerRef.current &&
      //@ts-ignore
      !userInfoContainerRef.current.contains(event.target)
    ) {
      setAccountPopupShow(false);
    }
  };

  const handleAccountButtonClick = () => {
    history.push(getPathnameModel(MainRoutes.registrationPage.path));
  };

  const handleAccountClick = () => {
    toggleClosePopup();
    if (userInfo) {
      history.push(getPathnameModel(MainRoutes.accountPage.path));
    } else {
      handleAccountButtonClick();
    }
  };

  const handleLogoutClick = () => {
    toggleClosePopup();
    store.dispatch(logoutAction());
    history.push(getPathnameModel(MainRoutes.loginPage.path));
  };

  useEffect(() => {
    window.addEventListener('click', (event: any) => {
      handleClickOutsideServicesPopup(event);
      closeAccountPopup(event);
    });
  });



  return (
    <nav
      className={`navigation ${
        isDarkThemeNav() ? 'index_page' : ''
      }`}
    >
      <Wrapper
        isRow
        isCenter
        className={isOpenMobileNav ? 'navigation__border-shadow' : ''}
      >
        <div className='navigation__main'>
          <div className='flex-row'>
            <div
              className='navigation__logo-and-services'
              onClick={() => {
                toggleClosePopup();
                setIsOpenMobileNav(false);
              }}
              ref={logotypeAndArrowRef}
            >
              <Typography 
                size='s' 
                type='text' 
                color='white'
                mark='medium'
                as='div'
                className={isDarkThemeNav() ? '' : 'dark-text'}
                style={{ marginRight: '5px', cursor: 'pointer' }}
              >
                МЕНЮ
              </Typography>
              <ArrowIcon
                className={getArrowClassName() + ' svg-image navigation__arrow'}
              />
            </div>
            <Popup isShow={servicesPopupShown} fromTop={true}>
              <LinkMain
                to={
                  store.getState().globalLinkState.transformLink(
                    `https://zt.ru/services/letter/payments-letter?utm_source=fsin&utm_medium=knopka&utm_campaign=fsinfoto_noviy_servis&utm_content=${currentPath}`,
                    store.getState().globalLinkState.utmList,
                  )}
                isExternal
                className='link'
                onClick={() => toggleClosePopup()}
              >
                <MailServiceIcon className='svg-image' />
                <Typography
                  {...getTypographyProps('text', 'xs', 'dark-text', 'medium')}
                  children={'Письмо заключенному'}
                />
              </LinkMain>
              <LinkMain
                to={
                  store.getState().globalLinkState.transformLink(
                    `https://zt.ru/services/remittance/money-order?utm_source=fsin&utm_medium=knopka&utm_campaign=money-transfer&utm_content=${currentPath}`,
                    store.getState().globalLinkState.utmList,
                  )}
                isExternal
                data-testid='test_perevod'
                className='link'
                onClick={() => toggleClosePopup()}
              >
                <TransferServiceIcon className='svg-image' />
                <Typography
                  {...getTypographyProps('text', 'xs', 'dark-text', 'medium')}
                  children={'Деньги заключенному'}
                />
              </LinkMain>
              <LinkMain
                to={'https://zt.ru/services/photo-fsin/buy-photo?utm_source=sizoru&utm_medium=redirect&utm_campaign=fsinfoto&utm_content=/foto'}
                isExternal
                data-testid='test_foto'
                className='link'
                onClick={() => toggleClosePopup()}
              >
                <PhotoServiceIcon className='svg-image' />
                <Typography
                  {...getTypographyProps('text', 'xs', 'dark-text', 'medium')}
                  children={'Фото заключенному'}
                />
              </LinkMain>
              <LinkMain
                to={store.getState().globalLinkState.transformLink(
                    'https://v-sizo.ru/',
                    store.getState().globalLinkState.utmList,
                )}
                isExternal
                className='link'
                onClick={() => toggleClosePopup()}
              >
                <QueueServiceIcon color="#B336B3" className='svg-image' />
                <Typography
                  {...getTypographyProps('text', 'xs', 'dark-text', 'medium')}
                  children={'Запись на свидание '}
                />
              </LinkMain>
            </Popup>
            <nav
              className={`navigation_links mobile_hide tablet_S_hide ${
                isDarkThemeNav() ? 'navigation_dark' : ''
              } ${isOpenMobileNav ? 'show' : ''}`}
            >
              {renderLinks()}
            </nav>
          </div>

          <div className='flex-row'>
            {/* <Typography
              isExternalLink
              className='navigation__telefon'
              {...getTypographyProps(
                'text',
                'l',
                isDarkThemeNav() ? 'white' : 'dark-text'
              )}
              to={'tel:' + phone}
              children={phone}
            /> */}

            {/* {userInfo && (
              <div
                className='tablet_S_hide navigation__user-info-and-popup-container'
                onClick={toggleAccountPopup}
              >
                <div
                  className='navigation__user-info-container'
                  ref={userInfoContainerRef}
                >
                  <Typography
                    className='tablet_S_hide mobile_hide'
                    {...getTypographyProps(
                      'text',
                      'm',
                      isDarkThemeNav() ? 'white' : 'dark-text'
                    )}
                    children={userInfoShortName(userInfo)}
                  />
                  {userInfo.avatar && (
                    <img
                      src={userInfo.avatar}
                      className='navigation__avatar tablet_S_hide mobile_hide'
                    />
                  )}
                  {!userInfo.avatar && (
                    <NoAvatarIcon className='navigation__avatar tablet_S_hide mobile_hide' />
                  )}
                </div>
                <Popup fromTop={true} isShow={accountPopupShow}>
                  <LinkMain
                    to={getPathnameModel(MainRoutes.registrationPage.path)}
                    onForceClick={() => handleAccountClick()}
                  >
                    <PersonIcon className='svg-image' />
                    <Typography
                      {...getTypographyProps('text', 'm', 'main')}
                      children={'Личный кабинет'}
                    />
                  </LinkMain>
                  <LinkMain
                    to={getPathnameModel(MainRoutes.loginPage.path)}
                    onForceClick={() => handleLogoutClick()}
                  >
                    <ExitIcon className='svg-image' />
                    <Typography
                      {...getTypographyProps('text', 'm', 'main')}
                      children={'Выход'}
                    />
                  </LinkMain>
                </Popup>
              </div>
            )} */}
            {/* {!userInfo && (
              <Button
                label='Личный кабинет'
                className={`flex mobile_hide tablet_S_hide ${
                  isDarkThemeNav()
                    ? 'navigation__registration-button-white'
                    : ''
                }`}
                isOutline
                isBlue
                onClick={handleAccountButtonClick}
              />
            )} */}
            {/* <LinkMain
              to={getPathnameModel(MainRoutes.registrationPage.path)}
              className={`tablet_S_show ${
                isDarkThemeNav()
                  ? 'navigation__person-ic-dark-theme'
                  : 'navigation__person-ic'
              }`}
              onForceClick={() => handleAccountClick()}
            >
              <p className='navigation__transrarent_text'>вход</p>
              <PersonIcon className='svg-mage mobile-menu__person-ic' />
            </LinkMain> */}

            <LinkMain
              className={`mobile_show tablet_S_show ${
                isDarkThemeNav()
                  ? 'navigation__search-dark-theme'
                  : 'navigation__search'
              }`}
              to={getPathnameModel(MainRoutes.searchPage.path)}
            >
              <p className='navigation__transrarent_text'>Поиск</p>
              <SearchMobile className='svg-image' />
            </LinkMain>
            <Burger
              className='mobile_show tablet_S_show navigation__burger'
              isDarkTheme={isDarkThemeNav()}
              onClick={() => toggleMobileNav()}
              isShowMobileMenu={isOpenMobileNav}
            />
          </div>
        </div>
      </Wrapper>
    </nav>
  );
};

const mapStateToProps = (state: IGlobalState) => ({
  userInfo: selectAccountUserInfo(state),
});

export default connect(mapStateToProps)(Navigation);
