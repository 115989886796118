import { initialFotoGlobalState } from '../initialState';
import { IReduxAction, IFotoOrderPage } from '../../types/redux';
import { CLEAR_STATE_FOTO_INDEX_PAGE, SET_STATE_FOTO_INDEX_PAGE } from '../actionsList';

export default function(
    state: IFotoOrderPage = initialFotoGlobalState.fotoPageState,
    action: IReduxAction
  ): IFotoOrderPage {
    switch (action.type) {
      case SET_STATE_FOTO_INDEX_PAGE: 
        return {
          ...state,
          ...action.response
        };
      
      case CLEAR_STATE_FOTO_INDEX_PAGE: 
        return initialFotoGlobalState.fotoPageState;
      
      default:
        return state;
    }
  }