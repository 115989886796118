import classNames from 'classnames';
import './NewServiceCard.scss';

type NewServiceCardProps = {
  color: 'blue' | 'green' | 'orange' | 'purple';
  Icon: React.FC;
  title: string;
  description: string;
  button: string;
  link: string;
  activeButton?: boolean;
  withBackground?: {
    desktop: string;
    mobile: string;
  };
};

const NewServiceCard: React.FC<NewServiceCardProps> = ({
  activeButton,
  button,
  color,
  description,
  Icon,
  title,
  link,
  withBackground,
}) => {
  return (
    <a href={link} className={classNames('new_service_card')}>
      <div
        className={classNames(
          'service_body',
          color,
          activeButton ? 'activeButton' : undefined,
          withBackground ? 'with_bg' : undefined
        )}
      >
        <div className='service_title'>
          <div className='service_icon'>
            <Icon />
          </div>
          <div className='service_text'>{title}</div>
        </div>
        <div className='service_description'>{description}</div>
        {withBackground && (
          <div className='service_bg_image_mobile'>
            <img src={withBackground.mobile} />
          </div>
        )}
        <div className='service_button_wrapper'>
          <div className='service_button'>{button}</div>
        </div>
      </div>
      {withBackground && (
        <div className='service_bg_image'>
          <img src={withBackground.desktop} />
        </div>
      )}
    </a>
  );
};

export default NewServiceCard;
