// import bind from '../../lib/decorators/bind';
import React, { Component } from 'react';
import { Props, State } from './interface';
import Picker from 'emoji-picker-react';

class EmojiPicker extends Component<Props, State> {

  render(){
    return (
      <Picker  onEmojiClick={this.props.addEmoji} disableSearchBar  disableSkinTonePicker/>
    )
  }
}

export default EmojiPicker;
