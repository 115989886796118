import { useEffect, useState } from 'react';

const useLoadingYMAB = (): boolean => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let interval: NodeJS.Timer;
    if (isLoading) {
      interval = setInterval(() => {
        if (window.hasOwnProperty('ymab')) {
          setIsLoading(false);
        }
      }, 400);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [isLoading]);

  return isLoading;
};

const metrikID = 49249702;

type FlagsReturn<T extends Record<string, string>> = {
  flags: T | undefined;
  isLoadingFlags: boolean;
};

function useYMABFlags<T extends Record<string, string>>(): FlagsReturn<T> {
  const isLoadingYmab = useLoadingYMAB();
  const [flags, setFlags] = useState<T>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!isLoadingYmab) {
      if (window.hasOwnProperty('ymab')) {
        setIsLoading(true);
        const result: Record<string, string> = {};
        ymab('metrika.' + String(metrikID), 'getFlags', function (flags: Record<string, string>) {
          for (const [key, value] of Object.entries(flags)) {
            if (key && value !== undefined) {
              result[key] = value[0];
            }
          }
          setIsLoading(false);
        });

        setFlags(result as T);
      }
    }
  }, [isLoadingYmab]);

  return { flags: flags, isLoadingFlags: isLoading || isLoadingYmab };
}

export default useYMABFlags;
