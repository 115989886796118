import gql from 'graphql-tag';
import { IGQLRequest } from '../../types/app';
import { mapFotoTariffs, mapFotoOrderDataById, mapFotoConfirmUrl } from '../mappers';
import { FOTO_HTTP_END_POINT } from '../../lib/request/config';

interface IFotoController {
  getTariffs: (type: string) => IGQLRequest;
  getOrderData: (uploadId: string) => IGQLRequest;
  getConfirmationUrl: (uploadId: string) => IGQLRequest;
}
class Foto {
  static getTariffs(type: string) {
    return {
      mapper: mapFotoTariffs,
      query: gql`
        {
          tariffs(type: "${type}") {
            id
            min
            max
            cost
            oldCost
            name
            type
          }
        }
      `,
      endPoint: FOTO_HTTP_END_POINT
    };
  }

  static getOrderData(uploadId: string) {
    console.log(FOTO_HTTP_END_POINT);
    return {
      mapper: mapFotoOrderDataById,
      query: gql`
        {
          orderDataByUploadId(uploadId: "${uploadId}"){
            externalId
            uploadId
            sender
            recipient
            statusCollection {
              data {
                id
                date
              }
            }
            colony {
              fullName
            }
            cart {
              amount
              items {
                name
                amount
                qty
              }
            }
          }
        }
      `,
      endPoint: FOTO_HTTP_END_POINT
    };
  }

  static getConfirmationUrl(uploadId: string){
    return {
      mapper: mapFotoConfirmUrl,
      query: gql`
        {
          confirmationUrl(uploadId: "${uploadId}")
        }
      `
    }
  }
}

export default Foto as IFotoController;
