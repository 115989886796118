const getSelectionText = () => {
  let text = '';
  const { selection } = document as any;
  const { getSelection } = window as any;

  if (getSelection) {
    text = getSelection().toString();
  } else if (selection && selection.type !== 'Control') {
    ({ text } = selection.createRange());
  }

  return text;
};

export default getSelectionText;
