import step1 from '../../../images/guide/mail/1.jpg';
import step2 from '../../../images/guide/mail/2.jpg';
import step3 from '../../../images/guide/mail/3.jpg';
import step4 from '../../../images/guide/mail/4.jpg';
import step51 from '../../../images/guide/mail/5-1.jpg';
import step52 from '../../../images/guide/mail/5-2.jpg';
import step6 from '../../../images/guide/mail/6.jpg';
import step71 from '../../../images/guide/mail/7-1.jpg';
import step72 from '../../../images/guide/mail/7-2.jpg';

const guide = [
  {
    title: 'Укажите куда будет доставляться письмо',
    content: [
      {
        image: step1,
        html: [
          '<p><b>Выберите регион</b> из списка куда будет осуществляться доставка письма или введите его название</p>',
          '<p>Затем <b>выберите учреждение</b></p>',
          '<p class="warning"><b>Внимание!</b> Вы должны точно знать расположение осужденного или подследственного</p>'
        ]
      }
    ]
  },
  {
    title: 'Укажите данные получателя',
    content: [
      {
        image: step2,
        html: [
          '<p>Корректно введите <b>Имя, Фамилию</b> и <b>Отчество</b> на русском языке, если отчество отсутствует оставьте поле пустым</p>',
          '<p>Затем введите <b>год рождения</b></p>'
        ]
      }
    ]
  },
  {
    title: 'Укажите свои данные',

    content: [
      {
        image: step3,
        html: [
          '<p>Корректно введите <b>Фамилию, Имя</b> и <b>Отчество</b> на русском языке через пробел</p>',
          '<p>Затем введите номер <b>мобильного телефона телефона</b></p>',
          '<p>Затем введите <b>электронную почту</b></p>',
          '<p>Чтобы в дальнейшем не вводить свои данные повторно нажмите <b>“Запомнить мои данные”</b></p>'
        ]
      }
    ]
  },
  {
    title: 'Напишите письмо',
    content: [
      {
        image: step4,
        html: ['<p>В поле <b>“Текст письма”</b> напечатайте необходимый текст. Одна страница это 3000 символов. Под полем вы можете отслеживать количество символов и страниц.</p>']
      }
    ]
  },
  {
    title: 'Добавьте фотографии по желанию',
    content: [
      {
        image: step51,
        html: [
          '<p>По желанию вы можете добавить фотографии нажав <b>“Загрузить фотографии”</b> и выберете необходимые фотографии на своем устройстве. Вы можете выделеть несколько фотографий, но не более 3-х шт.</p>',
          '<p>Вы так же можете перенести в поле необходимые вам фотографии.</p>'
        ]
      },
      {
        image: step52,
        html: [
          '<p>Нажмите <b>“Цветные фотографии”</b> и фото будут распечатаны в цвете.</p>',
          '<p class="warning"><b>Внимание!</b> Если после выбора учреждения данная опция не появилась, значит в учреждении отсутствует возможность цветной печати.</p>'
        ]
      }
    ]
  },
  {
    title: 'Закажите бланк ответа по желанию',
    content: [
      {
        image: step6,
        html: [
          '<p>Бланк ответа необходим для того, чтобы заключенный или осужденный смог вам написать письмо в ответ.</p>',
          '<p>Укажите в <b>поле</b> необходимое количество бланков.</p>'
        ]
      }
    ]
  },
  {
    title: 'Оплата',
    content: [
      {
        image: step71,
        html: ['<p>В разделе “Итого” вы можете посмотреть итоговую стоимость и перейти на страницу оплаты нажав кнопку <b>“Оплатить”</b> после чего вы перейдете на страницу оплаты.</p>']
      },
      {
        image: step72,
        html: [
          '<p>Если вы готовы оплатить заказ нажмите <b>“Оплатить”</b>, после чего откроектся сервис Яндекс Касса. Вы сможете выбрать любой удобный для вас способ оплаты</p>',
          '<p>Вы так же можете оплатить заказ позже нажав <b>“Оплатить потом”</b>, у вас будет 3 дня для того чтобы оплатить заказ.</p>',
          '<p>На “Страницу оплаты” вы всегда сможете вернуться по ссылке в письме, которое вам придет на электронную почту</p>'
        ]
      }
    ]
  }
];

export default guide;
