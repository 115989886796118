import React, { Component } from 'react';
import withApollo from '../../../lib/HOC/withApollo';
import { selectAccountUserInfo } from '../../../redux/reducers/accountReducer';
import {
  selectContact,
  selectContacts,
} from '../../../redux/reducers/contactReducer';
import { IGlobalState } from '../../../types/redux';
import { connect } from 'react-redux';
import { PropsQuery } from '../../udo/IndexPage/interface';
import Regions from '../../../gql/actions/Regions';
import Page from '../../../components/Page';
import Wrapper from '../../../components/Wrapper';
import Service from '../../../components/Service';
import { links } from '../../../lib/getServiceType';
import MenuRegistration from './components/menu';
import ContainerWrapper from './components/containerWrapper';
import { Props, ISelectedInstitution } from './interface';
import FormRegion from './components/formRegion';
import FormInstitution from './components/formInstitution';
import routes, {
  getPathnameModel,
} from '../../../components/Router/config/routes';
import Schedule from '../../../gql/actions/Schedule';
import cn from 'classnames';
import 'moment/locale/ru';

import './IndexPage.scss';
import bind from '../../../lib/decorators/bind';
import moment from 'moment';
import FormFinal from './components/formAll';
import FormConfirm from './components/formConfirm';
import { QUEUE_END_POINT } from '../../../lib/request/config';
import FormResult from './components/formResult';
import {
  queueTab1,
  queueTab2,
  queueTab3,
  queueTab4,
  queueTab5,
  queueTab6,
  queueTabSuccess,
} from '../../../lib/dataLayerEvents';
import Notification from '../../../components/Notification';
import gql from 'graphql-tag';
import './components/formCalendar/style.scss';
import Typography from '../../../components/Typography';
import { ISelectItem } from '../../../types/app';
import MetaTags from '../../../components/MetaTags';
import Select from "../../../components/Select/Select";

const classNames = {
  preload: 'page_preload',
  content: 'page__content',
  sidebar: 'page__sidebar',
  form: 'page__sidebar-form',
  services: 'page__sidebar-services',
  formCalc: 'page__sidebar-form-calculate',
};

export interface State {
  tabMax: string;
  tabState: string;
  selectedRegion: any;
  selectedInstitution: ISelectedInstitution;
  selectedToVisit?: ISelectItem;
  selectedDay: ScheduleDay;
  selectedTime: ScheduleTimeSlot;
  firstName: string;
  lastName: string;
  birthDay: string;
  middleName: string;
  senderFirstName: string;
  senderLastName: string;
  senderMiddleName: string;
  passportSeries: string;
  passportNumber: string;
  senderPhone: string;
  senderEmail: string;
  acceptData: boolean;
  acceptOffer: boolean;
  scheduleId: string;
  recordingStatus: boolean;
  scheduleUserNext: any[];
  scheduleUser: any[];
  scheduleNext: any[];
  schedule: any[];
  loadedSchedule: number;
  timeSlots: Record<'current' | 'next', TimeSlot[]>;
  timeBusyByUser: Record<'current' | 'next', TimeExists[]>;
  isLoadingSlots: boolean;
  relationsList: ISelectItem[];
  relationship: ISelectItem | undefined;
  visitorPassportIssuedBy: string;
  visitorPassportIssuedDate: string;
  visitorRegistration: string;
  visitorYearOfBirth: string;
}

type TimeSlot = {
  rooms: number;
  start_time: string;
  end_time: string;
  company_id: string;
  service_type: string;
  available_rooms: number;
};

type TimeExists = {
  user_id: string;
  start_time: string;
  end_time: string;
};

type TimeSlotsResponse = {
  time_slots: TimeSlot[];
  schedule: TimeExists[];
  relation_degree: { name: string; id: string }[];
};

type ScheduleTimeSlot = {
  startTime: string;
  endTime: string;
};

type ScheduleDay = {
  day: number;
  dayWeekName: string;
  preparedDate: string;
  busyByUser: ScheduleTimeSlot | undefined;
  timeSlots: Record<'free' | 'busy', ScheduleTimeSlot[]>;
};

type ScheduleMutationArguments = {
  // random uuid
  id: string;
  user_id: string;
  company_id: string;
  service_type: string;
  person_name: string;
  person_surname: string;
  person_patronymic: string;
  person_year_of_birth: number;
  start_time: string;
  end_time: string;
  created: string;
  visitor_name: string;
  visitor_surname: string;
  visitor_patronymic: string;
  visitor_passport_number: string;
  visitor_passport_series: string;
  visitor_email: string;
  visitor_phone: string;
  relation_degree_id: string;
  visitor_passport_issued_by: string;
  visitor_passport_issued_date: string;
  visitor_registration: string;
  visitor_year_of_birth: number;
};

function parseLocalStorage(item: string) {
  try {
    // @ts-ignore
    let object: string = localStorage.getItem(item);
    return JSON.parse(object);
  } catch {
    return false;
  }
}

const VISIT_MALE_ADULTS = 1;
const VISIT_FEMALE_MALE_NOT_ADULTS = 2;
const VISIT_FEMALE_MALE_HOUSEKEEPING = 3;

const visitItems: ISelectItem[] = [
  {label: 'Мужчины (совершеннолетние)', value: VISIT_MALE_ADULTS},
  {label: 'Женщины и несовершеннолетние', value: VISIT_FEMALE_MALE_NOT_ADULTS},
  {label: 'Женщины и мужчины по хоз. обслуживанию', value: VISIT_FEMALE_MALE_HOUSEKEEPING},
];

class IndexPage extends Component<Props> {
  state: State = {
    tabMax: '1',
    tabState: '1',
    selectedRegion: parseLocalStorage('selectedRegion') || '',
    selectedInstitution: parseLocalStorage('selectedInstitution') || '',
    selectedToVisit: undefined,
    selectedDay: {
      day: 0,
      dayWeekName: '',
      timeSlots: { busy: [], free: [] },
      busyByUser: undefined,
      preparedDate: '',
    },
    selectedTime: { endTime: '', startTime: '' },
    firstName: '',
    lastName: '',
    birthDay: '',
    middleName: '',
    senderFirstName: this.props.userInfo
      ? this.props.userInfo.firstname || ''
      : '',
    senderLastName: this.props.userInfo
      ? this.props.userInfo.lastname || ''
      : '',
    senderMiddleName: this.props.userInfo
      ? this.props.userInfo.middlename || ''
      : '',
    passportSeries: '',
    passportNumber: '',
    senderPhone: this.props.userInfo
      ? this.props.userInfo.phone || ''
      : '',
    senderEmail: this.props.userInfo
      ? this.props.userInfo.email || ''
      : '',
    acceptData: false,
    acceptOffer: false,
    scheduleId: '',
    recordingStatus: false,
    scheduleUserNext: [],
    scheduleUser: [],
    scheduleNext: [],
    schedule: [],
    loadedSchedule: 0,
    timeSlots: { current: [], next: [] },
    timeBusyByUser: { current: [], next: [] },
    isLoadingSlots: true,
    relationsList: [],
    relationship: undefined,
    visitorPassportIssuedBy: '',
    visitorPassportIssuedDate: '',
    visitorRegistration: '',
    visitorYearOfBirth: '',
  };

  componentDidMount() {
    if (this.props.userInfo) {
      if (this.state.selectedInstitution) {
        this.onHandleNextStep('3');
        localStorage.removeItem('selectedInstitution');
        localStorage.removeItem('selectedRegion');
      }
    }
  }

  scrollToBegin() {
    // @ts-ignore
    document.querySelector('.queue').scrollIntoView();
  }

  @bind
  onClickMenu(id: string) {
    if (this.state.tabMax >= id) {
      this.setState({ tabState: id });
    }
    if (id === '1') {
      const requests = () => {
        return {
          regions: Regions.getRegions(),
        };
      };
      this.props.updateApollo(requests);
    }
  }

  @bind
  onHandleNextStep(id: string) {
    this.scrollToBegin();
    this.setState({ tabState: id });
    if (id === '3') {
      this.setState({
        timeSlots: { current: [], next: [] },
        timeBusyByUser: { current: [], next: [] },
      } as Partial<State>);
      this.getTimeSlots('current');
      this.getTimeSlots('next');
    }
    if (id === '1') {
      const requests = () => {
        return {
          regions: Regions.getRegions(),
        };
      };
      this.props.updateApollo(requests);
    }
    if (id === '6') queueTab5();
    if (this.state.tabMax < id) {
      this.setState({ tabMax: id });
    }
  }

  @bind
  getTimeSlots(month: 'current' | 'next') {
    this.setState({ isLoadingSlots: true } as Partial<State>);
    const { selectedInstitution } = this.state;
    if (selectedInstitution) {
      const transformDay = (date: number): string => {
        if (date < 10) {
          return '0' + date;
        }
        return String(date);
      };
      const transformDate = (date: Date): string => {
        return [
          date.getFullYear(),
          transformDay(date.getMonth() + 1),
          transformDay(date.getDate()),
        ].join('-');
      };
      const date = new Date();
      const currentDay = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate() + 1
      );
      const firstDay = new Date(date.getFullYear(), date.getMonth() + 1, 1);
      const lastDay = new Date(
        date.getFullYear(),
        date.getMonth() + (month === 'current' ? 1 : 2),
        1
      );

      const request = Schedule.getTimeSlots(
        selectedInstitution.serviceType,
        selectedInstitution.id,
        transformDate(month === 'current' ? currentDay : firstDay),
        transformDate(lastDay),
        this.props.userInfo.user_id
      );
      this.customFetchTimeSlots(request, month);
    }
  }

  customFetchTimeSlots(query: any, month: 'current' | 'next') {
    fetch(query.endPoint, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.props.auth.accessToken,
      },
      method: 'POST',
      body: JSON.stringify({
        opertionName: 'TimeSlots',
        variables: {},
        query: query.query.loc.source.body,
      }),
    })
      .then((a) => a.json())
      .then((r) => {
        const data = r.data as TimeSlotsResponse;
        this.setState(
          (st: State) =>
            ({
              timeBusyByUser: {
                ...st.timeBusyByUser,
                [month]: data.schedule?.concat(st.timeBusyByUser[month]),
              },
              timeSlots: {
                ...st.timeSlots,
                [month]: data.time_slots?.concat(st.timeSlots[month]),
              },
              relationsList: data.relation_degree?.map((item) => ({
                label: item.name[0].toUpperCase() + item.name.slice(1),
                value: item.id,
              })),
              isLoadingSlots: false,
            } as Partial<State>)
        );
      });
  }

  @bind
  setSelectedTime(item: ScheduleTimeSlot) {
    queueTab4();
    this.setState({ selectedTime: item });
  }

  @bind
  setSelectedRegion(item: any) {
    queueTab1();
    this.setState({ selectedRegion: item });
  }

  @bind
  setSelectedInstitution(item: ISelectedInstitution) {
    queueTab2();
    this.setState({ selectedInstitution: item });
  }

  @bind
  setSelectedDay(item: ScheduleDay) {
    queueTab3();
    this.setState({ selectedDay: item });
  }

  @bind
  handleChange(name: string) {
    return (name: string, val: string) => {
      if (
        name === 'birthDay' ||
        name === 'passportSeries' ||
        name === 'passportNumber'
      ) {
        val = (val as string).replace(/[^\d]/g, '');
      }

      if (name === 'passportSeries' || name === 'birthDay') {
        val = (val as string).slice(0, 4);
      }

      if (name === 'passportNumber') {
        val = (val as string).slice(0, 6);
      }

      this.setState({
        [name]: val,
      });
    };
  }

  @bind
  refInput(name: string) {
    return (ref: any) => {
      this.inputRefs[name] = ref;
    };
  }

  inputRefs: any = {};

  @bind
  phoneChange(name: string, senderPhone: string, data: any) {
    const minSenderPhoneLength = data.format.length;

    this.setState({
      senderPhone,
      minSenderPhoneLength,
    });
  }

  SET_SCHEDULE = ({
    user_id,
    company_id,
    created,
    end_time,
    id,
    person_name,
    person_patronymic,
    person_surname,
    person_year_of_birth,
    service_type,
    start_time,
    visitor_name,
    visitor_passport_number,
    visitor_passport_series,
    visitor_patronymic,
    visitor_surname,
    visitor_email,
    visitor_phone,
    relation_degree_id,
    visitor_passport_issued_by,
    visitor_passport_issued_date,
    visitor_registration,
    visitor_year_of_birth
  }: ScheduleMutationArguments): any => {
    return gql`
      mutation scheduleByID{
        scheduleByID(
          delete: true
          insert: {
            id: "${id}"
            company_id: "${company_id}"
            user_id: "${user_id}"
            service_type: "${service_type}"
            person_name: "${person_name}"
            person_surname: "${person_surname}"
            person_patronymic: "${person_patronymic}"
            person_year_of_birth: ${person_year_of_birth}
            start_time: "${start_time}"
            end_time: "${end_time}"
            created: "${created}"
            visitor_name: "${visitor_name}"
            visitor_surname: "${visitor_surname}"
            visitor_patronymic: "${visitor_patronymic}"
            visitor_passport_number: "${visitor_passport_number}"
            visitor_passport_series: "${visitor_passport_series}"
            visitor_email: "${visitor_email}"
			      visitor_phone: "${visitor_phone}"
            relation_degree_id: "${relation_degree_id}"
            visitor_passport_issued_by: "${visitor_passport_issued_by}"
            visitor_passport_issued_date: "${visitor_passport_issued_date}"
            visitor_registration: "${visitor_registration}"
            visitor_year_of_birth: ${visitor_year_of_birth}
          }
        ) {
          id
        }
      }
    `;
  };

  @bind
  sendFormInfo() {
    const { selectedInstitution, selectedTime } = this.state;
    const { userInfo } = this.props;
    const uuidv4 = (): string => {
      return (([1e7] as any) + -1e3 + -4e3 + -8e3 + -1e11).replace(
        /[018]/g,
        (c: number) =>
          (
            c ^
            (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
          ).toString(16)
      );
    };
    const data: ScheduleMutationArguments = {
      id: uuidv4(),
      company_id: selectedInstitution.id,
      user_id: userInfo.user_id,
      service_type: selectedInstitution.serviceType,
      person_name: this.state.firstName,
      person_patronymic: this.state.middleName,
      person_surname: this.state.lastName,
      person_year_of_birth: +this.state.birthDay,
      start_time: moment(selectedTime.startTime).format('yyyy-MM-DD HH:mm:ss'),
      end_time: moment(selectedTime.endTime).format('yyyy-MM-DD HH:mm:ss'),
      created: moment(new Date()).format('yyyy-MM-DD HH:mm:ss'),
      visitor_name: this.state.senderFirstName,
      visitor_surname: this.state.senderLastName,
      visitor_patronymic: this.state.senderMiddleName,
      visitor_passport_number: this.state.passportNumber,
      visitor_passport_series: this.state.passportSeries,
      visitor_email: this.state.senderEmail,
      visitor_phone: this.state.senderPhone,
      relation_degree_id: this.state.relationship?.value as string,
      visitor_passport_issued_by: this.state.visitorPassportIssuedBy,
      visitor_passport_issued_date: this.state.visitorPassportIssuedDate,
      visitor_registration: this.state.visitorRegistration,
      visitor_year_of_birth: +this.state.visitorYearOfBirth,
    };

    const { body } = this.SET_SCHEDULE(data).loc.source;
    queueTab6();
    fetch(QUEUE_END_POINT, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.props.auth.accessToken,
      },
      method: 'POST',
      body: JSON.stringify({
        opertionName: 'scheduleByID',
        variables: {},
        query: body,
      }),
    })
      .then((response) => response.json())
      .then((d: { data?: { scheduleByID?: { id?: string }[] } }) => {
        if (d?.data?.scheduleByID?.length) {
          queueTabSuccess();
          this.onHandleNextStep('7');
        } else {
          Notification.show('Произошла ошибка во время записи');
        }
      })
      .catch((e) => Notification.show('Произошла ошибка при записи'));
  }

  @bind
  finalClick() {
    this.setState({
      tabMax: '1',
      selectedRegion: '',
      selectedInstitution: undefined,
      scheduleUserNext: [],
      scheduleUser: [],
      scheduleNext: [],
      schedule: [],
      loadedSchedule: 0,
      selectedDay: {
        day: 0,
        dayWeekName: '',
        timeSlots: { busy: [], free: [] },
        busyByUser: undefined,
        preparedDate: '',
      },
      selectedTime: { endTime: '', startTime: '' },
      timeSlots: { current: [], next: [] },
      timeBusyByUser: { current: [], next: [] },
    } as Partial<State>);
    this.onHandleNextStep('1');
  }

  redirectToLogin() {
    const { selectedRegion, selectedInstitution } = this.state;
    localStorage.setItem('selectedRegion', JSON.stringify(selectedRegion));
    localStorage.setItem(
      'selectedInstitution',
      JSON.stringify(selectedInstitution)
    );
    this.props.history.push(getPathnameModel(routes.loginPage.path));
  }

  renderMenuRegistration() {
    return (
      <>
        {this.state.tabState !== '7' && (
          <MenuRegistration
            onClick={(id: string) => this.onClickMenu(id)}
            activeItem={this.state.tabState}
          />
        )}
      </>
    );
  }

  renderSidebar() {
    return (
      <div className={classNames.sidebar}>
        <div className='sidebar_row'>
          <Service forceLink={links.MONEY_FSIN} isShort type='transfer' />
          <Service isShort type='photo' />
        </div>
        <div className='sidebar_row'>
          <Service isShort type='mail' />
          <Service isShort type='video_talks' />
        </div>
      </div>
    );
  }

  modifiedDays(monthValue: 'current' | 'next'): ScheduleDay[] {
    const days: ScheduleDay[] = [];
    const monthSlots = this.state.timeSlots[monthValue];
    if (!monthSlots) return days;

    const transformNumber = (num: number): string => {
      return num < 10 ? '0' + num : String(num);
    };

    const transformDate = (date: Date, separator = '-') => {
      return [
        date.getFullYear(),
        transformNumber(date.getMonth() + 1),
        transformNumber(date.getDate()),
      ].join(separator);
    };

    const findBusyTimeSlot = (
      startTime: string
    ): ScheduleTimeSlot | undefined => {
      const date = new Date(startTime);
      for (let i = 0; i < this.state.timeBusyByUser[monthValue].length; i++) {
        const existDate = new Date(
          this.state.timeBusyByUser[monthValue][i].start_time
        );

        if (transformDate(date) === transformDate(existDate)) {
          return {
            startTime: this.state.timeBusyByUser[monthValue][i].start_time,
            endTime: this.state.timeBusyByUser[monthValue][i].end_time,
          };
        }
      }
      return undefined;
    };

    const slotsByDay: Record<string, ScheduleDay['timeSlots']> = {};

    for (let i = 0; i < monthSlots.length; i++) {
      const currentTimeSlot = monthSlots[i];
      const currentDate = new Date(currentTimeSlot.start_time);
      const timeSlots: ScheduleDay['timeSlots'] = {
        free: [],
        busy: [],
      };
      const amountBusySlots =
        currentTimeSlot.rooms - currentTimeSlot.available_rooms;
      for (let j = 0; j < currentTimeSlot.available_rooms; j++) {
        timeSlots.free.push({
          startTime: currentTimeSlot.start_time,
          endTime: currentTimeSlot.end_time,
        });
      }

      for (let j = 0; j < amountBusySlots; j++) {
        timeSlots.busy.push({
          startTime: currentTimeSlot.start_time,
          endTime: currentTimeSlot.end_time,
        });
      }

      if (slotsByDay[transformDate(currentDate)]) {
        slotsByDay[transformDate(currentDate)].free.push(...timeSlots.free);
        slotsByDay[transformDate(currentDate)].busy.push(...timeSlots.busy);
      } else {
        slotsByDay[transformDate(currentDate)] = timeSlots;
      }
    }

    const visitableDays = {
      [VISIT_MALE_ADULTS]: ['понедельник', 'среда'],
      [VISIT_FEMALE_MALE_NOT_ADULTS]: ['вторник'],
      [VISIT_FEMALE_MALE_HOUSEKEEPING]: ['четверг'],
    };

    const existsDays: Record<string, boolean> = {};
    for (let i = 0; i < monthSlots.length; i++) {
      const currentDate = new Date(monthSlots[i].start_time);
      if (!existsDays[transformDate(currentDate)]) {
        existsDays[transformDate(currentDate)] = true;
        const dayWeekName = moment(
          [
            currentDate.getFullYear(),
            transformNumber(currentDate.getMonth() + 1),
            transformNumber(currentDate.getDate()),
          ].join('')
        )
          .locale('ru')
          .format('dddd');

        const visitable = this.state.selectedInstitution.serviceType === 'package'
          // @ts-ignore
          || this.state.selectedToVisit && visitableDays[this.state.selectedToVisit.value].includes(dayWeekName) 
          || this.state.selectedInstitution.serviceType === 'visit' && this.state.selectedRegion.id !== 171;
        days.push({
          day: currentDate.getDate(),
          dayWeekName,
          preparedDate: transformDate(currentDate),
          busyByUser: findBusyTimeSlot(monthSlots[i].start_time),
          timeSlots: visitable ? slotsByDay[transformDate(currentDate)] : {free: [], busy: []},
        });
      }
    }

    return days;
  }

  getHumanReadableMonth(month: 'current' | 'next') {
    let numberOfMonth =
      month === 'current'
        ? new Date().getMonth() + 1
        : new Date().getMonth() + 2;
    let monthParsed: string = String(numberOfMonth);
    if (+monthParsed < 10) {
      monthParsed = '0' + monthParsed;
    }
    return moment(new Date().getFullYear() + monthParsed + '01')
      .locale('ru')
      .format('MMMM');
  }

  getHumanReadablePlacesWord(num: number): string {
    if (num % 10 === 1 && num !== 11) {
      return 'место';
    } else if (num % 10 < 5) {
      return 'места';
    } else {
      return 'мест';
    }
  }

  formatDate(selectedTime: ScheduleTimeSlot) {
    return (
      moment(selectedTime.startTime).locale('ru').format('с HH:mm') +
      ' до ' +
      moment(selectedTime.endTime).locale('ru').format('HH:mm')
    );
  }

  @bind
  setSelectedVisit(item: ISelectItem) {
    this.setState({selectedToVisit: item});
  }

  renderVisitSelect() {
    return (
      <Select
        className='FormCalendar__visit-select'
        items={visitItems}
        value={this.state.selectedToVisit}
        onChange={this.setSelectedVisit}
        placeholder='Пол и возраст осужденного'
      />
    );
  }

  // рендер расписания на текущий или следующий месяц
  renderMonthSchedule(month: 'current' | 'next') {
    const days = this.modifiedDays(month);
    if (!days.length) return;
    return (
      <>
        <h5 className='FormCalendar__month-name'>
          {this.getHumanReadableMonth(month)}
        </h5>
        <ul className='FormCalendar__list'>
          {days.map((dayItem, index) => {
            return (
              <li
                key={index}
                className={cn(
                  'FormCalendar__item',
                  dayItem.timeSlots.free.length === 0 &&
                    !Boolean(dayItem.busyByUser) &&
                    'FormCalendar__item_disabled',
                  Boolean(dayItem.busyByUser) && 'FormCalendar__item_active'
                )}
                onClick={() => {
                  if (
                    !Boolean(dayItem.busyByUser) &&
                    dayItem.timeSlots.free.length > 0
                  ) {
                    this.setSelectedDay(dayItem);
                    this.onHandleNextStep('4');
                  }
                }}
              >
                <h5>{dayItem.day}</h5>
                <p>{dayItem.dayWeekName}</p>
                {dayItem.timeSlots.free.length &&
                !Boolean(dayItem.busyByUser) ? (
                  <p>
                    {dayItem.timeSlots.free.length}{' '}
                    <span>
                      {this.getHumanReadablePlacesWord(
                        dayItem.timeSlots.free.length
                      )}
                    </span>
                  </p>
                ) : dayItem.busyByUser ? (
                  <p style={{ display: 'flex', flexDirection: 'column' }}>
                    <span>Вы записаны:</span>
                    <span
                      style={{
                        fontWeight: 500,
                        paddingTop: '4px',
                        color: '#717a8b',
                      }}
                    >
                      {this.formatDate(dayItem.busyByUser)}
                    </span>
                  </p>
                ) : (
                  <p>Нет записи</p>
                )}
              </li>
            );
          })}
        </ul>
      </>
    );
  }

  // рендер слотов времени на основе выбранного дня
  renderTimeSlots() {
    const slotsArray = (): (ScheduleTimeSlot & { disabled: boolean })[] => {
      const result: (ScheduleTimeSlot & { disabled: boolean })[] = [];
      this.state.selectedDay.timeSlots.free.forEach((item) => {
        result.push({
          startTime: item.startTime,
          endTime: item.endTime,
          disabled: false,
        });
      });
      this.state.selectedDay.timeSlots.busy.forEach((item) => {
        result.push({
          startTime: item.startTime,
          endTime: item.endTime,
          disabled: true,
        });
      });
      return result;
    };
    return slotsArray().map((item, key) => {
      return (
        <li
          key={key}
          className={cn(
            'PeriodVisit__item',
            item.disabled && 'PeriodVisit__item_disabled'
          )}
          onClick={() => {
            if (!item.disabled) {
              this.onHandleNextStep('5');
              this.setSelectedTime({
                endTime: item.endTime,
                startTime: item.startTime,
              });
            }
          }}
        >
          {moment(item.startTime).format('HH:mm')}
          <svg
            width='8'
            height='12'
            viewBox='0 0 8 12'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M0.231805 11.6402C0.58537 12.0645 1.21593 12.1218 1.64021 11.7682L7.64021 6.76825C7.8682 6.57825 8.00002 6.2968 8.00002 6.00003C8.00002 5.70325 7.8682 5.4218 7.64021 5.2318L1.64021 0.231804C1.21593 -0.12176 0.585369 -0.0644364 0.231805 0.35984C-0.121759 0.784117 -0.0644351 1.41468 0.359842 1.76825L5.43798 6.00003L0.359842 10.2318C-0.0644349 10.5854 -0.121759 11.2159 0.231805 11.6402Z'
              fill='#E0E6ED'
            />
          </svg>
          {moment(item.endTime).format('HH:mm')}
        </li>
      );
    });
  }

  render() {
    const { tabState } = this.state;

    return (
      <Page page='queue' className='queue'>
        <MetaTags 
          description='Сервисы ФСИН России'
          title="Сервисы ФСИН России"
        />
        <Wrapper isPageWrapper>
          {this.renderMenuRegistration()}
          {tabState !== '5' && tabState !== '7' && (
            <div className={classNames.content}>
              {this.props.regions.loading && <p>Загрузка...</p>}
              {!this.props.regions.loading && tabState === '1' && (
                <ContainerWrapper title='Выберите регион'>
                  {!this.props.regions.loading && (
                    <FormRegion
                      setSelectedRegion={this.setSelectedRegion}
                      onClick={() => this.onHandleNextStep('2')}
                      regions={this.props.regions}
                    />
                  )}
                </ContainerWrapper>
              )}
              {tabState === '2' && (
                <ContainerWrapper title='Выберите учреждение'>
                  <FormInstitution
                    setSelectedInstitution={this.setSelectedInstitution}
                    regionId={this.state.selectedRegion.id}
                    institutions={this.state.selectedRegion.companies}
                    onClick={() =>
                      this.props.userInfo
                        ? this.onHandleNextStep('3')
                        : this.redirectToLogin()
                    }
                  />
                </ContainerWrapper>
              )}

              {tabState === '3' && !this.state.isLoadingSlots && (
                <ContainerWrapper title='Выберите дату посещения'>
                  <div className='FormCalendar__month'>
                    {this.state.selectedInstitution.serviceType === 'visit' && this.state.selectedRegion.id === 171 && this.renderVisitSelect()}
                    {(this.state.selectedInstitution.serviceType === 'package' 
                    || this.state.selectedToVisit 
                    || this.state.selectedInstitution.serviceType === 'visit' && this.state.selectedRegion.id !== 171) && this.renderMonthSchedule('current')}
                    {(this.state.selectedInstitution.serviceType === 'package' 
                    || this.state.selectedToVisit 
                    || this.state.selectedInstitution.serviceType === 'visit' && this.state.selectedRegion.id !== 171) && this.renderMonthSchedule('next')}
                  </div>
                </ContainerWrapper>
              )}
              {tabState === '3' && this.state.isLoadingSlots && (
                <p>Загрузка расписания...</p>
              )}
              {tabState === '4' && this.state.selectedDay && (
                <ContainerWrapper title='Выберите время посещения'>
                  <Typography size='xl' type='heading'>
                    {moment(
                      this.state.selectedDay.timeSlots?.free[0]?.startTime
                    )
                      .locale('ru')
                      .format('Do MMMM')}
                  </Typography>
                  <ul className='PeriodVisit__list'>
                    {this.renderTimeSlots()}
                  </ul>
                </ContainerWrapper>
              )}
              {tabState === '6' && (
                <ContainerWrapper title='Подтвердите данные'>
                  <FormConfirm
                    fullState={this.state}
                    handleChange={this.handleChange}
                    onConfirm={this.sendFormInfo}
                  />
                </ContainerWrapper>
              )}
            </div>
          )}
          {tabState === '5' && (
            <FormFinal
              relationsList={this.state.relationsList}
              handleChange={this.handleChange}
              refInput={this.refInput}
              onClick={() => this.onHandleNextStep('6')}
              userInfo={this.props.userInfo}
              fullState={this.state}
              phoneChange={this.phoneChange}
              inputRefs={this.inputRefs}
            />
          )}
          {tabState === '7' && (
            <FormResult props={this.state} finalClick={this.finalClick} />
          )}
          {this.renderSidebar()}
        </Wrapper>
      </Page>
    );
  }
}

function mapQuery(props: PropsQuery) {
  return {
    regions: Regions.getRegions(props.location.pathname),
  };
}

function mapState(state: IGlobalState) {
  return {
    userInfo: selectAccountUserInfo(state),
    auth: state.auth ?? '',
    contact: selectContact(state),
    contacts: selectContacts(state),
  };
}

const IndexPageWithRedux = connect(mapState)(IndexPage);

export default withApollo(mapQuery, undefined)(IndexPageWithRedux);
