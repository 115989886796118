import React from 'react';
import { Props, State } from './interface';
import bind from '../../lib/decorators/bind';

import Svg from '../Svg';
import Button from '../Button';
import Typography from '../Typography';
import getTypographyProps from '../../lib/getTypographyProps';
import Notification from '../Notification';

import './DropZone.scss';


class DropZone extends React.Component<Props, State> {
  state: State = {
    files: [],
    images: [],
    isDraggable: false,
    size: 0,
    isLoading: false
  };

  fileRead(file: File): Promise<string | ArrayBuffer | null> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = (event: ProgressEvent) => {
        const { result } = event.target as FileReader;
        result ? resolve(result) : reject(result);
      };
    });
  }

  @bind
  onDragLeave(event: React.DragEvent) {
    event.preventDefault();
    event.stopPropagation();

    const { isDraggable } = this.state;
    if (isDraggable) this.setState({ isDraggable: false });
  }

  @bind
  onDragOver(event: React.DragEvent) {
    event.preventDefault();
    event.stopPropagation();

    const { isDraggable } = this.state;
    if (!isDraggable) this.setState({ isDraggable: true });
  }

  @bind
  onDrop(event: React.DragEvent) {
    event.preventDefault();
    event.stopPropagation();

    const { isDraggable } = this.state;
    if (isDraggable) {
      this.setState({ isDraggable: false });
      this.onLoadFiles(event.dataTransfer.files);
    }
  }

  filterImages(allImages: any){
    return new Promise((resolve, reject) => {
      let resultImages = allImages.filter((file: any) => {
        if (
          file.type === 'image/jpeg' ||
          file.type === 'image/jpg' ||
          file.type === 'image/png'
        ) {
          if (!this.props.maxImageSizeMB) return true;

          const maxSizeExceeded = file.size > this.props.maxImageSizeMB * 1024 * 1024;
          if (maxSizeExceeded) {
            Notification.show(`Файл слишком большой (${Math.floor(file.size / (1024 * 1024))}Мб). Максимальный размер файла: ${this.props.maxImageSizeMB}Мб.`);
          }
          return !maxSizeExceeded;
        }else{
          Notification.show(`Неверное расширение файла (.${file.name.split('.').pop()}). Допустимые расширения: .jpg .jpeg .png`);
          return false
        }
      })
      resolve(resultImages)
    })
  }

  @bind
  async onLoadFiles(filesEvent: FileList) {
    const { images, files } = this.state;
    const { maxImages } = this.props
    this.setState({isLoading:true})
    let allFiles: any = Object.values(filesEvent)
    allFiles = await this.filterImages(allFiles)
    let acceptedFiles = []
    for(let image of allFiles){
      const base64 = await this.fileRead(image);
      acceptedFiles.push(base64)
    }

    const newImages: string[] = images.concat(acceptedFiles).splice(0, maxImages) as string[];
    const validFiles = files.concat(allFiles).splice(0, maxImages)

    this.setState({ images: newImages, files: validFiles, isLoading:false});
    if (this.props.type === 'foto') {
      this.props.onChange(validFiles)
    } else {
      this.props.onChange(newImages);
    }
  }

  @bind
  onClick(e: any) {
    e.preventDefault()
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.multiple = true;
    fileInput.accept = 'image/jpg, image/jpeg, image/png';
    fileInput.style.display = 'none'
    fileInput.dataset.testid = 'dropzone_upload_input'
    document.body.appendChild(fileInput)
    // fileInput.onchange = (e: Event) => this.onLoadFiles((e.target as HTMLInputElement).files!);
    fileInput.addEventListener('change', (e: Event) =>  {
      this.onLoadFiles((e.target as HTMLInputElement).files!)
      setTimeout(() => {
        document.body.removeChild(fileInput)
        fileInput.remove();
      }, 100)
    })
    fileInput.click();
  }

  @bind
  renderPlaceholder() {
    const { images } = this.state;
    const { maxImages } = this.props
    if (images.length >= maxImages) return null;

    return (
      <React.Fragment>

        <Typography
          {...getTypographyProps('text', 's', 'text')}
          children={`Перетащите сюда необходимые фотографии в формате .jpg или .png, не более ${maxImages} шт.`}
        />

        <Button
          isColored
          isTransparent
          label='Загрузить фотографии'
          onClick={this.onClick}
          dataTestId="dropzone_btn"
        />
      </React.Fragment>
    );
  }

  @bind
  onDeleteImage(index: number) {
    return () => {
      const { images, files } = this.state;
      images.splice(index, 1);
      files.splice(index, 1)
      this.setState({ files, images })
      if (this.props.type === 'foto') {
        this.props.onChange(files)
      } else {
        this.props.onChange(images as string[]);
      }
    };
  }

  @bind
  renderImages() {
    const { images } = this.state;
    if (!images.length) return null;

    const imageList = images.map((a, i) => (
      <div key={`image${i}`}>
        <img src={a as string} data-testid={'dropzone_image_' + i} />
        <div
          className='delete'
          data-testid={'dropzone_del_image_' + i}
          onClick={this.onDeleteImage(i)}
        />
      </div>
    ));

    return (<div
      children={imageList}
      className='image-list'
    />);
  }

  render() {
    const { isDraggable, isLoading } = this.state;
    const className = isDraggable ? 'drop-zone drop-zone_draggable' : 'drop-zone';

    return (
      <div>
        <div
          className={className + ' ' + (this.props.isError ? 'red_line' : '')}
          onDragLeave={this.onDragLeave}
          onDragOver={this.onDragOver}
          onDrop={this.onDrop}
          id="dropzone"
          data-testid="dropzone_uploadImages"
        >
          {isLoading && (<div className='loading'>Загрузка...</div>)}
          {this.renderImages()}
          {this.renderPlaceholder()}
        </div>
        {this.props.isError && <Typography
          children='Загрузите фотографии'
          {...getTypographyProps('text', 'xs', 'red')}
          className='error'
          dataTestId="form_error"
        />}
      </div>
    );
  }
}

export default DropZone;
