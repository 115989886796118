import gql from 'graphql-tag';
import { mapCustomPage } from '../mappers';
import { IGQLRequest } from '../../types/app';
import { TCustomPage } from '../../pages/common/CustomPage/interface';

interface IPagesController {
  getBySlug: (slug: TCustomPage) => IGQLRequest;
}

class Pages {
  static getBySlug(slug: TCustomPage) {
    return {
      mapper: mapCustomPage,
      query: gql`
      {
        pages(slug: "${slug}") {
          data {
            meta_title
            meta_description
            title
            content
            slug
          }
        }
      }
      `
    };
  }
}

export default Pages as IPagesController;
