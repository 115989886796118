import React from 'react';
import { Props } from './interface';
import './Page.scss';

const classNames = {
  block: 'page',
  isEmpty: 'page_empty',
};

const Page = (props: Props) => {

  const getClasses = () => {
    let className = classNames.block;
    if (props.className) className += ` ${props.className}`;
    if (props.isEmpty) className += ` ${classNames.isEmpty}`;
    return className
  }
// @ts-ignore
  return <div  className={getClasses()} children={props.children} name={props.page} />;
};

export default Page
