import React, { Component, ReactElement } from 'react';
import bind from '../../lib/decorators/bind';
import { Props } from './interface';

import InfiniteScroll from '../InfiniteScroll';
import './GridList.scss';

class Grid extends Component<Props> {
  getClassName() {
    let className = this.classNames.main;
    if (this.props.type) className += ` ${this.classNames.main}_${this.props.type}`;
    if (this.props.className) className += ` ${this.props.className}`;

    return className;
  }

  @bind
  renderEmptyItems(): JSX.Element[] {
    const className = `${this.classNames.item} ${this.classNames.emptyItem}`;
    const element = () => <div className={className} />;
    const items = new Array(3).fill(element);

    return items.map((Comp, i) => <Comp key={`empty-${i}`} />);
  }

  classNames = {
    main: 'grid',
    item: 'grid__item',
    emptyItem: 'grid__item_empty'
  };

  @bind
  renderItems(): (Element | JSX.Element)[] {
    const { items } = this.props;

    return [
      ...items.map((item: ReactElement, i: number) => (
        <div
          key={i}
          className={this.classNames.item}
        >
          {item}
        </div>
      )),
      ...this.renderEmptyItems()
    ];
  }

  @bind
  renderInfiniteScroll() {
    const { totalPages, isChangedItems } = this.props;
    if (!totalPages) return null;

    return (<InfiniteScroll
      children={this.renderItems()}
      isChangedItems={isChangedItems}
      totalPages={totalPages}
    />);
  }

  render() {
    const { totalPages } = this.props;

    return (
      <div className={this.getClassName()}>
        {totalPages ? this.renderInfiniteScroll() : this.renderItems()}
      </div>
    );
  }
}

export default Grid;
