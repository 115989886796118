import React, { Component } from 'react';
import { Props, State } from './interface';
import { IMetaTags } from '../../../types/server';

import Typography from '../../../components/Typography';
import getTypographyProps from '../../../lib/getTypographyProps';

import Svg from '../../../components/Svg';
import Page from '../../../components/Page';
import Button from '../../../components/Button';
import Wrapper from '../../../components/Wrapper';
import withApollo from '../../../lib/HOC/withApollo';
import SectionTitle from '../../../components/SectionTitle';

import error from '../../../images/error.svg';

import './ErrorPage.scss';
import { getPathnameModel, MainRoutes } from '../../../components/Router/config/routes';
import MetaTags from '../../../components/MetaTags';

class ErrorPage extends Component<Props, State> {
  setMeta(){
    const meta: IMetaTags = {
      title: 'Навигатор по колониям и СИЗО России. Адреса, телефоны, график приема, график передач',
      keywords: 'письмо фотографии справочник колонии денежный перевод сервисы исправительные учреждения',
      description: 'Удобные сервисы, помогают получить информацию по учреждениям, отправлять письма и фотографии для заключенных.',
    };

    return (
      <MetaTags title={meta.title} keywords={meta.keywords} description={meta.description} />
    )
  }

  render() {
    return (
      <Page page='error'>
        {this.setMeta()}
        <Wrapper
          isCenter
          isRow
        >
          <div>
            <SectionTitle
              className='page__title'
              title='Неверный адрес страницы'
            />
            <Typography {...getTypographyProps('text', 'l', 'text')}>
              Проверьте, правильно ли вы ввели его. Если данные верны, пожалуйста, сообщите нам об
              ошибке.
            </Typography>

            <div className='buttons'>
              <Button
                isTransparent
                label='На главную'
                to={getPathnameModel(MainRoutes.indexPage.path)}
                withArrowBack
              />
            </div>
          </div>
          <div>
            <img src={error} />
          </div>
        </Wrapper>
      </Page>
    );
  }
}

export default withApollo(undefined, undefined)(ErrorPage);
