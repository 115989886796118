import { Component } from 'react'
import { Helmet } from 'react-helmet-async'
import {Props} from './interface'
import YandexRobot from '../../lib/yandexRobot';

class MetaTags extends Component<Props>{

    showOGmeta() {
        const {canonical, title, description} = this.props;

        const tags: JSX.Element[] = [];
        if (title) {
            tags.push(<meta property="og:title" content={title} />);
        }

        if (canonical) {
            tags.push(<meta property="og:url" content={'https://sizo.ru' + canonical} />);
        }

        if (description) {
            tags.push(<meta property="og:description" content={description} />);
        }

        tags.push(<meta property="og:type" content="website" />)
        tags.push(<meta property="og:image" content="https://sizo.ru/favicon.svg" />)

        return tags;
    }


    showTitle(){
        const {title} = this.props

        if(title){
            return (
              <title>{title}</title>
            )
        } else {
            return false
        }
    }

    showDescription() {
      const { description } = this.props
      if (description) {
        return (
          <meta
            content={description}
            name='description'
          />
        )
      } else {
        return false
      }
    }

    showViewport() {
      const { viewport } = this.props
      if (viewport) {
        return (
          <meta
            content='width=device-width, initial-scale=1'
            name='viewport'
          />
        )
      } else {
        return false
      }
    }

    showCharSet() {
      const { charSet } = this.props
      if (charSet) {
        return (
          <meta charSet='utf-8' />
        )
      } else {
        return false
      }
    }

    showKeywords() {
      const { keywords } = this.props
      if (keywords) {
        return (
          <meta
            content={keywords}
            name='keywords'
          />
        )
      } else {
        return false
      }
    }

    showCanonical(){

      const { canonical } = this.props
      if (canonical) {
        return (
          <link
            href={'https://sizo.ru' + canonical}
            rel='canonical'
          />
        )
      } else {
        return false
      }
    }

    showLastModified() {
      const { lastModified } = this.props
      if (lastModified) {
        return (
          <meta
            content={lastModified}
            http-equiv='last-modified'
          />
        )
      } else {
        return false
      }
    }


    showJsonLd(){
      const { jsonLd } = this.props
      if (jsonLd) {
        return (
          <script type='application/ld+json'>{jsonLd}</script>
        )
      } else {
        return false
      }
    }

    showRobots() {
      const { robots } = this.props
      if (robots) {
        return (
          <meta
            content={robots}
            name='robots'
          />
        )
      } else {
        return false
      }
    }

    componentDidMount() {
        // setTimeout на всякий случай, если helmet решит монтировать теги после didMount
        setTimeout(() => YandexRobot.metaTagsSet(), 100);
    }

    render() {
      const { children } = this.props
      return (
        <Helmet>
          {this.showOGmeta()}
          {this.showTitle()}
          {this.showDescription()}
          {this.showViewport()}
          {this.showCharSet()}
          {this.showKeywords()}
          {this.showCanonical()}
          {this.showJsonLd()}
          {this.showRobots()}
          {this.showLastModified()}
          {children}
        </Helmet>
      )
    }
}

export default MetaTags