import { IReduxAction, IGlobalState, IAuthState } from '../../types/redux';
import { IUserInfoFieldsType } from '../../models/types/IUserInfoFieldsType';

export const AuthActionType = {
  SET_AUTH_DATA: 'SET_AUTH_DATA',
};

export function setAuthDataAction(accessToken: string | null, refreshToken: string | null, guest: boolean) {
  return {type: AuthActionType.SET_AUTH_DATA, payload: {accessToken, refreshToken, guest}}
}

export function selectAuth(state: IGlobalState) {
  return state.auth;
}

export function selectAuthAccessToken(state: IGlobalState) {
  return selectAuth(state).accessToken;
}

export function selectAuthRefreshToken(state: IGlobalState) {
  return selectAuth(state).refreshToken;
}

export function selectAuthGuest(state: IGlobalState) {
  return selectAuth(state).guest;
}

export default function (
  state: any = {},
  action: IReduxAction
): IAuthState {
  switch (action.type) {
    case AuthActionType.SET_AUTH_DATA:
      return {
        ...state,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
        guest: action.payload.guest,
      };

    default:
      return state;
  }
}
