import React, { Component } from 'react';
import bind from '../../lib/decorators/bind';

import Modal from '../../components/Modal';
import { ModalProps } from '../../components/Modal/interface';
import { IObjectAnyType } from '../../types/app';

interface StaticProps {
  name: string;
}

const showModal = (instance: any) => () => {
  if (instance && instance.modal) {
    const { clickReady, state } = instance.modal;
    if (clickReady && !state.visible) instance.modal.onShow();
  }
};

const withStatic = (WrappedContent: React.ComponentType) => {
  class StaticModal extends Component<StaticProps> {
    static instance?: StaticModal = undefined;

    static show(additionalShowProps: IObjectAnyType = {}) {
      const { instance } = StaticModal;
      if (instance) {
        instance.setState({ ...additionalShowProps }, showModal(instance));
      }
    }

    static hide(withoutScrollBrake?: boolean) {
      const { instance } = StaticModal;
      if (instance && instance.modal) instance.modal.onHide(withoutScrollBrake);
    }

    state = {};

    componentDidMount() {
      StaticModal.instance = this;
    }

    @bind
    onRefModal(modal: any) {
      this.modal = modal;
    }

    @bind
    getModalProps() {
      const { name } = this.props;
      const children = <WrappedContent {...this.state} />;

      const props: ModalProps = {
        name,
        children,
        onRef: this.onRefModal,
        afterShow: this.onAfterShow,
        afterHide: this.onAfterHide,
        beforeShow: this.onBeforeShow,
        beforeHide: this.onBeforeHide,
      };

      return props;
    }

    @bind
    onBeforeShow() {
      // @ts-ignore
      if (this.state.onBeforeShow) {
        // @ts-ignore
        this.state.onBeforeShow();
      }
    }

    @bind
    onBeforeHide() {
      // @ts-ignore
      if (this.state.onBeforeHide) {
        // @ts-ignore
        this.state.onBeforeHide();
      }
    }

    @bind
    onAfterShow() {
      // @ts-ignore
      if (this.state.onAfterShow) {
        // @ts-ignore
        this.state.onAfterShow();
      }
    }

    @bind
    onAfterHide() {
      // @ts-ignore
      if (this.state.onAfterHide) {
        // @ts-ignore
        this.state.onAfterHide();
      }
    }

    modal?: Modal = undefined;

    additionalProps: IObjectAnyType = {};

    render() {
      return <Modal {...this.getModalProps()} />;
    }
  }

  return StaticModal;
};

export default withStatic;
