import React, { Component, useEffect } from 'react';
import { Props, State } from './interface';
import bind from '../../../lib/decorators/bind';
import withApollo from '../../../lib/HOC/withApollo';
import { IShortDistricts, IShortRegion } from '../../../types/app';

import Link from '../../../components/Link';
import Page from '../../../components/Page';
import Region from '../../../components/Region';
import Button from '../../../components/Button';
import GridRow from '../../../components/GridRow';
import Service from '../../../components/Service';
import Wrapper from '../../../components/Wrapper';
import Collapse from '../../../components/Collapse';
import Typography from '../../../components/Typography';
import ArticleCard from '../../../components/ArticleCard';
import SectionTitle from '../../../components/SectionTitle';
import TabSlider from '../../../components/TabSlider/TabSlider';
import getTypographyProps from '../../../lib/getTypographyProps';

import defaultData from '../../../lib/defaultData';

import News from '../../../gql/actions/News';
import Districts from '../../../gql/actions/Districts';
import './IndexPage.scss';
import {
  getPathnameModel,
  MainRoutes,
} from '../../../components/Router/config/routes';
import MetaTags from '../../../components/MetaTags';
import BannerText from './banner/text.png';
import BannerLogo from './banner/Logo.png';
import BannerImg from './banner/img.png';
import SizovikBannerLogo from './banner_sizovik/logo.png';
import SizovikBackgroundDesktopLarge from './banner_sizovik/background_desktop_l.png';
import SizovikBackgroundDesktop from './banner_sizovik/background_desktop_m.png';
import SizovikBackgroundMobile from './banner_sizovik/background_mobile.png';
import { store } from '../../../redux/createStore';
import useYMABFlags from '../../../lib/useYMABFlags';
import NewServiceCard from '../../../components/NewServiceCard/NewServiceCard';
import MailIcon from '../../../components/NewServiceCard/icons/Mail';
import MoneyIcon from '../../../components/NewServiceCard/icons/MoneyIcon';
import PhotoIcon from '../../../components/NewServiceCard/icons/PhotoIcon';
import VideoIcon from '../../../components/NewServiceCard/icons/VideoIcon';
import MoneyTransferBG from '../../../components/NewServiceCard/backgrounds/money-transfer.png';
import MoneyTransferBG_Mobile from '../../../components/NewServiceCard/backgrounds/money-transfer_mobile.png';

const IndexPage: React.FC<Props> = (props: Props) => {
  const { flags } = useYMABFlags<{ tdp: '0' | '1' }>();

  const renderServices = () => {
    return (
      <Wrapper className='services'>
        <GridRow>
          <NewServiceCard
            button='Написать письмо'
            color='blue'
            description='Сервис предназначен для отправки электронных сообщений лицам, находящимся в следственных изоляторах или отбывающим наказание в местах лишения свободы'
            Icon={MailIcon}
            title='Письмо заключенному'
            link='https://zt.ru/services/letter/payments-letter?utm_source=fsin&utm_medium=top_blok&utm_campaign=payments_letter&utm_content=/'
          />
          <NewServiceCard
            button='Отправить деньги'
            color='green'
            description='Сервис позволяет отправить электронный денежный перевод лицам, отбывающим наказание в исправительных учреждениях России'
            Icon={MoneyIcon}
            title={Boolean(Number(flags?.tdp)) ? 'Деньги на магазин' :'Деньги заключенному'}
            link='https://zt.ru/services/remittance/money-order?utm_source=fsin&utm_medium=knopka&utm_campaign=money-transfer&utm_content=/&utm_trans-dp=sizo'
            activeButton={!Boolean(Number(flags?.tdp))}
            withBackground={
              Boolean(Number(flags?.tdp))
                ? {
                  desktop: MoneyTransferBG,
                  mobile: MoneyTransferBG_Mobile,
                }
                : undefined
            }
          />
        </GridRow>
        <GridRow>
          <NewServiceCard
            button='Отправить фотографию'
            color='orange'
            description='Сервис позволяет отправлять своим близким фотографии на фотобумаге размером 10х15'
            Icon={PhotoIcon}
            title='Фото заключенному'
            link='https://zt.ru/services/photo-fsin/buy-photo'
          />
          <NewServiceCard
            button='Подробнее о сервисе'
            color='purple'
            description='Сервис позволяет осужденным общаться с родственниками при помощи технологии видеоконференцсвязи'
            Icon={VideoIcon}
            title='Видеосвязь с заключенным'
            link='https://zt.ru/service/videoperegovory?utm_source=fsinru&utm_medium=article_right'
          />
        </GridRow>
        <GridRow>
          <a
            href='https://близкаядуша.рф'
            target='_blank'
            className='soul_banner'
          >
            <div className='item text-item'>
              <img src={BannerText} />
            </div>
            <div className='item image-item'>
              <img src={BannerImg} />
            </div>
            <div className='item logo-item'>
              <img src={BannerLogo} />
            </div>
          </a>
        </GridRow>
        <GridRow>
          <a
            href='https://sizovik.ru/?utm_source=sizo.ru'
            target='_blank'
            className='sizovik_banner'
          >
            <div className='item text-item'>
              <h2>Ваш комфорт-Наша работа!</h2>
              Доставка в учреждения ФСИН
            </div>
            <div className='item logo-item'>
              <img src={SizovikBannerLogo} />
            </div>
            <div className='item bg-desktop-large'>
              <img src={SizovikBackgroundDesktopLarge} />
            </div>
            <div className='item bg-desktop'>
              <img src={SizovikBackgroundDesktop} />
            </div>
            <div className='item bg-mobile'>
              <img src={SizovikBackgroundMobile} />
            </div>
          </a>
        </GridRow>
        <Typography
          to={MainRoutes.servicesPage.path}
          {...getTypographyProps('text', 'm', 'main')}
          children='Все сервисы'
          className='tablet_show'
        />
        {renderMobileContent()}
      </Wrapper>
    );
  };

  const renderMobileContent = () => {
    const articles = getArticles();

    return (
      <div className='mobile-content mobile_show'>
        <Collapse title='Полезные статьи'>
          {articles}
          <Typography
            className='collapse__footer'
            to={MainRoutes.articlesPage.path}
            {...getTypographyProps('text', 'm', 'main')}
            children='Все полезные статьи'
          />
        </Collapse>
      </div>
    );
  };

  const renderQuestions = () => {
    let i = 0;
    const data: any[] = [[]];

    defaultData.questions.forEach((a, index) => {
      if (data[i].length > 1) {
        i++;
        data[i] = [];
      }

      data[i].push(
        <div key={index}>
          <Typography
            className='title'
            {...getTypographyProps('heading', 'm', 'dark-text')}
            children={a.title}
          />
          <div>
            {a.questions.map((b: any) => (
              <Typography
                key={b.title}
                {...getTypographyProps('text', 'm', 'main')}
                children={<Link isColored label={b.title} to={b.path} />}
              />
            ))}
          </div>
        </div>
      );
    });

    const content = data.map((a, index) => <div key={index}>{a}</div>);

    return (
      <Wrapper className='questions mobile_hide'>
        <SectionTitle title='Частые вопросы' />
        <div className='content'>{content}</div>
        <div className='button-wrapper'>
          <Button label='Все частые вопросы' />
        </div>
      </Wrapper>
    );
  };

  const getText = (value: number = 0) => {
    let word = 'учреждений';

    const string = value.toString();
    let numeral = parseInt(string[string.length - 1], 10);
    if (value === 11) numeral = 5;

    if (numeral === 1) word = 'учреждение';
    else if (numeral > 1 && numeral < 5) word = 'учреждения';
    else if (numeral >= 5) word = 'учреждений';

    return `${value} ${word}`;
  };

  const renderInstitutions = () => {
    let count = 0;
    const content = [];
    const { data } = props.districts;
    const isPreload = !(data && data.length);
    const districts = !isPreload ? data : new Array(0).fill({});

    districts.sort((a, b) => (a.name === 'Центральный округ' ? -1 : 1));

    for (let i = 0; i < districts.length; i++) {
      const { id, name, regions } = districts[i] as IShortDistricts;
      const rows: any = [[]];
      let rowIndex = 0;

      regions.sort((a: any, b: any) => {
        if (a.name === 'Санкт-Петербург' || a.name === 'Москва') return -1;
        return a.name > b.name ? 1 : -1;
      });

      for (let r = 0; r < regions.length; r++) {
        const city = regions[r] as IShortRegion;
        count += city.companies.length;

        if (rows[rowIndex].length > 1) {
          rowIndex++;
          rows[rowIndex] = [];
        }

        rows[rowIndex].push(
          <Region
            {...city}
            key={`${id} ${city.name}`}
            count={city.companies.length}
            withoutBold
          />
        );
      }

      content[i] = rows.map((a: any, index: number) => (
        <div key={`${name}-${index}`}>{a}</div>
      ));
    }

    const tabs = districts.map((a: IShortDistricts) => (
      <Typography
        key={a.id}
        isDivElement
        {...getTypographyProps('text', 'm', 'dark-text')}
        children={a.name}
      />
    ));

    return (
      <div className='institutions mobile_hide'>
        <Wrapper className='wrapper_withTabs'>
          <SectionTitle
            additional={
              <Typography
                {...getTypographyProps('text', 'xl', 'light-text', 'medium')}
              >
                {getText(count)}
              </Typography>
            }
            headingTag='h2'
            title='Справочник учреждений'
          />
          <TabSlider
            className='content'
            content={content}
            direction='vertical'
            tabs={tabs}
          />
          <div className='button-wrapper'>
            <Link to={getPathnameModel(MainRoutes.institutionsPage.path)}>
              <Button label='Все учреждения' />
            </Link>
          </div>
        </Wrapper>
      </div>
    );
  };

  const getArticles = () => {
    const { data } = props.news;
    const isPreload = !(data && data.length);
    const news = !isPreload ? data : new Array(3).fill({});

    const content = [];

    for (let i = 0; i < Math.min(3, news.length); i++) {
      content.push(
        <ArticleCard
          key={news[i].id || i}
          isPreload={isPreload}
          type='article'
          {...news[i]}
        />
      );
    }

    return content;
  };

  const renderArticles = () => {
    const articles = getArticles();

    return (
      <div className='articles mobile_hide'>
        <Wrapper>
          <SectionTitle title='Полезные статьи' headingTag='h2' />
          <div className='content'>{articles}</div>
          <div className='button-wrapper'>
            <Link to={getPathnameModel(MainRoutes.articlesPage.path)}>
              <Button label='Все статьи' />
            </Link>
          </div>
        </Wrapper>
      </div>
    );
  };

  const setMeta = () => {
    return (
      <MetaTags
        title='Навигатор по колониям и СИЗО России. Адреса, телефоны, график приема, график передач'
        description='Удобные сервисы, помогают получить информацию по учреждениям, отправлять письма и фотографии для заключенных.'
        canonical='/'
      />
    );
  };

  return (
    <Page page='index'>
      {setMeta()}
      {renderServices()}
      {renderInstitutions()}
      {renderArticles()}
    </Page>
  );
};

function mapQuery() {
  return {
    districts: Districts.get(),
    news: News.get('articles', { limit: 3 }),
  };
}

export default withApollo(mapQuery, undefined)(IndexPage);
