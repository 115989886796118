/* eslint-disable no-constant-condition */
import bind from '../../../lib/decorators/bind';
import { IDocument } from '../../../types/app';
import { IMetaTags } from '../../../types/server';
import React, { PureComponent, ReactElement } from 'react';
import { Props, State, IInstitutionTabLabel } from './interface';
import Comments from '../../../components/Comments/Comments';

import withApollo from '../../../lib/HOC/withApollo';

import Sticky from 'react-stickynode';
import Page from '../../../components/Page';
import Link from '../../../components/Link';
import Wrapper from '../../../components/Wrapper';
import Document from '../../../components/Document';
import Collapse from '../../../components/Collapse';
import Typography from '../../../components/Typography';
import Service from '../../../components/Service/Service';
import SectionTitle from '../../../components/SectionTitle';
import getTypographyProps from '../../../lib/getTypographyProps';

import {ReactComponent as Photo} from '../../../images/service/icon/photo.svg';

import sidebar from './config';
import Institution from '../../../gql/actions/Institution';
import isEmpty from 'lodash/isEmpty';
import './InstitutionPage.scss';
import { getPathnameModel, MainRoutes } from '../../../components/Router/config/routes';
import News from '../../../gql/actions/News';
import ErrorPage from '../../common/ErrorPage';
import MetaTags from '../../../components/MetaTags';
import { store } from '../../../redux/createStore';

const STICKY_ID = 'sticky-container';

class InstitutionsPage extends PureComponent<Props, State> {
  state = {
    activeTab: sidebar[0].label,
    redirect: false
  };

  componentDidUpdate(){
    const {institution} = this.props
    if (!institution.loading && institution.data.length === 0) {
        this.setState({redirect: true})
    }
  }

  toggleCollapse(name: string) {
    return () => {
      // @ts-ignore
      this.setState({ [name]: !this.state[name] });
    };
  }

  onChangePageTab(activeTab: IInstitutionTabLabel) {
    return () => {
      this.setState({ activeTab });
    };
  }

  renderLinksUsefulArticles() {
    const { data } = this.props.articles;
    const isPreload = !(data && data.length);
    const articles = !isPreload ? data : new Array(3).fill({});

    const renderArticles = (articles: any[]) => {
      const result = [];
      const containsIndexes: Record<number, boolean> = {};
      for (let i = 0; i < 3; i++) {
        // get random id between 0 and length of articles
        const articleIndex = Math.floor(Math.random() * articles.length);
        if (containsIndexes.hasOwnProperty(articleIndex)) {
          i--
          continue
        }
        containsIndexes[articleIndex] = true
        const item = articles[articleIndex];

        result.push(
          <Typography
            key={i}
            isLinkElement
            className={this.classNames.usefulArticlesItem}
            {...getTypographyProps('text', 's', 'text')}
            to={getPathnameModel(MainRoutes.articleByIdPage.path).replace(':slug', item.slug)}
          >
            {isEmpty(item.image) ? <Photo className='page__sidebar-useful-articles-svg' /> : <img
              className='page__sidebar-useful-articles-svg'
              alt='фото'
              src={item.image}
            />}
            <p className={this.classNames.usefulArticlesItemText}>{item.title}</p>
          </Typography>
        )
      }

      return result;
    }

    if (!isEmpty(articles)) {
      return (
        <div className='service_short'>
          <Typography
            className={this.classNames.usefulArticlesItem}
            {...getTypographyProps('heading', 'm', 'dark-text')}
            children='ПОЛЕЗНЫЕ СТАТЬИ'
          />
          <hr className={this.classNames.usefulArticlesItem} />
          {renderArticles(articles)}

          <div className='button-wrapper'>
            <Typography
              {...getTypographyProps('heading', 'm', 'dark-text')}
              children={
                <Link to={getPathnameModel(MainRoutes.articlesPage.path)}
                  isColored
                  label={'Другие статьи'}
                />
              }
            />
          </div>
        </div>
      );
    }
  }

  renderSideBar() {
    const { slug } = this.props.match.params;
    const { services, region } = this.props.institution.data[0];

    const getClassName = (label: string) => {
      let className = this.classNames.sidebarTab;
      if (false && this.state.activeTab === label) className += ' active';

      return className;
    };

    const tabs = sidebar.map((a, i) => {
      const Icon = a.icon
      return <div
        key={`${a.label} ${i}`}
        className={getClassName(a.label)}
        onClick={this.onChangePageTab(a.label)}
      >
        <Icon />
        <Typography
          {...getTypographyProps('heading', 'm', 'dark-text')}
          children={a.label}
        />
      </div>
    });

    const { institution } = this.props;
    const institutionId = institution?.data[0]?.id;

    const items = services
      .filter((item: any) => (item !== 'video_talks' && item !== 'phone' && (item !== 'queue' || institutionId === '6dc20d4b-ae31-46ab-a5bf-94861a705cb0')))
      .map((a: any, i) => (<Service
        key={`${a} ${i}`}
        isShort
        region={region}
        slug={slug}
        type={a}
      />));

    return (
      <Sticky
        bottomBoundary={`#${STICKY_ID}`}
        enabled={false}
        top={32}
      >
        <div className={this.classNames.sidebar}>
          <Typography size='l' type='heading' style={{marginLeft: '5px'}}>Цифровые сервисы</Typography>
          <div className={this.classNames.services}>
            {items}
          </div>
          {this.renderLinksUsefulArticles()}
        </div>
      </Sticky>
    );
  }

  renderTable(body: ReactElement[], withFlexColumn?: boolean, withoutBorder?: boolean) {
    if (!body.length)
      return (
        <Typography
          {...getTypographyProps('text', 'm', 'text')}
          children='График отсутствует'
          className='table'
        />
      );

    let className = '';
    if (withoutBorder) className += ' without-border';
    if (withFlexColumn) className += ' with_flex_column';

    return (
      <table className={className}>
        <tbody>{body}</tbody>
      </table>
    );
  }

  renderInfo() {
    const {
      fullName,
      address,
      region,
      contacts,
      chiefName,
      limit,
      point
    } = this.props.institution.data[0];

    const coords = point && point.split(',');
    const link = `https://yandex.ru/maps/?mode=routes&rtext=~${coords[0]}%2C${coords[1]}&rtt=auto`;

    const contactsInfo: { label: string; value: any }[] = [];

    for (let i = 0; i < contacts.length; i++) {
      contactsInfo.push({
        value: (<Link
          isColored
          isExternal
          label={contacts[i].number}
          to={contacts[i].type === "Электронная почта" ? `mailto:${contacts[i].number}` : `tel:${contacts[i].number}`}
        />
        ),
        label: contacts[i].type
      });
    }

    const tableData = [
      {
        label: 'Расположение',
        value: (
          <Link
            isColored
            label={region.name}
            to={getPathnameModel(MainRoutes.institutionsByRegionPage.path).replace(':region', region.slug)} //
          />
        )
      },

      {
        label: 'Название',
        value: fullName
      },

      {
        label: 'Адрес',
        value: (
          <React.Fragment>
            {address}
            <Link
              isColored
              isExternal
              label='Построить маршрут'
              to={link}
            />
          </React.Fragment>
        )
      },
      ...contactsInfo,
      {
        label: 'Начальник учреждения',
        value: chiefName
      },
      {
        label: 'Лимит наполнения',
        value: limit
      }
    ];

    const tableBody: ReactElement[] = [];

    tableData.forEach((a, i) => {
      if (a.value) {
        tableBody.push(
          <tr key={`${a.label}-${i}`}>
            <td className='fl-col fl-col-4'>
              <Typography
                {...getTypographyProps('text', 's', 'dark-text')}
                children={a.label}
              />
            </td>
            <td className='fl-col fl-col-8'>
              <Typography
                {...getTypographyProps('text', 'm', 'text')}
                children={a.value}
              />
            </td>
          </tr>
        );
      }
    });

    return (
      <Collapse
        isDisabled
        title='Общая информация'
      >
        <table className='without-border'>
          <tbody>{tableBody}</tbody>
        </table>
      </Collapse>
    );
  }

  renderReceptionSchedule() {
    if (!this.props.institution.data[0].receiptionSchedule.length) return null;
    if(this.props.institution.data[0].receiptionSchedule.length > 0 &&
      this.props.institution.data[0].receiptionSchedule[0].schedule.length === 0)
    {
      return null
    }

    const { receiptionSchedule } = this.props.institution.data[0];

    const body = receiptionSchedule.map((a: any, i: number) => (
      <tr key={i}>
        <td className='fl-col fl-col-4 fl-col_only-row'>
          <Typography
            {...getTypographyProps('text', 's', 'dark-text')}
            children={a.fullname}
          />
          {a.position && (
            <Typography
              {...getTypographyProps('text', 's', 'text')}
              children={a.position}
            />
          )}
        </td>
        <td className='tablet_hide fl-col fl-col-4 fl-col_only-row'>
          <Typography
            {...getTypographyProps('text', 's', 'text')}
            children={a.schedule[0].day}
          />
        </td>
        <td className='fl-col fl-col-4 fl-col_only-row'>
          <Typography
            className='tablet_show'
            {...getTypographyProps('text', 's', 'text')}
            children={a.schedule[0].day}
          />
          <Typography
            isDivElement
            {...getTypographyProps('text', 's', 'text')}
            children={a.schedule[0].times.split(';').map((c: any) => (
              <div key={c + i}>{c}</div>
            ))}
          />
        </td>
      </tr>
    ));

    return (
      <Collapse title='График приема'>
        {this.renderTable(body)}
      </Collapse>
    );
  }

  renderTransferSchedule() {
    if (!this.props.institution.data[0].transferSchedule.length) return null;
    const { transferSchedule } = this.props.institution.data[0];

    const body = transferSchedule.map((a, i) => {
      const { days, times } = a;

      return (
        <tr key={`${times}-${i}`}>
          <td className='fl-col fl-col-6 fl-col_only-row'>
            <Typography
              isHtmlContent
              {...getTypographyProps('text', 'm', 'dark-text')}
              children={days}
            />
          </td>
          <td className='fl-col fl-col-6 fl-col_only-row'>
            <Typography
              isHtmlContent
              {...getTypographyProps('text', 'm', 'text')}
              children={times}
            />
          </td>
        </tr>
      );
    });

    return <Collapse title='Прием передач'>{this.renderTable(body, true, true)}</Collapse>;
  }

  renderQueueSchedule() {
    if (!this.props.institution.data[0].queueSchedule.length) return null;
    const { queueSchedule } = this.props.institution.data[0];

    const body = queueSchedule.map((a, i) => {
      const { days, times } = a;

      return (
        <tr key={`${times}-${i}`}>
          <td className='fl-col fl-col-6 fl-col_only-row'>
            <Typography
              isHtmlContent
              {...getTypographyProps('text', 'm', 'dark-text')}
              children={days}
            />
          </td>
          <td className='fl-col fl-col-6 fl-col_only-row'>
            <Typography
              isHtmlContent
              {...getTypographyProps('text', 'm', 'text')}
              children={times}
            />
          </td>
        </tr>
      );
    });

    return <Collapse title='Свидания'>{this.renderTable(body, true, true)}</Collapse>;
  }

  renderDocuments() {
    const { documents } = this.props.institution.data[0];
    if (!documents.length) return null;

    const items = documents.map((a: IDocument) => (<Document
      key={a.id}
      {...a}
    />));

    return (<Collapse
      children={items}
      title='Бланки заявлений'
    />);
  }

  renderContent() {
    const { activeTab } = this.state;

    switch (activeTab) {
      case 'Страница учреждения':
        return (
          <div className={this.classNames.content}>
            <Service
              isShort
              withButton
              className='is_mobile'
              description='Отправка электронных писем осужденным'
              style={{marginBottom: '22px'}}
              buttonText="Написать письмо"
              type="mail"
              forceLink='https://zt.ru/services/letter/payments-letter'
            />
            {this.renderInfo()}
            {this.renderReceptionSchedule()}
            {this.renderTransferSchedule()}
            {this.renderQueueSchedule()}
            {/* {this.renderDocuments()} */}
            {/* <div className={'mobile_show'}>{this.renderLinksUsefulArticles()}</div>
            {this.renderLinksUsefulArticles()} */}
          </div>
        );

      default:
        return (
          <div className={this.classNames.content}>
            <Typography
              className='warning'
              {...getTypographyProps('text', 'm', 'text')}
              children='Нет информации'
            />
          </div>
        );
    }
  }

  classNames = {
    title: 'page__title',
    preload: 'page_preload',
    content: 'page__content',
    sidebar: 'page__sidebar',
    usefulArticlesItem: 'page__sidebar-useful-articles-item',
    usefulArticlesItemText: 'page__sidebar-useful-articles-item-text',
    sidebarTab: 'page__sidebar-tab',
    services: 'page__sidebar-services',
    navigation: 'page__sidebar-navigation',
    comments: 'page__comments'
  };

  renderComments() {
    return (
      <Comments collapse account={this.props.account} uid={this.props.institution.data[0].id} />
    );
  }

  @bind
  renderPreload() {
    return (
      <Page page='institution'>
        <Wrapper
          className={this.classNames.preload}
          isPageWrapper
        >
          <div className={this.classNames.title} />
          <div>
            <div className={this.classNames.sidebar}>
              <div className={this.classNames.navigation}>
                <div />
                <div />
                <div />
                <div />
                <div />
              </div>
              <div className={this.classNames.services}>
                <div />
                <div />
                <div />
                <div />
                <div />
              </div>
            </div>
            <div className={this.classNames.content}>
              <div className='collapse' />
              <div className='collapse' />
              <div className='collapse' />
              <div className='collapse' />
              <div className='collapse' />
              <div className={this.classNames.comments} />
            </div>
          </div>
        </Wrapper>
      </Page>
    );
  }

  setMeta(){
    const { shortName } = this.props.institution.data[0];
    const url  = this.props.match.url;
    const meta: IMetaTags = {};
    const text = ': ☎ телефон, 🌐 адрес, фото, время приема, новости, 👥информация для родственников';
    const extendDescription = ', официальная информация: ✓адрес тюрьмы, ⏲часы работы, ✓карта проезда, ☎телефоны, ✓почтовые ящики, 💬график приема. Информация об учреждении, актуальные электронные услуги: денежный перевод, письма, свидания для родственников, передачи.';
    const region = this.props.institution
      && this.props.institution.data[0]
      && this.props.institution.data[0].region.name;
    let longName;
    const longNames = [
      'исправительная колония',
      'следственный изолятор',
      'воспитательная колония',
      'лечебно-профилактическое учреждение',
      'лечебно-исправительное учреждение',
      'колония поселения'
    ];
    switch (shortName.split(' ')[0].split('-')[0]) {
      case 'ИК':
        longName = ` (${longNames[0]}) `;
        break;
      case 'СИЗО':
        longName = ` (${longNames[1]}) `;
        break;
      case 'ВК':
        longName = ` (${longNames[2]}) `;
        break;
      case 'ЛПУ':
        longName = ` (${longNames[3]}) `;
        break;
      case 'ЛИУ':
        longName = ` (${longNames[4]}) `;
        break;
      case 'КП':
        longName = ` (${longNames[5]}) `;
        break;
      default:
        longName = ' ';
        break;
    }
    meta.title = '🏢 ' + shortName + longName + region + text;
    meta.description = shortName + extendDescription;
    meta.canonical = url

    return (
      <MetaTags
        canonical={meta.canonical}
        description={meta.description}
        title={meta.title}
      />
    )
  }

  render() {
    const { loading, data } = this.props.institution;
    if (this.state.redirect) {
      return (<ErrorPage/>)
    }

    else if (loading || data.length === 0) return this.renderPreload();
    const { shortName } = this.props.institution.data[0];
    return (
      <Page page='institution'>
        {this.setMeta()}
        <Wrapper isPageWrapper>
          <SectionTitle
            className={this.classNames.title}
            title={shortName}
          />
          <div id={STICKY_ID}>
            {this.renderSideBar()}
            {this.renderContent()}
          </div>
        </Wrapper>
      </Page>
    );
  }
}

function mapQuery(props: Props) {
  const { slug } = props.match.params;

  return {
    institution: Institution.getBySlug(slug),
    articles: News.get('articles', { limit: 4 })
  };
}

export default withApollo(mapQuery, undefined)(InstitutionsPage);
