import gql from 'graphql-tag';
import { IObjectAnyType } from '../../types/app';
import { mapNews, mapShortNews, mapTags, mapComments } from '../mappers';

export type TNews = 'articles' | 'stories';
export type TNewsById = 'article' | 'story';

const getTypeId = (type: TNewsById | TNews) => {
  const arr: (TNewsById | TNews)[] = ['article', 'articles'];

  if (arr.includes(type)) {
    return 'article_id';
  }

  return 'news_id';
};

class News {
  static get(type: TNews, query?: IObjectAnyType) {
    const search: any = {
      order: {
        creation_date: 'desc'
      }
    };

    if (query && query.page && query.LIMIT) {
      const OFFSET = query.LIMIT * (query.page - 1);
      search.limit = query.LIMIT;
      search.offset = OFFSET;
    }
    if (query && query.tags) {
      search.filters = {
        tag: {
          in: `&${query.tags}&`
        }
      };
    }
    const queryString = JSON.stringify(search).replace(/"/g, '');
    const filters = `(search: ${queryString.replace(/&/g, '"')})`;
    return {
      mapper: mapShortNews,
      query: gql`
        {
          ${type}${filters}{
            total
            limit
            offset
            data {
              name
              slug
              ${getTypeId(type)}
              image
              creation_date
              updation_date
              tags {
                value
              }
            }
          }
        }
      `
    };
  }

  static getTags(type: TNews) {
    const typeMod = type[0].toUpperCase() + type.slice(1);
    return {
      mapper: mapTags,
      query: gql`
      query{
        tags${typeMod}(search:{order:{total:asc}}) {
          limit
          offset
          total
          data {
            tag_id
            value
          }
        }
      }
    `
    };
  }

  static getBySlug(type: TNewsById, slug: string) {
    const tagsArticles = type === 'article' ? 'articles' : 'stories';
    const typeArticles = type === 'article' ? 'article' : 'news';

    return {
      mapper: mapNews,
      query: gql`
        {
          ${type}(slug: ${JSON.stringify(slug)}){
            data {
              name
              slug
              ${getTypeId(type)}
              image
              creation_date
              updation_date
              time_to_read
              content
              tags {
                ${tagsArticles}{
                  ${typeArticles}_id
                  image
                  name
                  slug
                }
                value
              }
              banners {
                btn_link,
                btn_text,
                content,
                position,
                title,
                type,
              }
            }
          }
        }
      `
    };
  }

  static getComments(uiid: string) {
    return {
      mapper: mapComments,
      query: gql`
      query Comments{
        data @rest(type: "Comments", path: "${uiid}") {
          comments
        } 
      }`
    };
  }

}

export default News;
