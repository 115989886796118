import { CSSProperties } from 'react';

const getBackgroundStyle = (source: string, size?: string, position?: string): CSSProperties => ({
  backgroundImage: `url('${source}')`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: size || '100%',
  backgroundPosition: position || '0 0'
});

export default getBackgroundStyle;
