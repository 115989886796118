import { store } from '../redux/createStore';
import { setAuthDataAction } from '../redux/reducers/authReducer';
import { IStorage } from './StorageModel';

const ACCESS_TOKEN_FIELD = 'access_token';
const REFRESH_TOKEN_FIELD = 'refresh_token';
const IAT_FIELD = 'iat';
const GUEST_FIELD = 'guest';

export function setAuthDataInStorage(accessToken: string, refreshToken: string, iat: string = '', guest: boolean) {
  if (typeof window !== 'undefined' && typeof document !== 'undefined') {
    const domainString = 'domain='+window.location.hostname
    if (accessToken) {
      if (guest) {
        document.cookie = ACCESS_TOKEN_FIELD + '=' + accessToken + '; path=/; ' + domainString;
        document.cookie = IAT_FIELD + '=' + iat + '; path=/; ' + domainString;
        document.cookie = REFRESH_TOKEN_FIELD + '=' + refreshToken + '; path=/; ' + domainString;
        document.cookie = GUEST_FIELD + '=' + (!!guest).toString() + '; path=/; ' + domainString;
      } else {
        document.cookie = ACCESS_TOKEN_FIELD + '=' + accessToken + '; expires=Tue, 19 Jan 2100 03:14:07 GMT; path=/; ' + domainString;
        document.cookie = IAT_FIELD + '=' + iat + '; expires=Tue, 19 Jan 2100 03:14:07 GMT; path=/; ' + domainString;
        document.cookie = REFRESH_TOKEN_FIELD + '=' + refreshToken + '; expires=Tue, 19 Jan 2100 03:14:07 GMT; path=/; ' + domainString;
        document.cookie = GUEST_FIELD + '=' + (!!guest).toString() + '; expires=Tue, 19 Jan 2100 03:14:07 GMT; path=/; ' + domainString;
      }
    } else {
      document.cookie = ACCESS_TOKEN_FIELD + '=; expires=Tue, 19 Jan 2000 03:14:07 GMT; path=/; ' + domainString;
      document.cookie = IAT_FIELD + '=; expires=Tue, 19 Jan 2000 03:14:07 GMT; path=/; ' + domainString;
      document.cookie = REFRESH_TOKEN_FIELD + '=; expires=Tue, 19 Jan 2000 03:14:07 GMT; path=/; ' + domainString;
      document.cookie = GUEST_FIELD + '=; expires=Tue, 19 Jan 2000 03:14:07 GMT; path=/; ' + domainString;
    }
  }

  store.dispatch(setAuthDataAction(accessToken, refreshToken, guest))
}
function getCookie(name: string) {
  const value: string = `; ${document.cookie}`;
  const parts: Array<string> = value.split(`; ${name}=`);
  // @ts-ignore
  if (parts.length === 2) return parts.pop().split(';').shift();
}
export function getAccessTokenFromStorage() {
  return getCookie(ACCESS_TOKEN_FIELD)
}

export function getRefreshTokenFromStorage() {
  return getCookie(REFRESH_TOKEN_FIELD)
}

export function getGuestFromStorage() {
  return getCookie(GUEST_FIELD) === 'true'
}