import React from 'react';
import { Props } from './interface';

import Link from '../Link';
import Typography from '../Typography';
import getTypographyProps from '../../lib/getTypographyProps';

import { MainRoutes, getPathnameModel } from '../Router/config/routes';
import './PaymentAgreement.scss';

const PaymentAgreement = (props: Props) => {
  const to = getPathnameModel(MainRoutes.customPage.path).replace(':slug', 'privacypolicy');
  return (
    <Typography {...getTypographyProps('text', 'xs', 'light-text')}>
      Нажимая кнопку “Оплатить”, вы соглашаетесь с{' '}
      <Link
        children='условиями использования'
        isColored
        isExternal
        to={to}
      />{' '}сервиса
    </Typography>
  );
};

export default PaymentAgreement;
