/* eslint-disable global-require */

import React, { Component } from 'react';
import { IPropsApp } from '../types/app';
import bind from '../lib/decorators/bind';
import SERVICE from '../lib/getServiceType';
import { withRouter } from 'react-router-dom';

import Header from '../components/Header';
import Footer from '../components/Footer';
import TextError from '../modal/TextError';
import Router from '../components/Router';
import Notification from '../components/Notification';
import getSelectionText from '../lib/getSelectionText';

import '../css/base.scss';
import '../css/App.scss';
import {
  getPathnameModel,
  MainRoutes,
} from '../components/Router/config/routes';
import Navigation from '../components/Navigation';
import { store } from '../redux/createStore';
import YandexRobot from '../lib/yandexRobot';

class App extends Component<IPropsApp> {
  state = {
    isOpenModal: false,
  };

  componentDidMount() {
    const { isKeyHotkey } = require('is-hotkey');
    const isCallErrorModal = isKeyHotkey('ctrl+enter');

    if (global.document) {
      const value = `; ${global.document.cookie}`;
      const parts = value.split(`; first_visit=`);
      if (parts.length !== 2) {
        const now = new Date();
        const date = new Date().setDate(now.getDate() + 365);
        const expires = new Date(date).toUTCString();
        document.cookie = `first_visit=true; expires=${expires}; path=/`;
        this.setState({
          isOpenModal: true,
        });
      }
    }

    store.dispatch({ type: 'set_utm', value: global.location?.href });

    YandexRobot.onPageLoaded();

    window.addEventListener('keydown', (event) => {
      if (isCallErrorModal(event)) {
        const mistake: string = getSelectionText();

        if (mistake) {
          TextError.show({ mistake });
        } else {
          Notification.show('Выделите текст!');
        }
      }
    });
  }

  @bind
  isIndexPage() {
    const { location } = this.props;
    return location.pathname === '/';
  }

  @bind
  renderRouter() {
    const { serverRoute } = this.props;
    if (serverRoute) return serverRoute;
    return <Router {...this.props} />;
  }

  renderModals() {
    return (
      <React.Fragment>
        <Notification />
        <TextError name='text-error' />
      </React.Fragment>
    );
  }

  renderContent() {
    const currentPath = this.props.location.pathname;
    return (
      <React.Fragment>
        <Navigation
          className='mobile_hide tablet_S_hide'
          currentPath={currentPath}
          history={this.props.history}
        />
        <Header isIndexPage={this.isIndexPage()} />
        {this.renderRouter()}
        <Footer />
      </React.Fragment>
    );
  }

  render() {
    return (
      <React.Fragment>
        {this.renderContent()}
        {this.renderModals()}
      </React.Fragment>
    );
  }
}

const Application = withRouter(App);
export default Application;
