import gql from 'graphql-tag';
import SERVICE from '../../lib/getServiceType';
import { IGQLRequest } from '../../types/app';
import { mapRegion, mapShortRegion, mapFotoRegions } from '../mappers';
import { FOTO_HTTP_END_POINT } from '../../lib/request/config';

interface IRegionsController {
  getRegions: (currentPath?: string | null) => IGQLRequest;
  getRegion: (id: string) => IGQLRequest;
  getFotoRegions: () => IGQLRequest;
  getCompany: (company_id: string) => IGQLRequest;
}

class Regions {
  static getRegions(currentPath: string | null = null) {
    const filters = '(search:{order:{name:asc}, limit: 0})';
    let filteredByActiveService = '';

    if (SERVICE === 'TRANSFER') filteredByActiveService = '(service_id:16)';
    else if (SERVICE === 'MAIL') filteredByActiveService = '(service_id:20)';
    if (currentPath === '/pochta') {
      filteredByActiveService = '(service_id:20)';
    } else if (currentPath === '/perevod') {
      filteredByActiveService = '(service_id:16)';
    }

    return {
      sorters: [
        (a: any, b: any) => {
          if (a.name === 'Москва' && b.name === 'Санкт-Петербург') return -1;
          if (a.name === 'Санкт-Петербург' && b.name === 'Москва') return 1;
          return a.name === 'Москва' || a.name === 'Санкт-Петербург' ? -1 : 1;
        },
      ],
      mapper: mapShortRegion,
      query: gql`
        {
          regions${filters} {
            data {
              slug
              name
              region_id
              companies${filteredByActiveService} {
                slug
                company_id
                shortname
                address
                can_color_print
              }
            }
          }
        }
      `,
    };
  }

  static getCompany(company_id: string) {
    return {
      mapper: mapRegion,
      query: gql`
      query companyByid {
        company(id: "${company_id}") {
          data {
            fullname
            shortname
            slug
          }
        }
      }
      `,
    };
  }

  static getRegion(slug: string) {
    let filteredByActiveService = '';

    if (SERVICE === 'TRANSFER') filteredByActiveService = '(service_id:16)';
    else if (SERVICE === 'MAIL') filteredByActiveService = '(service_id:20)';

    return {
      mapper: mapRegion,
      query: gql`
        {
          region(slug: ${JSON.stringify(slug)}) {
            data {
              slug
              name
              region_id
              companies${filteredByActiveService} {
                slug
                address
                main
                fullname
                company_id
                shortname
                can_color_print
                services{
                  name
                }
                region {
                  name
                  region_id
                }
                company_contacts{
                  description
                  number
                  type
                }
              }
            }
          }
        }
      `,
    };
  }

  static getFotoRegions() {
    return {
      mapper: mapFotoRegions,
      query: gql`
        {
          colonies {
            id
            name
            region {
              id
              name
            }
            delivery {
              colony {
                avg
              }
            }
            view
          }
        }
      `,
      endPoint: FOTO_HTTP_END_POINT,
    };
  }
}

export default Regions as IRegionsController;
