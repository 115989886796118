import React, { useEffect, useState } from 'react';
import { Props } from './interface';

import './PageBlock.scss';

const PageBlock = (props: Props) => {
  const [renderedClasses, setRenderedClasses] = useState('');

  useEffect(() => {
    let className = 'page__block';
    if (props.className) className += ` ${props.className}`;
    setRenderedClasses(className);
  }, [props.className]);

  return <div id={props.id} children={props.children} className={renderedClasses} />;
};

export default PageBlock;
