interface ICounterEvents {
  moneyClickButtonPay: () => void;
  moneySubmitFormPay: () => void;
}

class CounterEvents {
  private static execute(eventName: string) {
    const { ym } = window as any;
    const { dataLayer } = window as any;

    try {
      dataLayer.push({'event':eventName});
      ym(49249702, 'reachGoal', eventName);
    } catch (error) {
      console.log('Developer error: Yandex Metrika reachGoal'); 
    }
  }

  public static moneyClickButtonPay() {
    this.execute('money_fsin_pay'); 
  }

  public static moneySubmitFormPay() {
    this.execute('money_fsin_form'); 
  }
}

export default CounterEvents as ICounterEvents;
