import moment from 'moment';
import React, { Component } from 'react';
import Button from '../../../../../components/Button';
import bind from '../../../../../lib/decorators/bind';
import Modal from '../../../../../components/ModalCustom';
import './style.scss';
import { State } from '../../IndexPage';

interface Props {
  props: State;
  finalClick: CallableFunction;
}

class FormResult extends Component<Props> {
  state = {
    isOpenModal: false,
    isRules: false,
    isThings: false,
  };

  @bind
  toggleModal(type: string | null = null) {
    if (type === 'rules') {
      this.setState({ isRules: true, isThings: false });
    } else if ('things') {
      this.setState({ isRules: false, isThings: true });
    }
    this.setState({ isOpenModal: !this.state.isOpenModal });
    document.body.classList.toggle('scroll-brake');
  }

  renderDate() {
    return (
      moment(this.props.props.selectedTime.startTime)
        .locale('ru')
        .format('DD MMMM yyyy[г. с] HH:mm') +
      ' до ' +
      moment(this.props.props.selectedTime.endTime)
        .locale('ru')
        .format('HH:mm')
    );
  }

  render() {
    const { selectedDay, selectedTime, selectedInstitution } = this.props.props;
    const { finalClick } = this.props;

    return (
      <>
        <Modal
          isOpen={this.state.isOpenModal}
          rules={this.state.isRules}
          things={this.state.isThings}
          setOpenModal={this.toggleModal}
        />
        <div className='FormResult'>
          <div className='FormResult__header'>
            <svg
              width='72'
              height='72'
              viewBox='0 0 72 72'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <circle cx='36' cy='36' r='36' fill='#3FCC6F' fillOpacity='0.2' />
              <path
                d='M68.6847 12.0081C69.5705 12.8317 69.6208 14.2174 68.7972 15.1032L35.3232 51.1032C34.9228 51.5339 34.3659 51.7854 33.778 51.8011C33.1901 51.8168 32.6206 51.5954 32.1977 51.1868L15.6716 35.2179C14.8018 34.3774 14.7781 32.991 15.6185 32.1212C16.459 31.2514 17.8454 31.2277 18.7152 32.0681L33.6358 46.4857L65.5896 12.1206C66.4132 11.2349 67.7989 11.1845 68.6847 12.0081Z'
                fill='#3FCC6F'
              />
            </svg>
            <div className='FormResult__header-text'>
              <h4>
                {selectedInstitution.serviceType === 'visit'
                  ? 'Вы записались на свидание.'
                  : 'Вы записались на передачу.'}
              </h4>
              <p>{selectedInstitution.shortName}</p>
              <p>
                Дата и Время :{' '}
                {this.renderDate()}
              </p>
            </div>
          </div>
          <div className='FormResult__body'>
            <h5 className='FormResult__title'>
              Пожалуйста, ознакомьтесь с правилами свидания и передачи
            </h5>
            <ul className='FormResult__list'>
              <li>Явка за полчаса до назначенного времени.</li>
              <li>
                Для проведения короткосрочного свидания обязательно наличие
                паспорта РФ (временной регистрации)
              </li>
              <li>
                При себе иметь - паспорт,{' '}
                <a
                  href={`https://sizo.ru/api/documents/download?file_id=49ab4333-7074-408f-96a9-cc86494aaee9`}
                >
                  Письменное разрешение
                </a>{' '}
                лица или органа, в производстве которых находится уголовное
                дело. В письменном разрешении на свидание (заверенном (синей)
                гербовой печатью) должно быть указание, кому и с какими лицами оно
                разрешается.
              </li>
              <li>
                Пользователь может держать только 1 электронный аккаунт на сайте
                sizo.ru.
              </li>
              <li>Очередь не подлежит передаче.</li>
              <li>
                Лицо, которое записалось на очередь, должно совпадать с лицом,
                которое посетило СИЗО или ИК (проверяется наличие документов).
              </li>
              <li>
                Есть список{''}
                <span
                  className='link_queue'
                  onClick={() => this.toggleModal('things')}
                >
                  запрещенных к передаче предметов
                </span>{' '}
                и
                <span
                  className='link_queue'
                  onClick={() => this.toggleModal('rules')}
                >
                  правила передачи.
                </span>
              </li>
            </ul>
            <div className='FormResult__warn'>
              Несоблюдение установленных правил приведет к аннулированию
              электронной очереди.
            </div>
            <Button label='На главную' onClick={() => finalClick()} />
          </div>
        </div>
      </>
    );
  }
}

export default FormResult;
