import { Type, Sizes, Color, Mark } from '../components/Typography/interface';

const getTypographyProps = (type: Type, size: Sizes, color: Color, mark?: Mark) => {
  const props: any = { size, type, color };
  if (mark) props.mark = mark;
  
  return props;
};

export default getTypographyProps;
