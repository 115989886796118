import React from "react";
import Button from '../Button/index';
import Typography from '../Typography';

import { IService, IServiceKeys, Props } from './interface';
import './Banner.scss';
import { ReactComponent as IconEducation } from '../../images/education-service.svg';
import { ReactComponent as IconMail } from '../../images/mail-service.svg';
import { ReactComponent as IconPhoto } from '../../images/photo-service.svg';
import { ReactComponent as IconTransfer } from '../../images/transfer-service.svg';
import { ReactComponent as IconVideo } from '../../images/video-service.svg';


const Banner: React.FC<Props> = ({
  btn_link= 'https://zt.ru/services/remittance/money-order',
  btn_text = 'Отправить деньги',
  content = '',
  title = 'Отправляйте деньги заключенным онлайн',
  type = 'transfer'
}) => {
  const classNames = {
    background: 'banner__background',
    block: 'banner',
    button: 'banner__button',
    content: 'banner__content',
    contentDefault: 'banner__content_default',
    header: 'banner__header',
    headerIcon: 'banner__header_icon',
    headerService: 'banner__header_service',
    title: 'banner__title',
  };

  const services: { [k in IServiceKeys]: IService } = {
    education: {
      className: 'banner--education',
      header: 'Образование',
      icon: IconEducation,
    },
    mail: {
      className: 'banner--mail',
      header: 'Письмо заключенному',
      icon: IconMail,
    },
    photo: {
      className: 'banner--photo',
      header: 'Фото заключенному',
      icon: IconPhoto,
    },
    transfer: {
      className: 'banner--transfer',
      contentDefault: 'Самая низкая комиссия на денежные переводы',
      header: 'Деньги заключенному',
      icon: IconTransfer,
    },
    video: {
      className: 'banner--video',
      header: 'Видеосвязь с заключенным',
      icon: IconVideo,
    },
  };

  const service: IService = services[type];
  const IconService = service.icon;

  return (
    <div className={classNames.block + ' ' + service.className}>
      <div className={classNames.header}>
        <IconService className={classNames.headerIcon} />
        <Typography className={classNames.headerService} size="m" type="heading">
          {service.header}
        </Typography>
      </div>
      <Typography className={classNames.title} color="dark-text" size="xl" type="heading">
        {title}
      </Typography>
      {content ? (
        <Typography
          className={classNames.content}
          size="s"
          type="text"
        >
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </Typography>
      ) : service.contentDefault && (
        <Typography
          className={classNames.content + ' ' + classNames.contentDefault}
          color="red"
          mark="medium"
          size="s"
          type="text"
        >
          {service.contentDefault}
        </Typography>
      )}
      <Button
        className={classNames.button}
        isExternal
        label={btn_text}
        to={btn_link}
      />
    </div>
  )
}

export default Banner;