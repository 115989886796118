import { IReduxAction, IGlobalState, IAuthState } from '../../types/redux';
import { IUserInfoFieldsType } from '../../models/types/IUserInfoFieldsType';
import { IContactFieldsType } from '../../models/types/IContactFieldsType';

export interface IContactState {
  contacts?: IContactFieldsType | null;
  contact?: IContactFieldsType | null;
  contact_id?: string | null;
}

export const ContactActionType = {
  SET_CONTACTS: 'SET_CONTACTS',
  SET_CONTACT: 'SET_CONTACT',
  DELETE_CONTACT: 'DELETE_CONTACT',
  SET_ACTIVE_CONTACT: 'SET_ACTIVE_CONTACT',
};

export function setContactsAction(contacts?: IContactFieldsType | null) {
  return { type: ContactActionType.SET_CONTACTS, payload: { contacts } };
}

export function deleteContactReducer(id: string) {
  return { type: ContactActionType.DELETE_CONTACT, payload: { id } };
}

export function selectActiveContactReducer(id: string) {
  return {
    type: ContactActionType.SET_ACTIVE_CONTACT,
    payload: { id },
  };
}

export function setContactAction(contact?: IContactFieldsType | null) {
  return { type: ContactActionType.SET_CONTACT, payload: { contact } };
}

export function selectContactPart(state: IGlobalState) {
  return state.contact;
}

export function selectContacts(state: IGlobalState) {
  return selectContactPart(state).contacts;
}

export function selectContact(state: IGlobalState) {
  return selectContactPart(state).contact;
}

export default function (state: any = {}, action: IReduxAction): IContactState {
  switch (action.type) {
    case ContactActionType.SET_CONTACTS:
      return {
        ...state,
        contacts: action.payload.contacts,
      };

    case ContactActionType.SET_CONTACT:
      return {
        ...state,
        contact: action.payload.contact,
      };

    case ContactActionType.DELETE_CONTACT:
      return {
        ...state,
        contacts: state.contacts.filter(
          (elem: IContactFieldsType) => elem.contact_id !== action.payload.id
        ),
      };

    case ContactActionType.SET_ACTIVE_CONTACT:
      return {
        ...state,
        contact_id: action.payload.id
      };

    default:
      return state;
  }
}
