import React, { Component } from 'react';
import { Props, State } from './interface';
import bind from '../../lib/decorators/bind';
import withStatic from '../../lib/HOC/withStatic';

import Button from '../../components/Button';
import Wrapper from '../../components/Wrapper';
import SectionTitle from '../../components/SectionTitle';
import Notification from '../../components/Notification';
import Form, { FormElement } from '../../components/Form';

import TextError from '.';
import './TextError.scss';
import { createMistake } from '../../gql/actions/Mutations';
import { ICreateMistakeBody } from '../../types/app';

class StaticModal extends Component<Props, State> {
  state: State = {
    message: ''
  };

  onHide() {
    TextError.hide();
  }

  @bind
  onChange(name: string, value: string | boolean) {
    // @ts-ignore
    this.setState({
      [name]: value
    });
  }

  @bind
  onSubmit() {
    const body: ICreateMistakeBody = {
      mistake: this.props.mistake,
      message: this.state.message
    };

    createMistake(body, ({ data }: any) => {
      try {
        if (data.createErrorMsg.error_id) {
          this.onHide();
          Notification.show('Сообщение успешно отправлено');
        }
      } catch (error) {
        Notification.show('Ошибка при отправке сообщения');
      }
    });
  }

  render() {
    return (
      <Wrapper>
        <SectionTitle
          size='l'
          title='Сообщить об ошибке'
        />
        <Form onSubmit={this.onSubmit}>
          <FormElement
            name='message'
            onChange={this.onChange}
            placeholder='Описание'
            required
            type='textarea'
            value={this.state.message}
          />
          <div className='buttons'>
            <Button
              isButtonTag
              label='Отправить'
            />
            <Button
              isTransparent
              label='Отменить'
              onClick={this.onHide}
            />
          </div>
        </Form>
      </Wrapper>
    );
  }
}

// @ts-ignore
export default withStatic(StaticModal);
