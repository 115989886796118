import { combineReducers } from 'redux';

import authReducer from './authReducer';
import accountReducer from './accountReducer';
import contactReducer from './contactReducer';
import mailPageReducer from './mailPageReducer';
import fotoPageReducer from './fotoPageReducer';
import formErrorReducer from './formErrorReducer';
import { globalLinkReducer } from './globalLink';


const getAdditionalReducers = () => {
  // if (SERVICE === 'MAIL') return { mailPageState: mailPageReducer };
  return { mailPageState: mailPageReducer, fotoPageState: fotoPageReducer, globalLinkState: globalLinkReducer }
  // return {};
};

const baseReducer = { isFormError: formErrorReducer,auth: authReducer, account: accountReducer, contact: contactReducer };
const reducers: any = { ...baseReducer, ...getAdditionalReducers() };

export default combineReducers(reducers);
