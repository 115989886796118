import React from 'react';
import request from '../request';
import { Query } from 'react-apollo';
import cookie from 'cookie';
import { IMapQuery, IGQLRequest } from '../../types/app';

import bind from '../../lib/decorators/bind';
import { SSR } from '../../app';
import { IMetaTags } from '../../types/server';
// import { setMetaTags } from '../../components/Router/config/meta';

import { store } from '../../redux/createStore';
import { CLEAR_STATE_MAIL_INDEX_PAGE } from '../../redux/actionsList';
import SERVICE from '../getServiceType';
import gql from "graphql-tag";
import { ACCOUNT_END_POINT, END_POINT } from '../request/config';
import { setAccountUserInfoAction } from '../../redux/reducers/accountReducer';

const isServer = process.env.IS_SERVER_SIDE;

const USER_INFO_REQUEST = {
  mapper: (a: any) => a,
  query: gql`
          {
            userInfo {
              user {
                avatar
                city
                date_of_birth
                firstname
                gender
                inn
                lastname
                middlename
                passport
                snils
                user_id
              }
            }
          }`,
  endPoint: ACCOUNT_END_POINT
}

const mapRequestResponse = (responses: any, requests: any, isSSR: boolean = false) => {
  const result: any = {};
  try {
    for (const propsName in responses) {
      if (responses.hasOwnProperty(propsName)) {
        const response = responses[propsName];
        result[propsName] = {};
        for (const key in response) {
          if (response.hasOwnProperty(key)) {
            if (key === 'data') {
              if (response.data === undefined || response.data === null ) {
                result[propsName].data = [];
              } else {
                const requestName = Object.keys(response[key])[0];
                let newResult = [];
                const {data, ...fields} = responses[propsName].data[requestName];
                if (responses[propsName].data[requestName].data) {
                  if (requests[propsName]) {
                    const {sorters, mapper} = requests[propsName];
                    // query documents not array!
                    // if (!Array.isArray(responses[propsName].data[requestName].data)) {
                    //   newResult = data[Object.keys(data)[0]].map(mapper);
                    // } else {
                      newResult = data.map(mapper);
                    // }

                    if (sorters && sorters.length) {
                      for (let i = 0; i < sorters.length; i++) {
                        newResult.sort(sorters[i]);
                      }
                    }
                  }
                } else {
                  newResult = responses[propsName].data[requestName]
                }


                result[propsName].data = newResult;
                result[propsName] = {...result[propsName], ...fields};
              }
            } else {
              result[propsName][key] = response[key];
            }
          }
        }
      }
    }
  } catch (error) {
    console.log('error', error);
    for (const propsName in requests) {
      if (requests.hasOwnProperty(propsName)) {
        result[propsName] = {data: [], loading: true};
      }
    }
  }

  return result;
};

const gqlRequest = async (data: IGQLRequest | undefined, name: any, result: any) => {
  // @ts-ignore
  const {query} = data;
  const {body} = query.loc.source;
  const operation = 'query';

  result[name] = {data: [], error: undefined, loading: false};

  const success = (response: any) => {
    result[name].data = response.data;
  };

  return request({
    method: 'POST',
    body: JSON.stringify({[operation]: body}),
    headers: { 'Content-Type': 'application/json' },
    success,
    endPoint: data && data.endPoint
  });
};

const defaultApolloData: any = {
  responses: {},
  requests: {}
};

const withApollo = (mapQuery: IMapQuery = () => ({}), setMeta?: (responses: any) => IMetaTags) => (
  Component: any
) => {
  if (isServer) {
    return (props: any) => {
      const serverSideRender = async () => {
        let redirectUrl: string | undefined = undefined;
        const requests: any = mapQuery(props);
        requests.userInfo = USER_INFO_REQUEST;

        const responses: any = {};

        await Promise.all(
          Object.keys(requests).map((a: any) => gqlRequest(requests[a], a, responses))
        );

        const componentProps = {
          ...props,
          ...mapRequestResponse(responses, requests)
        };

        store.dispatch(setAccountUserInfoAction(responses.userInfo.data.userInfo.user))

        if (SSR.redirectCondition) {
          redirectUrl = SSR.redirectCondition(componentProps);
        }

        if (typeof SSR.setRenderComponent === 'function') {
          SSR.setRenderComponent(
            () => <Component {...componentProps} />,
            setMeta && setMeta(componentProps),
            redirectUrl
          );
        }
      };

      serverSideRender();
      return null;
    };
  }

  class ApolloWrapper extends React.Component<any> {
    state = {query: mapQuery};

    shouldComponentUpdate() {
      const render = !this.withoutRerender;
      this.withoutRerender = false;
      return render;
    }

    componentWillUnmount() {
      if (SERVICE === 'MAIL') {
        store.dispatch({type: CLEAR_STATE_MAIL_INDEX_PAGE});
      }
    }

    @bind
    getRequests() {
      const {query} = this.state;
      const requests: any = query(this.props);

      this.apollo.requests = requests;
      return requests;
    }

    @bind
    updateApollo(query: any, withoutRerender?: boolean) {
      if (withoutRerender) this.withoutRerender = true;
      this.apollo = defaultApolloData;
      this.setState({query});
    }

    @bind
    mapResponses() {
      const {responses, requests} = this.apollo;
      return mapRequestResponse(responses, requests);
    }

    renderContent() {
      const {updateApollo} = this;
      const responses: any = this.mapResponses();
      const props: any = {...responses, ...this.props, updateApollo};

      if (setMeta) {
        let condition = true;

        for (const key in responses) {
          if (responses.hasOwnProperty(key)) {
            if (responses[key].loading) condition = false;
          }
        }

        // if (condition) setMetaTags(setMeta(props));
      }

      return <Component {...props} />;
    }

    withoutRerender: boolean = false;

    apollo = defaultApolloData;

    render() {
      const requests = this.getRequests();
      const keys = Object.keys(requests);

      const getQuery = (i = 0) => {
        if (i === keys.length) return this.renderContent();

        const gqlQueryName = keys[i];
        const gqlData = requests[gqlQueryName];
        const gqlQuery = gqlData && gqlData.query;

        return (
          <Query query={gqlQuery} fetchPolicy={gqlQueryName === "comments"  ? "network-only" : "cache-first" } context={{uri: gqlData.endPoint || END_POINT}}>
            {({loading, error, data}: any) => {
              const gqlResponse = {loading, error, data};
              this.apollo.responses[gqlQueryName] = gqlResponse;
              this.apollo.requests = requests;

              return getQuery(i + 1);
            }}
          </Query>
        );
      };

      return getQuery();
    }
  }

  return ApolloWrapper;
};

export default withApollo;
