import { IReduxAction, IGlobalState, IAuthState } from '../../types/redux';
import { IUserInfoFieldsType } from '../../models/types/IUserInfoFieldsType';

export interface IAccountState {
  userInfo?: IUserInfoFieldsType | null;
  transferOrders?: any | null;
}

export const AccountActionType = {
  SET_USER_INFO: 'SET_USER_INFO',
  SET_TRANSFER_ORDERS: 'SET_TRANSFER_ORDERS',
};

export function setAccountUserInfoAction(userInfo?: IUserInfoFieldsType | null) {
  return { type: AccountActionType.SET_USER_INFO, payload: { userInfo } }
}

export function setTransferOrdersAction(transferOrders?: any | null) {
  return { type: AccountActionType.SET_TRANSFER_ORDERS, payload: { transferOrders } }
}

export function selectAccount(state: IGlobalState) {
  return state.account;
}

export function selectAccountUserInfo(state: IGlobalState) {
  return selectAccount(state).userInfo;
}
export function selectTransferOrders(state: IGlobalState) {
  return selectAccount(state).transferOrders;
}

export default function (
  state: any = {},
  action: IReduxAction
): IAccountState {
  switch (action.type) {
    case AccountActionType.SET_USER_INFO:
      return {
        ...state,
        userInfo: action.payload.userInfo,
      };
    case AccountActionType.SET_TRANSFER_ORDERS:
      return {
        ...state,
        transferOrders: action.payload.transferOrders,
      };

    default:
      return state;
  }
}
