import { Props } from './interface';
import React from 'react';

import Typography from '../Typography';
import getTypographyProps from '../../lib/getTypographyProps';

import 'moment/locale/ru';
import './PromoWidgets.scss';
import Button from '../Button';
import { IServiceType } from '../../types/app';

import { ReactComponent as Mail } from '../../images/service/background/mail.svg';
import { ReactComponent as VideoTalks } from '../../images/service/background/videoTalks.svg';
import { ReactComponent as Photo } from '../../images/service/background/photo.svg';

import { ReactComponent as MailServiceIcon } from '../../images/mail-service.svg';
import { ReactComponent as TransferServiceIcon } from '../../images/transfer-service.svg';
import { ReactComponent as PhotoServiceIcon } from '../../images/photo-service.svg';
import { useLocation } from 'react-router';
import { store } from '../../redux/createStore';

const PromoWidgets: React.FC<Props> = (props) => {
  const location = useLocation();

  function getServiceInfo() {
    let headerModify = '';
    let icon = TransferServiceIcon;
    let service = 'Деньги заключенному';
    let buttonName = 'перевод';
    let descriptionService = 'деньги';
    let href = store.getState().globalLinkState.transformLink(
      `https://zt.ru/services/remittance/money-order?utm_source=fsin&utm_medium=knopka&utm_campaign=money-transfer&utm_content=${location.pathname}`,
      store.getState().globalLinkState.utmList,
    );

    switch (props.type) {
      case 'mail':
        headerModify = classNames.headerServiceMail;
        icon = MailServiceIcon;
        service = 'Письмо заключенному';
        buttonName = 'Отправить письмо';
        descriptionService = 'письма';
        href = store.getState().globalLinkState.transformLink(
          `https://zt.ru/services/letter/payments-letter?utm_source=fsin&utm_medium=knopka&utm_campaign=fsinfoto_noviy_servis&utm_content=${location.pathname}`,
          store.getState().globalLinkState.utmList,
        );
        break;
      case 'photo':
        headerModify = classNames.headerServiceFoto;
        icon = PhotoServiceIcon;
        service = 'Фото заключенному';
        buttonName = 'Отправить фото';
        descriptionService = 'фото';
        href = 'https://zt.ru/services/photo-fsin/buy-photo?utm_source=sizoru&utm_medium=redirect&utm_campaign=fsinfoto&utm_content=/foto';
        break;
      case 'transfer':
        headerModify = classNames.headerServiceTransfer;
        icon = TransferServiceIcon;
        service = 'Деньги заключенному';
        buttonName = 'Отправить деньги';
        descriptionService = 'деньги';
        href = store.getState().globalLinkState.transformLink(
          `https://zt.ru/services/remittance/money-order?utm_source=fsin&utm_medium=knopka&utm_campaign=money-transfer&utm_content=${location.pathname}`,
          store.getState().globalLinkState.utmList,
        );
        break;
      default:
        headerModify;
        icon;
        service;
        buttonName;
        descriptionService;
        href;

        break;
    }

    let description = `Отправляйте ${descriptionService} заключенным онлайн`;
    return { headerModify, icon, service, description, buttonName, href };
  }

  function renderBackground() {
    const { type } = props;
    if (type === 'mail' || type === 'photo') {
      const Icon = getIcon(type);
      return (
        <div className={classNames.background}>
          <Icon />
        </div>
      );
    }

    return null;
  }

  function getIcon(type: IServiceType) {
    switch (type) {
      case 'mail':
        return Mail;
      case 'photo':
        return Photo;
      case 'video_talks':
        return VideoTalks;
      default:
        return Mail;
    }
  }

  const classNames = {
    block: 'promo-widget',
    header: 'promo-widget__header',
    headerService: 'promo-widget__header_service',
    headerIcon: 'promo-widget__header_icon',
    header_case: 'promo-widget__header-case',
    title: 'promo-widget__title',
    background: 'promo-widget__background',
    button: 'promo-widget__button',
    headerServiceFoto: 'promo-widget__header_service--photo',
    headerServiceMail: 'promo-widget__header_service--mail',
    headerServiceTransfer: 'promo-widget__header_service--transfer',
    headerServicePress: 'promo-widget__header_service--press',
  };

  function renderHeader() {
    const service = getServiceInfo();
    const headerService = `${classNames.headerService} ${service.headerModify}`;
    const ServiceIcon = service.icon;
    return (
      <div className={classNames.header_case}>
        <ServiceIcon className={classNames.headerIcon} />
        <Typography
          className={classNames.header}
          {...getTypographyProps('heading', 'm', 'dark-text')}
        >

          <Typography
            className={headerService}
            {...getTypographyProps('heading', 'm', 'dark-text')}
            children={service.service}
          />
        </Typography>
      </div>
    );
  }

  return (
    <div className={classNames.block + ` ${classNames.block}--${props.type}`}>
      {renderBackground()}

      {renderHeader()}
      <Typography
        as='p'
        {...getTypographyProps('heading', 'xl', 'dark-text')}
        children={getServiceInfo().description}
        className={classNames.title}
      />
      {props.type === 'transfer' && (
        <Typography
          {...getTypographyProps('text', 's', 'red', 'medium')}
          children='Самая низкая комиссия на денежные переводы'
          className='typography_promo'
        />
      )}
      <Button
        className={classNames.button}
        isExternal
        isGreen={props.type === 'transfer'}
        label={getServiceInfo().buttonName}
        to={getServiceInfo().href}
      />
    </div>
  );
};

export default PromoWidgets;
