import gql from 'graphql-tag';
import { IGQLRequest } from '../../types/app';
import { mapScheduleMonth } from '../mappers';
import { QUEUE_END_POINT } from '../../lib/request/config';
import moment from 'moment';
import 'moment/locale/ru';

interface IScheduleController {
  getScheduleMonth: (
    institutionId: string,
    serviceType: string,
    momentMonth?: any | null,
    userId?: string | null
  ) => IGQLRequest;
  getTimeSlots: (
    serviceType: 'visit' | 'package',
    companyId: string,
    startTime: string,
    endTime: string,
    userId: string
  ) => IGQLRequest;
}

class Schedule {
  static getScheduleMonth(
    institutionId: string,
    serviceType: string,
    momentMonth: any | null = null,
    userId: string | null = null
  ) {
    let month = momentMonth || parseInt(moment(new Date(), 'M').format('M'));
    let extraData = userId ? `, user: ${JSON.stringify(userId)}` : '';

    return {
      mapper: mapScheduleMonth,
      query: gql`
            {
                GetScheduleMonth(month: ${month}, company: "${institutionId}", serviceType: "${serviceType}"${extraData}) {
                    result {
                        day
                        slots {
                            status
                            time
                        }
                    }
                    total
                }
            },
            `,
      endPoint: QUEUE_END_POINT,
    };
  }

  static getTimeSlots(
    serviceType: 'visit' | 'package',
    companyId: string,
    startTime: string,
    endTime: string,
    userId: string
  ) {
    return {
      mapper: mapScheduleMonth,
      query: gql`
            {
                time_slots: v_free_time_slots(
                    where: {
                      company_id: {eq: "${companyId}"}
                      service_type: { eq: "${serviceType}" }, 
                      start_time: { gte: "${startTime}" },
                      end_time:  { lte: "${endTime}" },
                      },
                      order_by: {start_time: asc},
                      limit: 1000
                   ) {
                    company_id
                    available_rooms
                    service_type
                    rooms
                    start_time
                    end_time
                }
                schedule(
                    where: {
                        company_id: {eq: "${companyId}"}
                        service_type: { eq: "${serviceType}" }
                        start_time: { gte: "${startTime}" }
                        end_time: { lte: "${endTime}" }
                        user_id: {eq: "${userId}"}
                    }
                ) {
                    user_id
                    start_time
                    end_time
                }
                relation_degree {
                  name
                  id
                }
            },
            `,
      endPoint: QUEUE_END_POINT,
    };
  }
}

export default Schedule as IScheduleController;
