/* eslint-disable no-shadow */
import Routes from './config/routes';
import React, { Component } from 'react';
import { Props, State } from './interface';
import {Switch, Route, RouteComponentProps, Redirect} from 'react-router-dom';
import { selectAccountUserInfo } from '../../redux/reducers/accountReducer';
import { IGlobalState } from '../../types/redux';
import { connect } from 'react-redux';

interface AppRouteComponentProps extends Props{
  userInfo: any
}

class AppRouteComponent extends Component<AppRouteComponentProps, State> {
  state ={
    prevLocation: ''
  }

  shouldComponentUpdate(nextProps: Props) {
    if (nextProps.location !== this.props.location) {
      this.setState({prevLocation: this.props.location})
      if (nextProps.location.pathname !== this.props.location.pathname) {
        this.scrollToBegin();
      }
    }

    return true;
  }

  scrollToBegin() {
    window.scrollTo(0, 0);
  }


  renderComponent(Component: any, path: string, userInfo: any) {
    // if (path != "/login" && !userInfo && path.indexOf(getPathnameModel(MainRoutes.accountPage.path)) !== -1) {
    //   return (props: RouteComponentProps) => <Routes.loginPage.component {...props} />;
    // }
    return (props: RouteComponentProps) => <Component {...props} prevLocation={this.state.prevLocation} />;
    
  }

  render() {
    const result = [];
    const { userInfo } = this.props

    // if (userInfo === null && this.props.location.pathname != "/login") {
    //   return <p style={{fontSize: '100px'}}>Loading...</p>;
    // } else {
      for (const key in Routes) {
        if (Routes.hasOwnProperty(key)) {
          // @ts-ignore
          const route = Routes[key];

          result.push(
            <Route
              key={key}
              exact
              path={route.path}
              render={this.renderComponent(
                route.component,
                route.path.toString(),
                userInfo
              )}
            />
          );
        }
      // }
    }

    return <Switch>{result}</Switch>;
  }
}


function mapState(state: IGlobalState) {
  return {
    userInfo: selectAccountUserInfo(state)
  }
}

const AppRoute = connect(mapState)(AppRouteComponent);


export default AppRoute;
