export const startRegistration = () => reachGoal('start-registration');
export const failRegistration = () => reachGoal('unsuccessful-registration');
export const successRegistration = () => reachGoal('successful-registration');

export const startPasswordRecovery = () => reachGoal('start-password-recovery');
export const successfulPasswordRecovery = () => reachGoal('successful-password-recovery');
export const unsuccessfulPasswordRecovery = () => reachGoal('unsuccessful-password-recovery');

export const startAuthorization = () => reachGoal('start-authorization');
export const successfulAuthorization = () => reachGoal('successful-authorization');
export const unsuccessfulAuthorization = () => reachGoal('unsuccessful-authorization');

export const startTransferOrder = () => reachGoal('start-transfer-order');
export const successfulTransferOrder = () => reachGoal('successful-transfer-order');
export const unsuccessfulTransferOrder = () => reachGoal('unsuccessful-transfer-order');

export const startFeedbackForm = () => reachGoal('start-feedback-form');
export const successfulFeedbackForm = () => reachGoal('successful-feedback-form');
export const unsuccessfulFeedbackForm = () => reachGoal('unsuccessful-feedback-form');

export const documentDownloadDataLayer = () => reachGoal('document-download');

export const queueTab1 = () => reachGoal('queue-step-1')
export const queueTab2 = () => reachGoal('queue-step-2')
export const queueTab3 = () => reachGoal('queue-step-3')
export const queueTab4 = () => reachGoal('queue-step-4')
export const queueTab5 = () => reachGoal('queue-step-5')
export const queueTab6 = () => reachGoal('queue-step-6')
export const queueTabSuccess = () => reachGoal('queue-success')

export const startFotoOrder = () => reachGoal('start-photo-order')
export const successfulPhotoOrder = () => reachGoal('successful-photo-order')
export const unsuccessfulPhotoOrder = () => reachGoal('unsuccessful-photo-order')

export const startMailOrder = () => reachGoal('start-mail-order');
export const successfulMailOrder = () => reachGoal('successful-mail-order');
export const unsuccessfulMailOrder = () => reachGoal('unsuccessful-mail-order');

function reachGoal(targetName: string) {
  if (global.window) {
      if(global.window.hasOwnProperty('dataLayer')){
        const {dataLayer} = global.window as any
        dataLayer.push({'event':targetName});
      }
  }
}
