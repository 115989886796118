import React, { Component } from 'react';
import Regions from '../../../gql/actions/Regions';
import Relations from '../../../gql/actions/Relations';
import { selectAccountUserInfo } from '../../../redux/reducers/accountReducer';
import { selectContacts } from '../../../redux/reducers/contactReducer';
import { IGlobalFotoState } from '../../../types/redux';
import { Props } from './interface';
import withApollo from '../../../lib/HOC/withApollo';
import Institution from '../../../gql/actions/Institution';
import { store } from '../../../redux/createStore';
import { connect } from 'react-redux';
import Page from '../../../components/Page';
import Wrapper from '../../../components/Wrapper';
import { SET_STATE_FOTO_INDEX_PAGE } from '../../../redux/actionsList';
import { loadContactsAction } from '../../../redux/models/ContactModel';
import { SendingTargetForm } from '../../../components/Sending/SendingTargetForm/SendingTargetForm';
import { SendingSenderForm } from '../../../components/Sending/SendingSenderForm/SendingSenderForm';
import bind from '../../../lib/decorators/bind';
import Form, { FormElement } from '../../../components/Form';
import Notification from '../../../components/Notification';
import { ISelectItem } from '../../../types/app';
import './IndexPage.scss';
import Typography from '../../../components/Typography';
import getTypographyProps from '../../../lib/getTypographyProps';
import Service from '../../../components/Service';
import { links } from '../../../lib/getServiceType';
import Button from '../../../components/Button';
import DropZone from '../../../components/DropZone';
import Foto from '../../../gql/actions/Foto';
import { FOTO_UPLOAD_END_POINT } from '../../../lib/request/config';
import { createFotoOrder } from '../../../gql/actions/Mutations';
import {
  getPathnameModel,
  MainRoutes,
} from '../../../components/Router/config/routes';
import Link from '../../../components/Link';
import Modal from '../../../components/ModalCustom';
import getLocalStorage from '../../../lib/storage/getLocalStorage';
import {
  successfulPhotoOrder,
  unsuccessfulPhotoOrder,
} from '../../../lib/dataLayerEvents';
import MetaTags from '../../../components/MetaTags';

class IndexPage extends Component<Props> {
  componentDidMount() {
    this.setState({
      senderEmail: getLocalStorage('email') || this.props.senderEmail,
      senderLastName: getLocalStorage('lastName') || this.props.senderLastName,
      senderFirstName:
        getLocalStorage('firstName') || this.props.senderFirstName,
      senderMiddleName:
        getLocalStorage('middleName') || this.props.senderMiddleName,
      senderPhone: getLocalStorage('phone') || this.props.senderPhone,
    });
    store.dispatch(loadContactsAction());
  }

  @bind
  handleChange(name: string) {
    return (inputName: string, val: string | boolean) => {
      if (name === 'birthDay')
        val = (val as string)
          .slice(0, 4)
          .replace(/[^\d;]/g, '')
          .replace(';', '');

      if (
        [
          'senderLastName',
          'senderFirstName',
          'senderMiddleName',
          'relation',
          'lastName',
          'firstName',
          'middleName',
        ].includes(name)
      ) {
        val = (val as string).replace(/\d/g, '');
      }

      if (name === 'textMessage') {
        val = (val as string).substr(0, 2000);
      }

      // @ts-ignore
      const state: State = { [name]: val };

      this.setState(state);
    };
  }

  sendOrder() {
    this.setState({ isLoadingOrder: true });
    const returnUrl = window.location.origin;

    const {
      birthDay,
      firstName,
      lastName,
      middleName,
      senderEmail,
      senderFirstName,
      senderLastName,
      senderMiddleName,
      senderPhone,
      uploadId,
      textMessage,
      activeInstitution,
    } = this.props;

    const order = {
      uploadId,
      returnUrl,
      // @ts-ignore
      colonyId: activeInstitution.value as string,
      text: textMessage.replace(/[\n\r]/g, ' ').replace(/["\\]/g, ''),
      sender: {
        firstName: senderFirstName,
        lastName: senderLastName,
        middleName: senderMiddleName,
        email: senderEmail,
        phone: senderPhone,
      },
      recipient: {
        firstName,
        lastName,
        middleName,
        birthDate: parseInt(birthDay),
      },
    };

    createFotoOrder(order, (response: any) => {
      try {
        this.setState({ isLoadingOrder: false });
        const { confirmationUrl } = response.data.create;
        const link = document.createElement('a');
        link.href = confirmationUrl;
        successfulPhotoOrder();
        link.click();
        link.remove();
      } catch (error) {
        try {
          console.log(response);
          Notification.show('Произошла ошибка при отправке заказа');
          unsuccessfulPhotoOrder();
        } catch (e) {
          console.log(e);
          Notification.show('Произошла ошибка при отправке заказа');
          unsuccessfulPhotoOrder();
        }
      }
    });
  }

  @bind
  onSubmit() {
    this.setState({ isLoadingOrder: true });
    const { images } = this.props;
    var formData = new FormData();
    for (let i = 0; i < images.length; i++) {
      formData.append('files[]', images[i], images[i].name);
    }

    fetch(FOTO_UPLOAD_END_POINT, {
      method: 'POST',
      body: formData,
    })
      .then((a) => a.json())
      .then((response) => {
        this.saveStorageData();
        this.setState({ isLoadingOrder: false });
        if (response.uploadId) {
          this.setState({ uploadId: response.uploadId });
          this.sendOrder();
        } else {
          Notification.show(
            'Возникла проблема при загрузке фото на сервер. Обратитесь в поддержку.'
          );
          unsuccessfulPhotoOrder();
        }
      })
      .catch((e) => {
        Notification.show(
          'Возникла проблема при загрузке фото. Обратитесь в поддержку.'
        );
        this.setState({ isLoadingOrder: false });
        unsuccessfulPhotoOrder();
      });
  }

  @bind
  getActiveInstitution() {
    if (
      this.props.match.params.id &&
      this.props.institution &&
      this.props.institution.data &&
      this.props.institution.data.length
    ) {
      return this.props.institution.data[0].id;
    }

    if (this.props.activeInstitution && this.props.activeInstitution.value) {
      return this.props.activeInstitution.value;
    }

    return '';
  }

  @bind
  onChangeInstitution(activeInstitution: ISelectItem) {
    this.setState({ activeInstitution });
  }

  @bind
  validateSelects() {
    const activeInstitution = this.getActiveInstitution();
    const selectGroup = document.getElementById('select-group');

    if (!activeInstitution) {
      if (selectGroup) selectGroup.scrollIntoView({ block: 'center' });
      this.setState({ regionError: true });
      this.setState({ institutionError: true });
      return;
    } else {
      this.setState({ regionError: false });
      this.setState({ institutionError: false });
    }
  }

  @bind
  additionalValidation() {
    if (this.props.numberOfImages === 0) {
      this.setState({ imagesError: true });
      let dropzone = document.getElementById('dropzone');
      // @ts-ignore
      if(dropzone)dropzone.scrollIntoView({ block: 'center', behavior: 'smooth' });
      return false;
    } else {
      this.setState({ imagesError: false });
      return true;
    }
  }

  updateState = (state: any) => {
    this.setState(state);
  };

  @bind
  refInput(name: string) {
    return (ref: any) => {
      this.inputRefs[name] = ref;
    };
  }

  inputRefs: any = {};

  setState(response: any) {
    store.dispatch({
      response,
      type: SET_STATE_FOTO_INDEX_PAGE,
    });
  }

  classNames = {
    content: 'page__content',
    sidebar: 'page__sidebar',
    form: 'page__sidebar-form',
    services: 'page__sidebar-services',
    tariff: 'page__sidebar-tariff',
    tariffCost: 'page__sidebar-tariff-cost',
    activeCost: 'page__sidebar-tariff-active',
    notActiveCost: 'page__sidebar-tariff-not-active',
    totalSum: 'page__sidebar-tariff-total-sum',
  };

  renderListOfTariffs() {
    const { tariffsImg, tariffsText, numberOfImages, textMessage } = this.props;
    let highligthText =
      textMessage.length > 0
        ? this.classNames.activeCost
        : this.classNames.notActiveCost;
    return (
      <>
        {tariffsImg.data.map((item: any, index: any) => {
          let isActive = numberOfImages > 0;
          let highlightClass =
            numberOfImages >= item.min && numberOfImages <= item.max
              ? this.classNames.activeCost
              : this.classNames.notActiveCost;
          return (
            <div
              className={
                this.classNames.tariff + ' ' + (isActive ? highlightClass : '')
              }
              key={index}
            >
              <Typography
                children={item.name}
                {...getTypographyProps('text', 'm', 'dark-text')}
              />
              <div className={this.classNames.tariffCost}>
                {item.oldCost && (
                  <Typography
                    children={item.oldCost + ' \u20BD'}
                    {...getTypographyProps('text', 's', 'light-text')}
                    className='line-trough'
                  />
                )}
                <Typography
                  children={item.cost + ' \u20BD'}
                  {...getTypographyProps('text', 'm', 'text')}
                />
              </div>
            </div>
          );
        })}
        <div
          className={
            this.classNames.tariff +
            ' ' +
            (numberOfImages > 0 ? highligthText : '')
          }
        >
          <Typography
            children={tariffsText.data[0].name}
            {...getTypographyProps('text', 'm', 'text')}
          />
          <div className={this.classNames.tariffCost}>
            <Typography
              children={tariffsText.data[0].cost + ' \u20BD'}
              {...getTypographyProps('text', 'm', 'text')}
            />
          </div>
        </div>
      </>
    );
  }

  renderSideBar() {
    const policyLink = getPathnameModel(MainRoutes.customPage.path).replace(
      ':slug',
      'privacypolicy'
    );
    const { tariffsText, tariffsImg, numberOfImages, textMessage } = this.props;
    let imgCost: number = 0;
    for (let i = 0; i < 3; i++) {
      if (
        numberOfImages >= tariffsImg.data[i].min &&
        numberOfImages <= tariffsImg.data[i].max
      ) {
        imgCost = tariffsImg.data[i].cost;
      }
    }
    let totalSum = imgCost;
    if (textMessage.length > 0) {
      totalSum += tariffsText.data[0].cost;
    }

    return (
      <div className='fixed-sidebar'>
        <div className={this.classNames.sidebar}>
          <div className={this.classNames.form}>
            <Typography
              isDivElement
              {...getTypographyProps('heading', 'l', 'dark-text', 'medium')}
              children='Итого'
            />

            <div>
              <Typography
                children='Тариф зависит от количества добавленных фотографий, чем больше фотографий, тем выгодней цена.'
                {...getTypographyProps('text', 'xs', 'text')}
              />
            </div>

            <div>
              {!this.props.tariffsImg.loading &&
                !this.props.tariffsText.loading &&
                this.renderListOfTariffs()}
            </div>

            <div className={this.classNames.totalSum}>
              <Typography
                children='Итого к оплате:'
                {...getTypographyProps('text', 'l', 'text')}
              />
              <div className={this.classNames.tariffCost}>
                <Typography
                  children={totalSum + ' \u20BD'}
                  {...getTypographyProps('text', 'xl', 'main', 'medium')}
                />
              </div>
            </div>

            <Button
              isButtonTag
              isColored
              isBlue
              isLoading={this.props.isLoadingOrder}
              label='Оплатить'
              dataTestId="form_submit"
            />

            <Typography {...getTypographyProps('text', 'xs', 'text')}>
              Нажимая кнопку "Отправить" вы соглашаетесь с{' '}
              <Link
                children='условиями использования'
                isColored
                isExternal
                to={policyLink}
              />{' '}
              и{' '}
              <Typography
                {...getTypographyProps('text', 'xs', 'main')}
                isSpanElement
                children='условиями и ценами оказываемых услуг'
                style={{ cursor: 'pointer' }}
                onClick={() => this.setState({ isOpenModal: true })}
              />{' '}
              сервиса
            </Typography>
          </div>
          <div className={this.classNames.services}>
            <Service forceLink={links.MONEY_FSIN} isShort type='transfer' />
            <Service isShort type='mail' />
          </div>
        </div>
      </div>
    );
  }

  @bind
  phoneChange(name: string, senderPhone: string, data: any) {
    this.setState({
      senderPhone,
    });
  }

  @bind
  onChangeImages(images: string[]) {
    this.setState({ numberOfImages: images.length });
    this.setState({ images });
    if (this.props.numberOfImages === 0) {
      this.setState({ imagesError: true });
    } else {
      this.setState({ imagesError: false });
    }
  }

  renderDropzone() {
    const { tariffsImg, numberOfImages } = this.props;
    let fotoCost: number = 0;
    for (let i = 0; i < 3; i++) {
      if (
        numberOfImages >= tariffsImg.data[i].min &&
        numberOfImages <= tariffsImg.data[i].max
      ) {
        fotoCost = tariffsImg.data[i].cost;
      }
    }

    return (
      <div>
        <div className='title-block'>
          <div>
            <Typography
              {...getTypographyProps('heading', 'l', 'dark-text', 'medium')}
              children='Фотографии'
            />
          </div>
          <div className='row'>
            <Typography
              {...getTypographyProps('text', 'm', 'light-text')}
              children={`${numberOfImages} фото:`}
            />
            <span>
              <Typography
                {...getTypographyProps('text', 'l', 'main', 'medium')}
                children={`${fotoCost} \u20BD`}
              />
            </span>
          </div>
        </div>
        <DropZone
          isError={this.props.imagesError}
          type='foto'
          maxImages={10}
          maxImageSizeMB={10}
          onChange={this.onChangeImages}
        />
      </div>
    );
  }

  renderMail() {
    const { textMessage, numberOfImages, tariffsText } = this.props;
    const textCost = textMessage.length > 0 ? tariffsText.data[0].cost : 0;
    return (
      <div className={numberOfImages === 0 ? 'disabled' : ''}>
        <div className='title-block'>
          <Typography
            {...getTypographyProps('heading', 'l', 'dark-text', 'medium')}
            children='Сообщение'
          />
          <div className='row'>
            <Typography
              {...getTypographyProps('text', 'm', 'light-text')}
              children='Стоимость:'
            />
            <span>
              <Typography
                {...getTypographyProps('text', 'l', 'main', 'medium')}
                children={`${textCost} \u20BD`}
              />
            </span>
          </div>
        </div>

        <FormElement
          name='textMessage'
          onChange={this.handleChange('textMessage')}
          type='textarea'
          value={textMessage}
          onRef={this.refInput('textMessage')}
          disabled={numberOfImages === 0}
        />

        <div className='counter'>
          <Typography
            {...getTypographyProps('text', 'm', 'text')}
            children={
              <span>
                Осталось символов:{' '}
                <span>
                  {tariffsText.loading
                    ? ''
                    : tariffsText.data[0].max - textMessage.length}
                </span>
              </span>
            }
          />
        </div>
      </div>
    );
  }

  @bind
  openModal() {
    this.setState({ isOpenModal: !this.props.isOpenModal });
  }

  @bind
  saveStorageData() {
    const {
      senderFirstName,
      senderLastName,
      senderMiddleName,
      senderPhone,
      senderEmail,
    } = this.props;

    if (this.props.isDataSave) {
      localStorage.setItem('email', senderEmail);
      localStorage.setItem('lastName', senderLastName);
      localStorage.setItem('firstName', senderFirstName);
      localStorage.setItem('middleName', senderMiddleName);
      localStorage.setItem('phone', senderPhone);
    }
  }

  setMeta() {
    return (
      <MetaTags
        title='Сервис Фото'
        description='Печать цифровых фотографий на качественной фотобумаге и отправка подследственным и заключенном в СИЗО, колонии России через Интернет. Отправить фотографию заключенному через Интернет.'
      />
    );
  }

  render() {
    const { userInfo, isOpenModal } = this.props;
    return (
      <Page page='index-foto'>
        {this.setMeta()}
        <Wrapper isPageWrapper isRow>
          <Form
            id='sticky-container'
            onSubmit={this.onSubmit}
            refs={this.inputRefs}
            validateSelects={this.validateSelects}
            additionalValidation={this.additionalValidation}
          >
            <div className={this.classNames.content}>
              <SendingTargetForm
                type='foto'
                {...this.props}
                refInput={this.refInput}
                inputRefs={this.inputRefs}
                updateState={this.updateState}
                handleChange={this.handleChange}
                dataLayerName='foto'
              />

              <SendingSenderForm
                mode='mail'
                {...this.props}
                refInput={this.refInput}
                inputRefs={this.inputRefs}
                updateState={this.updateState}
                handleChange={this.handleChange}
                phoneChange={this.phoneChange}
                userInfo={userInfo}
                dataLayerName='foto'
              />

              {!this.props.tariffsImg.loading &&
                !this.props.tariffsText.loading &&
                this.renderDropzone()}
              {!this.props.tariffsImg.loading &&
                !this.props.tariffsText.loading &&
                this.renderMail()}
            </div>
            {!this.props.tariffsImg.loading &&
              !this.props.tariffsText.loading &&
              this.renderSideBar()}
          </Form>
          <Modal isOpen={isOpenModal} setOpenModal={this.openModal} userRules />
        </Wrapper>
      </Page>
    );
  }
}

function mapQuery(props: Props) {
  const { slug } = props.match.params;

  if (slug) {
    return {
      institution: Institution.getBySlug(slug),
    };
  }

  return {
    regions: Regions.getFotoRegions(),
    relations: Relations.getRelations(),
    tariffsImg: Foto.getTariffs('img'),
    tariffsText: Foto.getTariffs('text'),
  };
}

function mapState(state: IGlobalFotoState) {
  return {
    ...state.fotoPageState,
    userInfo: selectAccountUserInfo(state),
    contacts: selectContacts(state),
  };
}

const IndexPageWithRedux = connect(mapState)(IndexPage);

export default withApollo(mapQuery, undefined)(IndexPageWithRedux);
