/* eslint-disable import/no-mutable-exports */
export const BASE_URL = process.env.BASE_URL as string;
export const END_POINT = process.env.REACT_APP_API as string
export const ACCOUNT_END_POINT = process.env.REACT_APP_ACCOUNT_API as string;
export const COMMENTS_END_POINT = process.env.REACT_APP_COMMENTS_API as string;
export const QUEUE_END_POINT = process.env.REACT_APP_QUEUE_API as string;
export const FOTO_HTTP_END_POINT = process.env.REACT_APP_FOTO_API as string;
export const FOTO_UPLOAD_END_POINT = process.env.REACT_APP_FOTO_UPLOAD_API as string;

// export const BASE_URL = "http://dev.ui.fsin.ru/api/graphql" as string;
// export const END_POINT = "http://dev.ui.fsin.ru/api/graphql" as string
// export const ACCOUNT_END_POINT = "http://dev.ui.fsin.ru/api/account/graphql" as string;
// export const COMMENTS_END_POINT = "http://test-ui.fsin.ru/api/comments" as string;
// export const QUEUE_END_POINT = "http://services.dev.svc.team/fsin-queue/graphjin" as string;
// export const FOTO_HTTP_END_POINT = "http://test-ui.fsin.ru/api/foto" as string;
// export const FOTO_UPLOAD_END_POINT = "http://test-ui.fsin.ru/api/foto/store" as string;

const getServers = () => {
  return END_POINT;
};
export default getServers();
