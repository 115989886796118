const getLocalStorage = (name: string) => {
  try {
    if (localStorage) {
      return localStorage.getItem(name);
    }
  } catch {
    return false;
  }
};

export default getLocalStorage;
