import React from 'react';
import { Props } from './interface';
import bind from '../../lib/decorators/bind';

import Typography from '../Typography';
import getTypographyProps from '../../lib/getTypographyProps';
import './Tag.scss';

class Institution extends React.Component<Props> {
  @bind
  getClassName() {
    let className = this.classNames.main;
    if (this.props.className) className += ` ${this.props.className}`;
    if (this.props.isPreload) className += ` ${this.classNames.isPreload}`;
    
    return className;
  }

  renderPreload() {
    return <div className={this.getClassName()} />;
  }
  
  classNames = {
    main: 'tag',
    isPreload: 'tag_preload'
  };

  render() {
    const { isPreload } = this.props;
    if (isPreload) return this.renderPreload();

    return (
      <Typography
        {...getTypographyProps('text', 's', 'main')}
        children={this.props.children}
        className={this.getClassName()}
        isUppercase
      />
    );
  }
}

export default Institution;
