import gql from 'graphql-tag';
import {mapDocumentsData} from '../mappers';

class Documents {
  static get() {
    return {
      mapper: mapDocumentsData,
      query: gql`
      {
        documents{
          data{
            categories {
              category_id
              name
               groups{
                 name
                documents{
                  name
                  document_id
                  files{
                    file_id
                    ext
                  }
                }
              }
            }
          }
        }
      }`
    };
  }
}


export default Documents;
