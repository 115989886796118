import React, { Component } from 'react';
import Typography from '../../../../../components/Typography';
import getTypographyProps from '../../../../../lib/getTypographyProps';
import Form, { FormGroup, FormElement } from '../../../../../components/Form';
import './style.scss';
import Button from '../../../../../components/Button';
import Select from '../../../../../components/Select';
import { State } from '../../IndexPage';
import bind from '../../../../../lib/decorators/bind';
import { ISelectItem } from '../../../../../types/app';

interface Props {
  onClick: CallableFunction;
  handleChange(name: string): any;
  refInput(name: string): any;
  userInfo: any;
  fullState: State;
  inputRefs: any;
  phoneChange: CallableFunction;
  relationsList: ISelectItem[];
}

type CurrentState = {
  isError: boolean;
  isErrorRelation: boolean;
};

class FormFinal extends Component<Props> {
  state: CurrentState = {
    isError: false,
    isErrorRelation: false,
  };

  onSubmit() {
    this.props.onClick();
  }

  isError(): boolean {
    const {
      birthDay,
      firstName,
      lastName,
      middleName,
      passportNumber,
      passportSeries,
      senderEmail,
      senderFirstName,
      senderLastName,
      senderMiddleName,
      senderPhone,
      relationship,
    } = this.props.fullState;
    return !Boolean(
      birthDay &&
        firstName &&
        lastName &&
        middleName &&
        passportNumber &&
        passportSeries &&
        senderEmail &&
        senderFirstName &&
        senderLastName &&
        senderMiddleName &&
        senderPhone &&
        relationship
    );
  }

  @bind
  validateSelects() {
    const activeRelationship = this.props?.fullState?.relationship;
    const selectGroup = document.getElementById('select_group');

    if (!activeRelationship) {
      if (selectGroup) selectGroup.scrollIntoView({ block: 'center' });
      this.setState({ isErrorRelation: true });
      return;
    } else {
      this.setState({ isErrorRelation: false });
    }
  }

  render() {
    const {
      handleChange,
      refInput,
      phoneChange,
      inputRefs,
      userInfo,
      fullState,
    } = this.props;

    return (
      <Form
        validateSelects={this.validateSelects}
        refs={inputRefs}
        onSubmit={() => {
          if (this.isError()) {
            this.setState({ isError: true });
          } else {
            this.setState({ isError: false });
            this.onSubmit();
          }
        }}
      >
        <div className='page__content form first__form'>
          {this.state.isError && (
            <Typography type='text' size='s' color='red'>
              * Заполните все поля
            </Typography>
          )}
          <Typography
            {...getTypographyProps('heading', 'l', 'dark-text', 'medium')}
            children={fullState.selectedInstitution.serviceType === 'package' ? 'Получатель' : 'Посещаемый'}
          />
          <FormGroup>
            <FormElement
              name='lastName'
              onChange={handleChange('lastName')}
              onRef={refInput('lastName')}
              placeholder='Фамилия'
              validations={['required', 'cyrillic', 'noDash']}
              value={fullState.lastName}
              isDynamicPlaceholder={true}
              isDynamic
            />
            <FormElement
              name='firstName'
              onChange={handleChange('firstName')}
              onRef={refInput('firstName')}
              placeholder='Имя'
              validations={['required', 'cyrillic', 'noDash']}
              value={fullState.firstName}
              isDynamicPlaceholder={true}
              isDynamic
            />
          </FormGroup>
          <FormGroup>
            <FormElement
              name='middleName'
              onChange={handleChange('middleName')}
              onRef={refInput('middleName')}
              placeholder='Отчество'
              validations={['required', 'cyrillic', 'noDash']}
              value={fullState.middleName}
              isDynamicPlaceholder={true}
              isDynamic
            />
            <FormElement
              name='birthDay'
              onChange={handleChange('birthDay')}
              onRef={refInput('birthDay')}
              placeholder='Год рождения'
              validations={['required', 'birthYear']}
              value={fullState.birthDay}
              isDynamicPlaceholder={true}
              isDynamic
            />
          </FormGroup>
          <FormGroup id='select_group'>
            <Select
              items={this.props.fullState.relationsList}
              blurInputOnSelect={true}
              onChange={(item) => {
                handleChange('relationship')('relationship', item);
              }}
              ref={refInput('relationship')}
              name='relationship'
              placeholder='Степень родства'
              canShowValidation={
                this.props.fullState.relationship
                  ? false
                  : this.state.isErrorRelation
              }
              errorMessage='Выберите степень родства'
              value={fullState.relationship}
            />
          </FormGroup>
        </div>

        <div className='page__content form'>
          <Typography
            {...getTypographyProps('heading', 'l', 'dark-text', 'medium')}
            children={fullState.selectedInstitution.serviceType === 'package' ? 'Отправитель' : 'Посетитель'}
          />
          <FormGroup>
            <FormElement
              name='senderLastName'
              onChange={handleChange('senderLastName')}
              onRef={refInput('senderLastName')}
              placeholder='Фамилия'
              validations={['required', 'cyrillic', 'noDash']}
              value={fullState.senderLastName}
              isDynamicPlaceholder={true}
              isDynamic
            />
            <FormElement
              name='senderFirstName'
              onChange={handleChange('senderFirstName')}
              onRef={refInput('senderFirstName')}
              placeholder='Имя'
              validations={['required', 'cyrillic', 'noDash']}
              value={fullState.senderFirstName}
              isDynamicPlaceholder={true}
              isDynamic
            />
          </FormGroup>
          <FormGroup>
            <FormElement
              name='senderMiddleName'
              onChange={handleChange('senderMiddleName')}
              onRef={refInput('senderMiddleName')}
              placeholder='Отчество'
              validations={['required', 'cyrillic', 'noDash']}
              value={fullState.senderMiddleName}
              isDynamicPlaceholder={true}
              isDynamic
            />
            <FormElement
              name='visitorYearOfBirth'
              onChange={handleChange('visitorYearOfBirth')}
              onRef={refInput('visitorYearOfBirth')}
              placeholder='Год рождения'
              minLength={6}
              validations={['required', 'year']}
              value={fullState.visitorYearOfBirth}
              isDynamicPlaceholder={true}
              isDynamic
            />
          </FormGroup>
          <Typography
            {...getTypographyProps('text', 'm', 'dark-text', 'medium')}
            children='Введите данные паспорта'
          />
          <FormGroup>
            <div className='form__element form_group-items'>
              <FormElement
                name='passportSeries'
                onChange={handleChange('passportSeries')}
                onRef={refInput('passportSeries')}
                placeholder='Серия'
                minLength={4}
                validations={['required', 'min']}
                value={fullState.passportSeries}
                isDynamicPlaceholder={true}
                isDynamic
              />
              <FormElement
                name='passportNumber'
                onChange={handleChange('passportNumber')}
                onRef={refInput('passportNumber')}
                placeholder='Номер'
                minLength={6}
                validations={['required', 'min']}
                value={fullState.passportNumber}
                isDynamicPlaceholder={true}
                isDynamic
              />
            </div>
            <FormElement
              name='visitorPassportIssuedDate'
              onChange={handleChange('visitorPassportIssuedDate')}
              onRef={refInput('visitorPassportIssuedDate')}
              placeholder='Дата выдачи'
              minLength={1}
              type='date'
              validations={['required']}
              value={fullState.visitorPassportIssuedDate}
              isDynamicPlaceholder={true}
              isDynamic
            />
          </FormGroup>
          <FormGroup>
            <FormElement
              name='visitorPassportIssuedBy'
              onChange={handleChange('visitorPassportIssuedBy')}
              onRef={refInput('visitorPassportIssuedBy')}
              placeholder='Кем выдан'
              minLength={4}
              validations={['required', 'min']}
              value={fullState.visitorPassportIssuedBy}
              isDynamicPlaceholder={true}
              isDynamic
            />
            <FormElement
              name='visitorRegistration'
              onChange={handleChange('visitorRegistration')}
              onRef={refInput('visitorRegistration')}
              placeholder='Место регистрации/проживания'
              minLength={4}
              validations={['required']}
              value={fullState.visitorRegistration}
              isDynamicPlaceholder={true}
              isDynamic
            />
          </FormGroup>
          <Typography
            {...getTypographyProps('text', 'm', 'dark-text', 'medium')}
            children='Введите телефоне и email'
          />
          <FormGroup>
            <FormElement
              country='ru'
              name='senderPhone'
              // @ts-ignore
              onChange={phoneChange}
              onRef={refInput('senderPhone')}
              preferredCountries={['ru']}
              value={fullState.senderPhone}
              withContyPhones
              validations={['required', 'phone']}
              className='error_start'
              // @ts-ignore
              specialLabel={false}
              isDynamic
              isDynamicPlaceholder
            />
            <FormElement
              name='senderEmail'
              onChange={handleChange('senderEmail')}
              onRef={refInput('senderEmail')}
              placeholder='Почта'
              validations={['email']}
              value={fullState.senderEmail}
              isDynamicPlaceholder={true}
              isDynamic
            />
          </FormGroup>
          <Button isButtonTag isColored label='Записаться' />
        </div>
      </Form>
    );
  }
}

export default FormFinal;
