/* eslint-disable react/jsx-no-bind */
import ReactDOM from 'react-dom';
import React, { Component } from 'react';
import bind from '../../lib/decorators/bind';
import { IObjectAnyType } from '../../types/app';
import { ModalProps, ModalState } from './interface';

import Svg from '../Svg';
import close from './images/modal-close.svg';

import './Modal.scss';

class Modal extends Component<ModalProps, ModalState> {
  state: ModalState = {
    isShow: false,
    isVisible: false
  };

  componentDidMount() {
    const { onRef } = this.props;

    if (onRef) onRef(this);
    window.addEventListener('keydown', this.onPressedKey.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.onPressedKey.bind(this));
  }

  clickReady = true;

  @bind
  onShow(newState: IObjectAnyType = {}) {
    this.brakeEnable();
    this.setState({ isShow: true }, () => {
      setTimeout(() => {
        this.setState({
          ...newState,
          isVisible: true
        });
      }, 0);
    });
  }

  @bind
  onHide(withoutScrollBrake?: boolean) {
    const { afterHide, beforeHide } = this.props;

    if (this.clickReady) {
      this.clickReady = false;
      this.setState({ isVisible: false });
      // withoutScrollBrake = false;
      if (beforeHide) beforeHide();

      setTimeout(() => {
        if (!withoutScrollBrake) this.brakeDisable();

        this.setState({ isShow: false }, () => {
          this.clickReady = true;
          if (afterHide) afterHide();
        });
      }, 500);
    }
  }

  @bind
  onPressedKey(event: any) {
    const { isShow } = this.state;
    if (isShow && event.key === 'Escape') this.onHide();
  }

  brakeEnable() {
    document.body.classList.add('scroll-brake');
  }

  brakeDisable() {
    document.body.classList.remove('scroll-brake');
  }

  toggleModal() {
    const { isShow } = this.state;

    if (this.clickReady) {
      if (isShow) this.onHide();
      else this.onShow();
    }
  }

  @bind
  getClassName() {
    const { isVisible } = this.state;

    let className = this.classNames.modal;
    if (isVisible) className += ` ${this.classNames.modalVisible}`;

    return className;
  }

  classNames = {
    modal: 'modal',
    modalVisible: 'modal_visible',

    fade: 'modal__fade',

    scrollContainer: 'scroll-container',
    scrollContainerWrapper: 'scroll-container__wrapper',
    scrollContainerWrapperContent: 'scroll-container__wrapper-content content',

    crossContainer: 'cross-container',
    crossContainerWrapper: 'cross-container__wrapper',
    crossContainerWrapperContent: 'cross-container__wrapper-content'
  };

  @bind
  renderCross() {
    return (
      <img
        className={this.classNames.crossContainer}
        src={close}
        onClick={() => this.onHide()}
      />
    );
  }

  @bind
  renderModal() {
    const { children, name } = this.props;

    return (
      <div
        className={this.getClassName()}
        data-modal={name}
      >
        <div className={this.classNames.scrollContainer}>
          <div className={this.classNames.scrollContainerWrapper}>
            <div className={this.classNames.scrollContainerWrapperContent}>
              {this.renderCross()}
              {children || null}
            </div>
            <div
              className={this.classNames.fade}
              onClick={() => this.onHide()}
            />
          </div>
        </div>
      </div>
    );
  }

  render() {
    if (!this.state.isShow) return null;
    return ReactDOM.createPortal(this.renderModal(), document.body);
  };
}

export default Modal;
