import React, { useEffect, useState } from 'react';
import { Props, State } from './interface';
import withApollo from '../../../lib/HOC/withApollo';

import Page from '../../../components/Page';
import Wrapper from '../../../components/Wrapper';
import Typography from '../../../components/Typography';
import SectionTitle from '../../../components/SectionTitle';
import getTypographyProps from '../../../lib/getTypographyProps';

import Documents from '../../../gql/actions/Documents';

import Search from '../../../components/Search';
import TabSlider from '../../../components/TabSlider';
import Service from '../../../components/Service';
import DocumentView from './DocumentView/DocumentView';
import './DocumentsPage.scss';
import MetaTags from '../../../components/MetaTags';

const classNames = {
  preload: 'page_preload',
  title: 'page__title',
  tabs: 'page__tabs',
  sidebar: 'page__sidebar',
  navigation: 'page__sidebar-navigation',
  services: 'page__sidebar-services',
  content: 'page__content',
  comments: 'page__comments',
};

const DocumentsPage = (props: Props) => {
  const [initState, setInitState] = useState<State | any>({
    items: [],
    docs: [],
  });
  const [docsLength, setDocsLength] = useState(0);
  useEffect(() => {
    const getInitialState = (): State => {
      const { documents } = props;
      try {
        setDocsLength(documents.data[0].categories.length);
        return {
          items: documents.data[0].categories || [],
          docs: documents.data[0].categories || [],
        };
      } catch (error) {
        return {
          items: [],
          docs: [],
        };
      }
    };
    setInitState(getInitialState());
  }, [props]);

  const onSubmit = (query: any) => {
    const { documents } = props;
    if (!query) {
      setInitState({ ...initState, docs: documents.data[0].categories });
      return;
    }

    const docs = JSON.parse(JSON.stringify(documents.data[0].categories)).reduce((categories: any, category: any) => {
      const groups = category.groups.reduce((groups: any, group: any) => {
        const documents = group.documents.reduce((documents: any, document: any) => {
          if (document.name.toLowerCase().includes(query.toLowerCase())) {
            documents.push(document);
          }
          return documents;
        }, []);
        if (documents.length) {
          group.documents = documents;
          groups.push(group);
        }
        return groups;
      }, []);
      if (groups.length) {
        category.groups = groups;
        categories.push(category);
      }
      return categories;
    }, []);

    setInitState({ ...initState, docs: docs });
  };

  const renderContent = () => {
    const { docs } = initState;
    return docs.map((a: any, key: number) => (
      <div key={key} className='page__content'>
        {a.groups &&
          a.groups.map((group: any, i: number) => (
            <div className='page__content-group' key={i}>
              <Typography
                {...getTypographyProps('heading', 'xl', 'dark-text', 'medium')}
                children={group.name}
              />
              {group.documents.map((d: any, i: number) => (
                <DocumentView
                  key={i}
                  className='page__document-view'
                  id='id'
                  name={d.name}
                  files={d.files}
                  descripion={d.name}
                />
              ))}
            </div>
          ))}
      </div>
    ));
  };

  const getTabs = () => {
    return initState.docs.map((a: any, key: number) => (
      <div key={key}>{a.name}</div>
    ));
  };

  const renderAside = () => {
    return ['mail', 'transfer', 'photo', 'queue'].map((a: any, i) => (
      <Service
        className='page__service'
        key={`${a} ${i}`}
        isShort
        kind='widget'
        type={a}
      />
    ));
  };

  const setMeta = () => {
    return (
      <MetaTags
        title='Шаблоны заявлений для осужденных и заключенных'
        description='Шаблоны заявлений для осужденных и заключенных'
        canonical={props.match.url}
      />
    );
  };

  const renderPreload = () => {
    return (
      <Page page='documents'>
        <Wrapper className={classNames.preload} isPageWrapper>
          <div className={classNames.title} />
          <div>
            <div className={classNames.sidebar}>
              <div className={classNames.navigation}>
                <div />
                <div />
                <div />
                <div />
                <div />
              </div>
              <div className={classNames.services}>
                <div />
                <div />
                <div />
                <div />
                <div />
              </div>
            </div>
            <div className={classNames.content}>
              <div className='collapse' />
              <div className='collapse' />
              <div className='collapse' />
              <div className='collapse' />
              <div className='collapse' />
              <div className={classNames.comments} />
            </div>
          </div>
        </Wrapper>
      </Page>
    );
  };

  if (props.documents.loading) return renderPreload();

  return (
    <Page page='documents'>
      {setMeta()}
      <Wrapper className='wrapper_withTabs' isPageWrapper>
        <SectionTitle className={classNames.title} title={'Документы'} />
        {docsLength !== 0 && (
          <Search
            placeholder={'Название бланка заявления'}
            required={false}
            onSubmit={onSubmit}
            autoSearch={true}
          />
        )}
        {docsLength !== 0 ? (
          <TabSlider
            tabsTitle={'БЛАНКИ ЗАЯВЛЕНИЙ'}
            className={'page__tabs'}
            content={renderContent()}
            direction='vertical'
            tabs={getTabs()}
            services={renderAside()}
          />
        ) : (
          <Typography
            {...getTypographyProps('text', 'xl', 'light-text', 'regular')}
            children={'Документы отсутствуют'}
          />
        )}
      </Wrapper>
    </Page>
  );
};

function mapQuery(props: Props) {
  return {
    documents: Documents.get(),
  };
}

export default withApollo(mapQuery, undefined)(DocumentsPage);
