import bind from '../../../lib/decorators/bind';
import React, { Component } from 'react';
import { Props, State } from './interface';
import Svg from '../../Svg';
import Typography from '../../Typography';
import getTypographyProps from '../../../lib/getTypographyProps';
import TextareaAutosize from 'react-textarea-autosize';
import {ReactComponent as Send} from '../../../images/comments/send.svg';
import {ReactComponent as Smile} from '../../../images/comments/smile.svg';
import {ReactComponent as NoAvatar} from '../../../images/no-avatar.svg'

import './FormComment.scss';

class FormComment extends Component<Props, State> {
  state: State = {
    componentMount: false,
    isOpenEmojiPicker: false
  };

  componentDidMount() {
    requestAnimationFrame(() => {
      this.setState({ componentMount: true });
    });
  }

  @bind
  onAddEmoji(e: any, emoji: string){
    this.props.onAddEmoji(emoji,this.props.commentInfo)
      this.setState({
        isOpenEmojiPicker: false
      })
  }

renderInitialsLogo (fn: string, ln?: string, avatar?: any){
  if (avatar) {
    return <img alt='avatar' width='40px' height='40px' src={avatar}/>
  }
  else if(fn) {
    const render = fn.charAt(0).toUpperCase() + (ln ? ln.charAt(0).toUpperCase() : "");
    return render;
    }
  else{
    return <NoAvatar className='svg-image logo_user__no-avatar' />;
  }

} 

@bind
emojiPickerToggle(){
  this.setState({
    isOpenEmojiPicker: !this.state.isOpenEmojiPicker
  })
}

  render() {
    const comment_id = this.props.commentInfo && this.props.commentInfo.comment_id;
    this.props.onAddName(this.props.commentInfo)
    const userInfo = this.props.account && this.props.account.userInfo;
    return (
        <form className = "comments__form" onSubmit={(e) => {this.props.commentSubmit(e, this.props.inputContent, comment_id);comment_id && this.props.closeInputComment()}} >
          <div 
            className = "logo_user" 
            data-firstname={userInfo && (userInfo.firstname + " " + userInfo.lastname) } >
            {userInfo 
              ? 
            this.renderInitialsLogo(userInfo.firstname, userInfo.lastname, userInfo.avatar)
              : 
             <NoAvatar className='svg-image logo_user__no-avatar' />}
          </div>
          {/* @ts-ignore */}
          <TextareaAutosize translate='false' disabled={!userInfo} onChange = {(e: any)=>this.props.onAddCommmentFormInput(e,this.props.commentInfo)} onKeyDown={(e: any)=> this.props.onKeypress(e, this.props.commentInfo, comment_id) } value = {this.props.inputContent} className = "comments__textArea" placeholder = "Оставить комментарий"/>
          <button disabled={!userInfo} type='button' onClick={this.emojiPickerToggle} className = "comments__emoji"><i className="svg-image"><Smile /></i></button>
         {this.state.isOpenEmojiPicker && <div className={`emoji_picker`}>
            { this.state.componentMount
              && React.createElement(
                require('../../Emoji-picker/Emoji-picker').default,
                {addEmoji: this.onAddEmoji}
              )
            }
          </div>}
          <button disabled={!userInfo} className = {`comments__send ${!userInfo ? 'hidden_mobile' : ''} `}><i className="svg-image"><Send /></i><span className='send_text'>Отправить</span></button>
          {!userInfo && <Typography
            className={"comments__auth_message"}
            {...getTypographyProps('text', 's', 'text')}
          >
            <Typography
              isLinkElement
              to={'/login'}
              className={"comments__auth_link"}
              {...getTypographyProps('text', 's', 'dark-text')}
              children={"Войдите"}
          />
            {', чтобы коментировать статью'}
          </Typography>}
        </form>
    );
  }
}

export default FormComment;
