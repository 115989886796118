const VideoIcon: React.FC = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='48'
      height='48'
      viewBox='0 0 48 48'
      fill='none'
    >
      <g clip-path='url(#clip0_504_1704)'>
        <path
          d='M24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48Z'
          fill='#9B51E0'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M17.424 14.146C17.433 14.117 19.717 14 22 14C24.284 14 26.567 14.117 26.576 14.146C27.547 14.221 28.323 14.313 28.928 14.405C30.338 14.618 31.425 15.771 31.656 17.332C31.833 18.523 32 20.244 32 22.495C32 24.745 31.833 26.466 31.656 27.657C31.425 29.219 30.339 30.372 28.929 30.585C27.57 30.79 25.36 31 22 31C18.64 31 16.43 30.79 15.072 30.585C13.662 30.372 12.575 29.219 12.344 27.657C12.167 26.467 12 24.745 12 22.495C12 20.245 12.167 18.523 12.344 17.332C12.575 15.771 13.662 14.618 15.072 14.405C15.8526 14.29 16.6371 14.2036 17.424 14.146ZM22 16C18.876 16 16.854 16.197 15.646 16.382C14.939 16.49 14.42 17.052 14.306 17.832C14.15 18.883 14 20.44 14 22.5C14 24.56 14.151 26.116 14.306 27.169C14.42 27.949 14.939 28.51 15.646 28.619C16.854 28.803 18.876 29 22 29C25.124 29 27.146 28.803 28.354 28.618C29.061 28.51 29.58 27.948 29.694 27.168C29.849 26.116 30 24.56 30 22.5C30 20.44 29.849 18.884 29.694 17.831C29.58 17.051 29.061 16.49 28.354 16.381C27.146 16.198 25.123 16 22 16Z'
          fill='white'
        />
        <path
          d='M31 19.2V25.8L37 28V17L31 19.2Z'
          stroke='white'
          stroke-width='2'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M20 30L16 35M24 30L28 35'
          stroke='white'
          stroke-width='2'
          stroke-linecap='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_504_1704'>
          <rect width='48' height='48' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default VideoIcon;
