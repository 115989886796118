import React, { Component, useEffect, useState } from 'react';
import { Props } from './interface';
import './Wrapper.scss';

const Wrapper = (props: Props) => {

  const getClasses = () => {
    let className = 'wrapper';
    if (props.isRow) className += ' wrapper_row';
    if (props.isCenter) className += ' wrapper_center';
    if (props.isPageWrapper) className += ' wrapper_page';
    if (props.className) className += ` ${props.className}`;

    return className;
  }

  return <div className={getClasses()}>{props.children}</div>;
};

export default Wrapper
