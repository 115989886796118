import React, { Component } from 'react';
import { Props, State } from './interface';
import bind from '../../../lib/decorators/bind';
import withApollo from '../../../lib/HOC/withApollo';

import Sticky from 'react-stickynode';
import Page from '../../../components/Page';
import Button from '../../../components/Button';
import Wrapper from '../../../components/Wrapper';
import Service from '../../../components/Service';
import Typography from '../../../components/Typography';
import Notification from '../../../components/Notification';
import getTypographyProps from '../../../lib/getTypographyProps';
import PaymentAgreement from '../../../components/PaymentAgreement';
import Form, { FormElement } from '../../../components/Form';

import Regions from '../../../gql/actions/Regions';
import formatPhone from '../../../lib/formatPhone';
import Institution from '../../../gql/actions/Institution';
import { createTransfer } from '../../../gql/actions/Mutations';
import getLocalStorage from '../../../lib/storage/getLocalStorage';
import { ISelectItem, ICreateTransferBody } from '../../../types/app';

import Validator from '../../../components/Form/Validator';
import CounterEvents from '../../../lib/CounterEvents';
import { parseStringToNumber, parseNumberToString } from '../../../lib/utils';
import './IndexPage.scss';
import { IGlobalState } from '../../../types/redux';
import { selectAccountUserInfo } from '../../../redux/reducers/accountReducer';
import { selectContacts, selectContact } from '../../../redux/reducers/contactReducer';
import { connect } from 'react-redux';
import { store } from '../../../redux/createStore';
import { loadContactsAction, loadContactAction, createContactAction } from '../../../redux/models/ContactModel';
import { getCompanyById, getRegionByCompanyId, getYearFromISOString } from '../../../models/ContactModel';
import { SendingTargetForm } from '../../../components/Sending/SendingTargetForm/SendingTargetForm';
import Relations from '../../../gql/actions/Relations';
import { SendingSenderForm } from '../../../components/Sending/SendingSenderForm/SendingSenderForm';
import { startTransferOrder, successfulTransferOrder, unsuccessfulTransferOrder } from '../../../lib/dataLayerEvents';
import TransferController from '../../../gql/actions/Transfer';
import { debounce } from 'throttle-debounce';
import { END_POINT } from '../../../lib/request/config';
import MetaTags from '../../../components/MetaTags';

function getPassportNumber(userInfo: any) {
  if (userInfo && userInfo.passport) {
    return userInfo.passport.slice(4);
  }
  return '';
}

function getPassportSeries(userInfo: any) {
  if (userInfo && userInfo.passport) {
    return userInfo.passport.slice(0, 4);
  }
  return '';
}

class IndexPage extends Component<Props, State> {
  state: State = {
    activeRegion: undefined,
    activeInstitution: undefined,

    message: '',
    firstName: '',
    middleName: '',
    lastName: '',
    birthDay: '',

    senderSnils:
      getLocalStorage('snils') ||
      (this.props.userInfo && this.props.userInfo.snils) ||
      '',
    senderPhone: getLocalStorage('phone') || '',
    senderEmail: getLocalStorage('email') || '',
    senderLastName:
      getLocalStorage('lastName') ||
      (this.props.userInfo && this.props.userInfo.lastname) ||
      '',
    senderFirstName:
      getLocalStorage('firstName') ||
      (this.props.userInfo && this.props.userInfo.firstname) ||
      '',
    senderMiddleName:
      getLocalStorage('middleName') ||
      (this.props.userInfo && this.props.userInfo.middlename) ||
      '',
    senderPassportNumber: getPassportNumber(this.props.userInfo),
    senderPassportSeries: getPassportSeries(this.props.userInfo),

    activeInput: 'sum',
    activeInputValue: '',

    minSenderPhoneLength: 0,
    isSnilsSend: false,
    isDataSave: false,
    regionError: false,
    institutionError: false,
    costInfo: {
      description: '',
      paymentSum: 0,
      sum: 0,
      tax: 0
    }
  };

  onChangeTransferSumm = debounce(500, () => {
    this.calculateTranferCost();
  });

  componentDidMount() {
    const { contact_id } = this.props
    if (contact_id) {
      store.dispatch(loadContactAction(contact_id as string));
    }
    store.dispatch(loadContactsAction());
  }

  componentWillReceiveProps(
    nextProps: Readonly<Props>,
    nextContext: any
  ): void {
    if (
      nextProps.contact &&
      !nextProps.regions.loading
    ) {
      const region = getRegionByCompanyId(
        nextProps.contact.companies[0].company_id,
        nextProps.regions
      );
      const company = getCompanyById(
        nextProps.contact.companies[0].company_id,
        nextProps.regions
      );
      this.setState({
        firstName: nextProps.contact.firstname,
        lastName: nextProps.contact.lastname,
        middleName: nextProps.contact.middlename,
        birthDay: getYearFromISOString(nextProps.contact.date_of_birth),
        activeRegion: {
          value: String(region.id),
          label: region.name
        },
        activeInstitution: {
          value: String(company.id),
          label: company.shortName
        }
      } as State);
    }
  }

  @bind
  refTotal(ref: any) {
    this.total = ref;
  }

  @bind
  refSum(ref: any) {
    this.sum = ref;
  }

  total: any = null;

  sum: any = null;

  @bind
  refInput(name: string) {
    return (ref: any) => {
      this.inputRefs[name] = ref;
    };
  }

  inputRefs: any = {};

  reverseString(str: string) {
    const splitString = str.split('');
    const reverseArray = splitString.reverse();
    const joinArray = reverseArray.join('');
    return joinArray;
  }

  calculateTranferCost() {

    let order: any;
    if (this.state.activeInstitution && (parseInt(this.state.activeInputValue) > 0)) {
      this.setState({ message: '' })
      order = {
        sum: parseStringToNumber(this.state.activeInputValue),
        company_id: this.state.activeInstitution.value
      }
    } else if (parseInt(this.state.activeInputValue) > 0 
    && this.props.institution
    && this.props.institution.hasOwnProperty('data')
    && this.props.institution.data.length > 0
    ) {
      order = {
        sum: parseStringToNumber(this.state.activeInputValue),
        company_id: this.props.institution.data[0].id
      }
    }

    if (order) {
      let query = TransferController.getCost(order)
      fetch(END_POINT, {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify({
          opertionName: 'transferCost',
          variables: {},
          query: query.query.loc.source.body
        })
      }).then(a => a.json())
        .then(response => {
          if (response.data) {
            this.setState({ costInfo: response.data.transferCost })
          } else {
            Notification.show('Возникла проблема при получении данных об оплате')
          }
        }).catch(e => {
          Notification.show('Возникла проблема при запросе данных об оплате')
        })
    }

  }

  @bind
  handleChange(name: string) {
    return (inputName: string, val: string | boolean) => {

      if (
        name === 'activeInputValue' ||
        name === 'senderPassportSeries' ||
        name === 'birthDay' ||
        name === 'senderPassportNumber' ||
        name === 'senderSnils'
      ) {
        if (name === 'activeInputValue') {
          this.onChangeTransferSumm()
          if (typeof val === 'string') {
            if (String(val.indexOf(',')) !== '-1') {
              val = val.substring(0, val.indexOf(',') + 3);
            }
            if (this.state.activeInputValue < val && val.length > 10) return;

            while (val.split(',').length > 2) {
              val = this.reverseString(
                this.reverseString(val).replace(',', '')
              );
            }

            if (val[val.length - 1] !== ',') {
              const str = val.replace(/[^\d,]/g, '').replace(',', '.');
              val = parseNumberToString(Number(str), true);
            }
          }
        } else {
          val = (val as string).replace(/[^\d]/g, '');
        }
      }

      if (name === 'senderPassportSeries' || name === 'birthDay') {
        val = (val as string).slice(0, 4);
      }

      if (name === 'senderPassportNumber') {
        val = (val as string).slice(0, 6);
      }

      if (name === 'senderSnils') {
        val = (val as string).slice(0, 11);
      }

      if (
        [
          'senderLastName',
          'senderFirstName',
          'senderMiddleName',
          'lastName',
          'firstName',
          'middleName'
        ].includes(name)
      ) {
        val = (val as string).replace(/\d/g, '');
      }

      // @ts-ignore
      this.setState({
        [name]: val,
        message: name === 'activeInputValue' ? '' : this.state.message
      });
    };
  }

  @bind
  checkValidateTransferData() {
    const { activeInputValue, activeInput, activeInstitution } = this.state;
    let message = '';

    if (this.props.match.params.hasOwnProperty('id')) {
      if (this.props.institution.data.length == 0) {
        message = 'Выберите регион и учреждение';
      } else {
        if (!activeInputValue) {
          message = 'Введите сумму';
          unsuccessfulTransferOrder()
        } else {
          const amount = parseStringToNumber(activeInputValue);

          const noValidMessage = Validator.transferSum(String(amount));
          message = noValidMessage || '';
        }
      }

    } else {
      if (!activeInstitution) {
        message = 'Выберите регион и учреждение';
      } else {
        if (!activeInputValue) {
          message = 'Введите сумму';
          unsuccessfulTransferOrder()
        } else {
          const amount = parseStringToNumber(activeInputValue);

          const noValidMessage = Validator.transferSum(String(amount));
          message = noValidMessage || '';
        }
      }
    }

    if (message) this.setState({ message });
    return message;
  }

  @bind
  onChangeActiveInput(activeInput: 'sum' | 'total', activeInputValue: string) {
    return () => {
      if (this.state.activeInput !== activeInput) {
        this.setState({ activeInput, activeInputValue, message: '' }, () => {
          try {
            this[activeInput].refInput.current.focus();
          } catch (error) {
            console.log('error');
          }
        });
      }
    };
  }

  @bind
  renderMessage() {
    if (!this.state.message) return null;

    return (
      <Typography
        isDivElement
        style={{ marginBottom: 16, textAlign: 'center' }}
        {...getTypographyProps('text', 'xs', 'red')}
        children={this.state.message}
      />
    );
  }

  renderSideBar() {
    const { activeInputValue } = this.state;
    const totalSumm = this.state.costInfo ? (this.state.costInfo.paymentSum ? this.state.costInfo.paymentSum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") : 0) : 0
    return (
      <div className='fixed-sidebar'>
        <div className={this.classNames.sidebar}>
          <div className={this.classNames.form}>
            <Typography
              isDivElement
              {...getTypographyProps('heading', 'l', 'dark-text', 'medium')}
              children='Денежный перевод'
            />
            <div className='info'>
              <Typography
                {...getTypographyProps('heading', 'xs', 'text')}
                children='Комиссия:'
                className="mb-10"
              />
              <div className='tax-row'>
                <Typography
                  {...getTypographyProps('text', 'xs', 'text', 'medium')}
                  children='1 - 9 999р.'
                />
                <Typography
                  {...getTypographyProps('text', 'xs', 'dark-green', 'medium')}
                  children='5% + 30р'
                />
              </div>
              <div className='tax-row last'>
                <Typography
                  {...getTypographyProps('text', 'xs', 'text', 'medium')}
                  children='10 000р. и больше'
                />
                <Typography
                  {...getTypographyProps('text', 'xs', 'dark-green', 'medium')}
                  children='1.99%'
                />
              </div>
              <Typography
                {...getTypographyProps('text', 'xs', 'text')}
                children='Минимальная сумма перевода - 1 рубль, максимальная - 60 000 рублей'
              />
            </div>
            <div className={this.classNames.formCalc}>
              <div>
                <Typography
                  {...getTypographyProps('text', 's', 'text')}
                  children='Сумма перевода'
                />
                <div>
                  <FormElement
                    isDynamicPlaceholder={false}
                    isSmallElement
                    onBlur={this.checkValidateTransferData}
                    onChange={this.handleChange('activeInputValue')}
                    onRef={this.refSum}
                    placeholder='0'
                    value={activeInputValue}
                  />
                  <Typography
                    {...getTypographyProps('text', 'm', 'text')}
                    children={'\u20BD'}
                  />
                </div>
              </div>
              <div>
                <Typography
                  {...getTypographyProps('text', 's', 'text')}
                  children={'Комиссия ' + (this.state.costInfo ? (this.state.costInfo.description ? this.state.costInfo.description : '') : '')}
                />
                <div>
                  <Typography
                    className='value'
                    {...getTypographyProps('text', 'm', 'text')}
                    children={this.state.costInfo ? (this.state.costInfo.tax ? this.state.costInfo.tax : 0) : 0}
                  />
                  <Typography
                    {...getTypographyProps('text', 'm', 'text')}
                    children={'\u20BD'}
                  />
                </div>
              </div>
              <div>
                <Typography
                  {...getTypographyProps('text', 's', 'text')}
                  children='Сумма платежа'
                />
                <div>
                  <Typography
                    className='value'
                    {...getTypographyProps('text', 'm', 'text')}
                    children={<span>{totalSumm}</span>}
                  />
                  <Typography
                    {...getTypographyProps('text', 'm', 'text')}
                    children={'\u20BD'}
                  />
                </div>
              </div>
            </div>
            {this.renderMessage()}
            <Button isButtonTag isGreen isExternal label='Оплатить' />
            <PaymentAgreement />
          </div>
          <div className={this.classNames.services}>
            <Service isShort type='mail' />
            <Service isShort type='photo' />
          </div>
        </div>
      </div>
    );
  }

  @bind
  toggleSendMode() {
    this.setState(
      {
        senderSnils: '',
        senderPassportNumber: '',
        senderPassportSeries: '',
        isSnilsSend: !this.state.isSnilsSend
      },
      () => {
        if (this.state.isSnilsSend) {
          delete this.inputRefs.senderPassportSeries;
          delete this.inputRefs.senderPassportNumber;
        } else {
          delete this.inputRefs.senderSnils;
        }
      }
    );
  }

  @bind
  onChangeRegion(activeRegion: ISelectItem) {
    const { regions } = this.props;
    startTransferOrder()
    let activeInstitution = undefined;
    const region = regions.data.filter(
      a => String(a.id) === String(activeRegion.value)
    );

    if (region.length) {
      activeInstitution = {
        value: region[0].companies[0].id,
        label: region[0].companies[0].shortName
      };
    }

    if (
      activeRegion.value !==
      (this.state.activeRegion && this.state.activeRegion.value)
    ) {
      this.setState({ activeRegion, activeInstitution });
    }
  }

  @bind
  onChangeInstitution(activeInstitution: ISelectItem) {
    this.setState({ activeInstitution });
  }

  @bind
  phoneChange(name: string, senderPhone: string, data: any) {
    const minSenderPhoneLength = data.format.length;
    this.setState({
      senderPhone,
      minSenderPhoneLength
    });
  }

  updateState = (state: any) => {
    if (state.hasOwnProperty('activeInstitution')) {
      this.onChangeTransferSumm()
    }

    this.setState(state);
  };

  @bind
  renderContent() {
    const {
      firstName,
      middleName,
      lastName,
      birthDay,
      activeRegion,
      activeInstitution,
      regionError,
      institutionError
    } = this.state;

    const { userInfo } = this.props;

    return (
      <div className={this.classNames.content}>
        <SendingTargetForm
          firstName={firstName}
          middleName={middleName}
          lastName={lastName}
          birthDay={birthDay}
          activeRegion={activeRegion}
          activeInstitution={activeInstitution}
          regionError={regionError}
          institutionError={institutionError}
          {...this.props}
          refInput={this.refInput}
          inputRefs={this.inputRefs}
          updateState={this.updateState}
          handleChange={this.handleChange}
          dataLayerName='transfer'
        />
        <div>
          <SendingSenderForm
            mode='transfer'
            {...this.state}
            refInput={this.refInput}
            inputRefs={this.inputRefs}
            updateState={this.updateState}
            handleChange={this.handleChange}
            phoneChange={this.phoneChange}
            toggleSendMode={this.toggleSendMode}
            userInfo={userInfo}
            dataLayerName='transfer'
          />
        </div>
      </div>
    );
  }

  @bind
  preSubmit() {
    CounterEvents.moneyClickButtonPay();
  }

  patchDate = (date_of_birth: string) => {
    let date = new Date();
    date.setFullYear(parseInt(date_of_birth));
    return date.toISOString();
  };

  @bind
  validateSelects() {
    const activeInstitution = this.getActiveInstitution();
    const selectGroup = document.getElementById('select-group');

    if (!activeInstitution) {
      if (selectGroup) selectGroup.scrollIntoView({ block: "center" });
      this.setState({ regionError: true });
      this.setState({ institutionError: true });
      return;
    } else {
      this.setState({ regionError: false });
      this.setState({ institutionError: false });
    }
  }

  createContact = (body: ICreateTransferBody) => {
    const { userInfo } = this.props;

    const { relation, birthDay } = this.state;

    if (!userInfo) {
      return;
    }

    store.dispatch(
      createContactAction(
        {
          contact: {
            date_of_birth: this.patchDate(birthDay),
            relation_id: Number(relation!.value),
            company_id: body.company_id,

            firstname: body.recipient.firstName,
            lastname: body.recipient.lastName,
            middlename: body.recipient.middleName
          }
        },
        () => { },
        (errorMessage: string) => { }
      )
    );
  };

  @bind
  getActiveInstitution() {
    if (
      this.props.match.params.id &&
      this.props.institution &&
      this.props.institution.data &&
      this.props.institution.data.length
    ) {
      return this.props.institution.data[0].id;
    }

    if (this.state.activeInstitution && this.state.activeInstitution.value) {
      return this.state.activeInstitution.value;
    }

    return '';
  }

  @bind
  onSubmit() {
    const activeInstitution = this.getActiveInstitution();

    if (!activeInstitution) {
      return;
    }

    const {
      firstName,
      lastName,
      middleName,
      birthDay,
      activeInput,
      activeInputValue,
      senderFirstName,
      senderEmail,
      senderLastName,
      senderMiddleName,
      senderSnils,
      senderPhone,
      isSnilsSend,
      senderPassportNumber,
      senderPassportSeries
    } = this.state;

    if (this.checkValidateTransferData()) return;

    const amount = parseStringToNumber(activeInputValue)

    const body: ICreateTransferBody = {
      amount,
      recipient: {
        lastName,
        firstName,
        middleName,
        birthDate: Number(birthDay)
      },
      //@ts-ignore
      company_id: activeInstitution,
      sender: {
        email: senderEmail,
        snils: senderSnils,
        lastName: senderLastName,
        firstName: senderFirstName,
        middleName: senderMiddleName,
        phone: formatPhone(senderPhone),
        passport: senderPassportSeries + senderPassportNumber
      }
    };

    if (isSnilsSend) {
      delete body.sender.passport;
    } else {
      delete body.sender.snils;
    }

    createTransfer(body, (response: any) => {
      try {
        const { confirmationUrl } = response.data.createTransferOrder;
        this.saveStorageData();
        successfulTransferOrder()
        const link = document.createElement('a');
        link.href = confirmationUrl;
        link.click();
        link.remove();
      } catch (error) {
        try {
          unsuccessfulTransferOrder()
          Notification.show(response.errors[0].message);
        } catch (err) {
          unsuccessfulTransferOrder()
          console.log(err);
          Notification.show(
            'Произошла ошибка, пожалуйста свяжитесь с поддержкой'
          );
        }
      }
    });
  }

  @bind
  onValidate() {
    const activeInstitution =
      this.props.match.params.id || this.state.activeInstitution;
    this.validateSelects()
    if (!activeInstitution) {
      unsuccessfulTransferOrder()
    }

    return !this.checkValidateTransferData();
  }

  saveStorageData() {
    const {
      senderFirstName,
      senderEmail,
      senderLastName,
      senderMiddleName,
      senderSnils,
      senderPhone
    } = this.state;

    if (this.state.isDataSave) {
      localStorage.setItem('email', senderEmail);
      localStorage.setItem('snils', senderSnils);
      localStorage.setItem('lastName', senderLastName);
      localStorage.setItem('firstName', senderFirstName);
      localStorage.setItem('middleName', senderMiddleName);
      localStorage.setItem('phone', formatPhone(senderPhone));
    }
  }

  classNames = {
    preload: 'page_preload',
    content: 'page__content',
    sidebar: 'page__sidebar',
    form: 'page__sidebar-form',
    services: 'page__sidebar-services',
    formCalc: 'page__sidebar-form-calculate'
  };

  setMeta() {
    const meta: any = {}
    const url = this.props.match.url;
    meta.title = "ФСИН Перевод - отправка электронных денежных переводов осужденным и подследственным."
    meta.description = "С помощью нашего сервиса вы можете отправить денежный перевод и пополнить лицевой счет осужденного."
    if (this.props.institution && this.props.institution.data && this.props.institution.data.length) {
      meta.title = `Отправить электронный перевод осужденному в ${this.props.institution.data[0].fullName}`;
      meta.description = `Пополнение лицевого счета осужденного находящегося в ${this.props.institution.data[0].fullName}`;
    }

    return (
      <MetaTags 
      title={meta.title}
      description={meta.descripion}
      canonical={url}
      />
    )
  }

  render() {

    return (
      <Page page='index'>
        {this.setMeta()}
        <Wrapper isRow>
          <Form
            additionalValidation={this.onValidate}
            id='sticky-container'
            onSubmit={this.onSubmit}
            preSubmit={this.preSubmit}
            refs={this.inputRefs}
            validateSelects={this.validateSelects}
          >
            {this.renderContent()}
            {this.renderSideBar()}
          </Form>
        </Wrapper>
      </Page>
    );
  }
}

function mapQuery(props: Props) {
  const { slug } = props.match.params;

  if (slug) {
    return {
      institution: Institution.getBySlug(slug)
    };
  }

  return {
    regions: Regions.getRegions(props.location.pathname),
    relations: Relations.getRelations()
  };
}


function mapState(state: IGlobalState) {
  return {
    userInfo: selectAccountUserInfo(state),
    contact: selectContact(state),
    contacts: selectContacts(state),
    contact_id: state.contact.contact_id
  }
}

//@ts-ignore
const IndexPageWithRedux = connect(mapState)(IndexPage);

export default withApollo(mapQuery, undefined)(IndexPageWithRedux);
