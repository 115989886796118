/* eslint-disable camelcase */
/* eslint-disable no-mixed-operators */
import { Props } from './interface';
import React, { Component } from 'react';
import bind from '../../../lib/decorators/bind';
import withApollo from '../../../lib/HOC/withApollo';

import Sticky from 'react-stickynode';
import Page from '../../../components/Page';
import Button from '../../../components/Button';
import Wrapper from '../../../components/Wrapper';
import Service from '../../../components/Service';
import DropZone from '../../../components/DropZone';
import Typography from '../../../components/Typography';
import getTypographyProps from '../../../lib/getTypographyProps';
import PaymentAgreement from '../../../components/PaymentAgreement';
import Form, { FormGroup, FormElement } from '../../../components/Form';

import Regions from '../../../gql/actions/Regions';
import Institution from '../../../gql/actions/Institution';
import {
  ISelectItem,
  ICreateMailBody,
  IMapQuery,
  IGetMailCostBody,
} from '../../../types/app';

import Notification from '../../../components/Notification';
import { createMail, createMailPayment } from '../../../gql/actions/Mutations';
import { links } from '../../../lib/getServiceType';
import './IndexPage.scss';

import { debounce } from 'throttle-debounce';
import Mail from '../../../gql/actions/Mail';
import formatPhone from '../../../lib/formatPhone';
import { store } from '../../../redux/createStore';
import { IGlobalMailState } from '../../../types/redux';
import { connect } from 'react-redux';
import { SET_STATE_MAIL_INDEX_PAGE } from '../../../redux/actionsList';
import { SendingTargetForm } from '../../../components/Sending/SendingTargetForm/SendingTargetForm';
import { SendingSenderForm } from '../../../components/Sending/SendingSenderForm/SendingSenderForm';
import Relations from '../../../gql/actions/Relations';
import { selectContacts } from '../../../redux/reducers/contactReducer';
import {
  loadContactsAction,
  createContactAction,
} from '../../../redux/models/ContactModel';
import { selectAccountUserInfo } from '../../../redux/reducers/accountReducer';
import getLocalStorage from '../../../lib/storage/getLocalStorage';
import MetaTags from '../../../components/MetaTags';
import { successfulMailOrder } from '../../../lib/dataLayerEvents';

const STICKY_ID = 'sticky-container';
class IndexPage extends Component<Props> {
  componentDidMount() {
    this.setState({
      senderEmail: getLocalStorage('email') || this.props.senderEmail,
      senderLastName: getLocalStorage('lastName') || this.props.senderLastName,
      senderFirstName:
        getLocalStorage('firstName') || this.props.senderFirstName,
      senderMiddleName:
        getLocalStorage('middleName') || this.props.senderMiddleName,
      senderPhone: getLocalStorage('phone') || this.props.senderPhone,
    });
    this.setState({ blanks: 1 });
    store.dispatch(loadContactsAction());
    this.calculateOrder();
  }

  componentDidUpdate(prevProps: Props) {
    const { activeInstitution, images, blanks, isColoredPhotos } = this.props;

    if (
      activeInstitution !== prevProps.activeInstitution ||
      images !== prevProps.images ||
      isColoredPhotos !== prevProps.isColoredPhotos ||
      blanks !== prevProps.blanks
    ) {
      this.onChangeDataCalculate();
    }
    if(this.props.activeInstitution?.label !== prevProps.activeInstitution?.label){
      this.setState({ isColoredPhotos: this.props.activeInstitution?.can_color_print})
    }
  }

  @bind
  onChangeMailText(a: any, mail: string | boolean) {
    this.setState({ mail: mail as string });
    this.onChangeMailTextCalculate();
  }

  onChangeMailTextCalculate = debounce(1000, () => {
    this.calculateOrder();
  });

  onChangeDataCalculate = debounce(500, () => {
    this.calculateOrder();
  });

  @bind
  calculateOrder() {
    const { slug } = this.props.match.params;
    const { images, isColoredPhotos, blanks, mail } = this.props;

    const activeInstitution = this.getActiveInstitution();
    if (!activeInstitution) return;

    const order: IGetMailCostBody = {
      message: mail,
      answerCount: blanks,
      imagesCount: images.length,
      isColored: isColoredPhotos,
      // @ts-ignore
      companyId: activeInstitution,
    };

    const requests: IMapQuery = () => {
      if (slug) {
        return {
          order: Mail.getCost(order),
          institution: Institution.getBySlug(slug),
        };
      }

      return {
        order: Mail.getCost(order),
        regions: Regions.getRegions(this.props.location.pathname),
      };
    };

    this.props.updateApollo(requests);
  }

  @bind
  handleChange(name: string) {
    return (inputName: string, val: string | boolean) => {
      if (
        name === 'sum' ||
        name === 'senderPassportSeries' ||
        name === 'birthDay' ||
        name === 'senderPassportNumber' ||
        name === 'senderSnils'
      )
        val = (val as string).replace(/[^\d;]/g, '');

      if (name === 'senderPassportSeries' || name === 'birthDay') {
        val = (val as string).slice(0, 4).replace(';', '');
      }

      if (name === 'senderPassportNumber') {
        val = (val as string).slice(0, 6);
      }

      if (name === 'sum') {
        val = (val as string).slice(0, 5);
      }

      if (name === 'senderSnils') {
        val = (val as string).slice(0, 11);
      }

      // @ts-ignore
      const state: State = { [name]: val };

      this.setState(state);
    };
  }

  @bind
  renderSideBar() {
    let sheetsSum = 0;
    let blanksSum = 0;
    let imagesSum = 0;
    let sheets = 0;
    let images = 0;
    let blanks = 0;
    let total = 0;

    if (
      this.props.order &&
      this.props.order.data &&
      this.props.order.data.length
    ) {
      const [data] = this.props.order.data;
      sheetsSum = data.pages.total;
      blanksSum = data.answers.total;
      imagesSum = data.images.total;
      sheets = data.pages.count;
      images = data.images.count;
      blanks = data.answers.count;
      ({ total } = data);
    }

    return (
      <Sticky bottomBoundary={`#${STICKY_ID}`} enabled={true} top={32}>
        <div className={this.classNames.sidebar}>
          <div className={this.classNames.form}>
            <Typography
              isDivElement
              {...getTypographyProps('heading', 'l', 'dark-text', 'medium')}
              children='Итого'
            />
            <div className={this.classNames.formCalc}>
              <div>
                <Typography
                  {...getTypographyProps('text', 's', 'text')}
                  children={
                    <span>
                      <span>{sheets}</span> страниц
                    </span>
                  }
                />
                <div>
                  <Typography
                    {...getTypographyProps('text', 'm', 'dark-text')}
                    children={`${sheetsSum} ₽`}
                  />
                </div>
              </div>
              <div>
                <Typography
                  {...getTypographyProps('text', 's', 'text')}
                  children={
                    <span>
                      <span>{images}</span> фотографий
                    </span>
                  }
                />
                <div>
                  <Typography
                    {...getTypographyProps('text', 'm', 'dark-text')}
                    children={`${imagesSum} ₽`}
                  />
                </div>
              </div>
              <div>
                <Typography
                  {...getTypographyProps('text', 's', 'text')}
                  children={
                    <span>
                      <span>{blanks}</span> бланк ответа
                    </span>
                  }
                />
                <div>
                  <Typography
                    {...getTypographyProps('text', 'm', 'dark-text')}
                    children={`${blanksSum} ₽`}
                  />
                </div>
              </div>
              <div className='total'>
                <Typography
                  {...getTypographyProps('text', 'm', 'dark-text')}
                  children='Итого к оплате:'
                />
                <div>
                  <Typography
                    {...getTypographyProps('text', 'xl', 'main')}
                    children={`${total} ₽`}
                  />
                </div>
              </div>
            </div>
            <Button
              isButtonTag
              isColored
              isLoading={this.props.isLoadingOrder}
              label='Оплатить'
            />
            <PaymentAgreement />
          </div>
          <div className={this.classNames.services}>
            <Service forceLink={links.MONEY_FSIN} isShort type='transfer' />
            <Service isShort type='photo' />
          </div>
        </div>
      </Sticky>
    );
  }

  @bind
  toggleSendMode() {
    this.setState({
      isSnilsSend: !this.props.isSnilsSend,
    });
  }

  @bind
  onChangeBlank(type: 'enlarge' | 'reduce') {
    return () => {
      let { blanks } = this.props;

      if (type === 'enlarge') {
        if (blanks < 3) blanks += 1;
      } else {
        if (blanks > 0) {
          blanks -= 1;
        }
      }

      this.setState({ blanks });
    };
  }

  @bind
  phoneChange(name: string, senderPhone: string, data: any) {
    const minSenderPhoneLength = data.format.length;

    this.setState({
      senderPhone,
      minSenderPhoneLength,
    });
  }

  @bind
  onChangeImages(images: string[]) {
    this.setState({ images });
    this.calculateOrder();
  }

  @bind
  onChangeInstitution(activeInstitution: ISelectItem) {
    this.setState({ activeInstitution });
  }

  @bind
  refInput(name: string) {
    return (ref: any) => {
      this.inputRefs[name] = ref;
    };
  }

  inputRefs: any = {};

  setState(response: any) {
    store.dispatch({
      response,
      type: SET_STATE_MAIL_INDEX_PAGE,
    });
  }

  renderMail() {
    let price = 0;
    let sheets = 0;
    const { mail } = this.props;

    if (
      this.props.order &&
      this.props.order.data &&
      this.props.order.data.length
    ) {
      const [data] = this.props.order.data;
      sheets = data.pages.count;
      ({ price } = data.pages);
    }

    return (
      <div>
        <div className='title-block'>
          <Typography
            {...getTypographyProps('heading', 'l', 'dark-text', 'medium')}
            children='Письмо'
          />
          <span>
            <Typography
              {...getTypographyProps('text', 'l', 'main', 'medium')}
              children={`${price} ₽`}
            />
            <Typography
              {...getTypographyProps('text', 'l', 'text')}
              children=' / стр'
            />
          </span>
        </div>

        <FormElement
          name='textMessage'
          onChange={this.onChangeMailText}
          type='textarea'
          value={mail}
          validations={['required']}
          onRef={this.refInput('textMessage')}
        />

        <div className='counter'>
          <Typography
            {...getTypographyProps('text', 'l', 'text')}
            children={
              <span>
                Символов: <span>{mail.length}</span>
              </span>
            }
          />
          <Typography
            {...getTypographyProps('text', 'l', 'text')}
            children={
              <span>
                Страниц: <span>{sheets}</span>
              </span>
            }
          />
        </div>
      </div>
    );
  }

  renderPhotos() {
    const { isColoredPhotos } = this.props;
    let price = 0;

    if (
      this.props.order &&
      this.props.order.data &&
      this.props.order.data.length
    ) {
      const [data] = this.props.order.data;
      ({ price } = data.images);
    }

    return (
      <div className={isColoredPhotos ? '' : 'grayscale'}>
        <div className='title-block'>
          <Typography
            {...getTypographyProps('heading', 'l', 'dark-text', 'medium')}
            children='Фотография'
          />
          <span>
            <Typography
              {...getTypographyProps('text', 'l', 'main', 'medium')}
              children={`${price} ₽`}
            />
            <Typography
              {...getTypographyProps('text', 'l', 'text')}
              children=' / фото'
            />
          </span>
        </div>

        <DropZone maxImages={3} onChange={this.onChangeImages} />

        {this.props.activeInstitution && this.props.activeInstitution.can_color_print && <FormGroup>
          <FormElement
            label='Цветные фотографии'
            onChange={this.handleChange('isColoredPhotos')}
            type='checkbox'
            value={isColoredPhotos}
          />
        </FormGroup>}
      </div>
    );
  }

  renderBlanks() {
    let price = 0;
    const { blanks } = this.props;

    if (
      this.props.order &&
      this.props.order.data &&
      this.props.order.data.length
    ) {
      const [data] = this.props.order.data;
      ({ price } = data.answers);
    }

    return (
      <div className='blanks-block'>
        <div className='title-block'>
          <Typography
            {...getTypographyProps('heading', 'l', 'dark-text', 'medium')}
            children='Бланк ответа'
          />
          <span>
            <Typography
              {...getTypographyProps('text', 'l', 'main', 'medium')}
              children={`${price} ₽`}
            />
            <Typography
              {...getTypographyProps('text', 'l', 'text')}
              children=' / шт'
            />
          </span>
        </div>

        <div className='counter-block'>
          <Typography
            onClick={this.onChangeBlank('reduce')}
            {...getTypographyProps('text', 'm', 'light-text')}
            children='–'
          />
          <Typography
            {...getTypographyProps(
              'text',
              'm',
              blanks > 0 ? 'dark-text' : 'light-text'
            )}
            children={blanks}
          />
          <Typography
            onClick={this.onChangeBlank('enlarge')}
            {...getTypographyProps('text', 'm', 'light-text')}
            children='+'
          />
        </div>
      </div>
    );
  }

  updateState = (state: any) => { 
    this.setState(state);
  };

  @bind
  renderContent() {
    const { userInfo } = this.props;

    return (
      <div className={this.classNames.content}>
        <SendingTargetForm
          {...this.props}
          refInput={this.refInput}
          inputRefs={this.inputRefs}
          updateState={this.updateState}
          handleChange={this.handleChange}
          dataLayerName='mail'
        />

        <SendingSenderForm
          mode='mail'
          {...this.props}
          refInput={this.refInput}
          inputRefs={this.inputRefs}
          updateState={this.updateState}
          handleChange={this.handleChange}
          phoneChange={this.phoneChange}
          userInfo={userInfo}
          dataLayerName='mail'
        />
        {this.renderMail()}
        {this.renderPhotos()}
        {this.renderBlanks()}
      </div>
    );
  }

  @bind
  getActiveInstitution() {
    if (
      this.props.match.params.id &&
      this.props.institution &&
      this.props.institution.data &&
      this.props.institution.data.length
    ) {
      return this.props.institution.data[0].id;
    }

    if (this.props.activeInstitution && this.props.activeInstitution.value) {
      return this.props.activeInstitution.value;
    }

    return '';
  }

  createContact = (body: ICreateMailBody) => {
    const { relation, userInfo, birthDay } = this.props;

    if (!userInfo) {
      return;
    }

    store.dispatch(
      createContactAction(
        {
          contact: {
            date_of_birth: this.patchDate(birthDay),
            relation_id: Number(relation!.value),
            company_id: body.company_id,
            firstname: body.recipient.firstName,
            lastname: body.recipient.lastName,
            middlename: body.recipient.middleName,
          },
        },
        () => {},
        (errorMessage: string) => {}
      )
    );
  };

  patchDate = (date_of_birth: string) => {
    let date = new Date();
    date.setFullYear(parseInt(date_of_birth));
    return date.toISOString();
  };

  @bind
  validateSelects() {
    const activeInstitution = this.getActiveInstitution();
    const selectGroup = document.getElementById('select-group');

    if (!activeInstitution) {
      if (selectGroup) selectGroup.scrollIntoView();
      this.setState({ regionError: true });
      this.setState({ institutionError: true });
      return;
    } else {
      this.setState({ regionError: false });
      this.setState({ institutionError: false });
    }
  }

  clearEmail(email: string) {
    if (email.includes('@')) {
      var emailChunks = email.split('@');
      return emailChunks[0] + '@' + emailChunks[1].toLowerCase();
    }
    return email;
  }

  @bind
  async onSubmit() {
    const {
      mail,
      blanks,
      senderEmail,
      senderLastName,
      senderFirstName,
      senderMiddleName,
      senderPhone,
      images,
      firstName,
      middleName,
      lastName,
      birthDay,
      isColoredPhotos,
    } = this.props;
    this.setState({ isLoadingOrder: true });

    const activeInstitution = this.getActiveInstitution();

    const body: ICreateMailBody = {
      images: images.map((image) =>
        image.replace(/^data:image\/(png|jpg|jpeg|gif|webp);base64,/, '')
      ),
      text: mail.replace(/[\"\\]/g, ''),
      answerCount: blanks,
      colored: isColoredPhotos,
      //@ts-ignore
      company_id: activeInstitution,
      recipient: {
        lastName,
        firstName,
        middleName,
        birthDate: Number(birthDay),
      },
      sender: {
        phone: senderPhone,
        email: this.clearEmail(senderEmail),
        lastName: senderLastName,
        firstName: senderFirstName,
        middleName: senderMiddleName,
      },
    };

    createMail(body, (response: any) => {
      try {
        const { order_id } = response.data.createMailOrder;
        if (order_id) {
          this.saveStorageData();
          createMailPayment(order_id, (response) => {
            try {
              successfulMailOrder();
              const paymentUrl = response.data.createMailPayment.payment_url;

              const link = document.createElement('a');
              link.href = paymentUrl;
              link.click();
              link.remove();
              this.setState({ isLoadingOrder: false });
            } catch (e) {
              try {
                this.setState({ isLoadingOrder: false });
              } catch (err) {
                this.setState({ isLoadingOrder: false });
                console.log(err);
                Notification.show(
                  'Произошла ошибка, пожалуйста свяжитесь с поддержкой'
                );
              }
            }
          });
          this.createContact(body);
        }
      } catch (error) {
        try {
          Notification.show(response.errors[0].message);
        } catch (err) {
          console.log(err);
          Notification.show(
            'Произошла ошибка, пожалуйста свяжитесь с поддержкой'
          );
        }
      }
    });
  }

  saveStorageData() {
    const {
      senderFirstName,
      senderLastName,
      senderMiddleName,
      senderPhone,
      senderEmail,
    } = this.props;

    if (this.props.isDataSave) {
      localStorage.setItem('email', senderEmail);
      localStorage.setItem('lastName', senderLastName);
      localStorage.setItem('firstName', senderFirstName);
      localStorage.setItem('middleName', senderMiddleName);
      localStorage.setItem('phone', formatPhone(senderPhone));
    }
  }

  classNames = {
    preload: 'page_preload',
    content: 'page__content',
    sidebar: 'page__sidebar',
    form: 'page__sidebar-form',
    services: 'page__sidebar-services',
    formCalc: 'page__sidebar-form-calculate',
  };

  setMeta() {
    let title = 'Фсин почта - написать электронное письмо в сизо и ик';
    let description =
      'Фсин почта - электронные письма в сизо и ик подследственным и заключенным через интернет. Быстрая доставка, возможность ответной переписки и отправки фотографий. Стоимость письма от 30 ₽.';
    if (
      this.props.institution &&
      this.props.institution.data &&
      this.props.institution.data.length
    ) {
      title =
        'Написать электронное письмо в ' +
        this.props.institution.data[0].shortName;
      description =
        'Фсин почта - электронные письма в ' +
        this.props.institution.data[0].shortName +
        ' подследственным и заключенным через интернет. Быстрая доставка, возможность ответной переписки и отправки фотографий. Стоимость письма от 30 ₽.';
    }

    const canonical = this.props.match.url;

    return (
      <MetaTags description={description} title={title} canonical={canonical} />
    );
  }

  render() {
    return (
      <Page page='index-mail'>
        {this.setMeta()}
        <Wrapper isPageWrapper isRow>
          <Form
            id={STICKY_ID}
            onSubmit={this.onSubmit}
            refs={this.inputRefs}
            validateSelects={this.validateSelects}
          >
            {this.renderContent()}
            {this.renderSideBar()}
          </Form>
        </Wrapper>
      </Page>
    );
  }
}

function mapQuery(props: Props) {
  const { slug } = props.match.params;

  if (slug) {
    return {
      institution: Institution.getBySlug(slug),
    };
  }

  return {
    regions: Regions.getRegions(props.location.pathname),
    relations: Relations.getRelations(),
  };
}

function mapState(state: IGlobalMailState) {
  return {
    ...state.mailPageState,
    userInfo: selectAccountUserInfo(state),
    contacts: selectContacts(state),
  };
}

const IndexPageWithRedux = connect(mapState)(IndexPage);

export default withApollo(mapQuery, undefined)(IndexPageWithRedux);
