import React from 'react';
import Typography from '../../Typography';
import getTypographyProps from '../../../lib/getTypographyProps';
import './sending-contact.scss';
import { ReactComponent as Check } from '../../../images/green-check.svg';
import classNames from 'classnames';

export interface Props {
  contactkey?: number;
  firstname: string;
  middlename: string;
  lastname: string;
  className?: string;
  isSelected: boolean;
  onHandleClick?: any;
}

const SendingContact = (props: Props) => {
  const getFullName = () => {
    const { firstname, middlename, lastname } = props;
    return lastname + ' ' + firstname + (middlename ? ' ' + middlename : '');
  };

  const handleClick = () => {
    const { contactkey, onHandleClick, isSelected } = props;
    if (!isSelected && (contactkey || contactkey === 0) && onHandleClick) {
      onHandleClick(contactkey);
    }
  };

  const { className, isSelected } = props;
  return (
    <div className={classNames('sending-contact', className)}>
      <div
        className={classNames(
          'sending-contact__container',
          isSelected ? 'sending-contact__selected' : ''
        )}
        onClick={handleClick}
      >
        {isSelected && <Check />}
        <Typography
          className='sending-contact__full-name'
          {...getTypographyProps(
            'heading',
            'm',
            isSelected ? 'black' : 'text',
            'regular'
          )}
          children={getFullName()}
        />
      </div>
    </div>
  );
};

export { SendingContact };
