import { Props, State } from './interface';
import { IMetaTags } from '../../../types/server';
import SERVICE from '../../../lib/getServiceType';
import withApollo from '../../../lib/HOC/withApollo';
import React, { Component, ReactElement, useEffect, useState } from 'react';

import Typography from '../../../components/Typography';
import getTypographyProps from '../../../lib/getTypographyProps';

import Page from '../../../components/Page';
import Grid from '../../../components/GridList';
import Search from '../../../components/Search';
import Region from '../../../components/Region';
import Button from '../../../components/Button';
import Wrapper from '../../../components/Wrapper';
import Institution from '../../../components/Institution';
import SectionTitle from '../../../components/SectionTitle';

import Regions from '../../../gql/actions/Regions';

import ErrorPage from '../../common/ErrorPage';
import { RegionData } from './interface';
import './InstitutionsPage.scss';
import {
  getPathnameModel,
  MainRoutes,
} from '../../../components/Router/config/routes';
import MetaTags from '../../../components/MetaTags';

const classNames = {
  regions: 'regions',
  regionInfo: 'region-info',
};

const InstitutionsPage = (props: Props) => {

  const nameThunk = (param: any, end: any) => {
    let first;
    let second;
    let third;
    if (param.length === 1) {
      switch (param[0]) {
        case 'Москва':
          return 'Москвы';
        case 'Севастополь':
          return 'Севастополя';
        case 'Санкт-Петербург':
          return 'Санкт-Петербургa';
        default:
          return param.data[0].name;
      }
    } else if (param.length === 2) {
      switch (param[0]) {
        case 'Республика':
          first = param[0].slice(0, -1) + end[2] + end[3];
          return first + param[1];
        // default:
        //  return param.data[0].name;
      }
      switch (param[1]) {
        case 'область':
          first = param[0].slice(0, -2) + end[0] + end[3];
          second = param[1].slice(0, -1) + end[2];
          return first + second;
        case 'край':
          first = param[0].slice(0, -2) + end[1] + end[3];
          second = param[1].slice(0, -1) + end[6];
          return first + second;
        case 'Республика':
          first = param[0].slice(0, -2) + end[0] + end[3];
          second = param[1].slice(0, -1) + end[2];
          return first + second;
      }
    } else if (param.length >= 3 || param.length < 6) {
      switch (param[0]) {
        case 'Республика':
          first = param[0].slice(0, -1) + end[2] + end[3];
          return first + param[1] + end[3] + param[2];
      }
      switch (param[1]) {
        case 'центрального':
          first = param[0].slice(0, -1) + end[4] + end[3];
          second = param[1] + end[3];
          [, , third] = param;
          return first + second + third;
        case 'автономный':
          first = param[0].slice(0, -2) + end[1] + end[3];
          second = param[1].slice(0, -2) + end[1] + end[3];
          third = param[2] + end[5];
          return first + second + third;
        case 'автономная':
          first = param[0].slice(0, -2) + end[0] + end[3];
          second = param[1].slice(0, -2) + end[0] + end[3];
          third = param[2].slice(0, -1) + end[2];
          return first + second + third;
        default:
          return param.data[0].name;
      }
    }
  };


    const getTitle = () => {
      const { region } = props;
  
      if (getRegion()) {
        if (region.loading) return '';
        const textBefore = 'Каталог исправительных учреждений ';
        if (region.data[0] && region.data[0].name) return textBefore + regionGenetiveTransform(region);
      }
      return 'Регионы';
    }

    const getRegion = () => {
      const { region } = props.match.params as { region?: string };
      return region;
    }

    const renderHeader = () => {
      let before = undefined;
      const region = getRegion();
      const title: string = getTitle();
  
      if (region) {
        before = (
          <Button
            className='mobile_hide'
            isTransparent
            label='Регионы'
            to={getPathnameModel(MainRoutes.institutionsPage.path)}
            withArrowBack
          />
        );
      }
  
      return (<SectionTitle
        beforeTitle={before}
        title={title}
      />);
    }

    const regionGenetiveTransform = (param: any) => {
      if (param.data && param.data[0]) {
        const ending = ['ой', 'ого', 'и', ' ', 'ов', 'а', 'я'];
        const parseName = param.data[0].name.split(' ');
        const regionGenetive = nameThunk(parseName, ending);
        return regionGenetive;
      }
    }

    const renderRegions = () => {
      let items: ReactElement[] = [];
      const { regions } = props;
  
      regions.data.sort((a, b) => {
        if (a.name > b.name) {
          return 1;
        }
        if (a.name < b.name) {
          return -1;
        }
        return 0;
      });
  
      if (regions.loading) {
        const loadingElements = new Array(24).fill({ id: '', name: '', count: '' });
        items = loadingElements.map((a: any, i: number) => (<Region
          key={i}
          isPreload
          {...a}
        />));
      } else if (regions.data && regions.data.length) {
        items = regions.data.map((a) => {
          const props = {
            id: a.id,
            slug: a.slug,
            name: a.name,
            count: a.companies.length
          };
  
          return (<Region
            key={a.id}
            {...props}
          />);
        });
      }
  
      return (<div
        children={items}
        className={classNames.regions}
      />);
    }

    const renderInstitutions = () => {
      let items: ReactElement[] = [];
      const region = getRegion();
      const { data, loading } = props.region;
      
      if (region) {
        if (loading) {
          const loadingElements = new Array(10).fill({ id: '', name: '', count: '' });
          items = loadingElements.map((a: any, i: number) => (
            <Institution
              key={i}
              {...a}
              isPreload
              region={data[0]}
              withoutInfo={Boolean(SERVICE)}
            />
          ));
        } else {
          if (data[0] && data[0].companies) {
            items = data[0].companies
              .filter((a) => !a.main)
              .map((a: any, i: number) => (
                <Institution
                  {...a}
                  key={a.id}
                  region={data[0]}
                  withoutInfo={Boolean(SERVICE)}
                />
              ));
          }
        }
      }
  
      return (<Grid
        items={items}
        type='institutions'
      />);
    }

    const renderRegionInfo = () => {
      const region = getRegion();
      const { data, loading } = props.region;
  
      if (region) {
        if (loading) {
          return <div className={`${classNames.regionInfo} preload`} />;
        }
        if (data[0] && data[0].companies) {
          const main = data[0].companies.filter((a) => a.main)[0];
  
          if (main) {
            return (
              <div className={classNames.regionInfo}>
                <Typography
                  {...getTypographyProps('heading', 'l', 'dark-text')}
                  children={main.fullName}
                />
                <table>
                  <tbody>
                    {main.contacts.map((a) => (
                      <tr key={a.type}>
                        <td
                          children={
                            <Typography
                              {...getTypographyProps('text', 's', 'dark-text')}
                              children={a.type}
                            />
                          }
                        />
                        <td
                          children={
                            <Typography
                              {...getTypographyProps('text', 's', 'text')}
                              children={a.number}
                            />
                          }
                        />
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            );
          }
        }
      }
  
      return null;
    }

    const renderContent = () => {
      const region = getRegion();
  
      if (region) {
        return (
          <React.Fragment>
            {renderInstitutions()}
            {renderRegionInfo()}
          </React.Fragment>
        );
      }
  
      return renderRegions();
    }

    const renderPageDescription = () => {
      const className = {
        pageDescription: 'page-description'
      };
      const { region } = props;
  
      const regionName = getRegion();
      let description;
      if (regionName) {
        description = `В разделе вы найдете полный каталог колоний, тюрем и СИЗО ${regionGenetiveTransform(region)}. По всем исправительным учреждениям представлена наиболее полная справочная информация содержащая телефоны и графики работы учреждений ФСИН ${regionGenetiveTransform(region)}`;
      } else {
        description = 'В данном разделе представлен каталог исправительных учреждений Федеральной службы исполнения наказаний Российской Федерации. В справочнике вы можете найти колонии, тюрьмы и следственные изоляторы расположенные в регионах России. Выбрав необходимое учреждение, вы можете ознакомится с доступными в ИС услугами, графиком приема посылок, графиками проведения краткосрочных и долгосрочных свиданий, а так же найти контактные данные руководства учреждения.';
      }
  
      return (
        <div className={className.pageDescription}>
          <Typography
            className='mobile_hide'
            {...getTypographyProps('text', 'm', 'dark-text')}
            children={description}
          />
        </div>
      );
    }

    const setMeta = () => {
      const url = props.match.url;
      const meta: IMetaTags = {
        title: 'Справочник колоний, СИЗО и тюрем России',
        keywords: 'Справочник колоний, СИЗО и тюрем России',
        description: 'Справочная информация по учреждениям: СИЗО, колониям, тюрьмам России ',
        };
      const { region } = props;
    
        if (props.region && !props.region.loading && props.region.data.length !== 0) {
        meta.canonical = url
        meta.status404 = true
        }
    //@ts-ignore
        if (!props.match.params.region) {
          meta.canonical = url
          meta.status404 = true
        }
      
      meta.last_modified = "Sat, 10 Oct 2020 06:59:13 GMT"
      meta.robots = 'follow';
      
    
      const type = props.match.path.substr(1).split('/');
      if (type.length === 2) {
        if(props.region && props.region.data[0] && props.region.data[0].name){
          meta.title = `${props.region.data[0].name}: СИЗО, тюрьмы, УФСИН, колонии, изоляторы`;
        }
          
          meta.description = `Исправительные учреждения ${regionGenetiveTransform(region)}: ✓адреса, ✓карты проезда,  ✓телефоны, ✓почтовые ящики`;
      }
      
    
      return (
        <MetaTags 
        title={meta.title} 
        description={meta.description}
        robots={meta.robots}
        lastModified={meta.last_modified}
        canonical={meta.canonical}
        keywords={meta.keywords}
        />
      );
    }

    const {region} = props

    const regionUrl = getRegion();
    if (regionUrl) {
      if (region && !region.loading && region.data.length === 0) {
            return <ErrorPage/>
      }
    }

  return (
    <Page page='institutions'>
      {setMeta()}
      <Wrapper isPageWrapper>
        {renderHeader()}
        <Search className='mobile_hide' />
        {renderContent()}
        {renderPageDescription()}
      </Wrapper>
    </Page>
  );
};

const mapQuery = (props: Props) => {
  const { region } = props.match.params as { region?: string };

  if (region) {
    return {
      region: Regions.getRegion(region),
      regions: Regions.getRegions(),
    };
  }

  return {
    regions: Regions.getRegions(),
  };
};

export default withApollo(mapQuery, undefined)(InstitutionsPage);
