import { Props } from './interface';
import React from 'react';

import { ReactComponent as Mail } from '../../../images/service/icon/mail.svg';
import { ReactComponent as VideoTalks } from '../../../images/service/icon/videoTalks.svg';
import { ReactComponent as Date } from '../../../images/service/icon/date.svg';
import { ReactComponent as Photo } from '../../../images/service/icon/photo.svg';
import { ReactComponent as Udo } from '../../../images/service/icon/udo.svg';
import { ReactComponent as Transfer } from '../../../images/service/icon/transfer.svg';
import { ReactComponent as Phone } from '../../../images/service/icon/phone.svg';

import './ServiceIcon.scss';

const ServiceIcon = (props: Props) => {
  const getClassName = () => {
    let className = 'service-icon';
    if (props.type) className += ` service-icon_${props.type}`;
    if (props.className) className += ` ${props.className}`;

    return className;
  };

  const getIcon = () => {
    const { type } = props;

    switch (type) {
      case 'mail':
        return Mail;
      case 'transfer':
        return Transfer;
      case 'photo':
        return Photo;
      case 'queue':
        return Date;
      case 'video_talks':
        return VideoTalks;
      case 'phone':
        return Phone;
      default:
        return '';
    }
  };

  const ItemName: any = getIcon();
  return <ItemName className={getClassName() + ' svg-image'} />;
};

export default ServiceIcon;
