import React from 'react';
import { Props } from './interface';
import './GridRow.scss';

const GridRow = (props: Props) => {
  const getClassName = () => {
    let className = 'grid-row';
    if (props.className) className += ` ${props.className}`;

    return className;
  };

  return <div className={getClassName()}>{props.children}</div>;
};

export default GridRow;
