type TService = undefined | 'TRANSFER' | 'MAIL';

const { SERVICE, MODE } = process.env;

const isProduction = MODE === 'production';
const isDevelopment = MODE === 'development' || !MODE;

const links = {
  MAIN_FSIN: isProduction ? '//sizo.ru' : '//dev.ui.sizo.ru',
  MONEY_FSIN: 'https://zt.ru/services/remittance/money-order'
};

const getGoogleTagManagerId = () => {
  switch (SERVICE as TService) {
    case undefined:
      return 'GTM-TSSJVGS';
    case 'TRANSFER':
      return 'GTM-NN4KF3R';
        
    default:
      return undefined;
  }
};

export { getGoogleTagManagerId, isProduction, isDevelopment, links }; 
export default SERVICE as TService;
