import React, { useState } from 'react';
import Button from '../Button';
import Search from '../Search';
import Wrapper from '../Wrapper';
import Typography from '../Typography/Typography';
import getTypographyProps from '../../lib/getTypographyProps';

import { ReactComponent as TransferMain } from '../../images/transfer-main.svg';
import { ReactComponent as QueueMain } from '../../images/queue-main.svg';
import { ReactComponent as MailMain } from '../../images/mail-main.svg';
import { ReactComponent as FotoMain } from '../../images/foto-main.svg';

import { Query } from 'react-apollo';
import Institution from '../../gql/actions/Institution';

import './head.scss';
import { getPathnameModel, MainRoutes } from '../Router/config/routes';

import { IGQLAnswer, IInstitution, IRegion } from '../../types/app';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Modal from '../ModalCustom';
import FotoHeader from '../../images/foto-header.svg';
interface Institution extends IGQLAnswer {
  data: IInstitution[];
}

interface Region extends IGQLAnswer {
  data: IRegion[];
}

export interface Props extends RouteComponentProps {
  className?: string;
  isIndexPage?: boolean;
  institution?: Institution;
  regions?: Region;
}

const Header = (props: Props) => {
  const [isOpenModal, setIsOpenModal] = useState(false);

  const getHeadClassName = () => {
    let className = 'head';
    let path = props.location.pathname.split('/');

    if (
      props.location.pathname === '/perevod' ||
      path[path.length - 2] === 'perevod'
    )
      className += ' head_service_perevod';

    if (path[1] === 'pochta') className += ' head_service_pochta';

    if (props.location.pathname === '/foto' || path[path.length - 3] === 'foto')
      className += ' head_service_foto';

    if (
      path[path.length - 2] !== 'perevod' &&
      path[path.length - 3] !== 'foto' &&
      path[path.length - 2] !== 'pochta'
    )
      className += ` head_service_${
        (path[path.length - 1] || '').toLowerCase() || 'main'
      }`;

    return className;
  };

  const renderMainHead = () => {
    var className = getHeadClassName();
    return (
      <div className={className}>
        <Wrapper>
          <Typography
            {...getTypographyProps('heading', '2xl', 'white')}
            children='Навигатор по колониям и СИЗО России'
          />
          <Typography
            className='mobile_hide'
            {...getTypographyProps('text', 'm', 'white')}
            children='На сайте вы найдете каталог исправительных учреждений с полной справочной информацией, сможете воспользоваться официальными сервисами по отправке писем, фотографий и денежный переводов осужденным и подследственным, а также найдете множество статей, которые будут полезны тем, чей близкий попал в сложную жизненную ситуацию.'
          />
          <Button
            className='mobile_show'
            isRed
            label='Справочник учреждений'
            to={getPathnameModel(MainRoutes.institutionsPage.path)}
          />
          <Search className='mobile_hide' isMenuSearch withExample />
        </Wrapper>
      </div>
    );
  };

  const getQueryData = (isMobile?: boolean) => {
    var path = props.location.pathname.split('/');
    path[path.length - 2] === 'perevod';

    if (path[path.length - 2] !== 'perevod') return null;

    return (
      <Query query={Institution.getBySlug(path[path.length - 1]).query}>
        {({ data }: any) => {
          let fullName = '';
          let shortName = '';
          let regionSlug = 'undefined';

          try {
            if (
              data &&
              data.company &&
              data.company.data &&
              data.company.data.length
            ) {
              const { mapper } = Institution.getBySlug(path[path.length - 1]);
              const info: IInstitution[] = data.company.data.map(mapper);

              [{ shortName, fullName }] = info;
              regionSlug = info[0].region.slug;
            }
          } catch {}

          const pathModel = getPathnameModel(
            MainRoutes.institutionByIdPage.path
          )
            .replace(':region', regionSlug)
            .replace(':id', path[path.length - 1]);
          const to = pathModel;

          return (
            <React.Fragment>
              <Typography
                {...getTypographyProps('heading', '2xl', 'white')}
                children={'Отправка перевода в ' + fullName}
                className={`opacity_full ${isMobile ? 'tablet_S_show' : ''}`}
              />
              <Typography
                className={`opacity_full ${isMobile ? 'tablet_S_show' : ''}`}
                {...getTypographyProps('text', 'l', 'white')}
                children={shortName}
              />
              <Typography
                {...getTypographyProps('text', 'm', 'red', 'medium')}
                className={`opacity_full typography_promo ${
                  isMobile ? 'tablet_S_show' : ''
                }`}
                children='Самая низкая комиссия на денежные переводы'
              />
              <Button
                className={`${isMobile ? 'tablet_S_show' : ''}`}
                isExternal
                isTransparent
                label='Страница учреждения'
                to={to}
              />
            </React.Fragment>
          );
        }}
      </Query>
    );
  };

  const getQueryMailData = (isMobile?: boolean) => {
    var path = props.location.pathname.split('/');
    return (
      <Query query={Institution.getBySlug(path[path.length - 1]).query}>
        {({ data }: any) => {
          let fullName = '';
          let shortName = '';
          let regionSlug = 'undefined';

          try {
            if (
              data &&
              data.company &&
              data.company.data &&
              data.company.data.length
            ) {
              const { mapper } = Institution.getBySlug(path[path.length - 1]);
              const info: IInstitution[] = data.company.data.map(mapper);

              [{ shortName, fullName }] = info;
              regionSlug = info[0].region.slug;
            }
          } catch {}

          const pathModel = getPathnameModel(
            MainRoutes.institutionByIdPage.path
          )
            .replace(':region', regionSlug)
            .replace(':id', path[path.length - 1]);
          const to = pathModel;

          return (
            <React.Fragment>
              <Typography
                {...getTypographyProps('heading', '2xl', 'white')}
                children={'Написать электронное письмо в ' + fullName}
                className={`opacity_full ${isMobile ? 'tablet_S_show' : ''}`}
              />
              <Typography
                className={`opacity_full ${isMobile ? 'tablet_S_show' : ''}`}
                {...getTypographyProps('text', 'l', 'white')}
                children={shortName}
              />
              <Button
                className={`${isMobile ? 'tablet_S_show' : ''}`}
                isExternal
                isTransparent
                label='Страница учреждения'
                to={to}
              />
            </React.Fragment>
          );
        }}
      </Query>
    );
  };

  const renderTransferHead = () => {
    var path = props.location.pathname.split('/');
    const className = getHeadClassName();

    let title = 'Сервис отправки денежных переводов в учреждения России';
    const description =
      'Наш сервис позволяет отправить электронный денежный перевод лицам, отбывающим наказание в исправительных учреждениях ФСИН России';
    if (path.length > 2 && path[path.length - 2] === 'perevod')
      title = 'Отправка перевода';

    return (
      <div className={className}>
        <Wrapper isCenter isRow>
          <div className='tablet_S_hide'>
            {path.length === 2 && (
              <>
                <Typography
                  {...getTypographyProps('heading', '2xl', 'white')}
                  children={title}
                />
                <Typography
                  {...getTypographyProps('text', 'm', 'red', 'medium')}
                  className='typography_promo'
                  children='Самая низкая комиссия на денежные переводы'
                />
              </>
            )}
            {path.length > 2 && path[path.length - 2] === 'perevod' ? (
              getQueryData()
            ) : (
              <Typography
                className='mobile_hide'
                {...getTypographyProps('text', 'm', 'white')}
                children={description}
              />
            )}
          </div>

          {path.length === 2 && (
            <>
              <Typography
                className='tablet_S_show'
                {...getTypographyProps('heading', '2xl', 'white')}
                children={title}
              />
              <Typography
                {...getTypographyProps('text', 'm', 'red', 'medium')}
                className='typography_promo tablet_S_show'
                children='Самая низкая комиссия на денежные переводы'
              />
            </>
          )}
          {path.length > 2 && path[path.length - 2] === 'perevod' ? (
            getQueryData(true)
          ) : (
            <Typography
              className='tablet_S_show'
              {...getTypographyProps('text', 'm', 'white')}
              children={description}
            />
          )}
          <TransferMain />
        </Wrapper>
      </div>
    );
  };

  const renderFotoHead = () => {
    const className = getHeadClassName();
    let title = 'Печать и отправка фотографий';
    const description =
      'Мы распечатаем ваши цифровые фотографии на фотобумаге размером 10х15 см. и доставим их получателю.';
    return (
      <>
        <Modal
          rules
          things
          isOpen={isOpenModal}
          setOpenModal={setIsOpenModal}
        />
        <div className={className}>
          <Wrapper isCenter isRow>
            <div className='tablet_S_hide'>
              <Typography
                {...getTypographyProps('heading', '2xl', 'white')}
                children={title}
              />
              <Typography
                className='mobile_hide'
                {...getTypographyProps('text', 'm', 'white')}
                children={description}
              />
            </div>
            <Typography
              className='tablet_S_show'
              {...getTypographyProps('heading', '2xl', 'white')}
              children={title}
            />
            <Typography
              className='tablet_S_show'
              {...getTypographyProps('text', 'm', 'white')}
              children={description}
            />
            <FotoMain />
          </Wrapper>
        </div>
      </>
    );
  };

  const renderMailHead = () => {
    var path = props.location.pathname.split('/');
    const className = getHeadClassName();

    let title = 'Сервис отправки электронных писем в учреждения России';
    const description =
      'Сервис позволяет отправлять сообщения, фотографии и получать ответы от лиц, находящихся в учреждениях ФСИН России';

    return (
      <div className={className}>
        <Wrapper isCenter isRow>
          <div className='tablet_S_hide'>
            {path.length > 2 && path[path.length - 2] === 'pochta' ? (
              getQueryMailData()
            ) : (
              <>
                <Typography
                  {...getTypographyProps('heading', '2xl', 'white')}
                  children={title}
                />
                <Typography
                  className='mobile_hide'
                  {...getTypographyProps('text', 'm', 'white')}
                  children={description}
                />
              </>
            )}
          </div>
          {path.length > 2 && path[path.length - 2] === 'pochta' ? (
            getQueryMailData(true)
          ) : (
            <>
              <Typography
                className='tablet_S_show'
                {...getTypographyProps('heading', '2xl', 'white')}
                children={title}
              />

              <Typography
                className='tablet_S_show'
                {...getTypographyProps('text', 'm', 'white')}
                children={description}
              />
            </>
          )}

          <svg
            width='381'
            height='280'
            viewBox='0 0 381 280'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <ellipse
              cx='326'
              cy='233'
              rx='55'
              ry='10'
              fill='black'
              fillOpacity='0.05'
            />
            <ellipse
              cx='41'
              cy='225.5'
              rx='41'
              ry='7.5'
              fill='black'
              fillOpacity='0.05'
            />
            <ellipse
              cx='190'
              cy='195'
              rx='110'
              ry='20'
              fill='black'
              fillOpacity='0.05'
            />
            <path
              d='M120 180.5V111.142C120 108.49 121.054 105.946 122.929 104.071L186.464 40.5355C188.417 38.5829 191.583 38.5829 193.536 40.5355L257.071 104.071C258.946 105.946 260 108.49 260 111.142V180.5C260 186.023 255.523 190.5 250 190.5H130C124.477 190.5 120 186.023 120 180.5Z'
              fill='#3D4C99'
            />
            <g filter='url(#filter0_d)'>
              <path
                d='M132 76C132 74.8954 132.895 74 134 74H249C250.105 74 251 74.8954 251 76V161H132V76Z'
                fill='url(#paint0_linear)'
              />
            </g>
            <path
              d='M155 96H225.5'
              stroke='#404080'
              strokeOpacity='0.1'
              strokeWidth='3'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M155 107H225.5'
              stroke='#404080'
              strokeOpacity='0.1'
              strokeWidth='3'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M155 118H204.5'
              stroke='#404080'
              strokeOpacity='0.1'
              strokeWidth='3'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <g filter='url(#filter1_dd)'>
              <path
                d='M120 180.5V111.586C120 109.84 122.081 108.933 123.361 110.121L186.598 168.841C188.516 170.622 191.484 170.622 193.402 168.841L256.639 110.121C257.919 108.933 260 109.84 260 111.586V180.5C260 186.023 255.523 190.5 250 190.5H130C124.477 190.5 120 186.023 120 180.5Z'
                fill='url(#paint1_linear)'
              />
            </g>
            <g filter='url(#filter2_d)'>
              <path
                d='M246.22 190.5H133.78C129.107 190.5 126.988 184.66 130.574 181.663L186.794 134.68C188.65 133.128 191.35 133.128 193.206 134.68L249.426 181.663C253.012 184.66 250.893 190.5 246.22 190.5Z'
                fill='url(#paint2_linear)'
              />
            </g>
            <path
              d='M81 154L80.9653 154.278C80.6995 156.404 78.8919 158 76.749 158H76.5C74.2909 158 72.5 156.209 72.5 154V154V154C72.5 151.791 70.7091 150 68.5 150V150C66.2909 150 64.5 151.791 64.5 154V154V154C64.5 156.209 62.7091 158 60.5 158H60.25C58.1789 158 56.5 156.321 56.5 154.25V154.25V154C56.5 151.791 54.7091 150 52.5 150V150C50.2909 150 48.5 151.791 48.5 154V154.25V154.25C48.5 156.321 46.8211 158 44.75 158H44.251C42.1081 158 40.3005 156.404 40.0347 154.278L40 154'
              stroke='#8095FF'
              strokeOpacity='0.1'
              strokeWidth='3'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M281 52L280.965 52.278C280.699 54.4043 278.892 56 276.749 56H276.5C274.291 56 272.5 54.2091 272.5 52V52V52C272.5 49.7909 270.709 48 268.5 48V48C266.291 48 264.5 49.7909 264.5 52V52V52C264.5 54.2091 262.709 56 260.5 56H260.25C258.179 56 256.5 54.3211 256.5 52.25V52.25V52C256.5 49.7909 254.709 48 252.5 48V48C250.291 48 248.5 49.7909 248.5 52V52.25V52.25C248.5 54.3211 246.821 56 244.75 56H244.251C242.108 56 240.301 54.4043 240.035 52.278L240 52'
              stroke='#8095FF'
              strokeOpacity='0.1'
              strokeWidth='3'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <circle
              cx='91'
              cy='65'
              r='7.5'
              stroke='#8095FF'
              strokeOpacity='0.5'
              strokeWidth='3'
            />
            <circle
              cx='300'
              cy='135'
              r='7.5'
              transform='rotate(-24.6126 300 135)'
              stroke='#8095FF'
              strokeOpacity='0.5'
              strokeWidth='3'
            />
            <circle
              cx='34.5'
              cy='58.5'
              r='5'
              stroke='#8095FF'
              strokeOpacity='0.1'
              strokeWidth='3'
            />
            <circle
              cx='318.5'
              cy='160.5'
              r='5'
              stroke='#8095FF'
              strokeOpacity='0.3'
              strokeWidth='3'
            />
            <circle
              cx='61'
              cy='101'
              r='3.5'
              stroke='#8095FF'
              strokeOpacity='0.3'
              strokeWidth='3'
            />
            <circle
              cx='365'
              cy='149'
              r='3.5'
              stroke='#8095FF'
              strokeOpacity='0.1'
              strokeWidth='3'
            />
            <circle
              cx='331'
              cy='122'
              r='3.5'
              stroke='#8095FF'
              strokeOpacity='0.3'
              strokeWidth='3'
            />
            <defs>
              <filter
                id='filter0_d'
                x='122'
                y='54'
                width='139'
                height='107'
                filterUnits='userSpaceOnUse'
                colorInterpolationFilters='sRGB'
              >
                <feFlood floodOpacity='0' result='BackgroundImageFix' />
                <feColorMatrix
                  in='SourceAlpha'
                  type='matrix'
                  values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                />
                <feOffset dy='-10' />
                <feGaussianBlur stdDeviation='5' />
                <feColorMatrix
                  type='matrix'
                  values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0'
                />
                <feBlend
                  mode='normal'
                  in2='BackgroundImageFix'
                  result='effect1_dropShadow'
                />
                <feBlend
                  mode='normal'
                  in='SourceGraphic'
                  in2='effect1_dropShadow'
                  result='shape'
                />
              </filter>
              <filter
                id='filter1_dd'
                x='100'
                y='89.5825'
                width='180'
                height='130.918'
                filterUnits='userSpaceOnUse'
                colorInterpolationFilters='sRGB'
              >
                <feFlood floodOpacity='0' result='BackgroundImageFix' />
                <feColorMatrix
                  in='SourceAlpha'
                  type='matrix'
                  values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                />
                <feOffset dy='-10' />
                <feGaussianBlur stdDeviation='5' />
                <feColorMatrix
                  type='matrix'
                  values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0'
                />
                <feBlend
                  mode='normal'
                  in2='BackgroundImageFix'
                  result='effect1_dropShadow'
                />
                <feColorMatrix
                  in='SourceAlpha'
                  type='matrix'
                  values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                />
                <feOffset dy='10' />
                <feGaussianBlur stdDeviation='10' />
                <feColorMatrix
                  type='matrix'
                  values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0'
                />
                <feBlend
                  mode='normal'
                  in2='effect1_dropShadow'
                  result='effect2_dropShadow'
                />
                <feBlend
                  mode='normal'
                  in='SourceGraphic'
                  in2='effect2_dropShadow'
                  result='shape'
                />
              </filter>
              <filter
                id='filter2_d'
                x='108.77'
                y='108.516'
                width='162.46'
                height='96.9838'
                filterUnits='userSpaceOnUse'
                colorInterpolationFilters='sRGB'
              >
                <feFlood floodOpacity='0' result='BackgroundImageFix' />
                <feColorMatrix
                  in='SourceAlpha'
                  type='matrix'
                  values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                />
                <feOffset dy='-5' />
                <feGaussianBlur stdDeviation='10' />
                <feColorMatrix
                  type='matrix'
                  values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0'
                />
                <feBlend
                  mode='normal'
                  in2='BackgroundImageFix'
                  result='effect1_dropShadow'
                />
                <feBlend
                  mode='normal'
                  in='SourceGraphic'
                  in2='effect1_dropShadow'
                  result='shape'
                />
              </filter>
              <linearGradient
                id='paint0_linear'
                x1='191.5'
                y1='74'
                x2='191.5'
                y2='161'
                gradientUnits='userSpaceOnUse'
              >
                <stop stopColor='white' />
                <stop offset='1' stopColor='#D8E5FF' />
              </linearGradient>
              <linearGradient
                id='paint1_linear'
                x1='190'
                y1='107'
                x2='190'
                y2='190.5'
                gradientUnits='userSpaceOnUse'
              >
                <stop stopColor='#5E80D4' />
                <stop offset='1' stopColor='#494DB6' />
              </linearGradient>
              <linearGradient
                id='paint2_linear'
                x1='190'
                y1='132'
                x2='190'
                y2='190.5'
                gradientUnits='userSpaceOnUse'
              >
                <stop stopColor='#638BDB' />
                <stop offset='1' stopColor='#4747B3' />
              </linearGradient>
            </defs>
          </svg>
        </Wrapper>
      </div>
    );
  };

  const renderQueueHead = () => {
    const className = getHeadClassName();
    let title =
      'Сервис предварительной записи';
    return (
      <>
        <Modal
          rules
          things
          isOpen={isOpenModal}
          setOpenModal={setIsOpenModal}
        />
        <div className={className}>
          <Wrapper isCenter isRow>
            <div className='tablet_S_hide'>
              <Typography
                {...getTypographyProps('heading', '2xl', 'white')}
                children={title}
              />
              <Button
                isTransparent
                isOutline
                label='Правила свидания и передачи'
                onClick={setIsOpenModal}
              />
            </div>
            <QueueMain className='tablet_S_show radial__gradient' />
            <Typography
              className='tablet_S_show'
              {...getTypographyProps('heading', '2xl', 'white')}
              children={title}
            />
            <Button
              isTransparent
              isOutline
              className='tablet_S_show'
              label='Правила свидания и передачи'
              onClick={setIsOpenModal}
            />
            <QueueMain className='tablet_S_hide radial__gradient' />
          </Wrapper>
        </div>
      </>
    );
  };

  const renderHead = () => {
    var path = props.location.pathname.split('/');

    switch (path[1]) {
      case 'perevod':
        return renderTransferHead();
      case 'foto':
        return renderFotoHead();
      case 'queue':
        return renderQueueHead();
      case 'pochta':
        return renderMailHead();
      case '':
        return renderMainHead();
      default:
        return <div></div>;
    }
  };

  return renderHead();
};

export default withRouter(Header);
