import { Props } from './interface';
import React, { Component } from 'react';
import { IMetaTags } from '../../../types/server';
import withApollo from '../../../lib/HOC/withApollo';

import Page from '../../../components/Page';
import Html from '../../../components/Html';
import Button from '../../../components/Button';
import Wrapper from '../../../components/Wrapper';
import Typography from '../../../components/Typography';
import SectionTitle from '../../../components/SectionTitle';
import getTypographyProps from '../../../lib/getTypographyProps';

import guide from './guide';
import './GuidePage.scss';
import { getPathnameModel, MainRoutes } from '../../../components/Router/config/routes';

class GuidePage extends Component<Props> {
  classNames = {
    preload: 'page_preload',
    content: 'page__content',
  };

  renderHeader() {
    const before = (
      <Button
        isTransparent
        label=''
        to={getPathnameModel(MainRoutes.institutionsPage.path)}
        withArrowBack
      />
    );

    return (<SectionTitle
      beforeTitle={before}
      title='Как написать письмо?'
    />);
  }

  renderContent() {
    const content = guide.map((a, i) => {
      const body = a.content.map((c, l) => (
        <div
          key={`body-${l}`}
          className='step__body'
        >
          <Html
            html={c.html.join('')}
            isSpecialContent
          />
          <div className='step__body-image'>
            <img src={c.image} />
          </div>
        </div>
      ));

      return (
        <div
          key={`step-${i}`}
          className='step'
        >
          <div className='step__head'>
            <div>
              <Typography
                {...getTypographyProps('heading', 'l', 'white')}
                children={i + 1}
              />
            </div>
            <Typography
              {...getTypographyProps('heading', 'l', 'dark-text')}
              children={a.title}
            />
          </div>
          <div
            children={body}
          />
        </div>
      );
    });

    return (<div
      children={content}
      className={this.classNames.content}
    />);
  }

  render() {
    return (
      <Page page='guide-mail'>
        <Wrapper isPageWrapper>
          {this.renderHeader()}
          {this.renderContent()}
        </Wrapper>
      </Page>
    );
  }
}

function mapQuery(props: Props) {
  return {};
}

function setMeta(data: Props): IMetaTags {
  const meta: IMetaTags = {
    title: 'Навигатор по колониям и СИЗО России. Адреса, телефоны, график приема, график передач',
    keywords:
      'письмо фотографии справочник колонии денежный перевод сервисы исправительные учреждения',
    description:
      'Удобные сервисы, помогают получить информацию по учреждениям, отправлять письма и фотографии для заключенных.'
  };
  
  meta.robots = ['index','follow'];

  return meta;
}

export default withApollo(mapQuery, setMeta)(GuidePage);
