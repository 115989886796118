import React, {FC, useEffect, useState} from 'react';
import './sending-target-form.scss';
import Typography from '../../Typography';
import {ISelectItem, IShortInstitution} from '../../../types/app';
import {IRegionIGQL, IRelationIGQL} from '../../../types/redux';
import {FormElement, FormGroup} from '../../Form';
import Button from '../../Button';
import Select from '../../Select';
import getTypographyProps from '../../../lib/getTypographyProps';
import {SendingContact} from '../SendingContact/SendingContact';
import {IContactFieldsType} from '../../../models/types/IContactFieldsType';
import {startFotoOrder, startMailOrder, startTransferOrder,} from '../../../lib/dataLayerEvents';
import {store} from '../../../redux/createStore';
import {setContactAction} from '../../../redux/reducers/contactReducer';

export interface Props {
  relations: IRelationIGQL;
  regions: IRegionIGQL;
  institutionError?: boolean;
  regionError?: boolean;
  match: any;
  contacts?: Array<IContactFieldsType>;
  contact?: IContactFieldsType;

  handleChange: any;
  updateState: any;

  firstName: string;
  middleName: string;
  lastName: string;
  birthDay: string;

  relation?: ISelectItem;
  activeRegion?: ISelectItem;
  activeInstitution?: ISelectItem;
  refInput: any;
  inputRefs: any;
  type?: string;
  dataLayerName: string;
}

const SendingTargetForm: FC<Props> = (props) => {
  const [contactCreation, setContactCreation] = useState(true);
  const [selectedContactIndex, setSelectedContactIndex] = useState<
    number | null
  >(null);

  useEffect(() => {
    const getMailContactsEffect = () => {
      return props.contacts || [];
    };
    const contactsCount = getMailContactsEffect().length;
    if (contactsCount === 1 && props.contacts && props.contacts.length) {
      console.log( props.contacts)
      setSelectedContactIndex(0);
    }

    const { contact } = props;
    if (contact && contactsCount > 1) {
      props.contacts?.forEach((elem, index) => {
        if (elem.contact_id === contact.contact_id) {
          setSelectedContactIndex(index);
        }
      });
    }
  }, [props]);

  const selectExistingContact = () => {
    setContactCreation(false);
    setSelectedContactIndex(null);
  };

  const getMailContacts = () => {
    return props.contacts || [];
  };

  const renderSelects = () => {
    if (props.match.params.id) return null;
    const {
      type,
      activeRegion,
      activeInstitution,
      regionError,
      institutionError,
    } = props;

    let regionsItems: ISelectItem[] = [];
    let institutionItems: ISelectItem[] = [];
    let unique: any = [];

    if (type === 'foto') {
      if (props.regions.data) {
        let regionsItems1 = props.regions.data.map((colony: any) => ({
          value: colony.region.id,
          label: colony.region.name,
          view: colony.view,
        }));

        regionsItems = regionsItems1.filter((region: any) => {
          let isExists = unique.includes(region.value);
          if (isExists === false && region.view) {
            unique.push(region.value);
            return isExists === false;
          }
        });

        regionsItems.sort((a, b) => {
          if (a.label > b.label) {
            return 1;
          }
          if (a.label < b.label) {
            return -1;
          }
          return 0;
        });

        institutionItems = props.regions.data.reduce(
          (acc: any[], colony: any) => {
            if (activeRegion && activeRegion.value) {
              if (colony.region.id === activeRegion.value) {
                acc.push({
                  value: colony.id,
                  label: colony.name,
                });
                return acc;
              }
            }
            return acc;
          },
          []
        );
      }
    } else {
      if (props.regions.data) {
        for (let i = 0; i < props.regions.data.length; i++) {
          const a = props.regions.data[i];
          if (a.companies.length)
            regionsItems.push({
              value: String(a.id),
              label: a.name,
            });
        }

        regionsItems.sort((a, b) => {
          if (a.label > b.label) {
            return 1;
          }
          if (a.label < b.label) {
            return -1;
          }
          return 0;
        });
        let region: any = props.regions.data.filter(
          (a: any) => String(a.id) === (activeRegion && activeRegion.value)
        )[0];
        if (!region) region = { companies: [] };

        institutionItems = region.companies.map((a: IShortInstitution) => ({
          value: a.id,
          label: a.shortName,
          can_color_print: a.can_color_print
        }));
        institutionItems.sort((a, b) => {
          if (a.label > b.label) {
            return 1;
          }
          if (a.label < b.label) {
            return -1;
          }
          return 0;
        });
      }
    }

    const onChangeRegion = (activeRegion: ISelectItem) => {
      props.updateState({ activeRegion });
      // @ts-ignore
      onChangeInstitution(undefined);
    };

    const onChangeInstitution = (activeInstitution: ISelectItem) => {
      props.updateState({ activeInstitution });
    };

    if (document) {
      return (
        <FormGroup id='select-group'>
          <Select
            items={regionsItems}
            onChange={onChangeRegion}
            placeholder='Регион'
            value={activeRegion}
            name='region'
            canShowValidation={activeRegion ? false : regionError}
            errorMessage='Выберите регион'
          />
          <Select
            isDisabled={!activeRegion}
            items={institutionItems}
            onChange={onChangeInstitution}
            placeholder='Учреждение'
            value={activeInstitution || ''}
            name='institution'
            canShowValidation={activeInstitution ? false : institutionError}
            errorMessage='Выберите учреждение'
          />
        </FormGroup>
      );
    }
  };

  const onFocusEvent = () => {
    switch (props.dataLayerName) {
      case 'transfer':
        startTransferOrder();
        break;
      case 'foto':
        startFotoOrder();
        break;
      case 'mail':
        startMailOrder();
        break;
      default:
        break;
    }
  };

  const renderButton = () => {
    if (selectedContactIndex || selectedContactIndex === 0) {
      return (
        <Button
          className='sending-target-form__button'
          isWhite
          onClick={(e) => {
            e.preventDefault();
            setSelectedContactIndex(null);
          }}
          isColored
          label='Изменить'
        />
      );
    } else {
      return (
        <Button
          className='sending-target-form__button'
          isWhite
          onClick={() => {
            setContactCreation(true);
            setSelectedContactIndex(null);
            store.dispatch(setContactAction(null));
            props.updateState({
              activeRegion: null,
              activeInstitution: null,
              lastName: '',
              firstName: '',
              middleName: '',
              birthDay: '',
            });
          }}
          isColored
          label='+ Создать контакт'
        />
      );
    }
  };

  const handleContactClick = (key: any) => {
    setSelectedContactIndex(key);
    const { contacts } = props;
    const contact = contacts![key];

    props.updateState({
      activeInstitution: {
        value: contact.companies[0].company_id,
      },

      lastName: contact.lastname,
      firstName: contact.firstname,
      middleName: contact.middlename,
      birthDate: contact.date_of_birth,
    });
  };

  const renderWithExistingContacts = () => {
    const content = getMailContacts()
      .filter((c: any, i: number) =>
        selectedContactIndex || selectedContactIndex === 0
          ? i === selectedContactIndex
          : true
      )
      .map((c: any, i: number) => {
        return (
          <div className='sending-target-form__existing-contact-wrapper'>
            <SendingContact
              contactkey={i}
              className='sending-target-form__existing-contact'
              firstname={c.firstname}
              middlename={c.middlename}
              lastname={c.lastname}
              onHandleClick={handleContactClick}
              isSelected={
                selectedContactIndex !== null || selectedContactIndex === 0
              }
            />
          </div>
        );
      });
    return (
      <div>
        <Typography
          {...getTypographyProps('heading', 'l', 'dark-text', 'medium')}
          children='Кому'
        />
        <div className='sending-target-form__existing-contacts-container'>
          {content}
          {renderButton()}
        </div>
      </div>
    );
  };

  if (!contactCreation && props.contacts && props.contacts.length > 0) {
    return renderWithExistingContacts();
  }

  let showAttentionText;
  if (props.match.params.id) {
    showAttentionText = props.match.params.id.includes('-komi')
                      && ['-ik-', '-kp-'].some(el => props.match.params.id.includes(el));
  } else {
    const { activeRegion, activeInstitution } = props;
    showAttentionText = activeRegion !== undefined
                      && activeInstitution !== undefined
                      && activeRegion.label.includes('Коми')
                      && ['ИК', 'КП'].some(el => activeInstitution.label.includes(el));
  }

  return (
    <div className='sending-target-form'>
      <Typography
        {...getTypographyProps('heading', 'l', 'dark-text', 'medium')}
        children='Кому'
      />
      {renderSelects()}
      <FormGroup>
        <FormElement
          name='lastName'
          onChange={props.handleChange('lastName')}
          onRef={props.refInput('lastName')}
          placeholder='Фамилия'
          validations={['cyrillic']}
          value={props.lastName}
          onFocus={() => onFocusEvent()}
          data-testid='targetLastName'
          isDynamicPlaceholder={true}
          isDynamic
        />
      </FormGroup>
      <FormGroup>
        <FormElement
          name='firstName'
          onChange={props.handleChange('firstName')}
          onRef={props.refInput('firstName')}
          placeholder='Имя'
          validations={['cyrillic']}
          value={props.firstName}
          onFocus={() => onFocusEvent()}
          data-testid='targetFirstName'
          isDynamicPlaceholder={true}
          isDynamic
        />
      </FormGroup>
      <FormGroup>
        <FormElement
          name='middleName'
          onChange={props.handleChange('middleName')}
          onRef={props.refInput('middleName')}
          placeholder='Отчество'
          validations={['cyrillicNotRequired']}
          value={props.middleName}
          onFocus={() => onFocusEvent()}
          data-testid='targetMiddleName'
          isDynamicPlaceholder={true}
          isDynamic
        />
      </FormGroup>
      <FormGroup>
        <FormElement
          maxLength={4}
          minLength={4}
          onChange={props.handleChange('birthDay')}
          onRef={props.refInput('birthDay')}
          placeholder='Год рождения'
          validations={['birthYear']}
          value={props.birthDay}
          onFocus={() => onFocusEvent()}
          name='birthYear'
          data-testid='targetBirthYear'
          isDynamicPlaceholder={true}
          isDynamic
        />
      </FormGroup>

      {/* @ts-ignore */}
      {contactCreation && props.userInfo && (
        <Button
          className='sending-target-form__select-button'
          isWhite
          onClick={selectExistingContact}
          isColored
          label='Выбрать существующий контакт'
        />
      )}

      {showAttentionText && (
        <Typography
          className='sending-target-form__attention-text'
          type='text'
          size='xs'
          color='gray'
          mark='italic'
        >
          Внимание! Для лиц, находящихся в строгих условиях отбывания наказания, возможен запрет на передачу электронных
          писем. Подробности уточняйте в администрации исправительного учреждения
        </Typography>
      )}
    </div>
  );
};


export { SendingTargetForm };
