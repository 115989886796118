import bind from '../../lib/decorators/bind';
import React, { Component, useEffect, useState } from 'react';
import { Props, State } from './interface';

import { Collapse } from 'react-collapse';

import Typography from '../Typography';
import getTypographyProps from '../../lib/getTypographyProps';

import { ReactComponent as Arrow } from '../../images/arrow-down.svg';

import './Collapse.scss';

const classNames = {
  main: 'collapse',
  title: 'collapse__title',
  content: 'collapse__content',
  isActive: 'collapse_active',
  isDisabled: 'collapse_disabled',
};

const ReactCollapse = (props: Props) => {
  const [isOpened, setIsOpened] = useState(false);
  const [renderedClasses, setRenderedClasses] = useState('');

  useEffect(() => {
    let className = classNames.main;
    if (props.className) className += ` ${props.className}`;
    if (props.isDisabled) className += ` ${classNames.isDisabled}`;
    else if (isOpened) className += ` ${classNames.isActive}`;

    setRenderedClasses(className);
    if(props.isDisabled !== undefined){
        setIsOpened(props.isDisabled)
    }
  }, [isOpened, props.className, props.isDisabled]);

  const toggleCollapse = () => {
    if (props.isDisabled) return;
    setIsOpened(!isOpened);
  };

  const renderTitle = () => {
    const { title, isDisabled } = props;

    const props_add: any = {
      className: classNames.title,
      onClick: toggleCollapse,
    };

    return (
      <div {...props_add}>
        <Typography
          {...getTypographyProps('heading', 'xl', 'dark-text', 'regular')}
          children={title}
        />
        {!isDisabled && <Arrow />}
      </div>
    );
  };

  const renderCollapse = () => {
    const propsCollapse = {
      isOpened: isOpened,
      children: props.children || 'Содержимое отсутствует',
    };
    
    return (
      <div
        children={<Collapse {...propsCollapse} />}
        className={classNames.content}
      />
    );
  };

  return (
    <div className={renderedClasses}>
      {renderTitle()}
      {renderCollapse()}
    </div>
  );
};

export default ReactCollapse;
