import React, { Component, useState } from 'react';
import { Link } from 'react-router-dom';
import getTypographyProps from '../../../../lib/getTypographyProps';
import Typography from '../../../../components/Typography';
import Button from '../../../../components/Button';
import { saveAs } from 'file-saver';
import API from '../../../../lib/request/config';
import classNames from 'classnames';
import { ReactComponent as Download } from '../../../../images/download.svg';
import './document-view.scss';
import { documentDownloadDataLayer } from '../../../../lib/dataLayerEvents';
interface Props {
  descripion: string;
  name: string;
  className?: string;
  id: string;
  files: any[];
}

const DocumentView = (props: Props) => {

  const downloadDocument = (ex: any, id: string) => {
    const { name } = props;
    const url = API + `/documents/download?file_id=${id}`;
    saveAs(url, name + '.' + ex);
    documentDownloadDataLayer();
  };


  const { descripion, className } = props;
  const items = props.files
    .map((item) => [item.ext, item.file_id])
    .map((ex, i) => (
      <div
        className='document-view__service-link'
        onClick={() => downloadDocument(ex[0], ex[1])}
        key={i}
      >
        <Typography
          isExternalLink
          className='doc'
          {...getTypographyProps('text', 'm', 'dark-text')}
        >
          {ex[0]}
          <Download className='svg-image' />
        </Typography>
      </div>
    ));

  return (
    <div className={classNames(className, 'document-view')}>
      {descripion}
      <div className='document-view__popup-container'>{items}</div>
    </div>
  );
};

export default DocumentView;
