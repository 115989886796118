import SERVICE from '../../lib/getServiceType';
import { INavigationLink } from '../../types/app';
import { getPathnameModel, MainRoutes } from '../Router/config/routes';

const getConfig = (location: string): INavigationLink[] => {
  switch (location) {

    default:
      return [
        { label: 'Учреждения', href: getPathnameModel(MainRoutes.institutionsPage.path) },
        { label: 'Документы', href: getPathnameModel(MainRoutes.documentsPage.path) },
        // { label: 'Перевод', href: getPathnameModel(MainRoutes.institutionByIdtransferPage.path) },    
        { label: 'Сервисы', href: getPathnameModel(MainRoutes.servicesPage.path) },
        { label: 'Новости', href: getPathnameModel(MainRoutes.newsPage.path) },
        { label: 'Статьи', href: getPathnameModel(MainRoutes.articlesPage.path) }
        // { label: 'Частые вопросы', href: MainRoutes.supportPage.path },
        // { label: 'О проекте', href: MainRoutes.aboutPage.path }
      ];
  }
};

export {getConfig};
