import React from 'react';
import { Props } from './interface';
import bind from '../../lib/decorators/bind';
import SERVICE from '../../lib/getServiceType';

import Link from '../Link';
import Typography from '../Typography';
import getTypographyProps from '../../lib/getTypographyProps';

import {ReactComponent as Moscow} from '../../images/moscow.svg';
import { Mark, Color } from '../Typography/interface';
import {ReactComponent as SaintPeterburg} from '../../images/saint-petersburg.svg';

import './Region.scss';
import { getPathnameModel, MainRoutes } from '../Router/config/routes';

const hadrcodeCities = ['Санкт-Петербург', 'Москва'];
class Region extends React.Component<Props> {
  getClassName() {
    let className = this.classNames.main;
    if (this.props.className) className += ` ${this.props.className}`;
    if (this.props.isPreload) className += ` ${this.classNames.isPreload}`;
    if (this.props.withoutBold) className += ` ${this.classNames.withoutBold}`;

    return className;
  }

  renderName() {
    const { name } = this.props;

    let Icon = undefined;
    let mark: Mark | undefined = undefined;

    if (name === hadrcodeCities[0] || name === hadrcodeCities[1]) {
      mark = 'medium';

      if (name === hadrcodeCities[1]) Icon = Moscow;
      else Icon = SaintPeterburg;
    }

    return (
      <Typography
        className={this.classNames.name}
        isDivElement
        {...getTypographyProps('text', 'm', 'dark-text', mark)}
      >
        {Icon && <Icon />}
        <div>{name}</div>
      </Typography>
    );
  }

  @bind
  renderPreload() {
    return <div className={this.getClassName()} />;
  }

  classNames = {
    main: 'region',
    name: 'region__name',
    count: 'region__count',
    isPreload: 'region_preload',
    withoutBold: 'region_without-bold'
  };

  getServiceColor(): Color {
    if (SERVICE === 'TRANSFER') return 'green';
    return 'main';
  }

  render() {
    const { count, slug, isPreload } = this.props;
    if (isPreload) return this.renderPreload();

    const to = getPathnameModel(MainRoutes.institutionsByRegionPage.path).replace(':region', slug);

    return (
      <Link
        className={this.getClassName()}
        to={to}
      >
        {this.renderName()}
        <Typography
          {...getTypographyProps('text', 'm', this.getServiceColor())}
          children={count}
          className={this.classNames.count}
        />
      </Link>
    );
  }
}

export default Region;
