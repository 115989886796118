import gql from "graphql-tag";
import { IGQLRequest } from "../../types/app";

interface IRelationsController {
    getRelations: () => IGQLRequest;
}

class Relations {
    static getRelations() {
        return {
            mapper: (a: any) => a,
            query: gql`
                  {
                    userRelations {
                      data{
                        name
                        relation_id
                      }
                    }
                  }`,
        }
    }
}


export default Relations as IRelationsController;
