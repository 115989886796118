import React, { FormEvent, useEffect } from 'react';
import { Props } from './interface';
import './Form.scss';
import { store } from '../../redux/createStore';
import {
  selectFormError,
  setFormErrorAction,
} from '../../redux/reducers/formErrorReducer';
import { useSelector } from 'react-redux';
import { IGlobalCommonState } from '../../types/redux';
import { failRegistration, unsuccessfulAuthorization, unsuccessfulFeedbackForm, unsuccessfulMailOrder, unsuccessfulPasswordRecovery, unsuccessfulPhotoOrder, unsuccessfulTransferOrder } from '../../lib/dataLayerEvents';
import { useLocation } from 'react-router';

const Form = (props: Props) => {

  const location = useLocation()

  function mapState(state: IGlobalCommonState) {
    return {
      isFormError: selectFormError(state),
    };
  }

  const isFormError = useSelector(mapState)

  useEffect(() => {
    const getInputRefEffect = (key: string): any => {
      if (props.refs[key] && props.refs[key].current) {
        return props.refs[key].current;
      }
  
      return props.refs[key];
    };
    store.dispatch(setFormErrorAction(false));
    if(props.refs){
      const inputNames = Object.keys(props.refs);
      if (inputNames) {
        for (let inputName of inputNames) {
          if (props.refs.hasOwnProperty(inputName)) {
            let inputElem = getInputRefEffect(inputName);
            if (inputElem.hasOwnProperty('numberInputRef')) {
              inputElem = inputElem.numberInputRef;
            }
            if(inputElem.hasOwnProperty('dataset'))
            inputElem.dataset.iserror = 'false'
          }
        }
      }
    }
  }, [location, props.refs])

  const getClassName = () => {
    let className = 'form';
    if (props.className) className += ` ${props.className}`;

    return className;
  };

  const getInputRef = (key: string): any => {
    if (props.refs[key] && props.refs[key].current) {
      return props.refs[key].current;
    }

    return props.refs[key];
  };

  const onSubmit = async (event: FormEvent) => {
    event.preventDefault();
    await store.dispatch(setFormErrorAction(true));
    if (props.preSubmit) props.preSubmit();
    if (props.validateSelects) props.validateSelects();
    const inputNames = Object.keys(props.refs);
    let numberOfErrors = 0
    if (inputNames) {
      for (let inputName of inputNames) {
        if (props.refs.hasOwnProperty(inputName)) {
          let inputElem = getInputRef(inputName);
          if (inputElem.hasOwnProperty('numberInputRef')) {
            inputElem = inputElem.numberInputRef;
          }
          if (inputElem.dataset && inputElem.dataset.iserror === 'true') {
            inputElem.focus();
            numberOfErrors++
            break;
          }
        }
      }
    }

    if (numberOfErrors > 0 || props.additionalValidation && !props.additionalValidation()) {
      store.dispatch(setFormErrorAction(true));
      console.log('Validation failed');
      failRegistration();
      unsuccessfulPasswordRecovery();
      unsuccessfulAuthorization();
      unsuccessfulTransferOrder();
      unsuccessfulFeedbackForm();
      unsuccessfulPhotoOrder();
      unsuccessfulMailOrder();
    } else {
      store.dispatch(setFormErrorAction(false));
      console.log('Validation success');
      props.onSubmit();
    }
  };

  let additionalProps = {};
  if (props.id) {
    // @ts-ignore
    additionalProps['id'] = props.id;
  }

  return (
    <form {...additionalProps} className={getClassName()} onSubmit={onSubmit}>
      {props.children}
    </form>
  );
};

export default Form;
