import React from 'react';
import { Props } from './interface';

import Typography from '../Typography';
import getTypographyProps from '../../lib/getTypographyProps';

import './SectionTitle.scss';

const SectionTitle = (props: Props) => {
  const getClassName = () => {
    let className = 'section-title';
    if (props.className) className += ` ${props.className}`;

    return className;
  };

  const {
    type = 'heading',
    size = '2xl',
    mark = 'medium',
    title,
    additional,
    beforeTitle,
  } = props;

  return (
    <div className={getClassName()}>
      <Typography as="div" {...getTypographyProps(type, size, 'dark-text', mark)} >
        {beforeTitle && beforeTitle}
        <Typography as={props.headingTag} {...getTypographyProps(type, size, 'dark-text', mark)} >{title}</Typography>
      </Typography>
      {additional && (
        <div className='section-title__additional'>{additional}</div>
      )}
    </div>
  );
};

export default SectionTitle;
