import request from '../../../lib/request';
import gql from "graphql-tag";
import { ACCOUNT_END_POINT } from '../../../lib/request/config';


export interface IInputContactType {
  date_of_birth: string
  relation_id: number
  company_id: string
  firstname: string
  lastname: string
  middlename?: string
}

export interface IInputVerificationArgumentType {
  id: string
}

export interface IInputCreateContact {
  contact: IInputContactType,
}

const CREATE_CONTACT = (): any => {
  return gql`
mutation contactCreateMutation($input: InputCreateContact) {
  createContact(input: $input) {
    contact {
      contact_id
    }
  }
}
`
};


export function contactCreateMutation(requestData: IInputCreateContact, success: (response: any) => void) {
  const {body} = CREATE_CONTACT().loc.source;
  const operation = 'query';

  const data = JSON.stringify({
    operationName: null,
    variables: {
      input: requestData
    },
    [operation]: body
  });

  return request({
    method: 'POST',
    body: data,
    success
  });
}
