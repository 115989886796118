import React, { Component } from 'react';
import ReactInfiniteScroll from 'react-infinite-scroller';

import bind from '../../lib/decorators/bind';
import { Props } from './interface';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

import './InfiniteScroll.scss';

// InfiniteScroll обновляет location приложения
// Новые запросы происходят внутри родительского компонента в componentDidUpdate

class InfiniteScroll extends Component<Props> {
  shouldComponentUpdate(nextProps: Props) {
    const isChangedItems = Boolean(nextProps.isChangedItems);
    const isChangeChildrenLength = this.props.children.length !== nextProps.children.length;

    return isChangeChildrenLength || isChangedItems;
  }

  @bind
  getValue(key: string, value: string): string {
    if (Array.isArray(value)) {
      return value.map((val) => this.getValue(key, val)).join('&');
    }
    return `${key}=${encodeURIComponent(value)}`;
  }

  @bind
  getQueryParameters(obj: { [key: string]: any }) {
    let res = '';
    const keys = Object.keys(obj);

    for (let j = 0; j < keys.length; j += 1) {
      const i = keys[j];
      if (obj[i] !== undefined) {
        if (res === '') {
          res = this.getValue(i, obj[i]);
        } else {
          res += `&${this.getValue(i, obj[i])}`;
        }
      }
    }

    return res;
  }

  @bind
  getQueryParams() {
    const { search } = this.props.location;
    return queryString.parse(search);
  }

  @bind
  getClassName() {
    const { className } = this.props;
    let cn = 'infinite-scroll';

    if (className) cn += ` ${className}`;
    return cn;
  }

  @bind
  onLoadMore() {
    const page = this.getCurrentPage() + 1;
    const hasMore = page <= this.props.totalPages;
    
    if (hasMore) {
      const params = this.getQueryParams();

      delete params.page;

      let path = `${this.props.location.pathname}?page=${page}`;
      if (Object.keys(params).length) path += `&${this.getQueryParameters(params)}`;

      this.props.history.replace(path);
    }
  }

  @bind
  getCurrentPage() {
    return Number(this.getQueryParams().page || 1);
  }

  render() {
    const { children } = this.props;

    return (
      <ReactInfiniteScroll
        children={children}
        className={this.getClassName()}
        hasMore
        initialLoad={false}
        loadMore={this.onLoadMore}
        pageStart={this.getCurrentPage()}
      />
    );
  }
}

export default withRouter(InfiniteScroll);
