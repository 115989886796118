import bind from '../../lib/decorators/bind';
import React, { Component } from 'react';
import { Props, State } from './interface';
import Moment from 'react-moment';

import Typography from '../Typography';
import getTypographyProps from '../../lib/getTypographyProps';
import Notification from '../../components/Notification';


import {ReactComponent as Down_right} from '../../images/comments/down-right.svg';
import {ReactComponent as Like} from '../../images/comments/like.svg';
import {ReactComponent as NoAvatar} from '../../images/no-avatar.svg'

import FormComment from '../Comments/FormComments/FormComment';

import './Comment.scss';
import { IComments } from './../Comments/interface';
import { IComment } from './../../types/app';

class Comment extends Component<Props, State> {
  state: State = {
    isVisible: false,
    isShow: false,
    isHiddenMitLowRating: false
  };

  @bind
  visibleLowComment(){
    this.setState({
      isHiddenMitLowRating: !this.state.isHiddenMitLowRating
    })
  }

  @bind
    showCommentList(){
      this.setState({
      isVisible: !this.state.isVisible
      })
    }

  @bind
    showAddComment(){
      this.setState({
      isShow: !this.state.isShow
      })
    }

    @bind
    closeInputComment(){
      this.setState({
      isShow: !this.state.isShow,
      isVisible: true
      })
    }

  onChangeRating(id: IComment, e: any){
    if (e.target.classList[0] === 'action__rating') {
      e = e.target;
    } else if (e.target.parentNode.classList[0] === 'action__rating') {
      e = e.target.parentNode;
    } else if (e.target.parentNode.parentNode.classList[0] === 'action__rating') {
      e = e.target.parentNode.parentNode;
    } else if (e.target.parentNode.parentNode.parentNode.classList[0] === 'action__rating') {
      e = e.target.parentNode.parentNode.parentNode;
    }
    this.props.onAddRating(id.comment_id, e.value);
  }

  renderInitialsLogo (fn: string, avatar?: string){
    if (avatar) {
     return <img alt='avatar' width='40px' height='40px' src={avatar}/>
    }
    else if(fn) {
    var fullName = fn.split(" ");
    const render = fullName[0].charAt(0).toUpperCase() + (fullName[1] ? fullName[1].charAt(0).toUpperCase() : "");
    return render;
    }
    else{
      return <NoAvatar className='svg-image logo_user__no-avatar' />;
    }
  } 

  renderBodyComment(commentInfo: IComment, replies: IComments[]){
    commentInfo.content = commentInfo.content.replace(/&lt;\/br&gt;/gm, '</br>')
    const userInfo = this.props.account && this.props.account.userInfo;
return (
  <>
   <Typography
        isHtmlContent
        className="comment__content"
        {...getTypographyProps('text', 'm', 'dark-text')}
        children={commentInfo.content}
      />
    <div className="comment__action action">
      <button  className="action__answer" onClick={()=> userInfo ? this.showAddComment() : Notification.show('Войдите или Зарегистрируйтесь!') } >ответить</button>
      <button disabled={!userInfo} onClick={(e)=> this.onChangeRating(commentInfo, e)} value='Up' className="action__rating like"> <Like  className="action__like_icon" /></button>
      <span className={`comment__rating ${commentInfo.rating > 0 ? 'up' : commentInfo.rating < 0 ? 'down' : ''}`}>{commentInfo && commentInfo.rating}</span>
      <button disabled={!userInfo} onClick={(e)=> this.onChangeRating(commentInfo, e)} value='Down' className="action__rating dislike"> <Like  className="action__dislike_icon rotate" /></button>
    </div>
    <div>
      {this.state.isShow && <FormComment { ...this.props} closeInputComment={this.closeInputComment}  commentInfo={commentInfo}/>}
    </div>
    <ul>
      {this.state.isVisible && this.props.item.Replies && this.props.item.Replies.length && this.props.item.Replies.map((item: IComments, i: number) => {
        return (
          <Comment
            item={item}
            commentSubmit={this.props.commentSubmit}
            className="replies__item"
            key={i}
            onAddName={this.props.onAddName}
            onAddCommmentFormInput={this.props.onAddCommmentFormInput}
            onAddEmoji={this.props.onAddEmoji}
            inputContent={this.props.inputContent}
            account={this.props.account}
            onAddRating={this.props.onAddRating}
            onKeypress={this.props.onKeypress}
          />
        )
      }) }
    </ul>
    {
      this.props.item &&
      replies &&
      replies.length > 0 &&
      <button className="comment__replies_drop"  onClick={() => this.showCommentList() }>
        <Down_right className={`comment__replies_drop_icon ${this.state.isVisible ? 'up' : '' }`}/>
        {replies.length} ответов
      </button>
    }
  </>
)
  }

  render(){
    const commentInfo = this.props.item.Comment || null;
    const replies = this.props.item.Replies;
    return commentInfo && (
      <li className={this.props.className}>
        <div>
          <div className="item__header">
            <div className='logo_user'>{this.renderInitialsLogo(commentInfo.author_name,commentInfo.author_avatar)}</div>
            <Typography
              {...getTypographyProps('text', 'l', 'black', "medium")}
              children={commentInfo.author_name}
            />
            <Moment 
              className='since_creation' 
              locale="ru" 
              fromNow>{commentInfo.created_at}
            </Moment>
          </div>
         { commentInfo.rating > -5  ? this.renderBodyComment(commentInfo,replies)
         :
         <button
         className='item__buttonViewBody'
            onClick={() => this.visibleLowComment()}
          >
          {this.state.isHiddenMitLowRating ? 'Скрыть' : 'Комментарий скрыт'}
          </button>
         }
         {this.state.isHiddenMitLowRating && this.renderBodyComment(commentInfo,replies)}
        </div>
      </li>
  )
}
}

export default Comment;
