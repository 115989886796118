import validator from 'validator';
import { preparePhone } from '../../models/PhoneModel';
import isNaN from 'lodash/isNaN';
import size from 'lodash/size';

export type TValidator =
  | 'cyrillicNotRequired'
  | 'required'
  | 'email'
  | 'birthYear'
  | 'emailOrPhone'
  | 'max'
  | 'min'
  | 'transferSum'
  | 'phone'
  | 'notRequiredEmail'
  | 'password'
  | 'equals'
  | 'year'
  | 'cyrillic'
  | 'noDash';
type IFormValidator = {
  [key in TValidator]: (
    value: string,
    name?: string,
    addOption?: number
  ) => string | false;
};

const inputNames = {
  lastName: 'Фамилию',
  firstName: 'Имя',
  middleName: 'Отчество',
  senderLastName: 'Фамилию',
  senderFirstName: 'Имя',
  senderMiddleName: 'Отчество',
  textMessage: 'сообщение',
  snils: 'снилс',
  passportSeries: 'серию паспорта',
  passportNumber: 'номер паспорта',
  password: 'пароль',
  birthYear: 'дату рождения',
  city: 'город',
  visitorPassportIssuedBy: 'кем выдан паспорт',
  visitorPassportIssuedDate: 'дату выдачи',
  visitorRegistration: 'место регистрации/проживания',
  visitorYearOfBirth: 'год рождения'
};

class Validator {
  public static required(value: string, name: string) {
    // @ts-ignore
    const inputName = inputNames[name];
    if (!value.toString().trim().length) {
      return 'Введите ' + inputName;
    }

    return false;
  }

  public static password(value: string) {
    // const passwordEstimation = zxcvbn(value);
    //!/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/.test(value)
    if (size(value) < 8) {
      return 'Пароль не может быть короче 8 символов и должен содержать цифры, латинские строчные и заглавные буквы';
    }
    return false;
  }

  public static cyrillic(value: string, name: string) {
    // @ts-ignore
    const inputName = inputNames[name];
    if (/[^а-я\-\u0020]/gi.test(value) || !value.toString().trim().length) {
      return `Введите ${inputName} русскими буквами`;
    }
    return false;
  }

  public static cyrillicNotRequired(value: string, name: string) {
    // @ts-ignore
    const inputName = inputNames[name];
    if (/[^а-я\-\u0020]/gi.test(value)) {
      return `Введите ${inputName} русскими буквами`;
    }
    return false;
  }

  public static noDash(value: string) {
    if (/\-$/.test(value)) {
      return 'Дефис запрещен в конце строки';
    }
  }

  public static year(value: string) {
    if (value.toString().trim().length) {
      const trimedValue = value.toString().trim();
      const numberValue = Number(trimedValue);
      if (
        trimedValue.length !== 4 ||
        isNaN(numberValue) ||
        numberValue > new Date().getFullYear() ||
        numberValue < 1900
      ) {
        return 'Некорректный год';
      }
    }

    return false;
  }

  public static equals(value: string, name?: string, equalsTo?: string) {
    if (equalsTo !== value) {
      return 'Пароль не совпадает';
    }

    return false;
  }

  public static email(value: string) {
    if (!validator.isEmail(value) || value.match(/[а-я]+/gi)) {
      return 'Введите правильный e-mail';
    }

    return false;
  }

  public static notRequiredEmail(value: string) {
    if (value.toString().trim().length && !validator.isEmail(value)) {
      return `${value} - неверная почта`;
    }

    return false;
  }

  public static phone(value: string) {
    if (!preparePhone(value)) {
      return `Неверный формат телефона`;
    }

    return false;
  }

  public static emailOrPhone(value: string) {
    if (!validator.isEmail(value) && !validator.isMobilePhone(value)) {
      return `Неверный формат телефона или почты`;
    }

    if (!validator.isEmail(value) && !preparePhone(value)) {
      return `Неверный формат телефона или почты`;
    }

    return false;
  }

  public static birthYear(value: string) {
    const currentYear = new Date().getFullYear();
    if (Number(value) < currentYear - 100 || Number(value) > currentYear - 14) {
      return `Некорректная дата рождения`;
    }

    return false;
  }

  public static transferSum(value: string) {
    const min = 1;
    const max = 60000;

    if (Number(value) < min) {
      return `Минимальная сумма перевода - ${min} рублей`;
    }

    if (Number(value) > max) {
      return `Максимальная сумма перевода - ${max} рублей`;
    }

    return false;
  }

  public static max(value: string, max?: number) {
    if (!max) return false;

    if (value.toString().trim().length > max) {
      return `Максимальное кол-во символов - ${max}`;
    }

    return false;
  }

  public static min(value: string, min?: number) {
    if (!min) return false;

    if (value.toString().trim().length < min) {
      return 'Поле заполнено не полностью';
    }

    return false;
  }
}

export default Validator as IFormValidator;
