import request from '../../../lib/request';
import { ACCOUNT_END_POINT } from '../../../lib/request/config';
import gql from "graphql-tag";

const QUERY = (): any => {
  return gql`
          {
            userInfo {
              user {
                avatar
                city
                email
                phone
                date_of_birth
                firstname
                gender
                inn
                lastname
                middlename
                passport
                snils
                user_id
              }
            }
          }`
};

export function userInfoQuery(success: (response: any) => void) {
  const {body} = QUERY().loc.source;
  const operation = 'query';

  return request({
    method: 'POST',
    body: JSON.stringify({[operation]: body}),
    headers: { 'Content-Type': 'application/json' },
    success,
    endPoint: ACCOUNT_END_POINT
  });
}