import React from 'react';
import './Burger.scss';
export interface Props {
  className?: string;
  isShowMobileMenu: boolean;
  isDarkTheme?: boolean;
  onClick: CallableFunction;
}

export interface State {}

const Burger = (props:Props) => {
  let ready = true;

  const classNames1 = {
    block: 'burger',
    line: 'burger__line',
    isShow: 'burger_show'
  };

  const getClassName = () => {
    let className = classNames1.block;
    if (props.isShowMobileMenu) className += ` ${classNames1.isShow}`;
    if (props.className) className += ` ${props.className}`;

    return className;
  }

  const onClick = () => {
    if (ready) {
      props.onClick()
      onUpdateTimeout();
    }
  }

  const onUpdateTimeout = () => {
    ready = false;
    setTimeout(() => {
      ready = true;
    }, 500);
  }

  const {isDarkTheme} = props;
    return (
      <div
        children={<span className={classNames1.line} />}
        className={`${getClassName()} ${isDarkTheme ? 'burger_dark' : ''}`}
        onClick={() => onClick()}
      />
    );
  
}

export default Burger;
