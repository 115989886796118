import SERVICE from '../../../lib/getServiceType';
import { TMainRouteObject, TTransferRouteObject, TMailRouteObject, TUdoRouteObject, IRoute } from '../../../types/server';

import IndexPage from '../../../pages/main/IndexPage';
import ArticlePage from '../../../pages/main/ArticlePage';
import ArticlesPage from '../../../pages/main/ArticlesPage';
import ServicesPage from '../../../pages/main/ServicesPage';
import InstitutionPage from '../../../pages/main/InstitutionPage';
import LoginPage from '../../../pages/main/Auth/LoginPage';

import ErrorPage from '../../../pages/common/ErrorPage';
import CustomPage from '../../../pages/common/CustomPage';
import SearchPage from '../../../pages/common/SearchPage';
import SupportPage from '../../../pages/common/SupportPage';
import QuestionsPage from '../../../pages/common/QuestionsPage';
import InstitutionsPage from '../../../pages/common/InstitutionsPage';
import IndexTransferPage from '../../../pages/transfer/IndexPage';

import IndexMailPage from '../../../pages/mail/IndexPage';
import GuideMailPage from '../../../pages/mail/GuidePage';
import AboutMailPage from '../../../pages/mail/AboutPage';
import PaymentMailPage from '../../../pages/mail/PaymentPage';
import RegistrationPage from '../../../pages/main/Auth/RegistrationPage/RegistrationPage';
import ForgotPasswordPage from '../../../pages/main/Auth/ForgotPasswordPage/ForgotPasswordPage';
import AccountPage from '../../../pages/main/Account/AccountPage';
import AccountEditPage from '../../../pages/main/Account/AccountEditPage';
import AccountContactPage from '../../../pages/main/Account/AccountContactPage';
import AccountContactsPage from '../../../pages/main/Account/AccountContactsPage';
import AccountCreateContactPage from '../../../pages/main/Account/AccountCreateContactPage';
import AccountTransfersPage from '../../../pages/main/Account/AccountTransfersPage';
import AccountQueuePage from '../../../pages/main/Account/AccountQueuePage';
import AccountEditContactPage from '../../../pages/main/Account/AccountEditContactPage';
import DocumentsPage from '../../../pages/main/DocumentsPage';
import IndexFotoPage from '../../../pages/foto/IndexPage'
import OrdersFotoPage from '../../../pages/foto/OrdersPage'

import IndexQueuePage from '../../../pages/queue/IndexPage';
export const alwaysLoad = () => () => {};
export const errorLoad = () => () => {};

export const getPathnameModel = (path: string | string[]): string =>
  (Array.isArray(path) ? path[0] : path);

// @ts-ignore
export const MainRoutes: TMainRouteObject = {
  indexPage: {
    path: '/',
    component: IndexPage
  },
  articlesPage: {
    path: '/articles',
    component: ArticlesPage
  },
  documentsPage: {
    path: '/documents',
    component: DocumentsPage
  },
  articleByIdPage: {
    path: '/articles/:slug',
    component: ArticlePage
  },
  newsPage: {
    path: '/news',
    component: ArticlesPage
  },
  newsByIdPage: {
    path: '/news/:slug',
    component: ArticlePage
  },
  institutionsPage: {
    path: '/colony',
    component: InstitutionsPage
  },
  institutionsByRegionPage: {
    path:'/colony/:region',
    component: InstitutionsPage
  },
  institutionByIdPage: {
    path: ['/colony/:region/:slug'],
    component: InstitutionPage
  },
  queuePage: {
    path: '/queue',
    component: IndexQueuePage
  },
  fotoPage:{
    path: '/foto',
    component: IndexFotoPage
  },
  fotoOrdersPage: {
    path:'/foto/orders/:order',
    component: OrdersFotoPage,
    redirectCondition: (propsScreen: any) => {
      try {
        const { slug } = propsScreen.region.data[0];
        const slugOrId = propsScreen.match.params.orderId;
        if (slugOrId !== slug) {
          const { search, hash } = propsScreen.location;

          const pathModel = getPathnameModel(MainRoutes.fotoOrdersPage.path);
          const path = pathModel.replace(':order', slug) + hash + search;
  
          return path;
        }

        return undefined;
      } catch (error) {
        console.log('Developer error: redirect foto orders');
        return undefined;
      }
    }
  },
  // loginPage: {
  //   path: '/login',
  //   component: LoginPage
  // },
  // accountPage: {
  //   path: '/account',
  //   component: AccountPage
  // },
  // accountTransfersPage: {
  //   path: '/account/transfers',
  //   component: AccountTransfersPage
  // },
  // accountQueuePage: {
  //   path: '/account/queue',
  //   component: AccountQueuePage
  // },
  // accountEditPage: {
  //   path: '/account/edit',
  //   component: AccountEditPage
  // },
  // accountContactPage: {
  //   path: '/account/contacts/:id',
  //   component: AccountContactPage
  // },
  // accountCreateContactPage: {
  //   path: '/account/contact/create',
  //   component: AccountCreateContactPage
  // },
  // accountEditContactPage: {
  //   path: '/account/contact/edit/:id',
  //   component: AccountEditContactPage
  // },
  // accountContactsPage: {
  //   path: '/account/contacts',
  //   component: AccountContactsPage
  // },
  // forgotPasswordPage: {
  //   path: '/forgot-password',
  //   component: ForgotPasswordPage
  // },
  // registrationPage: {
  //   path: '/registration',
  //   component: RegistrationPage
  // },

  searchPage: {
    path: '/search',
    component: SearchPage
  },
  servicesPage: {
    path: '/services',
    component: ServicesPage
  },
  // questionsPage: {
  //   path: '/questions',
  //   component: QuestionsPage
  // },
  supportPage: {
    path: '/support',
    component: SupportPage
  },
  customPage: {
    path: '/info/:slug',
    component: CustomPage
  },
  errorPage: {
    path: '*',
    component: ErrorPage
  }
};

export const TransferRoutes: TTransferRouteObject = {
  indexPage: {
    path: '/',
    component: IndexTransferPage
  },
  institutionsPage: {
    path: '/colony',
    component: InstitutionsPage
  },
  institutionsByRegionPage: {
    ...MainRoutes.institutionsByRegionPage,
  },
  institutionByIdPage: {
    ...MainRoutes.institutionByIdPage,
    component: IndexTransferPage
  },
  // questionsPage: {
  //   path: '/questions',
  //   component: QuestionsPage
  // },
  supportPage: {
    path: '/support',
    component: SupportPage
  },
  searchPage: {
    path: '/search',
    component: SearchPage
  },
  customPage: {
    path: '/info/:slug',
    component: CustomPage
  },
  errorPage: {
    path: '*',
    component: ErrorPage
  }
};

const MailRoutes: TMailRouteObject = {
  indexPage: {
    path: '/',
    component: IndexMailPage
  },
  institutionsPage: {
    path: '/colony',
    component: InstitutionsPage
  },
  institutionsByRegionPage: {
    ...MainRoutes.institutionsByRegionPage,
  },
  institutionByIdPage: {
    ...MainRoutes.institutionByIdPage,
    component: IndexMailPage
  },
  guidePage: {
    path: '/guide',
    component: GuideMailPage
  },
  paymentPage: {
    path: '/payment/:id',
    component: PaymentMailPage
  },
  searchPage: {
    path: '/search',
    component: SearchPage
  },
  aboutPage: {
    path: '/about',
    component: ErrorPage
  },
  errorPage: {
    path: '*',
    component: ErrorPage
  }
};

const getRoutes = (): { [key in any]: IRoute } => {
  switch (SERVICE) {
    case 'TRANSFER':
      return TransferRoutes as { [key in any]: IRoute };

    case 'MAIL':
      return MailRoutes as { [key in any]: IRoute };

    default:
      return MainRoutes as { [key in any]: IRoute };
  }
};

export default getRoutes();
