import React from 'react';
import withApollo from '../../../lib/HOC/withApollo';

import Page from '../../../components/Page';

import Wrapper from '../../../components/Wrapper';
import SectionTitle from '../../../components/SectionTitle';
import GridRow from '../../../components/GridRow';
import Service from '../../../components/Service/Service';

import './ServicesPage.scss';
import MetaTags from '../../../components/MetaTags';
import { store } from '../../../redux/createStore';

const classNames = {
  title: 'page__title',
  preload: 'page_preload',
  content: 'page__content',
};

const ServicesPage = (props: any) => {
    return (
        <Page page='services'>
          <MetaTags 
          title='Сервисы для колоний и СИЗО России'
          description='Сервисы для колоний и СИЗО России'
          canonical={props.match?.url}
          />
          <Wrapper isPageWrapper>
            <SectionTitle
              className={classNames.title}
              mark='regular'
              title='Cервисы'
            />
            <div className={classNames.content}>
              <GridRow>
                <Service type='mail' />
                <Service
                    type='photo'
                    forceLink={'https://zt.ru/services/photo-fsin/buy-photo?utm_source=sizoru&utm_medium=redirect&utm_campaign=fsinfoto&utm_content=/foto'}
                />
              </GridRow>
              <GridRow>
                <Service
                  type='transfer'
                  withoutImage
                  withSmallVerticalPadding
                />
                <Service
                  type='queue'
                  withoutImage
                  withSmallVerticalPadding
                />
              </GridRow>
            </div>
          </Wrapper>
        </Page>
      );
};

export default withApollo(undefined, undefined)(ServicesPage);
