import gql from 'graphql-tag';
import { mapMailOrder, mapMailCost } from '../mappers';
import { IGQLRequest, IGetMailCostBody } from '../../types/app';

interface IMailController {
  getOrder: (id: number) => IGQLRequest;
  getCost: (data: IGetMailCostBody) => IGQLRequest;
}

class MailController {
  static getOrder(id: number) {
    return {
      mapper: mapMailOrder,
      query: gql`
        {
          mailOrder(id: ${id}) {
            data{
              text
              company {
                address
                fullname
                company_id
                shortname
                services{
                  name
                }
                region {
                  name
                  region_id
                }
              }
              customer {
                surname
                lastname
                email
                phone
                firstname
                surname
              }
              cost{
                total
                price
                images {
                  count
                  price
                  total
                }
                answers{
                  count
                  price
                  total
                } 
                pages {
                  count
                  price
                  total
                }
              }
              prisoner {
                lastname
                firstname
                surname
                bdate
              }
            } 
          }
        }
      `
    };
  }

  static getCost(data: IGetMailCostBody) {
    const { message, answerCount, companyId, isColored, imagesCount } = data;

    return {
      mapper: mapMailCost,
      query: gql`
        {  
          mailCost(text: ${JSON.stringify(message)}, answer_count: ${answerCount}, company_id: "${companyId}", colored: ${isColored}, images_count: ${imagesCount}){
            data{ 
              pages {
                total
                price
                count
              }
              images {
                total
                price
                count
              }
              answers {
                total
                price
                count
              }
              price, 
              total
            }
          }
        }
      `
    };
  }
}

export default MailController as IMailController;
