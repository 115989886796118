import React, { Component } from 'react';
import cn from 'classnames';
import './style.scss';

type ButtonProps = {
  type?: "button" | "submit" | "reset" | undefined;
  text: string;
  theme?: string;
  onClick?: any | undefined;
  disabled?: boolean,
}

class Button extends Component<ButtonProps, {}> {
  render() {
    const {theme, type, text, onClick, disabled} = this.props;
    return (
      <button type={type} disabled={disabled} className={cn('Button', theme && `Button_theme_${theme}` )} onClick={onClick}>
        {text}
      </button>
    );
  }
}

export default Button;