import { initialMailGlobalState } from '../initialState';
import { IReduxAction, IMailOrderPage } from '../../types/redux';
import { SET_STATE_MAIL_INDEX_PAGE, CLEAR_STATE_MAIL_INDEX_PAGE } from '../actionsList';

export default function(
  state: IMailOrderPage = initialMailGlobalState.mailPageState,
  action: IReduxAction
): IMailOrderPage {
  switch (action.type) {
    case SET_STATE_MAIL_INDEX_PAGE: 
      return {
        ...state,
        ...action.response
      };
    
    case CLEAR_STATE_MAIL_INDEX_PAGE: 
      return initialMailGlobalState.mailPageState;
    
    default:
      return state;
  }
}
